//services for getuser
export const getuser = async (Ctechapp, loggedin_by) => {
  try {
    const data = await Ctechapp.functions.getuser(loggedin_by);
    if (data !== undefined) {
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};
//getUserFilter

export const getUserFilter = async (query, project, sort, limit, Ctechapp) => {
  try {
    const data = await Ctechapp.functions.getUserFilter(
      query,
      project,
      sort,
      limit
    );
    if (data !== undefined) {
      return data;
    }
  } catch (error) {
    return error.response.data.message;
  }
};
//services for getUserListByUserID
export const getUserListByUserID = async (Ctechapp, loggedin_by) => {
  try {
    const data = await Ctechapp.functions.getUserListByUserID(loggedin_by);
    if (data !== undefined) {
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};
//services for getUserListByUserID
export const getAdminUserListByUserID = async (Ctechapp, loggedin_by) => {
  try {
    const data = await Ctechapp.functions.getAdminUserListByUserID(loggedin_by);
    if (data !== undefined) {
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};

//services for getimportcontactsbyappname
export const getimportcontactsbyappname = async (
  Appname,
  Ctechapp,
  loggedin_by
) => {
  try {
    const data = await Ctechapp.functions.getimportcontactsbyappname(
      Appname,
      loggedin_by
    );
    if (data !== undefined) {
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};

//services for getuserbyid
export const getuserbyid = async (_id, Ctechapp) => {
  try {
    const data = await Ctechapp.functions.getuserbyid(_id);
    if (data !== undefined) {
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};
//services for getuserbyid
export const getuserbyauthproviderid = async (_id, Ctechapp) => {
  try {
    const data = await Ctechapp.functions.getuserbyauthproviderid(_id);
    if (data !== undefined) {
      return JSON.stringify(data);
    }
  } catch (error) {
    return error;
  }
};
//services for getusersbyuserid
export const getusersbyuserid = async (userid, Ctechapp, loggedin_by) => {
  try {
    const data = await Ctechapp.functions.getusersbyuserid(userid, loggedin_by);
    if (data !== undefined) {
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};

//services for getusersbyemail
export const getusersbyemail = async (email, Ctechapp, loggedin_by) => {
  try {
    const data = await Ctechapp.functions.getusersbyemail(email, loggedin_by);
    if (data !== undefined) {
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};

// //services for insertuser
export const insertUser = async (userdata, Ctechapp, loggedinby) => {
  try {
    const data = await Ctechapp.functions.insertUser(
      JSON.stringify(userdata),
      loggedinby
    );
    return data;
  } catch (error) {
    console.log("error : ", error);
    throw Error(error);
  }
};

// //services for insertuser
// export const insertuser = async (
//   userid,
//   firstname,
//   lastname,
//   email,
//   phone,
//   Ctechapp,
//   loggedinby
// ) => {
//   try {
//     const userdata = {
//       userid: userid,
//       firstname: firstname,
//       lastname: lastname,
//       email: email,
//       phone: phone,
//     };
//     const data = await Ctechapp.functions.insertuser(
//       JSON.stringify(userdata),
//       loggedinby
//     );
//     console.log("status: success");
//     // console.log("data", data);
//     return data;
//   } catch (error) {
//     console.log("error : ", error);
//     throw Error(error);
//   }
// };

//services for insertuser
export const createprocoreimportuser = async (
  procoreuserinfo,
  Ctechapp,
  loggedinby
) => {
  try {
    const data = await Ctechapp.functions.createprocoreimportuser(
      JSON.stringify(procoreuserinfo),
      loggedinby
    );
    console.log("status: success");
    // console.log("data", data);
    return data;
  } catch (error) {
    console.log("error : ", error);
    throw Error(error);
  }
};

//services for updateuserbyemail
export const updateuserbyemail = async (
  userid,
  firstname,
  lastname,
  email,
  phone,
  Ctechapp,
  loggedinby
) => {
  try {
    const userdata = {
      userid: userid,
      firstname: firstname,
      lastname: lastname,
      email: email,
      phone: phone,
    };
    const data = await Ctechapp.functions.updateuserbyemail(
      JSON.stringify(userdata),
      loggedinby
    );
    console.log("status: success");
    // console.log("data", data);
    return data;
  } catch (error) {
    console.log("error : ", error);
    throw Error(error);
  }
};

//services for updateuserbyuserid
export const updateuserbyuserid = async (
  userid,
  firstname,
  lastname,
  email,
  phone,
  Ctechapp,
  loggedinby
) => {
  try {
    const userdata = {
      userid: userid,
      firstname: firstname,
      lastname: lastname,
      email: email,
      phone: phone,
    };
    const data = await Ctechapp.functions.updateuserbyuserid(
      JSON.stringify(userdata),
      loggedinby
    );
    console.log("status: success");
    // console.log("data", data);
    return data;
  } catch (error) {
    console.log("error : ", error);
    throw Error(error);
  }
};

//services for updateuserbyuserid
export const updateuserbyid = async (userdata, Ctechapp, loggedinby) => {
  try {
    const data = await Ctechapp.functions.updateuserbyid(
      JSON.stringify(userdata),
      loggedinby
    );

    return data;
  } catch (error) {
    console.log("error : ", error);
    throw Error(error);
  }
};
//services for updateuserbyuserid
export const updateUserRoleById = async (
  _id,
  firstname,
  lastname,
  email,
  phone,
  role,
  Ctechapp,
  loggedinby
) => {
  try {
    const userdata = {
      _id: _id,
      firstname: firstname,
      lastname: lastname,
      email: email,
      phone: phone,
      role: role,
    };
    const data = await Ctechapp.functions.updateUserRoleById(
      JSON.stringify(userdata),
      loggedinby
    );
    console.log("status: success");
    // console.log("data", data);
    return data;
  } catch (error) {
    console.log("error : ", error);
    throw Error(error);
  }
};
//services for updateuserbyuserid
export const updateImportSignupByID = async (
  userinfo,
  Ctechapp,
  loggedinby
) => {
  try {
    const userdata = {
      _id: userinfo._id,
      firstname: userinfo.firstname,
      lastname: userinfo.lastname,
      email: userinfo.email,
      phone: userinfo.phone,
      userid: userinfo.userid,
      authId: userinfo.authId,
      authId_sub: userinfo.authId_sub,
    };
    const data = await Ctechapp.functions.updateImportSignupByID(
      JSON.stringify(userdata),
      loggedinby
    );
    console.log("updateImportSignupByID status: success");
    // console.log("data", data);
    return data;
  } catch (error) {
    console.log("updateImportSignupByID error : ", error);
    throw Error(error);
  }
};

export const updateuserEmailActivationByID = async (
  _id,
  Ctechapp,
  loggedin_by
) => {
  try {
    const data = await Ctechapp.functions.updateuserEmailActivationByID(
      _id,
      loggedin_by
    );
    if (data !== undefined) {
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};

export const sendResetEmailByID = async (
  emailaddress,
  clientappurl,
  Ctechapp,
  loggedin_by
) => {
  try {
    const data = await Ctechapp.functions.SendResetEmailBySendGrid(
      emailaddress,
      clientappurl,
      loggedin_by
    );
    if (data !== undefined) {
      return data;
    }
  } catch (error) {
    return error.response.data.message;
  }
};

export const ChargebeeUserOnboarding = async (
  chargebeeCheckoutID,
  invitationUrl,
  pathName,
  Ctechapp,
  loggedin_by
) => {
  try {
    const data = await Ctechapp.functions.insertorUpdateUserOnBoarding(
      chargebeeCheckoutID || "",
      invitationUrl || "",
      pathName || "",
      loggedin_by || ""
    );
    if (data !== undefined) {
      return data;
    }
  } catch (error) {
    console.log("ChargebeeUserOnboarding ", error);
  }
};

export const ChargebeeUserManageOnboarding = async (
  emailaddress,
  pathName,
  Ctechapp,
  loggedin_by
) => {
  try {
    const data = await Ctechapp.functions.UpdateUserManageOnBoarding(
      emailaddress || "",
      pathName || "",
      loggedin_by || ""
    );
    if (data !== undefined) {
      return data;
    }
  } catch (error) {
    console.log("ChargebeeUserManageOnboarding ", error);
  }
};

export const ChargebeeUserProjectmasterOnboarding = async (
  chargebeeCheckoutID,
  invitationUrl,
  pathName,
  Ctechapp,
  loggedin_by
) => {
  try {
    const data =
      await Ctechapp.functions.insertorUpdateProjectmasterUserOnBoarding(
        chargebeeCheckoutID || "",
        invitationUrl || "",
        pathName || "",
        loggedin_by || ""
      );
    if (data !== undefined) {
      return data;
    }
  } catch (error) {
    console.log("ChargebeeUserOnboarding ", error);
  }
};

export const ChargebeeUserCheckoutRetrieveID = async (
  chargebeeCheckoutID,
  invitationUrl,
  Ctechapp,
  loggedin_by
) => {
  try {
    const data = await Ctechapp.functions.getchargebeecheckoutpageretrieve_api(
      chargebeeCheckoutID || "",
      invitationUrl || "",
      loggedin_by || ""
    );
    if (data !== undefined) {
      return data;
    }
  } catch (error) {
    console.log("ChargebeeUserCheckoutRetrieveID ", error);
  }
};

export const get_mongodb_values = async (Ctechapp, loggedin_by) => {
  try {
    const data = await Ctechapp.functions.get_mongodb_values(loggedin_by || "");
    if (data !== undefined) {
      return data;
    }
  } catch (error) {
    console.log("get_mongodb_values ", error);
  }
};

export const getUserEmailByToken = async (token, Ctechapp, loggedin_by) => {
  try {
    const data = await Ctechapp.functions.getUserEmailByToken(
      token,
      loggedin_by
    );
    if (data !== undefined) {
      // console.log("users" + JSON.stringify(data));
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};

//services for getchargebeesubscriptionvalidatiobyid
export const getchargebeesubscriptionvalidationbyid = async (
  _id,
  Ctechapp,
  loggedin_by
) => {
  try {
    const data =
      await Ctechapp.functions.getchargebeesubscriptionvalidationbyid(
        _id,
        loggedin_by
      );
    if (data !== undefined) {
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};
//services for deleteusersByID
export const deleteusersByID = async (_id, Ctechapp, loggedin_by) => {
  try {
    const data = await Ctechapp.functions.deleteusersByID(_id, loggedin_by);
    if (data !== undefined) {
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};

export const getChargebeeSubscriptionStatus = async (email, Ctechapp) => {
  try {
    const data = await Ctechapp.functions.getChargebeeSubscriptionStatus(
      email,
      Ctechapp.id || ""
    );
    if (data !== undefined) {
      return data;
    }
  } catch (error) {
    return error.response.data.message;
  }
};
