import React, { useCallback, useEffect, useState } from "react";
import MainNav from "./main-nav";
import AuthNav from "./auth-nav";
import { useAuth0 } from "@auth0/auth0-react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { useRealmApp } from "../providers/RealmApp";
import { getuserbyauthproviderid } from "../services/users_services";
import { getcompaniesbyid } from "../services/companies_services";
import { useLocation } from "react-router-dom";

const NavBar = () => {
  const ctechapp = useRealmApp();
  const { isAuthenticated, user } = useAuth0();
  const location = useLocation();
  const [companyLogo, setCompanyLogo] = useState(null); // null means no logo fetched yet
  const [companyName, setCompanyName] = useState("");
  const [loading, setLoading] = useState(true); // New state to manage loading

  useEffect(() => {
    if (isAuthenticated) getUserInfoByID();
    else setLoading(false);
  }, [isAuthenticated]);

  const getUserInfoByID = async () => {
    setLoading(true); // Start loading
    try {
      console.log("  user.sub,", user.sub);
      const response = await getuserbyauthproviderid(
        user.sub,
        ctechapp.currentUser
      );
      let user_info = JSON.parse(response);
      if (user_info && user_info.length > 0) {
        let _user_info = user_info[0];
        await loadCompaniesByID(_user_info?._p_companyId);
      }
    } catch (exception) {
      console.error(exception);
      setLoading(false); // End loading
    } finally {
      setLoading(false); // End loading
    }
  };

  const loadCompaniesByID = useCallback(
    async (id) => {
      if (id && ctechapp.currentUser) {
        const response = await getcompaniesbyid(id, ctechapp.currentUser);
        if (response) {
          let companiesdata = JSON.parse(response);
          let _companyName =
            location.pathname && location.pathname.includes("/manage")
              ? ""
              : companiesdata?.companyName || "";
          setCompanyLogo(companiesdata?.companyLogo || null);
          setCompanyName(_companyName || "");
        }
      }
    },
    [ctechapp.currentUser, location.pathname]
  );

  return (
    <Navbar collapseOnSelect expand="lg" bg="light" variant="light">
      <Container>
        <Navbar.Brand href="/apps">
          {loading ? (
            <span>Loading...</span>
          ) : companyLogo ? (
            <img
              src={companyLogo}
              height="80px"
              width="200px"
              className="headerLogo d-none d-md-block"
              alt="Company Logo"
            />
          ) : companyName ? (
            <span
              className={companyName.length > 15 ? "h6 fw-bold" : "h5 fw-bold"}
            >
              {companyName}
            </span>
          ) : (
            <img
              src="/images/pm_logo.png"
              height="80px"
              width="200px"
              className="headerLogo d-none d-md-block"
              alt="Default Logo"
            />
          )}
        </Navbar.Brand>
        {!window.location.pathname.includes("contactverification") && (
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        )}

        <Navbar.Collapse id="responsive-navbar-nav">
          {!isAuthenticated && <Nav className="me-auto"></Nav>}
          <MainNav />
        </Navbar.Collapse>

        <Navbar.Collapse id="responsive-navbar-nav">
          <AuthNav />
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavBar;
