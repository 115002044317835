import { Formik } from "formik";
import { Button, Form } from "react-bootstrap";
import * as yup from "yup";
import * as Realm from "realm-web";
import SweetAlert from "react-bootstrap-sweetalert";
import { useEffect, useState } from "react";
//import { isAnon } from "../utils";
import Loading from "../../components/Loading";
import { useParams } from "react-router";
import {
  getuserbyid,
  updateImportSignupByID,
} from "../../services/users_services";
import { isEmail } from "validator";

import { useRealmApp } from "../../providers/RealmApp";
import PhoneInput from "react-phone-input-2";
export const audience = process.env.REACT_APP_CP_AUTH_CLIENTID;
function SignupByInvitation() {

  const [defaultcountry, setdefaultcountry] = useState(1);
  const [loading, setLoading] = useState(false);
  const [header, setHeader] = useState("Signup");

  const [companyid, setCompanyid] = useState();
  const [ackid, setAckid] = useState();
  const [approvedby, setApprovedby] = useState("");
  const ctechapp = useRealmApp();
  const [isSuccess, setisSuccess] = useState(false);
  const [isFailure, setisFailure] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const [validatemsg, SetValidatemsg] = useState("");
  const [isvaliddata, setisvaliddata] = useState(false);

  const [initialval, setInitialval] = useState({
    _id: "",
    firstname: "",
    lastname: "",
    userid: "",
    email: "",
    phone: "",
    password: "",
    confirmpassword: "",
    authId_sub:[]
  });
  const [errors, setErrors] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    password: "",
  });
  const { ids, emailsignup } = useParams();

  // Get the API key from the local environment
  const apiKey = process.env.REACT_APP_APIKEY;
  if (!apiKey) {
    throw new Error("Could  not find a Realm Server API Key.");
  }
  const credentials = Realm.Credentials.apiKey(apiKey);
  const localapp = new Realm.App(process.env.REACT_APP_REALM_APP_ID);

  useEffect(() => {
    console.log(ids, emailsignup);
    if (ids !== "0" && emailsignup === "1") {
      getcontactverifciationIDByToken();
    }
    async function getcontactverifciationIDByToken() {
      ctechapp.logIn(credentials).then(() => {
        setLoading(true);
     
      });
    }
  }, []);

  async function GetUserByID(id) {
    await getuserbyid(id, ctechapp.currentUser).then((response) => {
      if (response !== undefined) {
        let res = JSON.parse(response);
        console.log("res", res);
        setInitialval(res[0]);
      }
    });
  }

  // async function GetUserByID(id) {
  //   await getuserbyid(id, ctechapp.currentUser).then((response) => {
  //     if (response !== undefined) {
  //       let res = JSON.parse(response);
  //       console.log("res", res);
  //       setInitialval(res[0]);
  //     }
  //   });
  // }
// handle change for business phone
function handleChangePhone(e, property) {
  SetValidatemsg("");
  let value =e;
  
  SetValidatemsg("");
  const path = property.split(".");
  const finalProp = path.pop();
  const newuser = { ...initialval };
  let pointer = newuser;
  path.forEach((el) => {
    pointer[el] = { ...pointer[el] };
    pointer = pointer[el];
  });
  pointer[finalProp] =value
  setInitialval(newuser);
  setisvaliddata(true);
}
  const handleChange = (event) => {
    SetValidatemsg("");
    const path = event.target.name.split(".");
    const finalProp = path.pop();
    const newuser = { ...initialval };
    let pointer = newuser;
    path.forEach((el) => {
      pointer[el] = { ...pointer[el] };
      pointer = pointer[el];
    });
    pointer[finalProp] =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    setInitialval(newuser);
    handleFieldValidation(event);
    setisvaliddata(true);
  };
  const handleFieldValidation = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let localerror = "";
    switch (name) {
      case "firstname":
        localerror =
          value.length < 3 ? "First Name must be at least 3 characters" : "";
        break;
      case "lastname":
        localerror =
          value.length < 1 ? "Last Name must be at least 1 characters" : "";
        break;
      /*  case "phone":
        localerror =
          value.length < 10 ? "Phone must be at least 10 characters" : "";
        break;*/
      case "email":
        localerror = !isEmail(value) ? "Invalid Email" : "";
        break;
      case "password":
        localerror =
          value.length < 8 ? "Password must be at least 8 characters" : "";
        break;

        break;
      default:
        break;
    }
    setErrors({ ...errors, [name]: localerror });
    setisvaliddata(true);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      await submitHandler();
    }
  };
  const handlePhoneSubmit = async (e) => {
    e.preventDefault();
    if (validatePhoneForm()) {
      await submitPhoneHandler();
    }
  };
  //error handling functions
  const validateForm = () => {
    try {
      let valid = false;
      valid = handleFieldRequiredValidation();
      Object.values(errors).forEach((val) => {
        if (val.length > 0) valid = false;
      });
      //console.log("valid : ", valid);
      return valid;
    } catch (error) {
      return false;
    }
  };
  const validatePhoneForm = () => {
    try {
      let valid = false;
      valid = handlePhoneFieldRequiredValidation();
      Object.values(errors).forEach((val) => {
        if (val.length > 0) valid = false;
      });
      //console.log("valid : ", valid);
      return valid;
    } catch (error) {
      return false;
    }
  };
  const handleFieldRequiredValidation = () => {
    let iserror = "";

    setErrors({
      ...errors,
      firstname:
        initialval.firstname === ""
          ? (iserror = "First Name is required")
          : errors.firstname,
      lastname:
        initialval.lastname === ""
          ? (iserror = "Last Name is required")
          : errors.lastname,
      email:
        initialval.email === ""
          ? (iserror = "Email is required")
          : errors.email,
      /*   phone:
        initialval.phone === ""
          ? (iserror = "Phone Number is required")
          : errors.phone,*/
      password:
        initialval.password === ""
          ? (iserror = "Password is required")
          : errors.password,
    });

    return iserror.length === 0;
  };
  const handlePhoneFieldRequiredValidation = () => {
    let iserror = "";

    setErrors({
      ...errors,
      phone:
        initialval.phone === ""
          ? (iserror = "phone number is required")
          : errors.phone,
    });

    return iserror.length === 0;
  };

  function gotoContactVerification() {
    window.location.href = "/contactverification/" + ids;
  }

  async function submitHandler(values) {
    setLoading(true);

    // Get the API key from the local environment
    const apiKey = process.env.REACT_APP_APIKEY;
    if (!apiKey) {
      throw new Error("Could  not find a Realm Server API Key.");
    }
    const credentials = Realm.Credentials.apiKey(apiKey);
    const localapp = new Realm.App(process.env.REACT_APP_REALM_APP_ID);

    saveuser();
  }
  async function submitPhoneHandler(values) {
    setLoading(true);

    // Get the API key from the local environment
    const apiKey = process.env.REACT_APP_APIKEY;
    if (!apiKey) {
      throw new Error("Could  not find a Realm Server API Key.");
    }
    const credentials = Realm.Credentials.apiKey(apiKey);
    const localapp = new Realm.App(process.env.REACT_APP_REALM_APP_ID);
    savephoneuser();
  }
  async function saveuser() {
    setLoading(true);

    var authID = "";
    // console.log(JSON.stringify(values));
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(initialval);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch("/getauth0userbyemail", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        if (result.toUpperCase().includes("[ERROR]:")) {
          // console.log(result);
          var message = result.replace("[error]:", "");
          var errmsg = JSON.parse(message);
          //console.log(JSON.stringify(errmsg));
          seterrorMessage(errmsg);
          setisFailure(true);
        } else {
          // let authid = JSON.parse(result);
          // updateImportSignupdetailsByID(authid);
          if (result !== undefined) {
            let email_status = JSON.parse(result);
            if (email_status.length > 0) {
              let record = email_status[0];
              authID = record.user_id;
              updateImportSignupdetailsByID(record);
             
            } else {
              fetch("/createauthouserbysignupinvitation", requestOptions)
                .then((response) => response.text())
                .then((result) => {
                  if (result.toUpperCase().includes("[ERROR]:")) {
                    // console.log(result);
                    var message = result.replace("[error]:", "");
                    var errmsg = JSON.parse(message);
                    //console.log(JSON.stringify(errmsg));
                    seterrorMessage(errmsg);
                    setisFailure(true);
                  } else {
                    if (result !== undefined) {
                      let record = JSON.parse(result);
                    //let authid = JSON.parse(result);
                   updateImportSignupdetailsByID(record);
                   setisSuccess(true);

                    }
                  }
                })
                .catch((error) => console.log("error", error));
              setLoading(false);
            }
          }
        }
      })
      .catch((error) => console.log("error", error));
  }

  async function savephoneuser() {
    setLoading(true);
    // console.log(JSON.stringify(values));
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(initialval);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch("/createdirectuserbyphone", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        if (result.toUpperCase().includes("[ERROR]:")) {
          // console.log(result);
          var message = result.replace("[error]:", "");
          var errmsg = JSON.parse(message);
          //console.log(JSON.stringify(errmsg));
          seterrorMessage(errmsg);
          setisFailure(true);
        } else {
          setisSuccess(true);
        }
      })
      .catch((error) => console.log("error", error));
    setLoading(false);
  }
  const updateImportSignupdetailsByID = async (authinfo) => {
   
    if(authinfo?.user_id.length>0)
    {
    initialval.userid =  authinfo.user_id;
    initialval.authId =  authinfo.user_id;
    const authIdsub = [
      {
        aud: audience,
        auth_provider: authinfo.identities[0].provider,
        id: authinfo.identities[0].user_id,
        sub:  authinfo.user_id,
        token: "",
      },
    ];
    initialval.authId_sub = authIdsub;
  }
    await updateImportSignupByID(
      initialval,
      ctechapp.currentUser,
      ctechapp.currentUser.id
    ).then(async (response) => {
      if (response !== null  && response !== undefined && response.toUpperCase().includes("TYPEERROR")) {
        seterrorMessage(response?.message);
        setisFailure(true);
      }
      else{
        console.log("updated updateImportSignupByID");
        setisSuccess(true);
       
      }
    });
  };

  const handleSucessSweetAlert = () => {
    setisSuccess(false);
    gotoContactVerification();
  };
  const handleFailureSweetAlert = () => {
    setisFailure(false);
  };
  return (
    <form onSubmit={handleSubmit}>
      {/* {loading && <Loading />} */}
      <div className="bg-black">
        <div className="container py-5 h-80">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col-lg-8 col-xl-3">
              <div className="card rounded-3">
                <img
                  src="/images/pm_logo.png"
                  className="px-5 mt-5"
                  alt="Logo"
                />
                <div className="card-body p-4 p-md-2">
                  <h2 className="text-center">Welcome</h2>
                  <p className="text-center mb-0">
                    Create a password below to activate your
                  </p>
                  <p className="text-center mt-0">
                    {" "}
                    Command Technologies Inc account
                  </p>
                  {/* <form className="px-md-5"> */}
                  <div className="row mb-4 px-md-5">
                    <input
                      type="text"
                      id="firstname"
                      name="firstname"
                      className="form-control"
                      placeholder="First Name"
                      value={initialval.firstname}
                      onChange={handleChange}
                    />
                    <div style={{ color: "red" }}>{errors.firstname}</div>
                  </div>

                  <div className="row mb-4 px-md-5">
                    <input
                      type="text"
                      id="lastname"
                      name="lastname"
                      className="form-control"
                      placeholder="Last Name"
                      value={initialval.lastname}
                      onChange={handleChange}
                    />
                    <div style={{ color: "red" }}>{errors.lastname}</div>
                  </div>

                  <div className="row mb-4 px-md-5">
                  <PhoneInput
                                      inputStyle={{ width: "250px" }}
                                      country={"us"}
                                      id="phone"
                                      name="phone"
                                      value={initialval.phone}
                                      onChange={(event) => {
                                        handleChangePhone(
                                          event,
                                          "phone"
                                        );
                                      }}
                                    />
                    <div style={{ color: "red" }}>{errors.phone}</div>
                  </div>

                  <div className="row mb-4 px-md-5">
                    <input
                      type="text"
                      id="email"
                      name="email"
                      className="form-control"
                      placeholder="Email"
                      value={initialval.email}
                      onChange={handleChange}
                    />
                    <div style={{ color: "red" }}>{errors.email}</div>
                  </div>

                  <div className="row mb-4 px-md-5">
                    <input
                      type="password"
                      id="password"
                      name="password"
                      className="form-control"
                      placeholder="Create Password"
                      value={initialval.password}
                      onChange={handleChange}
                    />
                    <div style={{ color: "red" }}>{errors.password}</div>
                  </div>

                
                  <div className="row mb-2 px-md-5">
                    <Button
                      className="form-control btn btn-lg"
                      onClick={handlePhoneSubmit}
                      type="submit"
                    >
                      Continue with Phone
                    </Button>
                  </div>
                  <div className="row mb-2 px-md-5">
                    <Button
                      className="form-control btn btn-lg"
                      type="submit"
                      onClick={handleSubmit}
                    >
                      Continue with Email
                    </Button>
                  </div>
                  <div className="row px-md-5">
                    <Button
                      className="form-control btn btn-lg ml-2 mb-4"
                      onClick={() => gotoContactVerification()}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        {isSuccess && (
          <SweetAlert
            success
            style={{ color: "black" }}
            title="User is added successfully.."
            onConfirm={handleSucessSweetAlert}
          ></SweetAlert>
        )}
      </div>

      <div>
        {isFailure && (
          <SweetAlert
            danger
            style={{ color: "black" }}
            title="User Signup is failed."
            onConfirm={handleFailureSweetAlert}
          >
            {errorMessage}
          </SweetAlert>
        )}
      </div>
    </form>
  );
}
export default SignupByInvitation;
