export const appConstants = {
  YEARFORMAT: "YYYY",
  bannerimage: "/images/pm_logo.png",
  bannersmallimage: "/images/pm_small_logo.png",
  DATEFORMAT: "MM/DD/YYYY",

  import: {
    //? --> appConstants.import.alertmsg.projectalreadyexist
    alertmsg: {
      newlink: "New link has been sent successfully.",
      errorlink:
        "We are unable to generate new link. due to incorrect signup URL",
      lobupdatesucessfull: "LOB info updated successfully.",
      lobinsertsucessfull: "LOB info inserted successfully.",
      appdeletesucessfull: "App deleted successfully.",
      projectreject: "Project rejected successfully",
      projectapproved: "Project approved successfully",
      projectalreadyexist: "Project with same project number already Exists",
      projectcreatedsucessfull: "Project created successfully",
      projectupdatesucessfull: "Project updated successfully",
      projectnumberupdatesucessfull: "Project number updated successfully",
      projectdeletesucessfull: "Project deleted successfully.",
      contactupdatesettingsucessfull:
        "Contact Settings is updated succesfully.",
      contactupdatesettingfail: "Contact Settings  update is failed.",
      folderdeletesucessfull: "Folder deleted successfully.",
      foldercreatedsuccessfull: "Folder created successfully",
      folderupdatedsuccessful: "Folder updated successfully",
      usercreatedsucessfull: "User created successfully.",
      useralreadyexist: "User is already exists.",
      userupdatedsucessfull: "User updated successfully.",
      userdeletesucessfull: "User deleted successfully.",
      profileupdatesucessfull: "Profile updated successfully..",
    },
    //? --> appConstants.import.confirmationmsg.delete
    confirmationmsg: {
      company: "Do you want to change company ?",
      projectnumber: " Do you want to change the project number",
      delete: "Do you want to delete ?",
      deleteuser: "Do you want to delete this user ?",
      deleteproject: "Do you want to delete this project ?",
      deleteprojectfolder: "Do you want to delete this project folder ?",
      deleteaff: "Do you want to delete this affiliation ?",
      reactiveaff: "Do you want to reactive affiliation ?",
      rejectwish: " Do you wish to reject contact",
      contactverification_profile:
        "You should login to manage your profile. click here to continue.",
      contactverification_linkexpired: " Your link has expired",
      contactverification_linksend: " Do you want to resend the link ?",
      contactverification_thanks:
        "Thank you for verifying your information for",
      contactverification_alreadyverified:
        "Contact has been verified already...",
      contactverification_incorrectsignupurl:
        "We are unable to generate new link. due to incorrect signup URL",
      verificationmsg: "Thank you for verifying your information for ",
    },
    //? --> appConstants.import.handlefieldvalidation.nocpappimport
    handlefieldvalidation: {
      noprocoreapp: "No procore app found",
      noprocoreappimport: "No procore app found for import",
      nocpapp: "No commandpost app found",
      nocpappimport: "No commandpost app found for import",
      noeligiblerecord: " No records to import",
      nodata: " No data found",
      companyid: "Account display name required",
      fromdate: "From date required",
      todate: "To date required",
      totaldays:
        "Please select a valid from date and to date/to date should be after a from date",
    },
    //? --> appConstants.import.handlefieldrequiredvalidation.selectaction

    handlefieldrequiredvalidation: {
      action: "Action isn't selected.",
      selectaction: "No project selected, please select a project",
    },
    //? --> appConstants.import.commandpost.create
    commandpost: {
      create: "Command Post project record import has been initiated",
      error: "Command Post project record import was unsuccessful.",
      dedup: "Command Post deduplication import has been initiated.",
    },
    //? --> appConstants.import.procore.create
    procore: {
      create: "Procore project record import has been initiated",
      error: "Procore project record import was unsuccessful.",
      dedup: "Procore deduplication import has been initiated.",
    },
    //? --> appConstants.import.quickbooks.
    quickbooks: {
      create: "Quickbooks contact record import has been initiated.",
      Error: "Quickbooks contact record import was unsuccessful.",
      role: "Quickbooks app contact role not selected",
      dedup: "Quickbooks deduplication import has been initiated.",
      multipleuser:
        "Selected user has multiple selected quickbooks applications",
    },
    //? --> appConstants.import.quickbooks.
    entraid: {
      create: "EntraID contact record import has been initiated.",
      Error: "EntraID contact record import was unsuccessful.",
      dedup: "EntraID deduplication import has been initiated.",
    },
  },
};
