//services for getuserhistorybyid
export const getuserhistorybyid = async (_id, Ctechapp) => {
  try {
    const data = await Ctechapp.functions.getuserhistorybyid(_id);
    if (data !== undefined) {
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};

// services for updateuserhistorybyid

export const updateuserhistorybyid = async (
  userdata,
  Ctechapp,
  loggedin_by
) => {
  try {
    const data = await Ctechapp.functions.updateuserhistorybyid(
      JSON.stringify(userdata),
      loggedin_by
    );
    if (data !== undefined) {
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};
