export const getapplicationsbyid = async (app_id, Ctechapp, loggedin_by) => {
  try {
    const data = await Ctechapp.functions.getapplicationsbyid(
      app_id,
      loggedin_by
    );
    if (data !== undefined) {
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};

export const getProcorevendorlistbycompanyid = async (
  app,
  Ctechapp,
  loggedin_by
) => {
  try {
    const data = await Ctechapp.functions.getProcorevendorlistbycompanyid(
      JSON.stringify(app),
      loggedin_by
    );
    if (data !== undefined) {
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};
export const getApplicationByAppName_Environment = async (
  appname,
  appenvironment,
  Ctechapp,
  loggedin_by
) => {
  try {
    const data = await Ctechapp.functions.getApplicationByAppName_Environment(
      appname,
      appenvironment,

      loggedin_by
    );
    if (data !== undefined) {
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};

export const getAdminVerificationTaskByParent = async (
  apprelationship,
  Ctechapp,
  loggedin_by
) => {
  try {
    const data = await Ctechapp.functions.getAdminVerificationTaskByParent(
      apprelationship,
      loggedin_by
    );
    if (data !== undefined) {
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};

export const updateApplicationByID = async (app, Ctechapp, loggedin_by) => {
  try {
    const data = await Ctechapp.functions.updateApplicationByID(
      JSON.stringify(app),
      loggedin_by
    );
    if (data !== undefined) {
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};
export const updateApplicationByLogo = async (app, Ctechapp, loggedin_by) => {
  console.log("logo", app);
  try {
    const data = await Ctechapp.functions.updateApplicationByLogo(
      JSON.stringify(app),
      loggedin_by
    );
    if (data !== undefined) {
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};
//getAppMasterApplicationByFilter

export const getAppMasterApplicationByFilter = async (
  arg,
  loggedin_by,
  Ctechapp
) => {
  try {
    const data = await Ctechapp.functions.getAppMasterApplicationByFilter(
      arg,
      loggedin_by
    );
    if (data !== undefined) {
      return data;
    }
  } catch (error) {
    return error.response.data.message;
  }
};
export const getapplistbyuserid = async (Ctechapp, loggedin_by) => {
  try {
    const data = await Ctechapp.functions.getapplistbyuserid(loggedin_by);
    if (data !== undefined) {
      //console.log("data of getapplistbyuserid :" + JSON.stringify(data));
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};

export const getapplistbyfilter = async (Ctechapp, query, loggedin_by) => {
  try {
    const data = await Ctechapp.functions.getapplistbyfilter(
      query,
      loggedin_by
    );
    if (data !== undefined) {
      //console.log("data of getapplistbyuserid :" + JSON.stringify(data));
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};

export const getappcompanylistbyappname_userid = async (
  appname,
  Ctechapp,
  loggedin_by
) => {
  try {
    const data = await Ctechapp.functions.getappcompanylistbyappname_userid(
      appname,
      loggedin_by
    );
    if (data !== undefined) {
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};
//getCPPProjectsListByCompanyID

export const getCPPProjectsListByCompanyID = async (
  arg,
  Ctechapp,
  loggedin_by
) => {
  try {
    const data = await Ctechapp.functions.getCPPProjectsListByCompanyID(
      arg,
      loggedin_by
    );
    if (data !== undefined) {
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};
//getCPPJobStatusCodeListByCompanyID
export const getCPPJobStatusCodeListByCompanyID = async (
  arg,
  Ctechapp,
  loggedin_by
) => {
  try {
    const data = await Ctechapp.functions.getCPPJobStatusCodeListByCompanyID(
      arg,
      loggedin_by
    );
    if (data !== undefined) {
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};
export const get_CPPProjects_ByID_API = async (arg, Ctechapp, loggedin_by) => {
  try {
    const data = await Ctechapp.functions.get_CPPProjects_ByID_API(
      arg,
      loggedin_by
    );
    if (data !== undefined) {
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};

// export const getCPPProjectsListByCompanyID = async (arg, Ctechapp, loggedin_by) => {
//   try {
//     const data = await Ctechapp.functions.getCPPProjectsListByCompanyID(arg, loggedin_by);
//     if (data !== undefined) {
//       return JSON.stringify(data);
//     }
//   } catch (error) {
//     return error.response.data.message;
//   }
// };
export const getappinfobyappname_companyid = async (
  appname,
  companyid,
  Ctechapp,
  loggedin_by
) => {
  try {
    const data = await Ctechapp.functions.getappinfobyappname_companyid(
      appname,
      companyid,
      loggedin_by
    );
    if (data !== undefined) {
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};

export const getappinfobyappname_userid_companyid = async (
  appname,
  companyid,
  Ctechapp,
  loggedin_by
) => {
  try {
    const data = await Ctechapp.functions.getappinfobyappname_userid_companyid(
      appname,
      companyid,
      loggedin_by
    );
    if (data !== undefined) {
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};

export const insertApplication = async (appinfo, Ctechapp, loggedin_by) => {
  try {
    const data = await Ctechapp.functions.insertApplication(
      appinfo,
      loggedin_by
    );
    if (data !== undefined) {
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};

export const deleteAppByuserID = async (_id, Ctechapp, loggedin_by) => {
  try {
    const data = await Ctechapp.functions.deleteAppByuserID(_id, loggedin_by);
    if (data !== undefined) {
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};
export const updateApplicationRelationshipByID = async (
  app,
  Ctechapp,
  loggedin_by
) => {
  try {
    const data = await Ctechapp.functions.updateApplicationRelationshipByID(
      JSON.stringify(app),
      loggedin_by
    );
    if (data !== undefined) {
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};
export const isValidApplication = async (app, Ctechapp, loggedin_by) => {
  try {
    const data = await Ctechapp.functions.isValidApplication(
      JSON.stringify(app),
      loggedin_by
    );
    if (data !== undefined) {
      return data;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export const get_environment_config_value = async (Ctechapp, loggedin_by) => {
  try {
    const data = await Ctechapp.functions.get_environment_config_value(
      loggedin_by
    );
    if (data !== undefined) {
      return data;
    }
  } catch (error) {
    return error.response.data.message;
  }
};
