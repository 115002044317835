import { useEffect, useState, useMemo } from "react";
import { useRealmApp } from "../../providers/RealmApp";
import { Button, Modal } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { useAuth0 } from "@auth0/auth0-react";
import SweetAlert from "react-bootstrap-sweetalert";
import { getuserbyauthproviderid } from "../../services/users_services";
import Select from "react-select";
import GenericListTable from "../../components/gridTable/genericLlistTable";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router";
import Moment from "moment";
import customstyles from "../../components/customstyles/react-select-styles";
import useLocationFinder from "../../components/useLocationFinder";
import PhoneInput from "react-phone-input-2";
import LoadingDialog from "../../components/LoadingDialog";
import { appConstants } from "../../constants/app_constants";
import {
  insertProject,
  getProjectListByUserID,
  updateProjectByID,
  updateProjectByID_ProjectNumber,
  deleteProjectByID,
  getProjectListByID,
  getProjectListByFilter,
  getCommandPostNewProjectNumber,
  getCommandPostAccountSettingsByID,
} from "../../services/project_services";
import {
  getCPPJobStatusCodeListByCompanyID,
  getapplistbyuserid,
  get_environment_config_value,
} from "../../services/applications_services";
import { getAppMasterLOBApps } from "../../services/lob_app_info_services";
import LinkedApplicationCards from "../../components/LinkedApplicationCards";
import LOBAppShortLogoCards from "../../components/LOBAppShortLogoCards";
function ProjectList() {
  const { v4: uuidv4 } = require("uuid");
  const location = useLocation();
  // const action_value = location?.state?.action;
  // const [actionvalue, setactionvalue] = useState(action_value);
  let USDollar = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  const [noRecord, setNoRecord] = useState(false);
  const [list, setList] = useState([]);
  const [loadDialogOpen, setloadDialogOpen] = useState(false);
  const [connectedAppList, setConnectedAppList] = useState([]);
  const [isDeleteApp, setIsDeleteApp] = useState(false);
  const [isProject, setIsProject] = useState(false);
  const [isDetailProject, setIsDetailProject] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [isAlreadyExist, setIsAlreadyExist] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isSuccess1, setIsSuccess1] = useState(false);
  const [rowToDelete, setRowToDelete] = useState();
  const [displayMsg, setDisplayMsg] = useState();
  const [newProjectNumber, setNewProjNum] = useState("");
  const [isAutoLoad, setIsAutoLoad] = useState(false);
  const [isAddReady, setIsaddReady] = useState(false);
  const [isEditReady, setIsEditReady] = useState(false);
  const [isViewReady, setIsViewReady] = useState(false);
  const [isDeleteReady, setIsDeleteReady] = useState(false);
  const ctechapp = useRealmApp();
  const [CPAccountID, setCPAccountID] = useState("");
  const [CPUserID, setCPUserID] = useState("");
  const { isLoading, error, isAuthenticated, user } = useAuth0();
  const [userinfo, setUserinfo] = useState({
    firstname: "",
    lastname: "",
    email: "",
    role: "",
  });
  const initialemailstate = {
    id: uuidv4().replace(/-/g, ""),
    label: "",
    email: "",
    isPrimary: false,
  };
  const initialphonestate = {
    id: uuidv4().replace(/-/g, ""),
    type: "",
    ext: "",
    country: "",
    extPhone: "",
    fullPhone: "",
    phone: "",
    isPrimary: false,
  };
  const initialaddresstate = {
    id: uuidv4().replace(/-/g, ""),
    label: "",
    address1: "",
    address2: "",
    city: "",
    country: "",
    state: "",
    zipCode: "",
    lng: "",
    lat: "",
    isPrimary: false,
  };
  const initialinkedappstate = {
    id: uuidv4().replace(/-/g, ""),
    appName: "",
    appProjectID: "",
    appProjectLink: "",
    appClientID: "",
    appCompanyID: "",
    importedOn: "",
    status: "",
  };
  // const initalroleaffiliationstate = {
  //   id: uuidv4().replace(/-/g, ""),
  //   firstName: "",
  //   lastName: "",
  //   companyName: "",
  //   roleID: "",
  //   roleName: "",
  //   emailArr: [{ ...initialemailstate }],
  //   phoneArr: [{ ...initialphonestate }],
  //   addressArr: [{ ...initialaddresstate }],
  //   createdDate: new Date(),
  // };

  const initialprojectstate = {
    //	Project Information Section
    projectName: "",
    projectNumber: "",
    projectNumInt: "",
    projectNumPre: "",
    altProjectNumber: "",
    projectStatus: "",
    apn: "",
    jobStatusCodesName: "",
    jobStatusSortIndex: "",
    jobStatusCodes: "",
    projectCreatedDate: new Date(),
    projectAddressArr: [{ ...initialaddresstate }],
    pocArr: [],
    primeArr: [],
    apNum: "",
    prime_taxId: "",
    // Property Owner  Information Section
    propertyOwnerArr: [],
    // Property Owner POC Information Section
    propertyOwnerpocArr: [],
    // Contract Information section
    actual_ClientName: "",
    original_ContractValue: "",
    current_ContractValue: "",
    original_ContractStartDate: "",
    current_ContractStartDate: "",
    actual_StartDate: "",
    original_ContractEndDate: "",
    current_ContractEndDate: "",
    actual_EndDate: "",
    CoNumber: "",
    program_id: "",
    originalContractGrossProfit: "",
    currentGrossProfit: "",
    costToDate: "",
    costToComplete: "",
    //role affiliation array section
    roleAffiliationArr: [],
    //linked application array
    linkedApplicationArr: [{ ...initialinkedappstate }],
  };
  const initialprojecterrorstate = {
    projectName: "",
    projectNumber: "",
    projectStatus: "",
    projectNumPre: "",
    projectNumInt: "",
    original_ContractStartDate: "",
    original_ContractEndDate: "",
  };
  const [projectDetail, setProjectDetail] = useState();
  const [projectStatusList, setProjectStatusList] = useState();
  const [projectDetailError, setProjectDetailError] = useState(
    initialprojecterrorstate
  );
  //state variables
  const [isChangeProjectNumber, setChangeProjectNumber] = useState(false);
  const [
    isConfirmationToChangeProjectNumber,
    setConfirmationToChangeProjectNumber,
  ] = useState(false);
  const [isProjectNumberAlreadyExist, setProjectNumberAlreadyExist] =
    useState(false);
  const { googleAddress, setGoogleAddress, onInputElementCreated } =
    useLocationFinder();

  const history = useHistory();
  //table functions
  const data = useMemo(() => [...list], [list]);
  const appColumns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "_id",
        sortable: true,
        width: "20%",
        Filter: "",
        filter: "",
      },
      {
        Header: "Source app",
        accessor: "sourceInfo",
        sortable: true,
        width: "20%",
        Cell: ({ value, row }) => {
          return <span>{row.original?.sourceInfo?.sourceApp}</span>;
        },
      },
      {
        Header: "Connected app",
        accessor: "linkedapps",
        sortable: true,
        width: "20%",
        Filter: "",
        filter: "",
        // Filter: DefaultColumnFilter,connectedAppList
        // filter: 'value',
        Cell: ({ value, row }) => {
          return (
            <LOBAppShortLogoCards
              projectDetail={row.original}
              connectedAppList={row.original.connectedApps}
            />
          );
        },
      },
      {
        Header: "Project number",
        accessor: "projectNumber",
        sortable: true,
        width: "20%",
        Filter: "",
        filter: "",
        // Filter: DefaultColumnFilter,
        // filter: 'value',
      },
      {
        Header: "Project name",
        accessor: "projectName",
        sortable: true,
        width: "30%",
      },

      {
        Header: "Alternate project number",
        accessor: "altProjectNumber",
        sortable: true,
        width: "30%",
        Filter: "",
        filter: "",
      },
      {
        Header: "Created on",
        accessor: "activity_history",
        sortable: true,
        width: "10%",
        Filter: "",
        filter: "value",
        Cell: ({ value }) => (value && Moment(value).format("l")) || "",
      },
      // {
      //   Header: "Active",
      //   accessor: "isactive",
      //   sortable: false,
      //   width: "10%",
      //   Filter: "",
      //   filter: "value",
      //   Cell: ({ value }) => {
      //     return (
      //       <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
      //         <i className="fa fa-circle fs-9px fa-fw me-5px"></i>
      //         {value === "1" ? "Yes" : "No"}
      //       </span>
      //     );
      //   },
      // },
      {
        Header: "Action",

        Cell: ({ row }) => {
          const onViewItemClick = () => {
            handleViewClickApps(row);
          };
          const onEditItemClick = () => {
            handleEditClickApps(row);
          };
          const onDeleteItemClick = () => {
            handleDeleteClickApps(row);
          };
          return (
            <div className="d-flex gap-4">
              <Button className="btn btn-sm " onClick={onViewItemClick}>
                {/* <i className="fas fa-2x  fa-pencil"></i> */}
                View
              </Button>
              <Button className="btn btn-sm " onClick={onEditItemClick}>
                {/* <i className="fas fa-2x  fa-pencil"></i> */}
                Edit
              </Button>
              <Button className="btn btn-sm " onClick={onDeleteItemClick}>
                {/* <i className="fas fa-2x  fa-trash-can"></i> */}
                Delete
              </Button>
            </div>
          );
        },
      },
    ],
    []
  );

  const hiddenappColumns = ["_id", "appuserinfo[0].groupid"];
  useEffect(async () => {
    if (isAuthenticated) {
      const queryparams = new URLSearchParams(location.search);
      let paramsinfo = queryparams.get("action");
      await getUserInfoByID(paramsinfo);

      //  getData();
    }
  }, []);
  useEffect(() => {
    if (isAuthenticated) {
      if (projectStatusList !== undefined && isAutoLoad) {
        // console.log("isAddReady",isAutoLoad);
        // console.log("projectStatusList",projectStatusList)
        handleAddButton();
        setIsAutoLoad(false);
      }
      //  getData();
    }
  }, [isAutoLoad, projectStatusList]);

  useEffect(() => {
    //let _addressArray = [...projectDetail?.projectAddressArr];
    //   _addressArray[0] = googleAddress;
    setProjectDetail({
      ...projectDetail,
      ["projectAddressArr"]: [googleAddress],
    });
    //   console.log(googleAddress);
  }, [googleAddress]);

  async function getUserInfoByID(paramsinfo) {
    try {
      await getuserbyauthproviderid(user.sub, ctechapp.currentUser).then(
        async (response) => {
          let user_info = JSON.parse(response);
          if (response !== undefined || user_info?.length !== 0) {
            setUserinfo(user_info[0]);
            setloadDialogOpen(true);
            await Getapplistbyuserid(user_info[0], paramsinfo);
          }
        }
      );
    } catch (exception) {
      console.error("getuserbyauthproviderid Error", exception);
    }
  }

  async function getProjectListData(_LOBAppsList) {
    await getProjectListByUserID(ctechapp.currentUser, user.sub).then(
      (response) => {
        if (response !== undefined) {
          let _projectlist = JSON.parse(response);

          _projectlist?.map((project) => {
            project.linkedApplicationArr.map((arr) => {
              let filterarr = _LOBAppsList.filter(
                (lob) =>
                  lob?.appname?.toLowerCase() === arr?.appName?.toLowerCase()
              );
              if (filterarr.length > 0) {
                arr.applogo = filterarr[0].applogo;
                arr.appurl = filterarr[0].appurl;
                arr.sitename = filterarr[0]?.sitename;
                arr.shortname = filterarr[0].shortname;
                arr.shortnamecolor = filterarr[0].shortnamecolor;
              }
              return arr;
            });
          });
          //console.log("_projectlist", _projectlist);

          setList(_projectlist);
          if (_projectlist.length === 0) {
            setNoRecord(true);
          } else {
            setNoRecord(false);
          }
        }
      }
    );
  }
  async function GetProjectListByID(id) {
    try {
      // console.log("id", id);
      await getProjectListByID(id, ctechapp.currentUser, user.sub).then(
        (response) => {
          if (response !== undefined) {
            var projectinfo = response;
            //project address array
            if (
              projectinfo?.projectAddressArr === undefined ||
              projectinfo?.projectAddressArr?.length === 0
            ) {
              projectinfo.projectAddressArr = [{ ...initialaddresstate }];
            }

            setProjectDetail(projectinfo);
            setloadDialogOpen(false);
          }
        }
      );
    } catch (err) {
      console.error("GetProjectListByID Error", err);
    }
  }
  async function GetDetailProjectListByID(id, _linkedApplicationArr) {
    try {
      await getProjectListByID(id, ctechapp.currentUser, user.sub).then(
        (response) => {
          if (response !== undefined) {
            var projectinfo = response;
            projectinfo.linkedApplicationArr = _linkedApplicationArr;

            setProjectDetail(projectinfo);
          }
        }
      );
    } catch (err) {
      console.error("GetDetailProjectListByID Error", err);
    }
  }
  async function GetDetailProjectListByFilter(query) {
    try {
      return await getProjectListByFilter(
        query,
        ctechapp.currentUser,
        user.sub
      );
    } catch (err) {
      console.error("GetDetailProjectListByFilter Error", err);
    }
  }
  async function Getapplistbyuserid(_userinfo, paramsinfo) {
    await getapplistbyuserid(ctechapp.currentUser, _userinfo.userid).then(
      async (response) => {
        if (response !== undefined) {
          let apps_list = JSON.parse(response);
          setConnectedAppList(apps_list);
          console.log("apps_list", apps_list);
          getProjectListData(apps_list);
          let filter_applist = apps_list.filter(
            (app) => app.appname?.toLowerCase() === "commandpost"
          );
          if (filter_applist.length > 0) {
            //  console.log("filter_applist", filter_applist);
            var argobj = {
              _p_accountId: filter_applist[0].appaccountid,
            };
            setCPUserID(filter_applist[0].appaccountuserid);
            setCPAccountID(filter_applist[0].appaccountid);
            await GetCommandPostNewProjectNumber(
              filter_applist[0].appaccountid,
              paramsinfo
            );
            await GetCPPJobStatusCodeListByCompanyID(argobj, paramsinfo);
          }
          setloadDialogOpen(false);
        }
      }
    );
  }
  async function GetCommandPostNewProjectNumber(arg, paramsinfo) {
    // console.log("GetCommandPostNewProjectNumber", arg);
    let obj = {
      _id: arg?.slice(16),
    };
    console.log("obj", obj);
    try {
      await getCommandPostAccountSettingsByID(
        obj,
        ctechapp.currentUser,
        user.sub
      ).then((response) => {
        if (response !== undefined) {
          var projectinfo_Num = JSON.parse(response);

          if (projectinfo_Num.statusCode === 200) {
            setNewProjNum(projectinfo_Num?.data);

            setIsProject(true);
            setloadDialogOpen(false);
          }
          //    console.log("projectinfo_Num", projectinfo_Num);
        }
      });
    } catch (err) {
      console.error("getCommandPostAccountSettingsByID Error", err);
    }
  }

  async function GetCPPJobStatusCodeListByCompanyID(arg, paramsinfo) {
    try {
      await getCPPJobStatusCodeListByCompanyID(
        arg,
        ctechapp.currentUser,
        user.sub
      ).then((response) => {
        if (response !== undefined) {
          var projectstatusinfo = JSON.parse(response);
          if (projectstatusinfo.error === "") {
            var projectstatus = projectstatusinfo.data;
            var options = [];
            projectstatus?.map(function (status) {
              options.push({
                value: status.jobStatusCodesCode,
                label: status.jobStatusCodesName,
                index: status.jobStatusSortIndex,
                _id: status._id,
              });
            });
            //  console.log(options);
            setProjectStatusList(options);
            if (paramsinfo === "add") setIsAutoLoad(true);
            setloadDialogOpen(false);
          } else {
            console.error(
              "GetDetailProjectListByID Error",
              projectstatusinfo.error
            );
          }
        }
      });
    } catch (err) {
      console.error("GetDetailProjectListByID Error", err);
    }
  }

  function ondelete(id) {
    try {
      deleteProjectByID(id, ctechapp.currentUser, user.sub).then((response) => {
        if (response !== undefined) {
          setIsDeleted(true);
        }
      });
    } catch (error) {
      console.error("deleteProjectByID Error", error);
    }
  }
  async function InsertProject() {
    try {
      //  console.log("projectDetail", projectDetail);

      let _projectDetail = { ...projectDetail };
      _projectDetail.linkedApplicationArr = [
        {
          appName: "CommandPost",
          projectLink: "",
          projectID: "",
          projectNumber: {
            id: "",
            name: "",
            code: "",
            display:
              _projectDetail?.projectNumPre +
              "-" +
              _projectDetail?.projectNumInt.toString(),
          },
          imported_On: new Date(),
          userID: CPUserID || "",
          companyID: CPAccountID || "",
          status: "Created",
        },
      ];
      let spfilter_applist = connectedAppList.filter(
        (app) => app.appname?.toLowerCase() === "sharepoint"
      );
      if (spfilter_applist.length > 0) {
        spfilter_applist.map((row) => {
          let spappinfo = {
            appName: row.appname,
            projectLink: "",
            projectID: "",
            projectNumber: {
              id: "",
              name: "",
              code: "",
              display: "",
            },
            imported_On: new Date(),
            userID: userinfo.userid || "",
            companyID: row.appaccountid || "",
            status: "Created",
          };
          _projectDetail.linkedApplicationArr.push(spappinfo);
        });
      }
      let profilter_applist = connectedAppList.filter(
        (app) => app.appname?.toLowerCase() === "procore"
      );
      if (profilter_applist.length > 0) {
        profilter_applist.map((row) => {
          let proappinfo = {
            appName: row?.appname,
            projectLink: "",
            projectID: "",
            projectNumber: {
              id: "",
              name: "",
              code: "",
              display:
                _projectDetail?.projectNumPre +
                "-" +
                _projectDetail?.projectNumInt.toString(),
            },
            imported_On: new Date(),
            userID: userinfo.userid || "",
            companyID: row?.appaccountid || "",
            status: "Created",
          };
          _projectDetail.linkedApplicationArr.push(proappinfo);
        });
      }

      await insertProject(_projectDetail, ctechapp.currentUser, user.sub).then(
        (response) => {
          if (response !== undefined) {
            if (response.status === false) {
              setIsAlreadyExist(true);
              setDisplayMsg(appConstants.import.alertmsg.projectalreadyexist);
            } else {
              setIsSuccess(true);
              setDisplayMsg(
                appConstants.import.alertmsg.projectcreatedsucessfull
              );
              getProjectListData(connectedAppList);
            }
          }
        }
      );
    } catch (exception) {
      console.error("insertProject Error", exception);
    }
  }
  async function UpdateProject() {
    try {
      //  console.log("projectDetail", projectDetail);
      await updateProjectByID(
        projectDetail,
        ctechapp.currentUser,
        user.sub
      ).then((response) => {
        if (response !== undefined) {
          setIsSuccess(true);
          setDisplayMsg(appConstants.import.alertmsg.projectupdatesucessfull);
        }
      });
    } catch (exception) {
      console.error("updateProjectByID Error", exception);
    }
  }
  async function UpdateProjectByID_ProjectNumber() {
    try {
      //  console.log("projectDetail", projectDetail);
      await updateProjectByID_ProjectNumber(
        projectDetail,
        ctechapp.currentUser,
        user.sub
      ).then((response) => {
        if (response !== undefined) {
          getProjectListData(connectedAppList);
          setIsSuccess1(true);
          setDisplayMsg(
            appConstants.import.alertmsg.projectnumberupdatesucessfull
          );
        }
      });
    } catch (exception) {
      console.error("updateProjectByID_ProjectNumber Error", exception);
    }
  }
  const projectdetailspliceArrays = () => {
    // project address
    projectDetail?.projectAddressArr?.map((value, index) => {
      if (value.address1 === "" && value.address2 === "") {
        projectDetail?.projectAddressArr?.splice(index);
      }
    });
  };
  const projectdetailisMainArrays = () => {
    //project address array
    if (projectDetail?.projectAddressArr?.length > 0) {
      let address = projectDetail.projectAddressArr?.filter(
        (address) => address.isPrimary === true
      );
      if (address.length === 0) {
        projectDetail.projectAddressArr[0].isPrimary = true;
      }
    }
  };
  async function AddSubmitHandler() {
    if (validAddForm()) {
      await projectdetailspliceArrays();
      await projectdetailisMainArrays();
      InsertProject();
    }
  }
  const validAddForm = () => {
    try {
      let valid = false;
      valid = handleFieldListAddRequiredValidation();
      Object.values(projectDetailError).forEach((val) => {
        if (val.length > 0) valid = false;
      });
      //console.log("valid : ", valid);
      return valid;
    } catch (error) {
      return false;
    }
  };

  const handleFieldListAddRequiredValidation = () => {
    let iserror = "";

    setProjectDetailError({
      ...projectDetailError,
      projectName:
        projectDetail?.projectName === ""
          ? (iserror = "Project name required")
          : projectDetailError.projectName,
      projectNumPre:
        projectDetail?.projectNumPre === ""
          ? (iserror = "Project number pre required")
          : projectDetailError.projectNumPre,

      projectNumInt:
        projectDetail?.projectNumInt === ""
          ? (iserror = "Project number int required")
          : projectDetailError.projectNumInt,
      projectStatus:
        projectDetail?.projectStatus === ""
          ? (iserror = "Project status required")
          : projectDetailError.projectStatus,
      original_ContractStartDate:
        projectDetail?.original_ContractStartDate === ""
          ? (iserror = "Construction start date required")
          : projectDetailError.original_ContractStartDate,
      original_ContractEndDate:
        projectDetail?.original_ContractEndDate === ""
          ? (iserror = "Construction end date required")
          : projectDetailError.original_ContractEndDate,
    });

    return iserror.length === 0;
  };

  async function EditSubmitHandler() {
    if (validEditForm()) {
      await projectdetailspliceArrays();
      await projectdetailisMainArrays();
      UpdateProject();
    }
  }
  async function UpdateProjectNumberPopup() {
    setChangeProjectNumber(true);
  }
  const validEditForm = () => {
    try {
      let valid = false;
      valid = handleFieldListEditRequiredValidation();
      Object.values(projectDetailError).forEach((val) => {
        if (val.length > 0) valid = false;
      });
      //console.log("valid : ", valid);
      return valid;
    } catch (error) {
      return false;
    }
  };

  const handleFieldListEditRequiredValidation = () => {
    let iserror = "";

    setProjectDetailError({
      ...projectDetailError,
      projectName:
        projectDetail?.projectName === ""
          ? (iserror = "Project name required")
          : projectDetailError.projectName,
      projectNumber:
        projectDetail.projectNumber === "" ||
        projectDetail.projectNumber === null
          ? (iserror = "Project number required")
          : projectDetailError.projectNumber,
      projectNumPre:
        projectDetail?.projectNumPre === ""
          ? (iserror = "Project number pre required")
          : projectDetailError.projectNumPre,

      // projectNumInt:
      //   projectDetail?.projectNumInt === ""
      //     ? (iserror = "Project number int required")
      //     : projectDetailError.projectNumInt,
    });

    return iserror.length === 0;
  };
  const handleDropDownChange = (selectedOption) => {
    const { name, value } = selectedOption.target;
    let _projectDetail = { ...projectDetail };
    if (name === "projectStatus") {
      _projectDetail[name] = value.value;
      _projectDetail["jobStatusCodesName"] = value.label;
      _projectDetail["jobStatusSortIndex"] = value.index;
      _projectDetail["jobStatusCodes"] = value._id;
    }
    setProjectDetailError({ ...projectDetailError, [name]: "" });
    setProjectDetail(_projectDetail);
  };

  const handleChange = (event) => {
    const path = event.target.name.split(".");
    const finalProp = path.pop();
    const newuser = { ...projectDetail };
    let pointer = newuser;
    path.forEach((el) => {
      pointer[el] = { ...pointer[el] };
      pointer = pointer[el];
    });
    pointer[finalProp] =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    setProjectDetail(newuser);
    handleFieldValidation(event);
  };
  const handleFieldValidation = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let localerror = "";
    setProjectDetailError({ ...projectDetailError, [name]: localerror });
  };

  function handleProjectChangeAddress(e, index, property) {
    let _addressArray = [...projectDetail?.projectAddressArr];
    _addressArray[index][property] = e.target.value;

    setProjectDetail({
      ...projectDetail,
      ["projectAddressArr"]: _addressArray,
    });
  }
  const handleViewClickApps = (row) => {
    GetDetailProjectListByID(
      row.original._id,
      row.original.linkedApplicationArr
    );
    setIsDetailProject(true);
  };
  const handleEditClickApps = (row) => {
    GetProjectListByID(row.original._id);
    setIsProject(true);
    setIsEditReady(true);
    setIsaddReady(false);
  };
  const handleDeleteClickApps = (row) => {
    setIsDeleteApp(true);
    setRowToDelete(row.original._id);
  };
  const handleConfirmDelete = () => {
    setIsDeleteApp(false);
    ondelete(rowToDelete);
  };
  const handleConfirmDeleted = (row) => {
    setIsDeleted(false);
    getProjectListData(connectedAppList);
  };
  const handleCancelDelete = (row) => {
    setIsDeleteApp(false);
  };
  // This is a custom filter UI for selecting
  // a unique option from a list
  function SelectColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
  }) {
    // Calculate the options for filtering
    // using the preFilteredRows
    const options = useMemo(() => {
      const options = new Set();
      preFilteredRows.forEach((row) => {
        options.add(row.values[id]);
      });
      return [...options.values()];
    }, [id, preFilteredRows]);

    // Render a multi-select box
    return (
      <select
        className="form-select"
        value={filterValue}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
      >
        <option value="">All</option>
        {options.map((option, i) => (
          <option key={i} value={option}>
            {option}
          </option>
        ))}
      </select>
    );
  }
  // Define a default UI for filtering
  function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }) {
    const count = preFilteredRows.length;

    return (
      <input
        className="form-control"
        value={filterValue || ""}
        onChange={(e) => {
          setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }}
      />
    );
  }
  const handleClose = () => {
    setIsProject(false);
    setProjectDetail(initialprojectstate);
    setProjectDetailError(initialprojecterrorstate);
    //getProjectListData(connectedAppList);
    setIsSuccess(false);
    setIsSuccess1(false);
    setDisplayMsg("");
    setIsDetailProject(false);
    setChangeProjectNumber(false);
    setConfirmationToChangeProjectNumber(false);
  };

  const handleAddButton = () => {
    console.log("CPAccountID", CPAccountID);
    GetCommandPostNewProjectNumber(CPAccountID);
    let _initialprojectstate = { ...initialprojectstate };
    let _projectStatusList = projectStatusList?.filter(
      (list) => list.value?.toLowerCase() === "a"
    );
    _initialprojectstate.projectStatus = _projectStatusList[0].value;
    _initialprojectstate.jobStatusCodes = _projectStatusList[0]._id;
    _initialprojectstate.jobStatusCodesName = _projectStatusList[0].label;
    _initialprojectstate.jobStatusSortIndex = _projectStatusList[0].index;
    _initialprojectstate.projectNumInt = newProjectNumber?.initialJobNumber;
    _initialprojectstate.projectNumPre = newProjectNumber?.initialJobNumberPre;
    setIsProject(true);
    setProjectDetail(_initialprojectstate);
    setIsaddReady(true);
    setIsEditReady(false);
  };
  const handleCheckExistInProjectList = async () => {
    if (validEditForm()) {
      let obj = {
        projectNumber:
          projectDetail.projectNumPre + "-" + projectDetail.projectNumInt,
      };
      //  console.log("obj", obj);
      let result = await GetDetailProjectListByFilter(obj);
      console.log("result", result);
      if (result && result.length > 0) {
        setProjectNumberAlreadyExist(true);
      } else {
        setConfirmationToChangeProjectNumber(true);
      }
    }
  };
  const handleUpdateOnlyProjectNumber = async () => {
    await UpdateProjectByID_ProjectNumber();
  };

  return (
    <div>
      <h3 align="center">Project list</h3>
      <div className="d-flex justify-content-end">
        <Button
          onClick={() => {
            handleAddButton();
          }}
          className="btn btn-lg mb-2"
        >
          Add
          {/* <span className="fa-stack fa-2x">
          <i className="far fa-circle fa-stack-2x"></i>
          <i className="fa fa-plus fa-stack-1x"></i>
        </span> */}
        </Button>
      </div>
      <div className="table-responsive">
        <GenericListTable
          gcolumns={appColumns}
          gdata={data.length > 0 ? JSON.stringify(data) : ""}
          ghiddencolumns={hiddenappColumns}
          gplaceholder={"Search project list.."}
          gsearchDisabled={false}
          filterDisabled={true}
        />
        <div className="text-center">
          {noRecord && (
            <span className="text-danger fw-bold">
              {appConstants.import.handlefieldvalidation.nodata}
            </span>
          )}
        </div>
      </div>
      <div className="p-1"></div>
      {isDeleteApp && (
        <SweetAlert
          warning
          style={{ color: "black" }}
          title=""
          confirmBtnText="Delete"
          cancelBtnText="No"
          showCancel={true}
          onConfirm={handleConfirmDelete}
          onCancel={handleCancelDelete}
        >
          <div>{appConstants.import.confirmationmsg.deleteproject}</div>
        </SweetAlert>
      )}
      {isDeleted && (
        <SweetAlert
          success
          style={{ color: "black" }}
          title=""
          confirmBtnText="Ok"
          onConfirm={handleConfirmDeleted}
        >
          <div>{appConstants.import.alertmsg.projectdeletesucessfull}</div>
        </SweetAlert>
      )}
      {isProject && (isAddReady || isEditReady) && (
        <Modal
          scrollable={true}
          show={isProject && (isAddReady || isEditReady)}
          onHide={handleClose}
          size="xl"
          centered
        >
          <Modal.Header className="fw-bold h4">
            {projectDetail?._id === undefined ? "Add project" : "Edit project"}
            <a
              style={{ "text-decoration": "none" }}
              onClick={handleClose}
              className="col d-flex justify-content-end text-dark"
            >
              <i class="fa-solid fa-xmark"></i>
            </a>
          </Modal.Header>
          <Modal.Body>
            {" "}
            <div className="container p-3">
              <div className="p-1">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="h4 fw-bold">Project information </div>
                    <hr />

                    <div className="row p-1">
                      <div className="col-sm-12 col-md-4 col-lg-4">
                        Project number<label className="text-danger">*</label>
                      </div>
                      <div className="col-sm-1 col-md-1 col-lg-1"></div>
                      {projectDetail._id === undefined ? (
                        <div className="col-sm-6 col-md-3 col-lg-6">
                          <div className="row">
                            <div className="col-lg-6">
                              {" "}
                              <input
                                type="text"
                                name="projectNumPre"
                                id="projectNumPre"
                                // disabled={true}
                                placeholder="Project number pre"
                                className="form-control"
                                value={projectDetail?.projectNumPre}
                                onChange={handleChange}
                              />
                              {projectDetailError.projectNumPre.length > 0 && (
                                <div className="text-danger">
                                  {projectDetailError.projectNumPre}
                                </div>
                              )}
                            </div>
                            <div className="col-lg-6">
                              {" "}
                              <input
                                type="number"
                                name="projectNumInt"
                                id="projectNumInt"
                                // disabled={true}
                                placeholder="Project number int"
                                className="form-control"
                                value={projectDetail?.projectNumInt}
                                onChange={handleChange}
                              />
                              {projectDetailError.projectNumInt.length > 0 && (
                                <div className="text-danger">
                                  {projectDetailError.projectNumInt}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="col-sm-6 col-md-3 col-lg-6">
                          {projectDetail?.projectNumber}
                        </div>
                      )}
                    </div>
                    <div className="row p-1">
                      <div className="col-sm-12 col-md-4 col-lg-4">
                        Project name<label className="text-danger">*</label>
                      </div>
                      <div className="col-sm-1 col-md-1 col-lg-1"></div>
                      <div className="col-sm-12 col-md-4 col-lg-6">
                        <input
                          type="text"
                          name="projectName"
                          id="projectName"
                          placeholder="Project name"
                          className="form-control"
                          value={projectDetail?.projectName}
                          onChange={handleChange}
                        />
                        {projectDetailError.projectName.length > 0 && (
                          <div className="text-danger">
                            {projectDetailError.projectName}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="row p-1">
                      <div className="col-sm-12 col-md-4 col-lg-4">
                        Alternate project number
                      </div>
                      <div className="col-sm-1 col-md-1 col-lg-1"></div>
                      <div className="col-sm-12 col-md-4 col-lg-6">
                        <input
                          type="text"
                          name="altProjectNumber"
                          disabled={
                            projectDetail?._id === undefined ? false : true
                          }
                          id="altProjectNumber"
                          placeholder="Alternate project number"
                          className="form-control"
                          value={projectDetail?.altProjectNumber}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="row p-1">
                      <div className="col-sm-12 col-md-4 col-lg-4">
                        {" "}
                        Project status<label className="text-danger">*</label>
                      </div>
                      <div className="col-sm-1 col-md-1 col-lg-1"></div>
                      {projectDetail?._id === undefined ? (
                        <div className="col-sm-12 col-md-4 col-lg-6">
                          <Select
                            name="projectStatus"
                            options={projectStatusList}
                            className="form-control"
                            isSearchable={true}
                            isDisabled={
                              projectDetail?._id === undefined ? false : true
                            }
                            styles={customstyles}
                            value={projectStatusList?.filter(
                              (record) =>
                                record.value === projectDetail?.projectStatus
                            )}
                            onChange={(val) => {
                              handleDropDownChange({
                                target: {
                                  name: "projectStatus",
                                  value: val,
                                },
                              });
                            }}
                          ></Select>
                          {projectDetailError?.projectStatus.length > 0 && (
                            <div className="text-danger">
                              {projectDetailError?.projectStatus}
                            </div>
                          )}
                        </div>
                      ) : (
                        <div className="col-sm-12 col-md-4 col-lg-6">
                          {projectStatusList?.map((option) =>
                            option.value === projectDetail?.projectStatus ? (
                              <span>{option.label}</span>
                            ) : (
                              ""
                            )
                          )}
                        </div>
                      )}
                    </div>
                    <div className="row p-1">
                      <div className="col-sm-12 col-md-4 col-lg-4">
                        APN (Accessor's parcel number)
                      </div>
                      <div className="col-sm-1 col-md-1 col-lg-1"></div>
                      {projectDetail._id === undefined ? (
                        <div className="col-sm-12 col-md-4 col-lg-6">
                          <input
                            type="text"
                            name="apn"
                            id="apn"
                            placeholder="Accessor's parcel number"
                            className="form-control"
                            value={projectDetail?.apn}
                            onChange={handleChange}
                          />
                        </div>
                      ) : (
                        <div className="col-sm-12 col-md-4 col-lg-6">
                          {projectDetail?.apn}
                        </div>
                      )}
                    </div>
                    {/*Address Array  */}
                    {projectDetail?.projectAddressArr?.map((row, index) => {
                      return (
                        <div className="row p-1">
                          <div className="col-sm-12 col-md-4 col-lg-4">
                            {index === 0 && "Project address info "}
                            <i class="fa fa-search"></i>
                          </div>
                          <div class="form-check col-sm-12 col-md-4 col-lg-1  ">
                            <input
                              class="form-check-input star"
                              type="checkbox"
                              name={"isMain_" + index}
                              id={"isMain_" + index}
                              checked={row.isPrimary === true ? true : false}
                              onChange={(e) => {
                                let _addressArray = [
                                  ...projectDetail?.projectAddressArr,
                                ];
                                _addressArray[index].isPrimary = e.target
                                  .checked
                                  ? true
                                  : false;
                                _addressArray.map((address, addressindex) => {
                                  if (index !== addressindex) {
                                    address.isPrimary = false;
                                  }
                                });
                                setProjectDetail({
                                  ...projectDetail,
                                  ["poc_addressArr"]: _addressArray,
                                });
                              }}
                              data-gtm-form-interact-field-id="0"
                            />
                          </div>
                          <div className="col-sm-12 col-md-4 col-lg-6">
                            <div className="mt-1">
                              <input
                                type="text"
                                name="address1"
                                placeholder="Address 1"
                                id="address1"
                                className="form-control"
                                value={row?.address1}
                                ref={onInputElementCreated}
                                onChange={(e) =>
                                  handleProjectChangeAddress(
                                    e,
                                    index,
                                    "address1"
                                  )
                                }
                              />
                            </div>
                            <div className="mt-1">
                              <input
                                type="text"
                                name="address2"
                                id="address2"
                                className="form-control"
                                placeholder="Address 2"
                                value={row?.address2}
                                onChange={(e) =>
                                  handleProjectChangeAddress(
                                    e,
                                    index,
                                    "address2"
                                  )
                                }
                              />
                            </div>

                            <div className="mt-1">
                              {" "}
                              <input
                                type="text"
                                name="city"
                                id="city"
                                placeholder="City"
                                className="form-control"
                                value={row?.city}
                                onChange={(e) =>
                                  handleProjectChangeAddress(e, index, "city")
                                }
                              />
                            </div>

                            <div className="mt-1 row ">
                              <div className="col-lg-6">
                                {" "}
                                <input
                                  type="text"
                                  name="state"
                                  id="state"
                                  placeholder="State"
                                  className="form-control"
                                  value={row?.state}
                                  onChange={(e) =>
                                    handleProjectChangeAddress(
                                      e,
                                      index,
                                      "state"
                                    )
                                  }
                                />
                              </div>
                              <div className="col-lg-6">
                                {" "}
                                <input
                                  type="number"
                                  name="zipCode"
                                  id="zipCode"
                                  className="form-control"
                                  placeholder="zip Code"
                                  value={row?.zipCode}
                                  onChange={(e) =>
                                    handleProjectChangeAddress(
                                      e,
                                      index,
                                      "zipCode"
                                    )
                                  }
                                />
                              </div>
                            </div>
                            <div className="mt-1">
                              <input
                                type="text"
                                name="country"
                                id="country"
                                className="form-control"
                                placeholder="Country"
                                value={row?.country}
                                onChange={(e) =>
                                  handleProjectChangeAddress(
                                    e,
                                    index,
                                    "country"
                                  )
                                }
                              />
                            </div>
                            <div className="mt-1">
                              {" "}
                              <input
                                type="text"
                                name="label"
                                id="label"
                                className="form-control"
                                placeholder="Label"
                                value={row.label}
                                onChange={(e) =>
                                  handleProjectChangeAddress(e, index, "label")
                                }
                              />
                            </div>
                            <div className="mt-1">
                              {" "}
                              <input
                                type="text"
                                name="lng"
                                id="lng"
                                placeholder="Longitude"
                                className="form-control"
                                value={row.lng}
                                onChange={(e) =>
                                  handleProjectChangeAddress(e, index, "lng")
                                }
                              />
                            </div>
                            <div className="mt-1">
                              {" "}
                              <input
                                type="text"
                                name="lat"
                                id="lat"
                                placeholder="Longitude"
                                className="form-control"
                                value={row.lat}
                                onChange={(e) =>
                                  handleProjectChangeAddress(e, index, "lat")
                                }
                              />
                            </div>
                          </div>
                        </div>
                      );
                    })}
                    <div className="h4 fw-bold mt-3">Contract information</div>
                    <hr />
                    <div className="row p-1">
                      <div className="col-sm-12 col-md-4 col-lg-4">
                        {" "}
                        Construction start date (MM/DD/YYYY)
                        <label className="text-danger">*</label>
                      </div>
                      <div className="col-sm-1 col-md-1 col-lg-1"></div>

                      {isAddReady && (
                        <div className="col-sm-12 col-md-4 col-lg-6">
                          <input
                            type="date"
                            name="original_ContractStartDate"
                            id="original_ContractStartDate"
                            max={Moment().format(
                              projectDetail?.original_ContractEndDate,
                              "YYYY-MM-DD"
                            )}
                            className="form-control"
                            value={
                              projectDetail?.original_ContractStartDate || ""
                            }
                            onChange={handleChange}
                          />
                          {projectDetailError.original_ContractStartDate
                            .length > 0 && (
                            <div className="text-danger">
                              {projectDetailError.original_ContractStartDate}
                            </div>
                          )}
                        </div>
                      )}
                      {isEditReady && (
                        <div className="col-sm-12 col-md-4 col-lg-6">
                          {(projectDetail?.original_ContractStartDate &&
                            Moment(
                              projectDetail?.original_ContractStartDate
                            ).format("l")) ||
                            ""}
                        </div>
                      )}
                    </div>
                    <div className="row p-1">
                      <div className="col-sm-12 col-md-4 col-lg-4">
                        {" "}
                        Construction end date (MM/DD/YYYY)
                        <label className="text-danger">*</label>
                      </div>
                      <div className="col-sm-1 col-md-1 col-lg-1"></div>
                      {isAddReady && (
                        <div className="col-sm-12 col-md-4 col-lg-6">
                          <input
                            type="date"
                            name="original_ContractEndDate"
                            id="original_ContractEndDate"
                            min={Moment().format(
                              projectDetail?.original_ContractStartDate,
                              "YYYY-MM-DD"
                            )}
                            className="form-control"
                            value={
                              projectDetail?.original_ContractEndDate || ""
                            }
                            onChange={handleChange}
                          />
                          {projectDetailError.original_ContractEndDate.length >
                            0 && (
                            <div className="text-danger">
                              {projectDetailError.original_ContractEndDate}
                            </div>
                          )}
                        </div>
                      )}
                      {isEditReady && (
                        <div className="col-sm-12 col-md-4 col-lg-6">
                          {(projectDetail?.original_ContractEndDate &&
                            Moment(
                              projectDetail?.original_ContractEndDate
                            ).format("l")) ||
                            ""}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div></div>
              {isChangeProjectNumber && (
                <Modal
                  scrollable={true}
                  show={isChangeProjectNumber}
                  onHide={() => setChangeProjectNumber(false)}
                  size="lg"
                  centered
                  style={{
                    "background-color": "rgba(0,0,0,0.5)",
                    position: "fixed",
                    height: "100%",
                    width: " 100%",
                    top: 0,
                    left: 0,
                    display: "flex",
                    "align-items": "center",
                    "justify-content": "center",
                    overflow: "auto",
                    "z-index": "99999",
                  }}
                >
                  <Modal.Header className="fw-bold h4">
                    Sync parent project number
                    <a
                      style={{ "text-decoration": "none" }}
                      onClick={() => setChangeProjectNumber(false)}
                      className="col d-flex justify-content-end text-dark"
                    >
                      <i class="fa-solid fa-xmark"></i>
                    </a>
                  </Modal.Header>
                  <Modal.Body>
                    <div>
                      <div className="row p-1">
                        <div className="col-sm-12 col-md-4 col-lg-4">
                          Old project number
                        </div>
                        <div className="col-sm-1 col-md-1 col-lg-1"></div>
                        <div className="col-sm-6 col-md-3 col-lg-6">
                          {projectDetail?.projectNumber}
                        </div>
                      </div>
                      <div className="row p-1">
                        <div className="col-sm-12 col-md-4 col-lg-4">
                          New project number
                          <label className="text-danger">*</label>
                        </div>
                        <div className="col-sm-1 col-md-1 col-lg-1"></div>

                        <div className="col-sm-6 col-md-3 col-lg-6">
                          <div className="row">
                            <div className="col-lg-5">
                              {" "}
                              <input
                                type="text"
                                name="projectNumPre"
                                id="projectNumPre"
                                // disabled={true}
                                placeholder="Project number pre"
                                className="form-control"
                                value={projectDetail?.projectNumPre}
                                onChange={handleChange}
                              />
                              {projectDetailError.projectNumPre.length > 0 && (
                                <div className="text-danger">
                                  {projectDetailError.projectNumPre}
                                </div>
                              )}
                            </div>
                            -{" "}
                            <div className="col-lg-6">
                              {" "}
                              <input
                                type="number"
                                name="projectNumInt"
                                id="projectNumInt"
                                // disabled={true}
                                placeholder="Project number int"
                                className="form-control"
                                value={projectDetail?.projectNumInt}
                                onChange={handleChange}
                              />
                              {projectDetailError.projectNumInt.length > 0 && (
                                <div className="text-danger">
                                  {projectDetailError.projectNumInt}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      {isConfirmationToChangeProjectNumber && (
                        <SweetAlert
                          style={{ color: "black" }}
                          confirmBtnText="Yes"
                          cancelBtnText="No"
                          showCancel={true}
                          // onConfirm={handleConfirmDelete}
                          onCancel={() =>
                            setConfirmationToChangeProjectNumber(false)
                          }
                          // title="Do you want change the project number"
                          onConfirm={handleUpdateOnlyProjectNumber}
                        >
                          {appConstants.import.confirmationmsg.projectnumber}
                        </SweetAlert>
                      )}
                      {isProjectNumberAlreadyExist && (
                        <SweetAlert
                          style={{ color: "black" }}
                          info
                          confirmBtnText="Ok"
                          onConfirm={() => setProjectNumberAlreadyExist(false)}
                        >
                          Project number already exist
                        </SweetAlert>
                      )}
                      {isSuccess1 && (
                        <SweetAlert
                          success
                          style={{ color: "black" }}
                          title={displayMsg}
                          onConfirm={handleClose}
                        ></SweetAlert>
                      )}
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    {" "}
                    <div className="row p-1">
                      {" "}
                      <div className=" d-flex justify-content-end mt-3 col-sm-12 col-md-12 col-lg-12">
                        <button
                          className=" btn btn-primary me-2 "
                          onClick={() => setChangeProjectNumber(false)}
                        >
                          Close
                        </button>
                        <button
                          className=" btn btn-primary me-2 "
                          onClick={handleCheckExistInProjectList}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </Modal.Footer>
                </Modal>
              )}
              {isSuccess && (
                <SweetAlert
                  success
                  style={{ color: "black" }}
                  title={displayMsg}
                  onConfirm={handleClose}
                ></SweetAlert>
              )}
              {isAlreadyExist && (
                <SweetAlert
                  warning
                  style={{ color: "black" }}
                  title={displayMsg}
                  onConfirm={() => {
                    setIsAlreadyExist(false);
                    setDisplayMsg("");
                  }}
                ></SweetAlert>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            {" "}
            <div className="row p-1">
              {" "}
              <div className=" d-flex justify-content-end mt-3 col-sm-12 col-md-12 col-lg-12">
                <button
                  className=" btn btn-primary me-2 "
                  onClick={handleClose}
                >
                  Close
                </button>
                {projectDetail?._id === undefined ? (
                  <button
                    className="btn btn-primary me-2 "
                    // disabled={!isvaliddata}
                    onClick={AddSubmitHandler}
                  >
                    Save
                  </button>
                ) : (
                  <span>
                    <button
                      className="btn btn-primary me-2 "
                      // disabled={!isvaliddata}
                      onClick={EditSubmitHandler}
                    >
                      Update
                    </button>
                    <button
                      className="btn btn-primary me-2 "
                      // disabled={!isvaliddata}
                      onClick={UpdateProjectNumberPopup}
                    >
                      Update project# to connect apps
                    </button>
                  </span>
                )}
              </div>
            </div>
          </Modal.Footer>
        </Modal>
      )}

      {isDetailProject && (
        <Modal
          scrollable={true}
          show={isDetailProject}
          onHide={handleClose}
          size="xl"
          centered
        >
          <Modal.Header className="fw-bold h4">
            Detail project
            <a
              style={{ "text-decoration": "none" }}
              onClick={handleClose}
              className="col d-flex justify-content-end text-dark"
            >
              <i class="fa-solid fa-xmark"></i>
            </a>
          </Modal.Header>
          <Modal.Body>
            {" "}
            <div className="container">
              <div className="">
                <div className="row">
                  <div className="col-lg-12">
                    <div className=" row">
                      <div className="col-sm-12 col-md-4 col-lg-12">
                        {projectDetail?.projectName !== undefined &&
                          projectDetail?.projectName !== "" && (
                            <span className="h4 fw-bold d-flex justify-content-start mt-4">
                              {projectDetail?.projectName}
                            </span>
                          )}

                        <span className="d-flex justify-content-end">
                          <LOBAppShortLogoCards
                            projectDetail={projectDetail}
                            connectedAppList={connectedAppList}
                          />
                        </span>
                      </div>{" "}
                    </div>

                    <hr />
                    {projectDetail?.projectNumber !== undefined &&
                      projectDetail?.projectNumber !== "" && (
                        <div className="row p-1">
                          <div className="col-sm-12 col-md-3 col-lg-3 ">
                            {" "}
                            Project number
                          </div>
                          <div className="col-sm-12 col-md-9 col-lg-9">
                            {projectDetail?.projectNumber}
                          </div>
                        </div>
                      )}
                    {projectDetail?.altProjectNumber !== undefined &&
                      projectDetail?.altProjectNumber !== "" && (
                        <div className="row p-1">
                          <div className="col-sm-12 col-md-3 col-lg-3 ">
                            {" "}
                            Alternate project number
                          </div>
                          <div className="col-sm-12 col-md-9 col-lg-9">
                            {projectDetail?.altProjectNumber}
                          </div>
                        </div>
                      )}
                    {projectDetail?.projectStatus !== undefined &&
                      projectDetail?.projectStatus !== "" && (
                        <div className="row p-1">
                          <div className="col-sm-12 col-md-3 col-lg-3 ">
                            {" "}
                            Project status
                          </div>
                          <div className="col-sm-12 col-md-9 col-lg-9">
                            {projectStatusList?.map((option) =>
                              option.value === projectDetail?.projectStatus ? (
                                <span>{option.label}</span>
                              ) : (
                                ""
                              )
                            )}
                          </div>
                        </div>
                      )}
                    {projectDetail?.apn !== undefined &&
                      projectDetail?.apn !== "" && (
                        <div className="row p-1">
                          <div className="col-sm-12 col-md-3 col-lg-3 ">
                            {" "}
                            APN (Assessor’s parcel number)
                          </div>
                          <div className="col-sm-12 col-md-9 col-lg-9">
                            {projectDetail?.apn}
                          </div>
                        </div>
                      )}
                    {projectDetail?.projectAddressArr?.length > 0 && (
                      <div className="row p-1 ">
                        <div className="col-sm-12 col-md-3 col-lg-3">
                          {" "}
                          Project address info
                        </div>
                        <div className="col-sm-12 col-md-9 col-lg-9">
                          {projectDetail?.projectAddressArr?.length > 0 &&
                            projectDetail?.projectAddressArr?.map(
                              (row, index) => {
                                return (
                                  <div className="row mb-2">
                                    <div className="col-sm-12 col-md-10 col-lg-10">
                                      {" "}
                                      <div>
                                        <div>
                                          {(row?.address1 &&
                                            row?.address1 + ",") ||
                                            ""}

                                          <div></div>
                                          {(row?.address2 &&
                                            row?.address2 + ",") ||
                                            ""}
                                        </div>
                                        <div>
                                          {(row?.city && row?.city + ",") || ""}
                                        </div>
                                        <div>
                                          {(row?.state && row?.state + ",") ||
                                            ""}
                                        </div>
                                        <div>
                                          {(row?.country &&
                                            row?.country + ",") ||
                                            ""}
                                        </div>
                                        <div>
                                          {(row?.zipCode &&
                                            row?.zipCode + ",") ||
                                            ""}
                                        </div>
                                        <div>
                                          {(row?.lat && row?.lat + ",") || ""}
                                        </div>
                                        <div>
                                          {(row?.lng && row?.lng + ",") || ""}
                                        </div>
                                      </div>{" "}
                                    </div>
                                    <div className="col-sm-12 col-md-2 col-lg-2">
                                      <span>
                                        {" "}
                                        {row?.label?.length > 0
                                          ? "  (" + row?.label + ")"
                                          : ""}
                                      </span>
                                      <span className="ms-2">
                                        {row?.isPrimary === true ? (
                                          <i
                                            class="fa-solid fa-star"
                                            style={{ color: "#ffd43b" }}
                                          ></i>
                                        ) : (
                                          ""
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                );
                              }
                            )}
                        </div>
                      </div>
                    )}

                    {projectDetail?.pocArr?.length > 0 && (
                      <div className="row p-1 ">
                        <div className="h4 fw-bold mt-3">
                          Project POC information{" "}
                        </div>
                        <hr />
                        {projectDetail?.pocArr?.length > 0 &&
                          projectDetail?.pocArr?.map((row, index) => {
                            return (
                              <div>
                                {index !== 0 && (
                                  <div className="row">
                                    <span className="col">
                                      <hr />
                                    </span>
                                  </div>
                                )}
                                {row?.firstName !== undefined &&
                                  row?.firstName !== "" && (
                                    <div className="row p-1">
                                      <div className="col-sm-12 col-md-3 col-lg-3 ">
                                        {" "}
                                        First name
                                      </div>
                                      <div className="col-sm-12 col-md-9 col-lg-9">
                                        {row?.firstName}
                                      </div>
                                    </div>
                                  )}
                                {row?.lastName !== undefined &&
                                  row?.lastName !== "" && (
                                    <div className="row p-1">
                                      <div className="col-sm-12 col-md-3 col-lg-3 ">
                                        {" "}
                                        Last name
                                      </div>
                                      <div className="col-sm-12 col-md-9 col-lg-9">
                                        {row?.lastName}
                                      </div>
                                    </div>
                                  )}
                                {row?.companyName !== undefined &&
                                  row?.companyName !== "" && (
                                    <div className="row p-1">
                                      <div className="col-sm-12 col-md-3 col-lg-3 ">
                                        {" "}
                                        Company name
                                      </div>
                                      <div className="col-sm-12 col-md-9 col-lg-9">
                                        {row?.companyName}
                                      </div>
                                    </div>
                                  )}
                                {row?.taxId && (
                                  <div className="row p-1">
                                    <div className="col-sm-12 col-md-3 col-lg-3 ">
                                      {" "}
                                      Tax ID
                                    </div>
                                    <div className="col-sm-12 col-md-9 col-lg-9">
                                      {row?.taxId}
                                    </div>
                                  </div>
                                )}
                                {/* Email Array */}
                                {row?.emailArr?.length > 0 && (
                                  <div className="row p-1 ">
                                    <div className="col-lg-4">Email info</div>
                                    <div className="col-lg-9">
                                      {row?.emailArr?.length > 0 &&
                                        row?.emailArr?.map((row, index) => {
                                          return (
                                            <div>
                                              {row.email !== "" && (
                                                <div className="row mb-2">
                                                  <div className="col-sm-12 col-md-10 col-lg-10">
                                                    {" "}
                                                    <span>
                                                      {row?.email}
                                                      <br />
                                                    </span>{" "}
                                                  </div>
                                                  <div className="col-sm-12 col-md-2 col-lg-2">
                                                    <span>
                                                      {row?.label?.length > 0
                                                        ? " (" +
                                                          row?.label +
                                                          ")"
                                                        : ""}
                                                    </span>
                                                    <span className="ms-2">
                                                      {row?.isPrimary ===
                                                      true ? (
                                                        <i
                                                          class="fa-solid fa-star"
                                                          style={{
                                                            color: "#ffd43b",
                                                          }}
                                                        ></i>
                                                      ) : (
                                                        ""
                                                      )}
                                                    </span>
                                                  </div>
                                                </div>
                                              )}
                                            </div>
                                          );
                                        })}
                                    </div>
                                  </div>
                                )}
                                {/* Phone Array */}
                                {row?.phoneArr?.length > 0 && (
                                  <div className="row p-1 ">
                                    <div className="col-lg-3">Phone info</div>
                                    <div className="col-lg-9">
                                      {row?.phoneArr?.length > 0 &&
                                        row?.phoneArr?.map((row, index) => {
                                          return (
                                            <div>
                                              {row.fullPhone !== "" && (
                                                <div className="row">
                                                  <div className="col-sm-12 col-md-10 col-lg-10 mb-2">
                                                    <span className="">
                                                      {(row?.fullPhone &&
                                                        row?.fullPhone) ||
                                                        ""}
                                                      {(row?.extPhone &&
                                                        ", extn " +
                                                          row?.extPhone) ||
                                                        ""}
                                                      <br />
                                                    </span>
                                                  </div>
                                                  <div className="col-sm-12 col-md-2 col-lg-2">
                                                    <span>
                                                      {" "}
                                                      {row?.type?.length > 0
                                                        ? "  (" +
                                                          row?.type +
                                                          ")"
                                                        : ""}
                                                    </span>
                                                    <span className="ms-2">
                                                      {row?.isPrimary ===
                                                      true ? (
                                                        <i
                                                          class="fa-solid fa-star"
                                                          style={{
                                                            color: "#ffd43b",
                                                          }}
                                                        ></i>
                                                      ) : (
                                                        ""
                                                      )}
                                                    </span>
                                                  </div>
                                                </div>
                                              )}{" "}
                                            </div>
                                          );
                                        })}
                                    </div>
                                  </div>
                                )}
                                {/*Address Array  */}
                                {row.addressArr?.length > 0 && (
                                  <div className="row p-1 ">
                                    <div className="col-lg-3">
                                      {" "}
                                      Address info
                                    </div>
                                    <div className="col-lg-9">
                                      {row.addressArr?.length > 0 &&
                                        row.addressArr?.map((row, index) => {
                                          return (
                                            <div className="row mb-2">
                                              <div className="col-sm-12 col-md-10 col-lg-10">
                                                {" "}
                                                <div>
                                                  <div>
                                                    {(row?.address1 &&
                                                      row?.address1 + ",") ||
                                                      ""}

                                                    <div></div>
                                                    {(row?.address2 &&
                                                      row?.address2 + ",") ||
                                                      ""}
                                                  </div>
                                                  <div>
                                                    {(row?.city &&
                                                      row?.city + ",") ||
                                                      ""}
                                                  </div>
                                                  <div>
                                                    {(row?.state &&
                                                      row?.state + ",") ||
                                                      ""}
                                                  </div>
                                                  <div>
                                                    {(row?.country &&
                                                      row?.country + ",") ||
                                                      ""}
                                                  </div>
                                                  <div>
                                                    {(row?.zipCode &&
                                                      row?.zipCode + ",") ||
                                                      ""}
                                                  </div>
                                                  <div>
                                                    {(row?.lat &&
                                                      row?.lat + ",") ||
                                                      ""}
                                                  </div>
                                                  <div>
                                                    {(row?.lng &&
                                                      row?.lng + ",") ||
                                                      ""}
                                                  </div>
                                                </div>{" "}
                                              </div>
                                              <div className="col-sm-12 col-md-2 col-lg-2">
                                                <span>
                                                  {" "}
                                                  {row?.label?.length > 0
                                                    ? "  (" + row?.label + ")"
                                                    : ""}
                                                </span>
                                                <span className="ms-2">
                                                  {row?.isPrimary === true ? (
                                                    <i
                                                      class="fa-solid fa-star"
                                                      style={{
                                                        color: "#ffd43b",
                                                      }}
                                                    ></i>
                                                  ) : (
                                                    ""
                                                  )}
                                                </span>
                                              </div>
                                            </div>
                                          );
                                        })}
                                    </div>
                                  </div>
                                )}
                              </div>
                            );
                          })}
                      </div>
                    )}
                    {projectDetail?.apNum !== undefined &&
                      projectDetail?.apNum !== "" && (
                        <div className="row p-1">
                          <div className="col-sm-12 col-md-3 col-lg-3 ">
                            {" "}
                            APN (Assessor’s parcel number)
                          </div>
                          <div className="col-sm-12 col-md-9 col-lg-9">
                            {projectDetail?.apNum}
                          </div>
                        </div>
                      )}

                    {projectDetail?.primeArr &&
                      projectDetail?.primeArr?.length > 0 && (
                        <div className="row p-1 ">
                          <div className="h4 fw-bold mt-3">
                            Prime information
                          </div>
                          <hr />
                          {projectDetail?.primeArr?.length > 0 &&
                            projectDetail?.primeArr?.map((row, index) => {
                              return (
                                <div>
                                  {index !== 0 && (
                                    <div className="row">
                                      <span className="col">
                                        <hr />
                                      </span>
                                    </div>
                                  )}

                                  {row?.companyName !== undefined &&
                                    row?.companyName !== "" && (
                                      <div className="row p-1">
                                        <div className="col-sm-12 col-md-3 col-lg-3 ">
                                          {" "}
                                          Company name
                                        </div>
                                        <div className="col-sm-12 col-md-9 col-lg-9">
                                          {row?.companyName}
                                        </div>
                                      </div>
                                    )}
                                  {row?.taxId && row?.taxId.length > 0 && (
                                    <div className="row p-1">
                                      <div className="col-sm-12 col-md-3 col-lg-3 ">
                                        {" "}
                                        Tax ID
                                      </div>
                                      <div className="col-sm-12 col-md-9 col-lg-9">
                                        {row?.taxId}
                                      </div>
                                    </div>
                                  )}
                                  {/* Email Array */}
                                  {row?.emailArr?.length > 0 && (
                                    <div className="row p-1 ">
                                      <div className="col-lg-3">Email info</div>
                                      <div className="col-lg-9">
                                        {row?.emailArr?.length > 0 &&
                                          row?.emailArr?.map((row, index) => {
                                            return (
                                              <div>
                                                {row.email !== "" && (
                                                  <div className="row mb-2">
                                                    <div className="col-sm-12 col-md-10 col-lg-10">
                                                      {" "}
                                                      <span>
                                                        {row?.email}
                                                        <br />
                                                      </span>{" "}
                                                    </div>
                                                    <div className="col-sm-12 col-md-2 col-lg-2">
                                                      <span>
                                                        {row?.label?.length > 0
                                                          ? " (" +
                                                            row?.label +
                                                            ")"
                                                          : ""}
                                                      </span>
                                                      <span className="ms-2">
                                                        {row?.isPrimary ===
                                                        true ? (
                                                          <i
                                                            class="fa-solid fa-star"
                                                            style={{
                                                              color: "#ffd43b",
                                                            }}
                                                          ></i>
                                                        ) : (
                                                          ""
                                                        )}
                                                      </span>
                                                    </div>
                                                  </div>
                                                )}
                                              </div>
                                            );
                                          })}
                                      </div>
                                    </div>
                                  )}
                                  {/* Phone Array */}
                                  {row?.phoneArr?.length > 0 && (
                                    <div className="row p-1 ">
                                      <div className="col-lg-3">Phone info</div>
                                      <div className="col-lg-9">
                                        {row?.phoneArr?.length > 0 &&
                                          row?.phoneArr?.map((row, index) => {
                                            return (
                                              <div>
                                                {row.fullPhone !== "" && (
                                                  <div className="row">
                                                    <div className="col-sm-12 col-md-10 col-lg-10 mb-2">
                                                      <span className="">
                                                        {(row?.fullPhone &&
                                                          row?.fullPhone) ||
                                                          ""}
                                                        {(row?.extPhone &&
                                                          ", extn " +
                                                            row?.extPhone) ||
                                                          ""}
                                                        <br />
                                                      </span>
                                                    </div>
                                                    <div className="col-sm-12 col-md-2 col-lg-2">
                                                      <span>
                                                        {" "}
                                                        {row?.type?.length > 0
                                                          ? "  (" +
                                                            row?.type +
                                                            ")"
                                                          : ""}
                                                      </span>
                                                      <span className="ms-2">
                                                        {row?.isPrimary ===
                                                        true ? (
                                                          <i
                                                            class="fa-solid fa-star"
                                                            style={{
                                                              color: "#ffd43b",
                                                            }}
                                                          ></i>
                                                        ) : (
                                                          ""
                                                        )}
                                                      </span>
                                                    </div>
                                                  </div>
                                                )}{" "}
                                              </div>
                                            );
                                          })}
                                      </div>
                                    </div>
                                  )}
                                  {/*Address Array  */}
                                  {row.addressArr?.length > 0 && (
                                    <div className="row p-1 ">
                                      <div className="col-lg-3">
                                        {" "}
                                        Address info
                                      </div>
                                      <div className="col-lg-9">
                                        {row.addressArr?.length > 0 &&
                                          row.addressArr?.map((row, index) => {
                                            return (
                                              <div className="row mb-2">
                                                <div className="col-sm-12 col-md-10 col-lg-10">
                                                  {" "}
                                                  <div>
                                                    <div>
                                                      {(row?.address1 &&
                                                        row?.address1 + ",") ||
                                                        ""}

                                                      <div></div>
                                                      {(row?.address2 &&
                                                        row?.address2 + ",") ||
                                                        ""}
                                                    </div>
                                                    <div>
                                                      {(row?.city &&
                                                        row?.city + ",") ||
                                                        ""}
                                                    </div>
                                                    <div>
                                                      {(row?.state &&
                                                        row?.state + ",") ||
                                                        ""}
                                                    </div>
                                                    <div>
                                                      {(row?.country &&
                                                        row?.country + ",") ||
                                                        ""}
                                                    </div>
                                                    <div>
                                                      {(row?.zipCode &&
                                                        row?.zipCode + ",") ||
                                                        ""}
                                                    </div>
                                                    <div>
                                                      {(row?.lat &&
                                                        row?.lat + ",") ||
                                                        ""}
                                                    </div>
                                                    <div>
                                                      {(row?.lng &&
                                                        row?.lng + ",") ||
                                                        ""}
                                                    </div>
                                                  </div>{" "}
                                                </div>
                                                <div className="col-sm-12 col-md-2 col-lg-2">
                                                  <span>
                                                    {" "}
                                                    {row?.label?.length > 0
                                                      ? "  (" + row?.label + ")"
                                                      : ""}
                                                  </span>
                                                  <span className="ms-2">
                                                    {row?.isPrimary === true ? (
                                                      <i
                                                        class="fa-solid fa-star"
                                                        style={{
                                                          color: "#ffd43b",
                                                        }}
                                                      ></i>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </span>
                                                </div>
                                              </div>
                                            );
                                          })}
                                      </div>
                                    </div>
                                  )}
                                </div>
                              );
                            })}
                        </div>
                      )}

                    {projectDetail?.primepocArr &&
                      projectDetail?.primepocArr?.length > 0 && (
                        <div className="row p-1 ">
                          {projectDetail?.primepocArr?.length > 0 &&
                            projectDetail?.primepocArr?.map((row, index) => {
                              return (
                                <div>
                                  {index !== 0 && (
                                    <div className="row">
                                      <span className="col">
                                        <hr />
                                      </span>
                                    </div>
                                  )}

                                  {row?.firstName && row?.firstName !== "" && (
                                    <div className="row p-1">
                                      <div className="col-sm-12 col-md-3 col-lg-3 ">
                                        {" "}
                                        POC contact
                                      </div>
                                      <div className="col-sm-12 col-md-9 col-lg-9">
                                        {`${row?.firstName || ""} ${
                                          row?.lastName || ""
                                        }`}
                                      </div>
                                    </div>
                                  )}
                                </div>
                              );
                            })}
                        </div>
                      )}

                    {projectDetail?.properyOwnerArr?.length > 0 && (
                      <div className="row p-1 ">
                        <div className="h4 fw-bold mt-3">
                          Property owner information
                        </div>
                        <hr />
                        {projectDetail?.properyOwnerArr?.length > 0 &&
                          projectDetail?.properyOwnerArr?.map((row, index) => {
                            return (
                              <div>
                                {index !== 0 && (
                                  <div className="row">
                                    <span className="col">
                                      <hr />
                                    </span>
                                  </div>
                                )}
                                {row?.firstName !== undefined &&
                                  row?.firstName !== "" && (
                                    <div className="row p-1">
                                      <div className="col-sm-12 col-md-3 col-lg-3 ">
                                        {" "}
                                        First name
                                      </div>
                                      <div className="col-sm-12 col-md-9 col-lg-9">
                                        {row?.firstName}
                                      </div>
                                    </div>
                                  )}
                                {row?.lastName !== undefined &&
                                  row?.lastName !== "" && (
                                    <div className="row p-1">
                                      <div className="col-sm-12 col-md-3 col-lg-3 ">
                                        {" "}
                                        Last name
                                      </div>
                                      <div className="col-sm-12 col-md-9 col-lg-9">
                                        {row?.lastName}
                                      </div>
                                    </div>
                                  )}
                                {row?.companyName !== undefined &&
                                  row?.companyName !== "" && (
                                    <div className="row p-1">
                                      <div className="col-sm-12 col-md-3 col-lg-3 ">
                                        {" "}
                                        Company name
                                      </div>
                                      <div className="col-sm-12 col-md-9 col-lg-9">
                                        {row?.companyName}
                                      </div>
                                    </div>
                                  )}
                                {row?.taxId !== undefined &&
                                  row?.taxId !== "" && (
                                    <div className="row p-1">
                                      <div className="col-sm-12 col-md-3 col-lg-3 ">
                                        {" "}
                                        Tax ID
                                      </div>
                                      <div className="col-sm-12 col-md-9 col-lg-9">
                                        {row?.taxId}
                                      </div>
                                    </div>
                                  )}
                                {/* Email Array */}
                                {row?.emailArr?.length > 0 && (
                                  <div className="row p-1 ">
                                    <div className="col-lg-3">Email info</div>
                                    <div className="col-lg-9">
                                      {row?.emailArr?.length > 0 &&
                                        row?.emailArr?.map((row, index) => {
                                          return (
                                            <div>
                                              {row.email !== "" && (
                                                <div className="row mb-2">
                                                  <div className="col-sm-12 col-md-10 col-lg-10">
                                                    {" "}
                                                    <span>
                                                      {row?.email}
                                                      <br />
                                                    </span>{" "}
                                                  </div>
                                                  <div className="col-sm-12 col-md-2 col-lg-2">
                                                    <span>
                                                      {row?.label?.length > 0
                                                        ? " (" +
                                                          row?.label +
                                                          ")"
                                                        : ""}
                                                    </span>
                                                    <span className="ms-2">
                                                      {row?.isPrimary ===
                                                      true ? (
                                                        <i
                                                          class="fa-solid fa-star"
                                                          style={{
                                                            color: "#ffd43b",
                                                          }}
                                                        ></i>
                                                      ) : (
                                                        ""
                                                      )}
                                                    </span>
                                                  </div>
                                                </div>
                                              )}
                                            </div>
                                          );
                                        })}
                                    </div>
                                  </div>
                                )}
                                {/* Phone Array */}
                                {row?.phoneArr?.length > 0 && (
                                  <div className="row p-1 ">
                                    <div className="col-lg-3">Phone info</div>
                                    <div className="col-lg-9">
                                      {row?.phoneArr?.length > 0 &&
                                        row?.phoneArr?.map((row, index) => {
                                          return (
                                            <div>
                                              {row.fullPhone !== "" && (
                                                <div className="row">
                                                  <div className="col-sm-12 col-md-10 col-lg-10 mb-2">
                                                    <span className="">
                                                      {(row?.fullPhone &&
                                                        row?.fullPhone) ||
                                                        ""}
                                                      {(row?.extPhone &&
                                                        ", extn " +
                                                          row?.extPhone) ||
                                                        ""}
                                                      <br />
                                                    </span>
                                                  </div>
                                                  <div className="col-sm-12 col-md-2 col-lg-2">
                                                    <span>
                                                      {" "}
                                                      {row?.type?.length > 0
                                                        ? "  (" +
                                                          row?.type +
                                                          ")"
                                                        : ""}
                                                    </span>
                                                    <span className="ms-2">
                                                      {row?.isPrimary ===
                                                      true ? (
                                                        <i
                                                          class="fa-solid fa-star"
                                                          style={{
                                                            color: "#ffd43b",
                                                          }}
                                                        ></i>
                                                      ) : (
                                                        ""
                                                      )}
                                                    </span>
                                                  </div>
                                                </div>
                                              )}{" "}
                                            </div>
                                          );
                                        })}
                                    </div>
                                  </div>
                                )}
                                {/*Address Array  */}
                                {row.addressArr?.length > 0 && (
                                  <div className="row p-1 ">
                                    <div className="col-lg-3">
                                      {" "}
                                      Address info
                                    </div>
                                    <div className="col-lg-9">
                                      {row.addressArr?.length > 0 &&
                                        row.addressArr?.map((row, index) => {
                                          return (
                                            <div className="row mb-2">
                                              <div className="col-sm-12 col-md-10 col-lg-10">
                                                {" "}
                                                <div>
                                                  <div>
                                                    {(row?.address1 &&
                                                      row?.address1 + ",") ||
                                                      ""}

                                                    <div></div>
                                                    {(row?.address2 &&
                                                      row?.address2 + ",") ||
                                                      ""}
                                                  </div>
                                                  <div>
                                                    {(row?.city &&
                                                      row?.city + ",") ||
                                                      ""}
                                                  </div>
                                                  <div>
                                                    {(row?.state &&
                                                      row?.state + ",") ||
                                                      ""}
                                                  </div>
                                                  <div>
                                                    {(row?.country &&
                                                      row?.country + ",") ||
                                                      ""}
                                                  </div>
                                                  <div>
                                                    {(row?.zipCode &&
                                                      row?.zipCode + ",") ||
                                                      ""}
                                                  </div>
                                                  <div>
                                                    {(row?.lat &&
                                                      row?.lat + ",") ||
                                                      ""}
                                                  </div>
                                                  <div>
                                                    {(row?.lng &&
                                                      row?.lng + ",") ||
                                                      ""}
                                                  </div>
                                                </div>{" "}
                                              </div>
                                              <div className="col-sm-12 col-md-2 col-lg-2">
                                                <span>
                                                  {" "}
                                                  {row?.label?.length > 0
                                                    ? "  (" + row?.label + ")"
                                                    : ""}
                                                </span>
                                                <span className="ms-2">
                                                  {row?.isPrimary === true ? (
                                                    <i
                                                      class="fa-solid fa-star"
                                                      style={{
                                                        color: "#ffd43b",
                                                      }}
                                                    ></i>
                                                  ) : (
                                                    ""
                                                  )}
                                                </span>
                                              </div>
                                            </div>
                                          );
                                        })}
                                    </div>
                                  </div>
                                )}
                              </div>
                            );
                          })}
                      </div>
                    )}

                    {projectDetail?.propertyOwnerpocArr?.length > 0 && (
                      <div className="row p-1 ">
                        <div className="h4 fw-bold mt-3">
                          Property owner POC information
                        </div>
                        <hr />
                        {projectDetail?.propertyOwnerpocArr?.length > 0 &&
                          projectDetail?.propertyOwnerpocArr?.map(
                            (row, index) => {
                              return (
                                <div>
                                  {index !== 0 && (
                                    <div className="row">
                                      <span className="col">
                                        <hr />
                                      </span>
                                    </div>
                                  )}
                                  {row?.firstName !== undefined &&
                                    row?.firstName !== "" && (
                                      <div className="row p-1">
                                        <div className="col-sm-12 col-md-3 col-lg-3 ">
                                          {" "}
                                          First name
                                        </div>
                                        <div className="col-sm-12 col-md-9 col-lg-9">
                                          {row?.firstName}
                                        </div>
                                      </div>
                                    )}
                                  {row?.lastName !== undefined &&
                                    row?.lastName !== "" && (
                                      <div className="row p-1">
                                        <div className="col-sm-12 col-md-3 col-lg-3 ">
                                          {" "}
                                          Last name
                                        </div>
                                        <div className="col-sm-12 col-md-9 col-lg-9">
                                          {row?.lastName}
                                        </div>
                                      </div>
                                    )}
                                  {row?.companyName !== undefined &&
                                    row?.companyName !== "" && (
                                      <div className="row p-1">
                                        <div className="col-sm-12 col-md-3 col-lg-3 ">
                                          {" "}
                                          Company name
                                        </div>
                                        <div className="col-sm-12 col-md-9 col-lg-9">
                                          {row?.companyName}
                                        </div>
                                      </div>
                                    )}
                                  {row?.taxId !== undefined &&
                                    row?.taxId !== "" && (
                                      <div className="row p-1">
                                        <div className="col-sm-12 col-md-3 col-lg-3 ">
                                          {" "}
                                          Tax ID
                                        </div>
                                        <div className="col-sm-12 col-md-9 col-lg-9">
                                          {row?.taxId}
                                        </div>
                                      </div>
                                    )}
                                  {/* Email Array */}
                                  {row?.emailArr?.length > 0 && (
                                    <div className="row p-1 ">
                                      <div className="col-lg-3">Email info</div>
                                      <div className="col-lg-9">
                                        {row?.emailArr?.length > 0 &&
                                          row?.emailArr?.map((row, index) => {
                                            return (
                                              <div>
                                                {row.email !== "" && (
                                                  <div className="row mb-2">
                                                    <div className="col-sm-12 col-md-10 col-lg-10">
                                                      {" "}
                                                      <span>
                                                        {row?.email}
                                                        <br />
                                                      </span>{" "}
                                                    </div>
                                                    <div className="col-sm-12 col-md-2 col-lg-2">
                                                      <span>
                                                        {row?.label?.length > 0
                                                          ? " (" +
                                                            row?.label +
                                                            ")"
                                                          : ""}
                                                      </span>
                                                      <span className="ms-2">
                                                        {row?.isPrimary ===
                                                        true ? (
                                                          <i
                                                            class="fa-solid fa-star"
                                                            style={{
                                                              color: "#ffd43b",
                                                            }}
                                                          ></i>
                                                        ) : (
                                                          ""
                                                        )}
                                                      </span>
                                                    </div>
                                                  </div>
                                                )}
                                              </div>
                                            );
                                          })}
                                      </div>
                                    </div>
                                  )}
                                  {/* Phone Array */}
                                  {row?.phoneArr?.length > 0 && (
                                    <div className="row p-1 ">
                                      <div className="col-lg-3">Phone info</div>
                                      <div className="col-lg-9">
                                        {row?.phoneArr?.length > 0 &&
                                          row?.phoneArr?.map((row, index) => {
                                            return (
                                              <div>
                                                {row.fullPhone !== "" && (
                                                  <div className="row">
                                                    <div className="col-sm-12 col-md-10 col-lg-10 mb-2">
                                                      <span className="">
                                                        {(row?.fullPhone &&
                                                          row?.fullPhone) ||
                                                          ""}
                                                        {(row?.extPhone &&
                                                          ", extn " +
                                                            row?.extPhone) ||
                                                          ""}
                                                        <br />
                                                      </span>
                                                    </div>
                                                    <div className="col-sm-12 col-md-2 col-lg-2">
                                                      <span>
                                                        {" "}
                                                        {row?.type?.length > 0
                                                          ? "  (" +
                                                            row?.type +
                                                            ")"
                                                          : ""}
                                                      </span>
                                                      <span className="ms-2">
                                                        {row?.isPrimary ===
                                                        true ? (
                                                          <i
                                                            class="fa-solid fa-star"
                                                            style={{
                                                              color: "#ffd43b",
                                                            }}
                                                          ></i>
                                                        ) : (
                                                          ""
                                                        )}
                                                      </span>
                                                    </div>
                                                  </div>
                                                )}{" "}
                                              </div>
                                            );
                                          })}
                                      </div>
                                    </div>
                                  )}
                                  {/*Address Array  */}
                                  {row.addressArr?.length > 0 && (
                                    <div className="row p-1 ">
                                      <div className="col-lg-3">
                                        {" "}
                                        Address info
                                      </div>
                                      <div className="col-lg-9">
                                        {row.addressArr?.length > 0 &&
                                          row.addressArr?.map((row, index) => {
                                            return (
                                              <div className="row mb-2">
                                                <div className="col-sm-12 col-md-10 col-lg-10">
                                                  {" "}
                                                  <div>
                                                    <div>
                                                      {(row?.address1 &&
                                                        row?.address1 + ",") ||
                                                        ""}

                                                      <div></div>
                                                      {(row?.address2 &&
                                                        row?.address2 + ",") ||
                                                        ""}
                                                    </div>
                                                    <div>
                                                      {(row?.city &&
                                                        row?.city + ",") ||
                                                        ""}
                                                    </div>
                                                    <div>
                                                      {(row?.state &&
                                                        row?.state + ",") ||
                                                        ""}
                                                    </div>
                                                    <div>
                                                      {(row?.country &&
                                                        row?.country + ",") ||
                                                        ""}
                                                    </div>
                                                    <div>
                                                      {(row?.zipCode &&
                                                        row?.zipCode + ",") ||
                                                        ""}
                                                    </div>
                                                    <div>
                                                      {(row?.lat &&
                                                        row?.lat + ",") ||
                                                        ""}
                                                    </div>
                                                    <div>
                                                      {(row?.lng &&
                                                        row?.lng + ",") ||
                                                        ""}
                                                    </div>
                                                  </div>{" "}
                                                </div>
                                                <div className="col-sm-12 col-md-2 col-lg-2">
                                                  <span>
                                                    {" "}
                                                    {row?.label?.length > 0
                                                      ? "  (" + row?.label + ")"
                                                      : ""}
                                                  </span>
                                                  <span className="ms-2">
                                                    {row?.isPrimary === true ? (
                                                      <i
                                                        class="fa-solid fa-star"
                                                        style={{
                                                          color: "#ffd43b",
                                                        }}
                                                      ></i>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </span>
                                                </div>
                                              </div>
                                            );
                                          })}
                                      </div>
                                    </div>
                                  )}
                                </div>
                              );
                            }
                          )}
                      </div>
                    )}
                    <div className="h4 fw-bold mt-3">Contract information</div>
                    <hr />
                    {projectDetail?.actual_ClientName !== undefined &&
                      projectDetail?.actual_ClientName !== "" && (
                        <div className="row p-1">
                          <div className="col-sm-12 col-md-3 col-lg-3 ">
                            {" "}
                            Actual client name
                          </div>
                          <div className="col-sm-12 col-md-9 col-lg-9">
                            {projectDetail?.actual_ClientName}
                          </div>
                        </div>
                      )}
                    {projectDetail?.original_ContractValue !== undefined &&
                      projectDetail?.original_ContractValue !== "" && (
                        <div className="row p-1">
                          <div className="col-sm-12 col-md-3 col-lg-3 ">
                            {" "}
                            Original contract value
                          </div>
                          <div className="col-sm-12 col-md-9 col-lg-9">
                            {USDollar.format(
                              projectDetail?.original_ContractValue
                            )}
                          </div>
                        </div>
                      )}
                    {projectDetail?.current_ContractValue !== undefined &&
                      projectDetail?.current_ContractValue !== "" && (
                        <div className="row p-1">
                          <div className="col-sm-12 col-md-3 col-lg-3 ">
                            {" "}
                            Current contract value
                          </div>
                          <div className="col-sm-12 col-md-9 col-lg-9">
                            {USDollar.format(
                              projectDetail?.current_ContractValue
                            )}
                          </div>
                        </div>
                      )}
                    {projectDetail?.original_ContractStartDate !== undefined &&
                      projectDetail?.original_ContractStartDate !== "" && (
                        <div className="row p-1">
                          <div className="col-sm-12 col-md-3 col-lg-3 ">
                            {" "}
                            Original contract start date (Baseline)
                          </div>
                          <div className="col-sm-12 col-md-9 col-lg-9">
                            {(projectDetail?.original_ContractStartDate &&
                              Moment(
                                projectDetail?.original_ContractStartDate
                              ).format("l")) ||
                              ""}
                          </div>
                        </div>
                      )}
                    {projectDetail?.current_ContractStartDate !== undefined &&
                      projectDetail?.current_ContractStartDate !== "" && (
                        <div className="row p-1">
                          <div className="col-sm-12 col-md-3 col-lg-3 ">
                            {" "}
                            Current contract start date
                          </div>
                          <div className="col-sm-12 col-md-9 col-lg-9">
                            {(projectDetail?.current_ContractStartDate &&
                              Moment(
                                projectDetail?.current_ContractStartDate
                              ).format("l")) ||
                              ""}
                          </div>
                        </div>
                      )}

                    {projectDetail?.original_ContractEndDate !== undefined &&
                      projectDetail?.original_ContractEndDate !== "" && (
                        <div className="row p-1">
                          <div className="col-sm-12 col-md-3 col-lg-3 ">
                            {" "}
                            Original contract end date (Baseline ) (substantial
                            completion/delivered date)
                          </div>
                          <div className="col-sm-12 col-md-9 col-lg-9">
                            {(projectDetail?.original_ContractEndDate &&
                              Moment(
                                projectDetail?.original_ContractEndDate
                              ).format("l")) ||
                              ""}
                          </div>
                        </div>
                      )}
                    {projectDetail?.current_ContractEndDate !== undefined &&
                      projectDetail?.current_ContractEndDate !== "" && (
                        <div className="row p-1">
                          <div className="col-sm-12 col-md-3 col-lg-3 ">
                            {" "}
                            Current contract end date(agreed
                            completion/delivered date)
                          </div>
                          <div className="col-sm-12 col-md-9 col-lg-9">
                            {(projectDetail?.current_ContractEndDate &&
                              Moment(
                                projectDetail?.current_ContractEndDate
                              ).format("l")) ||
                              ""}
                          </div>
                        </div>
                      )}

                    {projectDetail?.actual_StartDate !== undefined &&
                      projectDetail?.actual_StartDate !== "" && (
                        <div className="row p-1">
                          <div className="col-sm-12 col-md-3 col-lg-3 ">
                            {" "}
                            Actual start date
                          </div>
                          <div className="col-sm-12 col-md-9 col-lg-9">
                            {(projectDetail?.actual_StartDate &&
                              Moment(projectDetail?.actual_StartDate).format(
                                "l"
                              )) ||
                              ""}
                          </div>
                        </div>
                      )}
                    {projectDetail?.actual_EndDate !== undefined &&
                      projectDetail?.actual_EndDate !== "" && (
                        <div className="row p-1">
                          <div className="col-sm-12 col-md-3 col-lg-3 ">
                            {" "}
                            Actual end date
                          </div>

                          <div className="col-sm-12 col-md-9 col-lg-9">
                            {(projectDetail?.actual_EndDate &&
                              Moment(projectDetail?.actual_EndDate).format(
                                "l"
                              )) ||
                              ""}
                          </div>
                        </div>
                      )}
                    {projectDetail?.CoNumber !== undefined &&
                      projectDetail?.CoNumber !== "" && (
                        <div className="row p-1">
                          <div className="col-sm-12 col-md-3 col-lg-3 ">
                            {" "}
                            Change Order information
                          </div>
                          <div className="col-sm-12 col-md-9 col-lg-9">
                            {projectDetail?.CoNumber}
                          </div>
                        </div>
                      )}
                    {projectDetail?.program_id &&
                      projectDetail?.program_id?.id && (
                        <div className="row p-1">
                          <div className="col-sm-12 col-md-3 col-lg-3 ">
                            {" "}
                            Program ID
                          </div>
                          <div className="col-sm-12 col-md-9 col-lg-9">
                            {projectDetail?.program_id?.id || ""}
                          </div>
                        </div>
                      )}
                    {projectDetail?.program_id &&
                      projectDetail?.program_id?.name && (
                        <div className="row p-1">
                          <div className="col-sm-12 col-md-3 col-lg-3 ">
                            {" "}
                            Program name
                          </div>
                          <div className="col-sm-12 col-md-9 col-lg-9">
                            {projectDetail?.program_id?.name || ""}
                          </div>
                        </div>
                      )}
                    {projectDetail?.originalContractGrossProfit !== undefined &&
                      projectDetail?.originalContractGrossProfit !== "" && (
                        <div className="row p-1">
                          <div className="col-sm-12 col-md-3 col-lg-3 ">
                            {" "}
                            Original contract gross profit
                          </div>
                          <div className="col-sm-12 col-md-9 col-lg-9">
                            {USDollar.format(
                              projectDetail?.originalContractGrossProfit
                            )}
                          </div>
                        </div>
                      )}
                    {projectDetail?.currentGrossProfit !== undefined &&
                      projectDetail?.currentGrossProfit !== "" && (
                        <div className="row p-1">
                          <div className="col-sm-12 col-md-3 col-lg-3 ">
                            {" "}
                            Current gross profit
                          </div>
                          <div className="col-sm-12 col-md-9 col-lg-9">
                            {USDollar.format(projectDetail?.currentGrossProfit)}
                          </div>
                        </div>
                      )}

                    {projectDetail?.costToDate !== undefined &&
                      projectDetail?.costToDate !== "" && (
                        <div className="row p-1">
                          <div className="col-sm-12 col-md-3 col-lg-3 ">
                            {" "}
                            Cost to date
                          </div>
                          <div className="col-sm-12 col-md-9 col-lg-9">
                            {projectDetail?.costToDate}
                          </div>
                        </div>
                      )}
                    {projectDetail?.costToComplete !== undefined &&
                      projectDetail?.costToComplete !== "" && (
                        <div className="row p-1">
                          <div className="col-sm-12 col-md-3 col-lg-3 ">
                            {" "}
                            Cost to complete
                          </div>
                          <div className="col-sm-12 col-md-9 col-lg-9">
                            {projectDetail?.costToComplete}
                          </div>
                        </div>
                      )}

                    {projectDetail?.roleAffiliationArr?.length > 0 && (
                      <div className="row p-1 ">
                        <div className="h4 fw-bold mt-3">
                          Role affiliation information
                        </div>
                        <hr />
                        {projectDetail?.roleAffiliationArr?.length > 0 &&
                          projectDetail?.roleAffiliationArr?.map(
                            (row, index) => {
                              return (
                                <div>
                                  {index !== 0 && (
                                    <div className="row">
                                      <span className="col">
                                        <hr />
                                      </span>
                                    </div>
                                  )}
                                  {row.roleName !== undefined &&
                                    row.roleName !== "" && (
                                      <div className="row p-1">
                                        <div className="col-sm-12 col-md-3 col-lg-3  fw-bolder h5">
                                          {row.roleName}
                                        </div>
                                      </div>
                                    )}
                                  {row?.firstName !== undefined &&
                                    row?.firstName !== "" && (
                                      <div className="row p-1">
                                        <div className="col-sm-12 col-md-3 col-lg-3 ">
                                          {" "}
                                          First name
                                        </div>
                                        <div className="col-sm-12 col-md-9 col-lg-9">
                                          {row?.firstName}
                                        </div>
                                      </div>
                                    )}
                                  {row?.lastName !== undefined &&
                                    row?.lastName !== "" && (
                                      <div className="row p-1">
                                        <div className="col-sm-12 col-md-3 col-lg-3 ">
                                          {" "}
                                          Last name
                                        </div>
                                        <div className="col-sm-12 col-md-9 col-lg-9">
                                          {row?.lastName}
                                        </div>
                                      </div>
                                    )}
                                  {row?.companyName !== undefined &&
                                    row?.companyName !== "" && (
                                      <div className="row p-1">
                                        <div className="col-sm-12 col-md-3 col-lg-3 ">
                                          {" "}
                                          Company name
                                        </div>
                                        <div className="col-sm-12 col-md-9 col-lg-9">
                                          {row?.companyName}
                                        </div>
                                      </div>
                                    )}
                                  {/* Email Array */}
                                  {row?.emailArr?.length > 0 && (
                                    <div className="row p-1 ">
                                      <div className="col-lg-3">Email info</div>
                                      <div className="col-lg-9">
                                        {row?.emailArr?.length > 0 &&
                                          row?.emailArr?.map((row, index) => {
                                            return (
                                              <div>
                                                {row.email !== "" && (
                                                  <div className="row mb-2">
                                                    <div className="col-sm-12 col-md-10 col-lg-10">
                                                      {" "}
                                                      <span>
                                                        {row?.email}
                                                        <br />
                                                      </span>{" "}
                                                    </div>
                                                    <div className="col-sm-12 col-md-2 col-lg-2">
                                                      <span>
                                                        {row?.label?.length > 0
                                                          ? " (" +
                                                            row?.label +
                                                            ")"
                                                          : ""}
                                                      </span>
                                                      <span className="ms-2">
                                                        {row?.isPrimary ===
                                                        true ? (
                                                          <i
                                                            class="fa-solid fa-star"
                                                            style={{
                                                              color: "#ffd43b",
                                                            }}
                                                          ></i>
                                                        ) : (
                                                          ""
                                                        )}
                                                      </span>
                                                    </div>
                                                  </div>
                                                )}
                                              </div>
                                            );
                                          })}
                                      </div>
                                    </div>
                                  )}
                                  {/* Phone Array */}
                                  {row?.phoneArr?.length > 0 && (
                                    <div className="row p-1 ">
                                      <div className="col-lg-3">Phone info</div>
                                      <div className="col-lg-9">
                                        {row?.phoneArr?.length > 0 &&
                                          row?.phoneArr?.map((row, index) => {
                                            return (
                                              <div>
                                                {row.fullPhone !== "" && (
                                                  <div className="row">
                                                    <div className="col-sm-12 col-md-10 col-lg-10 mb-2">
                                                      <span className="">
                                                        {(row?.fullPhone &&
                                                          row?.fullPhone) ||
                                                          ""}
                                                        {(row?.extPhone &&
                                                          ", extn " +
                                                            row?.extPhone) ||
                                                          ""}
                                                        <br />
                                                      </span>
                                                    </div>
                                                    <div className="col-sm-12 col-md-2 col-lg-2">
                                                      <span>
                                                        {" "}
                                                        {(row?.type &&
                                                          "  (" +
                                                            row?.type +
                                                            ")") ||
                                                          ""}
                                                      </span>
                                                      <span className="ms-2">
                                                        {row?.isPrimary ===
                                                        true ? (
                                                          <i
                                                            class="fa-solid fa-star"
                                                            style={{
                                                              color: "#ffd43b",
                                                            }}
                                                          ></i>
                                                        ) : (
                                                          ""
                                                        )}
                                                      </span>
                                                    </div>
                                                  </div>
                                                )}{" "}
                                              </div>
                                            );
                                          })}
                                      </div>
                                    </div>
                                  )}
                                  {/*Address Array  */}
                                  {row.addressArr?.length > 0 && (
                                    <div className="row p-1 ">
                                      <div className="col-lg-3">
                                        {" "}
                                        Address info
                                      </div>
                                      <div className="col-lg-9">
                                        {row.addressArr?.length > 0 &&
                                          row.addressArr?.map((row, index) => {
                                            return (
                                              <div className="row mb-2">
                                                <div className="col-sm-12 col-md-10 col-lg-10">
                                                  {" "}
                                                  <div>
                                                    <div>
                                                      {(row?.address1 &&
                                                        row?.address1 + ",") ||
                                                        ""}

                                                      <div></div>
                                                      {(row?.address2 &&
                                                        row?.address2 + ",") ||
                                                        ""}
                                                    </div>
                                                    <div>
                                                      {(row?.city &&
                                                        row?.city + ",") ||
                                                        ""}
                                                    </div>
                                                    <div>
                                                      {(row?.state &&
                                                        row?.state + ",") ||
                                                        ""}
                                                    </div>
                                                    <div>
                                                      {(row?.country &&
                                                        row?.country + ",") ||
                                                        ""}
                                                    </div>
                                                    <div>
                                                      {(row?.zipCode &&
                                                        row?.zipCode + ",") ||
                                                        ""}
                                                    </div>
                                                    <div>
                                                      {(row?.lat &&
                                                        row?.lat + ",") ||
                                                        ""}
                                                    </div>
                                                    <div>
                                                      {(row?.lng &&
                                                        row?.lng + ",") ||
                                                        ""}
                                                    </div>
                                                  </div>{" "}
                                                </div>
                                                <div className="col-sm-12 col-md-2 col-lg-2">
                                                  <span>
                                                    {" "}
                                                    {row?.label?.length > 0
                                                      ? "  (" + row?.label + ")"
                                                      : ""}
                                                  </span>
                                                  <span className="ms-2">
                                                    {row?.isPrimary === true ? (
                                                      <i
                                                        class="fa-solid fa-star"
                                                        style={{
                                                          color: "#ffd43b",
                                                        }}
                                                      ></i>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </span>
                                                </div>
                                              </div>
                                            );
                                          })}
                                      </div>
                                    </div>
                                  )}
                                </div>
                              );
                            }
                          )}
                      </div>
                    )}
                    {projectDetail?.linkedApplicationArr?.length > 0 && (
                      <div className="row p-1 ">
                        <div className="h4 fw-bold mt-3">
                          Project app connections
                        </div>
                        <hr />
                        <div className="row">
                          <Table className="table table-striped table-hover">
                            <thead>
                              <tr>
                                <th>App name</th>
                                <th>Source project number</th>
                                <th>Status</th>
                                <th>Last update</th>
                                <th>Comments</th>
                              </tr>
                            </thead>
                            <tbody>
                              {projectDetail?.linkedApplicationArr?.map(
                                (row, index) => {
                                  let filteredapp = connectedAppList.filter(
                                    (val) => val.appname === row.appName
                                  );
                                  //  console.log("filteredapp", filteredapp);
                                  if (filteredapp && filteredapp.length > 0) {
                                    return (
                                      <tr>
                                        <td>{row?.appName}</td>
                                        <td>
                                          <div>
                                            {row?.appName.toLowerCase() ===
                                            "sharepoint"
                                              ? "N/A"
                                              : row?.projectNumber?.display}

                                            <div>
                                              {row?.appName.toLowerCase() ===
                                                "quickbooks" &&
                                                row?.estimate?.length > 0 &&
                                                row?.estimate?.map((val) => {
                                                  return (
                                                    <span>
                                                      {val.qbEstimateID && (
                                                        <span>
                                                          <a
                                                            href="#"
                                                            class="badge rounded-pill bg-secondary"
                                                            onClick={() => {
                                                              let qbappfilter =
                                                                connectedAppList.filter(
                                                                  (_val) =>
                                                                    _val.appname.toLowerCase() ===
                                                                    "quickbooks"
                                                                );
                                                              if (
                                                                qbappfilter.length >
                                                                0
                                                              ) {
                                                                window.open(
                                                                  qbappfilter[0]
                                                                    .appurl +
                                                                    "app/estimate?txnId=" +
                                                                    val.qbEstimateID
                                                                );
                                                              }
                                                            }}
                                                          >
                                                            {
                                                              val?.cpEstimateNumber
                                                            }
                                                          </a>{" "}
                                                        </span>
                                                      )}
                                                    </span>
                                                  );
                                                })}
                                            </div>
                                          </div>
                                        </td>
                                        <td>
                                          {row?.projectID
                                            ? "Connected"
                                            : row?.appName.toLowerCase() ===
                                              "sharepoint"
                                            ? "Connected"
                                            : "Not connected"}
                                        </td>
                                        <td>
                                          {(row.imported_On &&
                                            Moment(row.imported_On).format(
                                              "l"
                                            )) ||
                                            ""}
                                        </td>
                                        <td>{row?.comments}</td>
                                      </tr>
                                    );
                                  }
                                }
                              )}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div></div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {" "}
            <div className="row p-1">
              {" "}
              <div className=" d-flex justify-content-end mt-3 col-sm-12 col-md-12 col-lg-12">
                <button
                  className=" btn btn-primary me-2 "
                  onClick={handleClose}
                >
                  Close
                </button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
      )}
      <LoadingDialog
        isOpen={loadDialogOpen}
        onClose={() => setloadDialogOpen(false)}
      />
    </div>
  );
}

export default ProjectList;
