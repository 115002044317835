import React from "react";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import { DropdownSubmenu, NavDropdownMenu } from "react-bootstrap-submenu";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import Loading from "../components/Loading";
import { getuserbyauthproviderid } from "../services/users_services";
import { useHistory } from "react-router-dom";
import { useRealmApp } from "../providers/RealmApp";
const MainNav = () => {
  const { user, isAuthenticated, loginWithRedirect, logout } = useAuth0();
  const ctechapp = useRealmApp();
  const [loading, setLoading] = useState(false);
  const [isLoadMenu, setisLoadMenu] = useState(false);
  const [isShowMenu, setShowMenu] = useState(true);
  const [isLoadSuperMenu, setisLoadSuperMenu] = useState(false);
  const history = useHistory();
  const [userinfo, setUserinfo] = useState({
    firstname: "",
    lastname: "",
    email: "",
    role: "",
  });
  const [cpUserMenuEnable, setcpUserMenuEnable] = useState(true);
  useEffect(() => {
    getUserInfoByID();
    if (
      window.location.pathname.includes("setup") ||
      window.location.pathname.includes("contactverification") ||
      window.location.pathname === "/contactverification" ||
      window.location.pathname.includes("verificationmsg") ||
      window.location.pathname.includes("/manage")
    ) {
      setShowMenu(false);
    }
  }, []);
  useEffect(() => {
    try {
      const _CPUserMode = localStorage.getItem("menuCPUserMode");
      if (_CPUserMode) {
        if (_CPUserMode === "2")
          setcpUserMenuEnable(false);
        else
          setcpUserMenuEnable(true);
      }
      else {
        setcpUserMenuEnable(true);
      }
    }
    catch (ex) {
      console.log("ex", ex);
    }

  }, []);
  async function getUserInfoByID() {
    try {
      setLoading(true);
      if (user !== undefined && ctechapp.currentUser != null)
        getuserbyauthproviderid(user.sub, ctechapp.currentUser).then(
          (response) => {
            if (response !== undefined) {
              let user_info = JSON.parse(response);
              if (user_info.length > 0) {
                if (
                  user_info[0].role === "8000" ||
                  user_info[0].role === "7000"
                ) {
                  setisLoadMenu(true);
                  setisLoadSuperMenu(false);
                }
                if (user_info[0].role === "9000") {
                  setisLoadMenu(false);
                  setisLoadSuperMenu(true);
                }
                console.log("user info", user_info);
                setUserinfo(user_info[0]);
              }
            }
          }
        );
      setLoading(false);
    } catch (exception) {
      setLoading(false);
      console.log(exception);
    }
  }
  return (
    <>
      {" "}
      {loading && <Loading />}
      {isAuthenticated && ctechapp.currentUser !== null && (
        <div className="me-auto">
          {isShowMenu && isLoadMenu && (
            <Nav>
              <Nav.Link
                href="/apps"
                exact
                className="nav-link text-decoration-none"
                activeClassName="router-link-exact-active"
              >
                CONNECT APPS
              </Nav.Link>
              {cpUserMenuEnable && (
                <>
                 <Nav.Link
                 href="/projectlist?action=add"
                 exact
                 className="nav-link text-decoration-none"
                 activeClassName="router-link-exact-active"
               >
                 CREATE NEW PROJECT
               </Nav.Link>
               <NavDropdownMenu
                 href="#action/3.1"
                 title="IMPORT PROJECTS"
                 id="collasible-nav-dropdown"
               >
                 <DropdownSubmenu title="COMMANDPOST">
                   <NavDropdown.Item href="/commandpostimportwiz">
                     IMPORT WIZARD
                   </NavDropdown.Item>
                   <NavDropdown.Item href="/AppImportStatus?action=CommandPost">
                     IMPORT STATUS
                   </NavDropdown.Item>
                 </DropdownSubmenu>
                 <DropdownSubmenu title="PROCORE">
                   <NavDropdown.Item href="/procoreimportwiz">
                   IMPORT WIZARD
                   </NavDropdown.Item>
                   <NavDropdown.Item href="/AppImportStatus?action=Procore">
                   IMPORT STATUS
                   </NavDropdown.Item>
                 </DropdownSubmenu>
               </NavDropdownMenu>
               <NavDropdownMenu
                 href="#action/3.1"
                 title="REVIEW UPDATES"
                 id="collasible-nav-dropdown"
               >
                 
                 <NavDropdown.Item href="/pendingcreationlist">
                   PENDING CREATION
                 </NavDropdown.Item>
                 <NavDropdown.Item href="/pendingupdatelist">
                   PENDING UPDATE
                 </NavDropdown.Item>
               </NavDropdownMenu>
               <Nav.Link
                 // href="/projectlist"
                 exact
                 className="nav-link text-decoration-none"
                 activeClassName="router-link-exact-active"
                 onClick={() => {
                   history.push({
                     pathname: "/projectlist",
                     state: { action: "" },
                   });
                 }}
               >
                 PROJECT LIST
               </Nav.Link>
 
               <NavDropdownMenu
                 href="#action/3.1"
                 title="WORKFLOW"
                 id="collasible-nav-dropdown"
               >
                 <DropdownSubmenu title=" SHAREPOINT FOLDERS">
                   <NavDropdown.Item href="/sharepointfolder">
                     TEMPLATES
                   </NavDropdown.Item>
                 </DropdownSubmenu>
               </NavDropdownMenu>
               </>
              )}
             
              <Nav.Link
                href="/userlist"
                exact
                className="nav-link text-decoration-none"
                activeClassName="router-link-exact-active"
              >
                USER LIST
              </Nav.Link>
            </Nav>
          )}
          {isLoadSuperMenu && (
            <Nav>
              <NavDropdownMenu
                href="#action/3.1"
                title="MASTER LIST"
                id="collasible-nav-dropdown"
              >
                <NavDropdown.Item href="/lobinfolist">
                  LOB INFO
                </NavDropdown.Item>
                <NavDropdown.Item href="/collectionlist">
                  COLLECTION LIST
                </NavDropdown.Item>
                <NavDropdown.Item href="/eventhistory">
                  EVENT HISTORY
                </NavDropdown.Item>
              </NavDropdownMenu>
              <Nav.Link
                href="/userlist"
                exact
                className="nav-link text-decoration-none"
                activeClassName="router-link-exact-active"
              >
                USER LIST
              </Nav.Link>
            </Nav>
          )}
        </div>
      )}
    </>
  );
};

export default MainNav;
