import { Panel, PanelHeader, PanelBody } from "../../components/panel/panel";
import React, { useState, useMemo, useEffect, useCallback } from "react";
import Select from "react-select";
import { Button, Modal } from "react-bootstrap";
import customstyles from "../../components/customstyles/react-select-styles";
import Table from "react-bootstrap/Table";
import LoadingDialog from "../../components/LoadingDialog";
import { appConstants } from "../../constants/app_constants";
import Moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";
import { getProjectListByUserID } from "../../services/project_services";
import { getuserbyauthproviderid } from "../../services/users_services";
import GenericListTable from "../../components/gridTable/genericLlistTable";
import GlobalListTablewithFilter from "../../components/gridTable/genericLlistTablewithFilter";
import GenericSimpleTablewithPage from "../../components/gridTable/genericSimpleTablewithPage";
import { getCommandPostProjectListWithoutPMProjectList } from "../../services/commandpost_project_services";
import {
  getappcompanylistbyappname_userid,
  getapplistbyuserid,
} from "../../services/applications_services";
import {
  getProcoreProjectListByCompanyID,
  getProcoreProjectListWithPMProjectList,
} from "../../services/procore_project_services";
import { createImportProjectTask } from "../../services/import_project_task_services";
import { useRealmApp } from "../../providers/RealmApp";
import { useAuth0 } from "@auth0/auth0-react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PageSubHeader from "../../components/pagesubheader";
const _appname = "CommandPost";
const initalSourceProjectInfo = {
  sourceID: "",
  projectName: "",
  projectNumber: "",
  altProjectNumber: "",
  companyId: "",
  source: "",
  sourceRecordIndex: "",
  projectCreatedDate: "",
  projectUpdatedDate: "",
  destinationID: "",
  destinationCompanyID: "",
  destinationSource: "",
  destinationProjectName: "",
  destinationProjectNumber: "",
  destinationProjectCreatedDate: "",
  destinationProjectUpdatedDate: "",
  stateMatched: "",
};

function CommandpostImportWizard() {
  const [tabProcoreProjects, setProcoreProjects] = useState(true);
  const [tabSendverification, setSendverification] = useState(false);
  const [noRecord, setNoRecord] = useState("");
  const [page, Stepage] = useState(0);
  const [sourceCompanyList, setSourceCompanyList] = useState([]);
  const { isLoading, error, isAuthenticated, user } = useAuth0();
  const [isApprove, setIsapprove] = useState(false);
  const [selectedMatchingSourceProject, setSelectedMatchingSourceProject] =
    useState([]);
  const [isMessage, setisMessage] = useState("");
  const [msgTitle, setMsgTitle] = useState("Project Sync");
  const [msgInfo, setmsgInfo] = useState("");
  const [loadDialogOpen, setloadDialogOpen] = useState(false);
  const FormTitles = ["commandpostproject", "sendverification"];
  const [selectedCompany, setSelectedCompany] = useState("");
  const [destinationCompanyList, setDestinationCompanyList] = useState("");
  var [companylist, setCompanyList] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [userlist_step3, setUserList_Step3] = useState([]);
  var [apiprojectlist, setApiprojectlist] = useState([]);
  const [sourceProjectList, setSourceProjectList] = useState([]);
  const [destinationProjectList, setDestinationProjectList] = useState([]);
  const [sourceSelectProjectInfo, setSourceSelectProjectInfo] = useState(
    initalSourceProjectInfo
  );
  const [destinationSelectProjectInfo, setDestinationSelectProjectInfo] =
    useState("");
  const [selectedRowIndex, setSelectedRowIndex] = useState("");
  const [loading, setLoading] = useState(false);
  const [order, SetOrder] = useState("ASC");
  const ctechapp = useRealmApp();
  const [errordisplay, SetErrorDisplay] = useState("");
  const [isDestinationListModelWindow, setIsDestinationListModelWindow] =
    useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [errorAlertMsg, setErrorAlertMsg] = useState("");
  const [apiinfo, setapiinfo] = useState();
  const [isApprovedMsg, setisApprovedMsg] = useState(false);
  var sortJsonArray = require("sort-json-array");

  const [userinfo, setUserinfo] = useState({
    role: "",
  });

  var array = [];

  // const sorting = (col) => {
  //   if (order === "ASC") {
  //     const sorted = projectList.sort((a, b) =>
  //       a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
  //     );
  //     setProcoreprojectlist(sorted);
  //     SetOrder("DSC");
  //   }
  //   if (order === "DSC") {
  //     const sorted = projectList.sort((a, b) =>
  //       a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
  //     );
  //     setProcoreprojectlist(sorted);
  //     SetOrder("ASC");
  //   }
  // };

  useEffect(() => {
    if (isAuthenticated) {
      getUserInfoByID();
      //  getCompanyListData(ctechapp.currentUser);
    }
  }, []);

  async function getUserInfoByID() {
    try {
      getuserbyauthproviderid(user.sub, ctechapp.currentUser).then(
        (response) => {
          let user_info = JSON.parse(response);
          if (response !== undefined || user_info.length !== 0) {
            setUserinfo(user_info[0]);
            getCompanyListData(user_info[0]);
            Getapplistbyuserid(user_info[0]);
          }
        }
      );
    } catch (exception) {
      console.log(exception);
    }
  }

  async function Getapplistbyuserid(_userinfo) {
    getapplistbyuserid(ctechapp.currentUser, _userinfo.userid).then(
      (response) => {
        if (response !== undefined) {
          let apps_list = JSON.parse(response);

          let pro_apps_list = apps_list.filter(
            (app) => app.appname.toLowerCase() === "procore"
          );
          pro_apps_list = pro_apps_list[0];
          setDestinationCompanyList(pro_apps_list?.appaccountid || "");
          GetProcoreProjectListWithPMProjectList(
            pro_apps_list?.appaccountid || ""
          );
          let cp_apps_list = apps_list.filter(
            (app) => app.appname.toLowerCase() === "commandpost"
          );
          let options = [];
          cp_apps_list.map((val) => options.push(val.appaccountid));

          setSourceCompanyList({ $in: options });
        }
      }
    );
  }
  async function getCompanyListData(userinfo) {
    try {
      getappcompanylistbyappname_userid(_appname, ctechapp.currentUser, {
        userid: userinfo.userid,
      }).then((response) => {
        try {
          if (response !== undefined) {
            let company_info = JSON.parse(response);
            var options = [];
            company_info?.map(function (company) {
              if (company?.appuserinfo.length > 0) {
                options.push({
                  value: company.appaccountid,
                  label: company.appuserinfo[0].appdisplayname,
                });
              }
            });

            setCompanyList(options);
            if (options.length > 0) {
              handleCompanyChange({
                target: {
                  name: "company_ID",
                  value: options[0].value,
                },
              });
            } else {
              setNoRecord(
                appConstants.import.handlefieldvalidation.nocpappimport
              );
            }
          }
        } catch (exception) {}
      });
    } catch (err) {
      setErrorAlert(true);
      setErrorAlertMsg(err);
    }
  }

  const handleChange_CheckBoxSelect = (e, data, rowindex) => {
    const { name, checked } = e.target;
    let _sourceProject = [...sourceProjectList];
    let row = _sourceProject[rowindex];
    if (checked) {
      // if cheked and selectall checkbox add all fileds to selectedList
      row.stateMatched = "new";
      row.selected = true;
    } else {
      row.stateMatched = "";
      row.selected = false;
      row.destinationID = "";
      row.destinationCompanyID = "";
      row.destinationSource = "";
      row.destinationProjectName = "";
      row.destinationProjectNumber = "";
      row.destinationProjectCreatedDate = "";
      row.destinationProjectUpdatedDate = "";
    }
    _sourceProject[rowindex] = row;
    setSourceProjectList(_sourceProject);
  };

  const handleChange_CheckBoxSelectALL = (e) => {
    const { name, checked } = e.target;
    let _sourceProject = [...sourceProjectList];

    if (checked) {
      // if cheked and selectall checkbox add all fileds to selectedList
      _sourceProject = _sourceProject.map((row) => {
        row.stateMatched = "new";
        row.selected = true;
        return row;
      });
    } else {
      _sourceProject = _sourceProject.map((row) => {
        row.stateMatched = "";
        row.selected = false;
        row.destinationID = "";
        row.destinationCompanyID = "";
        row.destinationSource = "";
        row.destinationProjectName = "";
        row.destinationProjectNumber = "";
        row.destinationProjectCreatedDate = "";
        row.destinationProjectUpdatedDate = "";
        return row;
      });
    }
    setSourceProjectList(_sourceProject);
  };

  const handleChange_MatchedRecordRemoveClick = (e, data, rowindex) => {
    let _sourceProject = [...sourceProjectList];
    let row = _sourceProject[rowindex];
    // if cheked and selectall checkbox add all fileds to selectedList
    row.stateMatched = "";
    row.selected = false;
    row.destinationID = "";
    row.destinationCompanyID = "";
    row.destinationSource = "";
    row.destinationProjectName = "";
    row.destinationProjectNumber = "";
    row.destinationProjectCreatedDate = "";
    row.destinationProjectUpdatedDate = "";
    _sourceProject[rowindex] = row;
    setSourceProjectList(_sourceProject);
  };

  const handleChangeDestination_MatchedRecordClick = () => {
    let _sourceProject = [...sourceProjectList];
    let _sourceSelectProjectInfo = { ...sourceSelectProjectInfo };
    let _destinationSelectProjectInfo = { ...destinationSelectProjectInfo };
    // console.log("_destinationSelectProjectInfo", _destinationSelectProjectInfo);
    if (_destinationSelectProjectInfo.sourceID === undefined) {
      setErrorAlertMsg(
        appConstants.import.handlefieldrequiredvalidation.selectaction
      );
      return;
    }
    let rowindex = _sourceSelectProjectInfo.sourceRecordIndex;
    _sourceProject[rowindex].stateMatched = "Matched";
    _sourceProject[rowindex].selected = true;
    _sourceProject[rowindex].destinationID =
      _destinationSelectProjectInfo.sourceID || "";
    _sourceProject[rowindex].destinationCompanyID =
      _destinationSelectProjectInfo.companyId || "";
    _sourceProject[rowindex].destinationSource =
      _destinationSelectProjectInfo.source || "";
    _sourceProject[rowindex].destinationProjectName =
      _destinationSelectProjectInfo.projectName || "";
    _sourceProject[rowindex].destinationProjectNumber =
      _destinationSelectProjectInfo.projectNumber || "";
    _sourceProject[rowindex].destinationProjectCreatedDate =
      _destinationSelectProjectInfo.projectCreatedDate || "";
    _sourceProject[rowindex].destinationProjectUpdatedDate =
      _destinationSelectProjectInfo.projectUpdatedDate || "";
    // if cheked and selectall checkbox add all fileds to selectedList
    setSourceProjectList(_sourceProject);
    setIsDestinationListModelWindow(false);
    setDestinationSelectProjectInfo("");
    //console.log(_sourceSelectProjectInfo, _destinationSelectProjectInfo)
  };

  const handleChange_DestinationSelect = (e, data, rowindex) => {
    setErrorAlertMsg("");
    setDestinationSelectProjectInfo(data);
  };

  const handleSourceSearchClickApps = (row) => {
    console.log("row.original", row.original);
    let _sourceSelectProjectInfo = row.original;
    _sourceSelectProjectInfo.sourceRecordIndex = row.index;
    setSourceSelectProjectInfo(_sourceSelectProjectInfo);
    setIsDestinationListModelWindow(true);
  };

  const showTab = (tab) => {
    switch (tab) {
      case "commandpostproject":
        setProcoreProjects(true);
        setSendverification(false);
        return;
      case "sendverification":
        setProcoreProjects(false);
        setSendverification(true);
        return;
      default:
        break;
    }
  };

  const handleMessage = () => {
    setisMessage(false);
    setmsgInfo("");
  };
  const handleCompanyChange = (event) => {
    setSelectedCompany(event.target.value);
    //  handleSelectedCompany(event.target.value);
    // GetProcoreProjectListWithPMProjectList(destinationCompanyList);
    GetCommandPostProjectListWithoutPMProjectList(event.target.value);
    setloadDialogOpen(true);
  };
  async function GetProcoreProjectListWithPMProjectList(companyid) {
    try {
      if (companyid) {
        getProcoreProjectListWithPMProjectList(
          companyid,
          ctechapp.currentUser,
          user.sub
        ).then((response) => {
          if (response !== undefined) {
            console.log(
              "getProcoreProjectListWithPMProjectList response",
              response
            );
            if (response.statusCode === 200) {
              setDestinationProjectList(response.data);
              // setloadDialogOpen(false);
              if (response.data.length === 0) {
                setNoRecord(
                  appConstants.import.handlefieldvalidation.noeligiblerecord
                );
              } else {
                setNoRecord("");
              }
            } else {
              //setloadDialogOpen(false);
              setErrorAlert(true);
              setErrorAlertMsg(
                JSON.stringify(response?.error || response.errorMessage)
              );
            }
          }
        });
      } else {
        // setloadDialogOpen(false);
        setErrorAlert(true);

        setErrorAlertMsg(
          appConstants.import.handlefieldvalidation.noprocoreapp
        );
      }
    } catch (err) {
      // setloadDialogOpen(false);
      setErrorAlert(true);
      setErrorAlertMsg(err);
    }
  }
  //getCommandPostProjectListWithPMProjectList
  async function GetCommandPostProjectListWithoutPMProjectList(companyid) {
    try {
      getCommandPostProjectListWithoutPMProjectList(
        companyid,
        ctechapp.currentUser,
        user.sub
      ).then((response) => {
        if (response !== undefined) {
          console.log(
            "getCommandPostProjectListWithPMProjectList response",
            response
          );
          if (response.statusCode === 200) {
            setSourceProjectList(response.data);
            setloadDialogOpen(false);
          } else {
            setloadDialogOpen(false);
            setErrorAlert(true);
            setErrorAlertMsg(JSON.stringify(response.error));
          }
        }
      });
    } catch (err) {
      setErrorAlert(true);
      setloadDialogOpen(false);
      setErrorAlertMsg(err);
    }
  }
  //setProcoreprojectlist;
  function approveclick() {
    setloadDialogOpen(true);
    try {
      setIsapprove(true);
      let appapiInfo = {
        appname: "CommandPost",
        companyid: selectedCompany,
        signupurl: window.location.origin,
      };
      let _selectedSourceProject = sourceProjectList.filter(
        (row) => row.selected === true
      );
      let _userinfo = {
        _id: userinfo._id,
        userid: userinfo.userid,
        firstName: userinfo.firstName,
        lastName: userinfo.lastName,
        email: userinfo.email,
      };
      // userprocoreapiInfo.appname = "Commandpost";
      // userprocoreapiInfo.LoggedinBy = userinfo.userid;
      // userprocoreapiInfo.signupurl = window.location.origin;
      // let date = new Date();

      let batchid = Moment(new Date()).format("YYYYMMDDHHmmss");

      let batch_coll_info = {
        status: "Pending",
        batchid: batchid,
        batchdate: new Date().toString(),
        sourceSystem: appapiInfo,
        userinfo: _userinfo,
      };

      let project_task_list = _selectedSourceProject.map((row) => {
        return {
          apiinfo: appapiInfo,
          userinfo: _userinfo,
          project_info: row,
          created_date: new Date(),
          status: "Pending",
          exception: "",
          batchid: batchid,
        };
      });

      createImportProjectTask(
        batch_coll_info,
        project_task_list,
        ctechapp.currentUser
      )
        .then((response) => {
          if (response != undefined) {
            // _selectedSourceProject.map((projectlist, index) => {
            //   const objIndex = projectList.findIndex(
            //     (obj) => obj.id === projectlist.id
            //   );
            //   if (objIndex !== -1) {
            //     const list = [...projectList];
            //     list[objIndex]["is_active"] = 0;
            //     setSourceProjectList(list);
            //   }
            // });

            console.log("response : ", response);
            console.log("Projects pushed Successfully.");
            //toast("Commandpost project record import has been initiated");
            setloadDialogOpen(false);
            setisApprovedMsg(true);
            // setTimeout(() => {
            //   window.location.href = "/projectlist";
            // }, [5000]);
          }
        })
        .catch((err) => {
          console.log("error : " + err);
          //  toast("Commandpost project record import has been not initiated");
          setloadDialogOpen(false);
        });
    } catch (exce) {
      setloadDialogOpen(false);
      setErrorAlert(true);
      setErrorAlertMsg(JSON.stringify(exce));
    }
  }

  const sourceProjectListColumns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "_id",
        sortable: false,
        width: "20%",
      },
      // {
      //   Header: ({ rows }) => {
      //     // Toggle checkboxes manually to dont toggle disabled cells
      //     const changeableRows = !isRowDisabled
      //       ? [...rows]
      //       : rows.filter((row) => !!isRowDisabled && !isRowDisabled(row));

      //     const allSelected = changeableRows.every((row) => row.isSelected);
      //     const allUnselected = changeableRows.every((row) => !row.isSelected);

      //     return (
      //       <Checkbox
      //         checked={!allUnselected}
      //         indeterminate={!allSelected && !allUnselected}
      //         onChange={() => changeableRows.forEach((row) => row.toggleRowSelected(!allSelected))}
      //       />
      //     )
      //   }
      // },
      {
        Header: () => (
          <>
            {" "}
            <input
              type="checkbox"
              id="select"
              checked={
                sourceProjectList.filter((row) => row.selected === true)
                  .length === sourceProjectList.length &&
                sourceProjectList.length > 0
                  ? true
                  : false
              }
              onChange={handleChange_CheckBoxSelectALL}
              name="select"
            />
          </>
        ),
        accessor: "selected",
        sortable: false,
        width: "10%",
        Cell: ({ row }) => (
          <input
            class="form-check-input mt-0"
            type="checkbox"
            name="destinationSelect"
            checked={row.original.selected}
            onChange={(e) =>
              handleChange_CheckBoxSelect(e, row.original, row.index)
            }
          />
        ),
      },
      {
        Header: "Commandpost project#",
        accessor: "projectNumber",
        sortable: false,
        width: "10%",
      },
      {
        Header: "Commandpost project name",
        accessor: "projectName",
        sortable: false,
        width: "30%",
      },
      {
        Header: "",
        accessor: "search",
        sortable: false,
        width: "30%",
        Cell: ({ row }) => {
          const onSerachItemClick = () => {
            handleSourceSearchClickApps(row);
          };
          return (
            <a title="search" className="text-dark" onClick={onSerachItemClick}>
              <i class="fa-solid fa-magnifying-glass"></i>
            </a>
          );
        },
      },
      {
        Header: "Procore project#",
        accessor: "destinationProjectNumber",
        sortable: false,
        width: "30%",
      },
      {
        Header: "Procore project name",
        accessor: "destinationProjectName",
        sortable: false,
        width: "30%",
      },
      {
        Header: "Status",
        accessor: "stateMatched",
        sortable: false,
        width: "30%",
      },
      {
        Header: "Action",
        accessor: "Action",
        sortable: false,
        width: "30%",
        Cell: ({ row }) => {
          return (
            <>
              {row.original?.stateMatched?.toLowerCase() === "matched" && (
                <a
                  title="Remove select"
                  onClick={
                    (e) =>
                      handleChange_MatchedRecordRemoveClick(
                        e,
                        row.original,
                        row.index
                      )
                    // let _procoreprojectlist = [...sourceProjectList];
                    // _procoreprojectlist.splice(row.index, 1);
                    // setSourceProjectList(_procoreprojectlist);
                  }
                >
                  <i
                    class="fa-regular fa-trash-can"
                    style={{ color: "#ff2424" }}
                  ></i>
                </a>
              )}
            </>
          );
        },
      },
    ],
    [sourceProjectList]
  );
  const hiddenSourceProjectListColumns = ["_id"];

  const approveProjectListColumns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "_id",
        sortable: false,
        width: "20%",
      },
      {
        Header: "Commandpost project#",
        accessor: "projectNumber",
        sortable: false,
        width: "10%",
      },
      {
        Header: "Commandpost project name",
        accessor: "projectName",
        sortable: false,
        width: "30%",
      },
      {
        Header: "Procore project#",
        accessor: "destinationProjectNumber",
        sortable: false,
        width: "30%",
      },
      {
        Header: "Procore project name",
        accessor: "destinationProjectName",
        sortable: false,
        width: "30%",
      },
      {
        Header: "Status",
        accessor: "stateMatched",
        sortable: false,
        width: "30%",
      },
    ],
    [sourceProjectList]
  );

  const destinationProjectListColumns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "_id",
        sortable: false,
        width: "20%",
      },
      {
        Header: "Select",
        accessor: "action",
        sortable: false,
        width: "10%",
        Cell: ({ row }) => (
          <input
            class="form-check-input mt-0"
            type="radio"
            name="destinationSelect"
            onChange={(e) =>
              handleChange_DestinationSelect(e, row.original, row.index)
            }
          />
        ),
      },

      {
        Header: "Procore project#",
        accessor: "projectNumber",
        sortable: true,
        width: "30%",
      },
      {
        Header: "Procore project name",
        accessor: "projectName",
        sortable: true,
        width: "30%",
      },

      {
        Header: "End date",
        accessor: "projectEndDate",
        sortable: true,
        width: "30%",
        Cell: ({ value }) =>
          (value && Moment(value).format(appConstants.DATEFORMAT)) || "",
      },
    ],
    []
  );
  const hiddenDestinationProjectListColumns = ["_id"];
  const handleClose = () => {
    setIsDestinationListModelWindow(false);
    setDestinationSelectProjectInfo("");
    setErrorAlertMsg("");
  };
  return (
    <div className="tab-content p-0">
      <PageSubHeader title={"Commandpost import wizard"} />

      {companylist && companylist.length > 1 && (
        <div className="d-flex justify-content-start align-items-center row mb-3">
          <div className="col-sm-12 col-lg-2">
            <label className="text-end">App display name:</label>
          </div>
          <div className="col-sm-12 col-lg-3">
            <Select
              name="company_ID"
              id="company_ID"
              styles={customstyles}
              isDisabled={tabSendverification}
              isSearchable={true}
              className="form-control"
              options={companylist}
              onChange={(val) => {
                handleCompanyChange({
                  target: {
                    name: "company_ID",
                    value: val.value,
                  },
                });
              }}
            ></Select>
          </div>
          <hr />
        </div>
      )}

      {/* <div className="ms-1 p-2" style={{ "background-color": "#d9d9d9" }}>
        Review and approve{" "}
      </div> */}

      <div
        className={
          "tab-pane fade " + (tabProcoreProjects ? "show active " : "")
        }
      >
        <div className=" stepWrapper justify-content-around">
          <div
            className={"stepBlock  " + (tabProcoreProjects ? "selected " : "")}
          >
            <div className="circleWrapper">
              <div className="wizardcircle d-flex align-item-center justify-content-center">
                1
              </div>
            </div>
            <span className="d-flex justify-content-center">
              Select project
            </span>
          </div>

          <div
            className={"stepBlock " + (tabSendverification ? "active " : "")}
          >
            <div className="circleWrapper d-flex align-item-center justify-content-center ">
              <div className="wizardcircle d-flex align-item-center justify-content-center">
                2
              </div>
            </div>
            <span className="d-flex justify-content-center">
              Review and Approve
            </span>
          </div>
        </div>
        <div className="col-xl-12">
          <Panel>
            <PanelHeader noButton={true}>
              {/* <div className="d-flex align-items-center ">
                <div>
                  <ul className="profile-header-tab nav nav-tabs">
                    <li className="nav-item me-2" key="commandpostproject">
                      <div
                        className={
                          "nav-link text-dark " +
                          (tabProcoreProjects ? "active " : "")
                        }
                      >
                        Select projects
                      </div>
                    </li>
                    <li className="nav-item" key="sendverification">
                      <div
                        className={
                          "nav-link text-dark " +
                          (tabSendverification ? "active " : "")
                        }
                      >
                        Review & approve
                      </div>
                    </li>
                  </ul>
                </div>
              </div> */}
            </PanelHeader>

            <PanelBody className="p-0 m-0">
              <div className="p-0 m-0">
                <label for="error1">{errordisplay}</label>
                <GenericListTable
                  gcolumns={sourceProjectListColumns}
                  gdata={
                    sourceProjectList.length > 0
                      ? JSON.stringify(sourceProjectList)
                      : ""
                  }
                  ghiddencolumns={hiddenSourceProjectListColumns}
                  gplaceholder={"Search import project list.."}
                  gsearchDisabled={false}
                  filterDisabled={true}
                />
                <div className="text-center">
                  {noRecord && (
                    <span className="text-danger fw-bold">{noRecord}</span>
                  )}
                </div>
              </div>
            </PanelBody>
          </Panel>
        </div>
      </div>
      <div
        className={
          "tab-pane fade " + (tabSendverification ? "show active " : "")
        }
      >
        <div className="stepWrapper  justify-content-around">
          <div
            className={"stepBlock " + (tabProcoreProjects ? "selected " : "")}
          >
            <div className="circleWrapper">
              <div className="wizardcircle d-flex align-item-center justify-content-center">
                1
              </div>
            </div>
            <span className="me-5 d-flex justify-content-center">
              Select project
            </span>
          </div>
          <div
            className={"stepBlock " + (tabSendverification ? "selected " : "")}
          >
            <div className="circleWrapper d-flex align-item-center justify-content-center">
              <div className="wizardcircle d-flex align-item-center justify-content-center">
                2
              </div>
            </div>
            <span className="me-5">Review and Approve</span>
          </div>
        </div>
        <div className="col-xl-12">
          <Panel>
            <PanelHeader noButton={true}>
              {/* <div className="d-flex align-items-center">
                <div>
                  <ul className="profile-header-tab nav nav-tabs">
                    <li className="nav-item me-2" key="commandpostproject">
                      <div
                        className={
                          "nav-link text-dark " +
                          (tabProcoreProjects ? "active " : "")
                        }
                      >
                        Select projects
                      </div>
                    </li>
                    <li className="nav-item" key="sendverification">
                      <div
                        className={
                          "nav-link text-dark " +
                          (tabSendverification ? "active " : "")
                        }
                      >
                        Review & approve
                      </div>
                    </li>
                  </ul>
                </div>
              </div> */}
            </PanelHeader>
            <PanelBody className="p-0 m-0">
              <GenericListTable
                gcolumns={approveProjectListColumns}
                gdata={
                  sourceProjectList.length > 0
                    ? JSON.stringify(
                        sourceProjectList.filter((row) => row.selected === true)
                      )
                    : ""
                }
                ghiddencolumns={hiddenSourceProjectListColumns}
                gplaceholder={"Search import project list.."}
                gsearchDisabled={false}
                filterDisabled={true}
              />
            </PanelBody>
          </Panel>
          <div className="d-flex justify-content-end mt-2 mb-2">
            <button
              className="btn btn-sm me-2"
              onClick={(e) => {
                approveclick(e);
              }}
            >
              {"Approve"}
            </button>
          </div>
        </div>
      </div>

      <div className="footer m-3">
        <button
          className="btn btn-sm me-2"
          disabled={page === 0}
          onClick={(e) => {
            Stepage((currPage) => currPage - 1);
            if (page) showTab(FormTitles[page - 1]);
          }}
        >
          {"<< Prev"}
        </button>
        <button
          className="btn btn-sm"
          disabled={
            page === FormTitles.length - 1
              ? true
              : false ||
                sourceProjectList.filter((row) => row.selected === true)
                  .length === 0
              ? true
              : false
          }
          onClick={(e) => {
            Stepage((currPage) => currPage + 1);
            showTab(FormTitles[page + 1]);
          }}
        >
          {"Next >>"}
        </button>
      </div>
      {isDestinationListModelWindow && (
        <Modal
          scrollable={true}
          show={isDestinationListModelWindow}
          onHide={handleClose}
          size="lg"
          centered
        >
          <Modal.Header className="fw-bold h4">
            Procore projects
            <a
              style={{ "text-decoration": "none" }}
              onClick={handleClose}
              className="col d-flex justify-content-end text-dark"
            >
              <i class="fa-solid fa-xmark"></i>
            </a>
          </Modal.Header>
          <Modal.Body>
            <GenericListTable
              gcolumns={destinationProjectListColumns}
              gdata={
                destinationProjectList.length > 0
                  ? JSON.stringify(destinationProjectList)
                  : ""
              }
              ghiddencolumns={hiddenDestinationProjectListColumns}
              gplaceholder={"Search import project list.."}
              gsearchDisabled={false}
              filterDisabled={true}
              gdisablePagination={destinationProjectList.length > 10?false:true}
            />
          </Modal.Body>
          <Modal.Footer>
            <div className=" d-flex justify-content-between mt-1 col-sm-12 col-md-12 col-lg-12">
              <button className=" btn btn-primary me-2 " onClick={handleClose}>
                Cancel
              </button>
              <span className="text-danger">{errorAlertMsg}</span>
              <button
                className="btn btn-primary me-2 "
                // disabled={!isvaliddata}
                onClick={handleChangeDestination_MatchedRecordClick}
              >
                Ok
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      )}
      {isMessage && (
        <SweetAlert
          info
          style={{ color: "black" }}
          title={msgTitle}
          onConfirm={handleMessage}
        >
          {msgInfo}
        </SweetAlert>
      )}
      {isApprovedMsg && (
        <SweetAlert
          success
          style={{ color: "black" }}
          title={appConstants.import.commandpost.create}
          onConfirm={(e) => {
            setisApprovedMsg(false);

            window.location.href = "/projectlist";

            //handleReload();
          }}
        ></SweetAlert>
      )}
      {errorAlert && (
        <SweetAlert
          info
          style={{ color: "black" }}
          title={msgTitle}
          onConfirm={() => setErrorAlert(false)}
        >
          {errorAlertMsg}
        </SweetAlert>
      )}
      <LoadingDialog
        isOpen={loadDialogOpen}
        onClose={() => setloadDialogOpen(false)}
      />
    </div>
  );
}

export default CommandpostImportWizard;
