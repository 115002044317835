import { useEffect, useState, useMemo } from "react";
import UserCard from "../../components/UserCard";
import { useRealmApp } from "../../providers/RealmApp";
import { getUserListByUserID } from "../../services/users_services";
import { useAuth0 } from "@auth0/auth0-react";
import { Button, Form, Modal } from "react-bootstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import customstyles from "../../components/customstyles/react-select-styles";
import PhoneInput from "react-phone-input-2";
import {
  getuserbyauthproviderid,
  getuser,
  getuserbyid,
  deleteusersByID,
  updateuserbyid,
  insertUser,
} from "../../services/users_services";
import GenericListTable from "../../components/gridTable/genericLlistTable";
import { useHistory } from "react-router";
import { appConstants } from "../../constants/app_constants";
import Moment from "moment";
import FileBase from "react-file-base64";
import { getCollectionListsByFormName } from "../../services/collection_lists_services";
import {
  getaccountbyid,
  updateAccountByID,
} from "../../services/accounts_services";
import {
  getcompaniesbyid,
  updateCompaniesByID,
} from "../../services/companies_services";
import { isEmail } from "validator";
function UserList() {
  const [userList, setUserList] = useState([]);
  const [isDeleted, setIsDeleted] = useState(false);
  const [isAddUser, setIsAddUser] = useState(false);
  const [isEditUser, setIsEditUser] = useState(false);
  const [isDetailUser, setIsDetailUser] = useState(false);
  const [isDeleteUser, setIsDeleteUser] = useState(false);
  const [isvaliddata, setisvaliddata] = useState(false);
  const [isSuccess, setisSuccess] = useState(false);
  const [isEditSuccess, setisEditSuccess] = useState(false);
  const [isAlreadyExist, setIsAlreadyExist] = useState(false);
  const [DeleteUserID, setDeleteUserID] = useState();
  const [userRoleOptions, setUserRoleOption] = useState([]);
  const [companyList, setCompanyList] = useState();
  const [accountList, setAccountList] = useState();
  const [companyInfo, setCompanyInfo] = useState();
  const [accountInfo, setAccountInfo] = useState();
  const [errMsg, setErrMsg] = useState("");
  const ctechapp = useRealmApp();
  const [userinfo, setUserinfo] = useState({
    firstName: "",
    lastName: "",
    email: "",
    role: "",
  });

  const { isLoading, error, isAuthenticated, user } = useAuth0();
  const { v4: uuidv4 } = require("uuid");
  const initialContactState = {
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    userImage: "",
  };
  const initialEmailState = {
    id: uuidv4().replace(/-/g, ""),
    email: "",
    label: "",
    isMain: 0,
  };

  const initialPhoneState = {
    id: uuidv4().replace(/-/g, ""),
    phone: "",
    ext: "",
    fullPhone: "",
    country: "",
    label: "",
    extphone: "",
    isMain: 0,
  };

  const initialAddressState = {
    id: uuidv4().replace(/-/g, ""),
    label: "",
    address1: "",
    address2: "",
    city: "",
    country: "",
    state: "",
    zipCode: "",
    isMain: 0,
  };
  const [emailArray, setEmailArray] = useState([initialEmailState]);
  const [phoneArray, setPhoneArray] = useState([initialPhoneState]);
  const [addressArray, setAddressArray] = useState([initialAddressState]);
  const [companyEmailArray, setCompanyEmailArray] = useState([]);
  const [companyPhoneArray, setCompanyPhoneArray] = useState([]);
  const [companyAddressArray, setCompanyAddressArray] = useState([]);
  const [accountPhoneArray, setAccountPhoneArray] = useState([]);
  const [userErrors, setUserErrors] = useState(initialContactState);
  const [companyErrors, setCompanyErrors] = useState({ companyLogo: "" });
  const initaluserstate = {
    _p_accountId: userinfo?._p_accountId,
    _p_companyId: userinfo?._p_companyId,
    userid: "",
    firstName: "",
    lastName: "",
    email: "",
    role: "100",
    userEmailActivation: true,
    createdFrom: "PMaster",
    phone: "",
    title: "",
    phoneArr: [],
    emailArr: [],
    addressArr: [],
    userImage: "",
  };

  const [adduser, setAddUser] = useState(initaluserstate);
  const [edituser, setEditUser] = useState();

  //state variables
  const history = useHistory();
  //table functions
  const data = useMemo(() => [...userList], [userList]);
  const Columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "_id",
        sortable: true,
        width: "20%",
        Filter: "",
        filter: "",
      },
      {
        Header: "First name",
        accessor: "firstName",
        sortable: true,
        width: "20%",
        Filter: "",
        filter: "",
      },
      {
        Header: "Last name ",
        accessor: "lastName",
        sortable: true,
        width: "20%",
        Filter: "",
        filter: "",
        // Filter: DefaultColumnFilter,
        // filter: 'value',
      },
      {
        Header: "Email ",
        accessor: "email",
        sortable: true,
        width: "20%",
        Filter: "",
        filter: "",
        // Filter: DefaultColumnFilter,
        // filter: 'value',
      },
      {
        Header: "Phone",
        accessor: "phone",
        sortable: true,
        width: "30%",
        Filter: "",
        filter: "",
      },

      {
        Header: "Created on",
        accessor: "activity_history",
        sortable: true,
        width: "10%",
        Filter: "",
        filter: "value",
        Cell: ({ value }) =>
          (value && Moment(value).format(appConstants.DATEFORMAT)) || "",
      },
      {
        Header: "Status",
        accessor: "status",
        sortable: false,
        width: "10%",
        Filter: "",
        filter: "value",
        Cell: ({ value }) => {
          return (
            <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
              <i className="fa fa-circle fs-9px fa-fw me-5px"></i>
              {value}
            </span>
          );
        },
      },
      {
        Header: "Action",

        Cell: ({ row }) => {
          const onViewItemClick = () => {
            handleViewClickApps(row);
          };
          const onEditItemClick = () => {
            handleEditClickApps(row);
          };
          const onDeleteItemClick = () => {
            handleDeleteClickApps(row);
          };
          return (
            <div className="d-flex gap-4">
              <Button className="btn btn-sm " onClick={onViewItemClick}>
                {/* <i className="fas fa-2x  fa-pencil"></i> */}
                View
              </Button>
              <Button className="btn btn-sm " onClick={onEditItemClick}>
                {/* <i className="fas fa-2x  fa-pencil"></i> */}
                Edit
              </Button>
              <Button className="btn btn-sm " onClick={onDeleteItemClick}>
                {/* <i className="fas fa-2x  fa-trash-can"></i> */}
                Delete
              </Button>
            </div>
          );
        },
      },
    ],
    []
  );

  const hiddenColumns = ["_id", "appuserinfo[0].groupid"];

  useEffect(() => {
    if (isAuthenticated) {
      getUserInfoByID();
      getUserList();
    }
  }, []);

  async function getUserInfoByID() {
    try {
      getuserbyauthproviderid(user.sub, ctechapp.currentUser).then(
        (response) => {
          let user_info = JSON.parse(response);
          if (response !== undefined || user_info.length !== 0) {
            setUserinfo(user_info[0]);
            if (user_info[0].role !== "9000") {
              loadcompanies(user_info[0]._p_companyId);
              loadaccount(user_info[0]._p_accountId);
            }

            GetUserRoleOptions(user_info[0].role);
          }
        }
      );
    } catch (exception) {
      console.log(exception);
    }
  }
  async function getUserList() {
    try {
      getuser(ctechapp.currentUser, user.sub).then((response) => {
        let user_info = JSON.parse(response);

        if (user_info.status === "200") {
          setUserList(user_info.data);
          setErrMsg("");
        } else {
          setErrMsg(user_info.error);
        }
      });
    } catch (exception) {
      console.log(exception);
    }
  }
  async function getEditUserByID(id) {
    try {
      getuserbyid(id, ctechapp.currentUser).then((response) => {
        let user_info = JSON.parse(response);
        if (user_info !== undefined) {
          if (user_info.emailArr.length === 0) {
            user_info.emailArr = [{ ...initialEmailState }];
          }
          if (user_info.phoneArr.length === 0) {
            user_info.phoneArr = [{ ...initialPhoneState }];
          }
          if (user_info.addressArr.length === 0) {
            user_info.addressArr = [{ ...initialAddressState }];
          }
          setEditUser(user_info);
          setEmailArray(user_info.emailArr);
          setPhoneArray(user_info.phoneArr);
          setAddressArray(user_info.addressArr);
          setIsEditUser(true);
        }
      });
    } catch (exception) {
      console.log(exception);
    }
  }
  async function getDetailUserByID(id) {
    try {
      getuserbyid(id, ctechapp.currentUser).then((response) => {
        let user_info = JSON.parse(response);
        if (user_info !== undefined) {
          setEditUser(user_info);
          setEmailArray(user_info.emailArr);
          setPhoneArray(user_info.phoneArr);
          setAddressArray(user_info.addressArr);
          setIsDetailUser(true);
        }
      });
    } catch (exception) {
      console.log(exception);
    }
  }
  async function GetUserRoleOptions(role) {
    await getCollectionListsByFormName(
      "ContactRole",
      ctechapp.currentUser
    ).then(async (response) => {
      if (response !== undefined) {
        let userrole_info = JSON.parse(response);

        userrole_info = await userrole_info.filter(
          (rolewise) => rolewise.listValue < role
        );

        console.log("userrole_info", userrole_info);
        let options = userrole_info.map(function (userrole) {
          return {
            value: userrole.listValue,
            label: userrole.listName,
          };
        });

        setUserRoleOption(options);
      }
    });
  }
  async function loadcompanies(id) {
    getcompaniesbyid(id, ctechapp.currentUser).then((response) => {
      if (response !== undefined) {
        let companiesdata = JSON.parse(response);
        if(companiesdata==null) return;
        if (companiesdata.addressArr.length === 0) {
          companiesdata.addressArr = [{ ...initialAddressState }];
        }
        if ( companiesdata.phoneArr.length === 0) {
          companiesdata.phoneArr = [{ ...initialPhoneState }];
        }
        if (companiesdata.companyEmail.length === 0) {
          companiesdata.companyEmail = [{ ...initialEmailState }];
        }
        setCompanyList(companiesdata);
          setCompanyInfo(companiesdata);
          setCompanyAddressArray(companiesdata.addressArr);
          setCompanyPhoneArray(companiesdata.phoneArr);
          setCompanyEmailArray(companiesdata.companyEmail);

       

        
       
      }
    });
  }
  async function loadaccount(id) {
    getaccountbyid(id, ctechapp.currentUser).then((response) => {
      if (response !== undefined) {
        //let data[] = response;
        //console.log("Facility" + JSON.parse(response));
        let accountdata = JSON.parse(response);
        if(accountdata==null) return;
        if (accountdata.accountPhoneArr.length === 0) {
          accountdata.accountPhoneArr = [{ ...initialPhoneState }];
        }
        setAccountList(accountdata);
        setAccountInfo(accountdata);
        setAccountPhoneArray(accountdata.accountPhoneArr);
      }
    });
  }
  function ondelete() {
    try {
      deleteusersByID(DeleteUserID, ctechapp.currentUser, user.sub).then(
        (response) => {
          if (response !== undefined) {
            getUserList();
            setIsDeleted(true);
          }
        }
      );
    } catch (error) {
      console.log(error);
    }
  }

  function gotoaddUser() {
    window.location.href = "/adduser";
  }
  function gotoedituser(id) {
    window.location.href = "/edituser/" + id;
  }
  function gotoviewuser(id) {
    window.location.href = "/detailuser";
  }

  const handleEditClickApps = (row) => {
    //gotoedituser(row.original._id);
    getEditUserByID(row.original._id);
  };
  const handleViewClickApps = (row) => {
    getDetailUserByID(row.original._id);
  };
  const handleDeleteClickApps = (row) => {
    setIsDeleteUser(true);
    setDeleteUserID(row.original._id);
  };
  const handleEditDropDownChange = (selectedOption, itemupdate, index) => {
    const { name, value } = selectedOption.target;
    setEditUser({ ...edituser, [name]: value });
    setisvaliddata(true);
  };
  // This is a custom filter UI for selecting
  // a unique option from a list
  function SelectColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
  }) {
    // Calculate the options for filtering
    // using the preFilteredRows
    const options = useMemo(() => {
      const options = new Set();
      preFilteredRows.forEach((row) => {
        options.add(row.values[id]);
      });
      return [...options.values()];
    }, [id, preFilteredRows]);

    // Render a multi-select box
    return (
      <select
        className="form-select"
        value={filterValue}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
      >
        <option value="">All</option>
        {options.map((option, i) => (
          <option key={i} value={option}>
            {option}
          </option>
        ))}
      </select>
    );
  }
  // Define a default UI for filtering
  function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }) {
    const count = preFilteredRows.length;

    return (
      <input
        className="form-control"
        value={filterValue || ""}
        onChange={(e) => {
          setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }}
      />
    );
  }

  //save functions
  const handleDropDownChange = (selectedOption, itemupdate, index) => {
    const { name, value } = selectedOption.target;
    setAddUser({ ...adduser, [name]: value });
    setisvaliddata(true);
  };
  function handlecontactPhoneChange(value) {
    console.log(value);

    setAddUser({ ...adduser, ["phone"]: value });
    // handlePersonalFieldValidation(event);
    setisvaliddata(true);
  }
  function handleEditcontactPhoneChange(value) {
    console.log(value);

    setEditUser({ ...edituser, ["phone"]: value });
    // handlePersonalFieldValidation(event);
    setisvaliddata(true);
  }
  const handleEditToRemoveUserImage = () => {
    var _user = { ...edituser };
    _user.userImage = "";
    setEditUser(_user);
    setisvaliddata(true);
  };
  const handleEditToRemoveCompanyLogoImage = () => {
    var _companyInfo = { ...companyInfo };
    _companyInfo.companyLogo = "";
    setCompanyInfo(_companyInfo);
    setisvaliddata(true);
  };
  const handleChange = (event) => {
    const path = event.target.name.split(".");
    const finalProp = path.pop();
    const newuser = { ...adduser };
    let pointer = newuser;
    path.forEach((el) => {
      pointer[el] = { ...pointer[el] };
      pointer = pointer[el];
    });
    pointer[finalProp] =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    setAddUser(newuser);
    handleFieldValidation(event);
    setisvaliddata(true);
  };
  const handleAccountChange = (event) => {
    const path = event.target.name.split(".");
    const finalProp = path.pop();
    const newuser = { ...accountInfo };
    let pointer = newuser;
    path.forEach((el) => {
      pointer[el] = { ...pointer[el] };
      pointer = pointer[el];
    });
    pointer[finalProp] =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    setAccountInfo(newuser);

    setisvaliddata(true);
  };
  const handleEditOnPhoneChange = (phoneinputvalue, index, property) => {
    let { value, data, event, formattedValue } = phoneinputvalue;
    let _phoneArray = [...phoneArray];
    //const dialcode = data.dialCode;
    _phoneArray[index].ext = "";
    _phoneArray[index].country = "";
    // const phonevalue = value;
    _phoneArray[index].fullPhone = phoneinputvalue;
    _phoneArray[index].phone = "";
    setPhoneArray(_phoneArray);
    setisvaliddata(true);
  };
  const handleCompanyOnPhoneChange = (phoneinputvalue, index, property) => {
    let { value, data, event, formattedValue } = phoneinputvalue;
    let _phoneArray = [...companyPhoneArray];
    //const dialcode = data.dialCode;
    _phoneArray[index].ext = "";
    _phoneArray[index].country = "";
    // const phonevalue = value;
    _phoneArray[index].fullPhone = phoneinputvalue;
    _phoneArray[index].phone = "";
    setCompanyPhoneArray(_phoneArray);
    setisvaliddata(true);
  };
  const handleAccountOnPhoneChange = (phoneinputvalue, index, property) => {
    let { value, data, event, formattedValue } = phoneinputvalue;
    let _phoneArray = [...accountPhoneArray];
    //const dialcode = data.dialCode;
    _phoneArray[index].ext = "";
    _phoneArray[index].country = "";
    // const phonevalue = value;
    _phoneArray[index].fullPhone = phoneinputvalue;
    _phoneArray[index].phone = "";
    setAccountPhoneArray(_phoneArray);
    setisvaliddata(true);
  };
  function handleEditChangePhone(e, index, property) {
    let _phoneArray = [...phoneArray];
    _phoneArray[index][property] = e.target.value;

    setPhoneArray(_phoneArray);
    setisvaliddata(true);
  }
  function handleCompanyChangePhone(e, index, property) {
    let _phoneArray = [...companyPhoneArray];
    _phoneArray[index][property] = e.target.value;

    setCompanyPhoneArray(_phoneArray);
    setisvaliddata(true);
  }
  function handleAccountChangePhone(e, index, property) {
    let _phoneArray = [...accountPhoneArray];
    _phoneArray[index][property] = e.target.value;

    setAccountPhoneArray(_phoneArray);
    setisvaliddata(true);
  }
  const handleEditChange = (event) => {
    const path = event.target.name.split(".");
    const finalProp = path.pop();
    const newuser = { ...edituser };
    let pointer = newuser;
    path.forEach((el) => {
      pointer[el] = { ...pointer[el] };
      pointer = pointer[el];
    });
    pointer[finalProp] =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    setEditUser(newuser);
    handleFieldValidation(event);
    setisvaliddata(true);
  };
  function handleEditChangeEmail(e, index, property) {
    let _emailArray = [...emailArray];
    _emailArray[index][property] = e.target.value;

    setEmailArray(_emailArray);
    setisvaliddata(true);
  }
  function handleCompanyChangeEmail(e, index, property) {
    let _emailArray = [...companyEmailArray];
    _emailArray[index][property] = e.target.value;

    setCompanyEmailArray(_emailArray);
    setisvaliddata(true);
  }
  function handleCompanyChangeAddress(e, index, property) {
    let _addressArray = [...companyAddressArray];
    _addressArray[index][property] = e.target.value;

    setCompanyAddressArray(_addressArray);
    setisvaliddata(true);
  }
  function handleEditChangeAddress(e, index, property) {
    let _addressArray = [...addressArray];
    _addressArray[index][property] = e.target.value;

    setAddressArray(_addressArray);
    setisvaliddata(true);
  }
  const handleFieldValidation = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let localerror = "";
    switch (name) {
      case "firstName":
        localerror =
          value.length < 3 ? "first name must be at least 3 characters" : "";
        break;
      case "lastName":
        localerror =
          value.length < 3 ? "last name must be at least 3 characters" : "";
        break;
      case "email":
        localerror = !isEmail(value) ? "Invalid Email" : "";
        break;
      default:
        break;
    }
    setUserErrors({ ...userErrors, [name]: localerror });
    setisvaliddata(true);
  };

  async function submitHandler(values) {
    var _emailArray = [];
    var _phoneArray = [];
    var _addressArray = [];
    emailArray.map((email, index) => {
      if (email.email.length > 0 && email.label.length > 0) {
        _emailArray.push(email);
      }
    });

    phoneArray.map((phone, index) => {
      if (phone.fullPhone.length > 0 && phone.label.length > 0) {
        _phoneArray.push(phone);
      }
    });
    addressArray.map((address, index) => {
      if (address.address1.length > 0 && address.label.length > 0) {
        _addressArray.push(address);
      }
    });
    var _p_accountId = userinfo?._p_accountId;
    var _p_companyId = userinfo?._p_companyId;
    adduser.emailArr = _emailArray;
    adduser.phoneArr = _phoneArray;
    adduser.addressArr = _addressArray;
    adduser._p_accountId = _p_accountId;
    adduser._p_companyId = _p_companyId;

    if (validForm()) {
      insertUser(adduser, ctechapp.currentUser, user.sub).then((response) => {
        if (response !== undefined) {
          if (response === false) {
            setIsAlreadyExist(true);
          } else {
            getUserList();
            setisSuccess(true);
          }
        }
      });
    }
  }

  async function EditSubmitHandler(values) {
    var _emailArray = [];
    var _phoneArray = [];
    var _addressArray = [];
    emailArray.map((email, index) => {
      if (email.email.length > 0 && email.label.length > 0) {
        _emailArray.push(email);
      }
    });

    phoneArray.map((phone, index) => {
      if (phone.fullPhone.length > 0 && phone.label.length > 0) {
        _phoneArray.push(phone);
      }
    });
    addressArray.map((address, index) => {
      if (address.address1.length > 0 && address.label.length > 0) {
        _addressArray.push(address);
      }
    });
    edituser.emailArr = _emailArray;
    edituser.phoneArr = _phoneArray;
    edituser.addressArr = _addressArray;
    if (validEditForm()) {
      UpdateAccountByID();
      UpdateCompaniesByID();
      updateuserbyid(edituser, ctechapp.currentUser, user.sub).then(
        (response) => {
          if (response !== undefined) {
            if (response === false) {
              setIsAlreadyExist(true);
            } else {
              getUserList();
              setisEditSuccess(true);
            }
          }
        }
      );
    }
  }
  async function UpdateAccountByID() {
    var _phoneArray = [];
    var _accountInfo = { ...accountInfo };
    accountPhoneArray.map((phone, index) => {
      if (phone.fullPhone.length > 0 && phone.label.length > 0) {
        _phoneArray.push(phone);
      }
    });
    _accountInfo.accountPhoneArr = _phoneArray;
    setAccountPhoneArray(_phoneArray);
    setAccountInfo(_accountInfo);
    updateAccountByID(_accountInfo, ctechapp.currentUser, user.sub).then(
      (response) => {
        if (response !== undefined) {
          console.log("Account updated");
        }
      }
    );
  }

  async function UpdateCompaniesByID() {
    var _emailArray = [];
    var _phoneArray = [];
    var _addressArray = [];
    var _companyInfo = { ...companyInfo };
    companyEmailArray.map((email, index) => {
      if (email.email.length > 0 && email.label.length > 0) {
        _emailArray.push(email);
      }
    });

    companyPhoneArray.map((phone, index) => {
      if (phone.fullPhone.length > 0 && phone.label.length > 0) {
        _phoneArray.push(phone);
      }
    });
    companyAddressArray.map((address, index) => {
      if (address.address1.length > 0 && address.label.length > 0) {
        _addressArray.push(address);
      }
    });
    _companyInfo.companyEmail = _emailArray;
    _companyInfo.phoneArr = _phoneArray;
    _companyInfo.addressArr = _addressArray;
    setCompanyAddressArray(_addressArray);
    setCompanyPhoneArray(_phoneArray);
    setCompanyEmailArray(_emailArray);
    setCompanyInfo(_companyInfo);
    updateCompaniesByID(_companyInfo, ctechapp.currentUser, user.sub).then(
      (response) => {
        if (response !== undefined) {
          console.log("Companies  updated");
        }
      }
    );
  }

  const validForm = () => {
    try {
      let valid = false;
      valid = handleFieldListRequiredValidation();
      Object.values(userErrors).forEach((val) => {
        if (val.length > 0) valid = false;
      });
      //console.log("valid : ", valid);
      return valid;
    } catch (error) {
      return false;
    }
  };
  const validEditForm = () => {
    try {
      let valid = false;
      valid = handleFieldListEditRequiredValidation();
      Object.values(userErrors).forEach((val) => {
        if (val.length > 0) valid = false;
      });
      //console.log("valid : ", valid);
      return valid;
    } catch (error) {
      return false;
    }
  };
  const handleFieldListEditRequiredValidation = () => {
    let iserror = "";

    setUserErrors({
      ...userErrors,
      firstName:
        edituser.firstName === ""
          ? (iserror = "First Name required")
          : userErrors.firstName,
      lastName:
        edituser.lastName === ""
          ? (iserror = "Last Name required")
          : userErrors.lastName,
      email:
        edituser.email === "" ? (iserror = "Email required") : userErrors.email,
    });

    return iserror.length === 0;
  };
  const handleFieldListRequiredValidation = () => {
    let iserror = "";

    setUserErrors({
      ...userErrors,
      firstName:
        adduser.firstName === ""
          ? (iserror = "First Name required")
          : userErrors.firstName,
      lastName:
        adduser.lastName === ""
          ? (iserror = "Last Name required")
          : userErrors.lastName,
      email:
        adduser.email === "" ? (iserror = "Email required") : userErrors.email,
    });

    return iserror.length === 0;
  };

  const handleToRemoveUserImage = () => {
    var _user = { ...adduser };
    _user.userImage = "";
    setAddUser(_user);
  };

  function handleChangeEmail(e, index, property) {
    let _emailArray = [...emailArray];
    _emailArray[index][property] = e.target.value;

    setEmailArray(_emailArray);
  }
  function handleChangePhone(e, index, property) {
    let _phoneArray = [...phoneArray];
    _phoneArray[index][property] = e.target.value;

    setPhoneArray(_phoneArray);
  }
  function handleChangeAddress(e, index, property) {
    let _addressArray = [...addressArray];
    _addressArray[index][property] = e.target.value;

    setAddressArray(_addressArray);
  }
  const handleOnPhoneChange = (phoneinputvalue, index, property) => {
    let { value, data, event, formattedValue } = phoneinputvalue;
    let _phoneArray = [...phoneArray];
    //const dialcode = data.dialCode;
    _phoneArray[index].ext = "";
    _phoneArray[index].country = "";
    // const phonevalue = value;
    _phoneArray[index].fullPhone = phoneinputvalue;
    _phoneArray[index].phone = "";
    setPhoneArray(_phoneArray);
  };
  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    if (file.size > 5242880) {
      setUserErrors({
        ...userErrors,
        ["userImage"]: "Image size should be less than 5mb",
      });
      return;
    }
    setisvaliddata(true);
    const base64 = await convertToBase64(file);
    setAddUser({ ...adduser, ["userImage"]: base64 });
    setUserErrors({ ...userErrors, ["userImage"]: "" });
  };
  const handleEditFileUpload = async (e) => {
    const file = e.target.files[0];

    if (file.size > 5242880) {
      setUserErrors({
        ...userErrors,
        ["userImage"]: "Image size should be less than 5mb",
      });
      return;
    }
    setisvaliddata(true);
    const base64 = await convertToBase64(file);
    setEditUser({ ...edituser, ["userImage"]: base64 });
    setUserErrors({ ...userErrors, ["userImage"]: "" });
  };
  const handleEditCompanyFileUpload = async (e) => {
    const file = e.target.files[0];

    if (file.size > 5242880) {
      setCompanyErrors({
        ...userErrors,
        ["companyLogo"]: "Logo size should be less than 5mb",
      });
      return;
    }
    const base64 = await convertToBase64(file);
    setCompanyInfo({ ...companyInfo, ["companyLogo"]: base64 });
    setCompanyErrors({ ...companyErrors, ["companyLogo"]: "" });
    setisvaliddata(true);
  };
  return (
    <div>
      <h3 align="center">User list</h3>
      <div className="d-flex justify-content-end">
        <Button
          onClick={() => {
            setIsAddUser(true);
            setAddUser(initaluserstate);
            setEmailArray([initialEmailState]);
            setPhoneArray([initialPhoneState]);
            setAddressArray([initialAddressState]);
          }}
          className="btn btn-lg mb-2"
        >
          Add
          {/* <span className="fa-stack fa-2x">
          <i className="far fa-circle fa-stack-2x"></i>
          <i className="fa fa-plus fa-stack-1x"></i>
        </span> */}
        </Button>
      </div>
      <div className="table-responsive">
        <GenericListTable
          gcolumns={Columns}
          gdata={data.length > 0 ? JSON.stringify(data) : ""}
          ghiddencolumns={hiddenColumns}
          gplaceholder={"Search user.."}
          gsearchDisabled={false}
          filterDisabled={true}
          // gkey={"_id"}
          // glink="/detailuser"
        />
      </div>
      <div className="p-1"></div>
      {/* Add User */}
      {isAddUser && (
        <Modal
          scrollable={true}
          show={isAddUser}
          onHide={() => {
            setIsAddUser(false);
          }}
          size="lg"
          centered
        >
          <Modal.Header className="fw-bold h4">
            Add User{" "}
            <a
              style={{ "text-decoration": "none" }}
              onClick={() => setIsAddUser(false)}
              className="col d-flex justify-content-end text-dark"
            >
              <i class="fa-solid fa-xmark"></i>
            </a>
          </Modal.Header>
          <Modal.Body>
            {" "}
            <div className="container p-3">
              <div className="p-1">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="row p-1">
                      <div className="col-sm-12 col-md-4 col-lg-4">
                        Login email<label className="text-danger">*</label>
                      </div>
                      <div className="col-sm-1 col-md-1 col-lg-1"></div>
                      <div className="col-sm-12 col-md-4 col-lg-6">
                        <input
                          type="text"
                          name="email"
                          id="email"
                          placeholder="Email"
                          className="form-control"
                          value={adduser?.email}
                          onChange={handleChange}
                        />
                        {userErrors.email.length > 0 && (
                          <div className="text-danger">{userErrors.email}</div>
                        )}
                      </div>
                    </div>
                    <div className="row p-1">
                      <div className="col-sm-12 col-md-4 col-lg-4">
                        Login phone<label className="text-danger">*</label>
                      </div>
                      <div className="col-sm-1 col-md-1 col-lg-1"></div>
                      <div className="col-sm-12 col-md-4 col-lg-6">
                        <PhoneInput
                          inputStyle={{ width: "100%" }}
                          country={"us"}
                          value={adduser?.phone}
                          placeholder="Phone Number"
                          name="phone"
                          id="phone"
                          onChange={handlecontactPhoneChange}
                        />
                        {userErrors.phone.length > 0 && (
                          <div className="text-danger">{userErrors.phone}</div>
                        )}
                      </div>
                    </div>
                    <div className=" row p-1">
                      <div className="col-sm-12 col-md-4 col-lg-4">
                        First name <label className="text-danger">*</label>
                      </div>
                      <div className="col-sm-1 col-md-1 col-lg-1"></div>
                      <div className="col-sm-12 col-md-4 col-lg-6">
                        <input
                          type="text"
                          name="firstName"
                          id="firstName"
                          className="form-control"
                          placeholder="First Name"
                          value={adduser?.firstName}
                          onChange={handleChange}
                        />
                        {userErrors.firstName.length > 0 && (
                          <div className="text-danger">
                            {userErrors.firstName}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="row p-1">
                      <div className="col-sm-12 col-md-4 col-lg-4">
                        Last name<label className="text-danger">*</label>
                      </div>
                      <div className="col-sm-1 col-md-1 col-lg-1"></div>
                      <div className="col-sm-12 col-md-4 col-lg-6">
                        <input
                          type="text"
                          name="lastName"
                          id="lastName"
                          placeholder="Last Name"
                          className="form-control"
                          value={adduser?.lastName}
                          onChange={handleChange}
                        />
                        {userErrors.lastName.length > 0 && (
                          <div className="text-danger">
                            {userErrors.lastName}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="row p-1">
                      <div className="col-sm-12 col-md-4 col-lg-4">
                        {" "}
                        User role<label className="text-danger">*</label>
                      </div>
                      <div className="col-sm-1 col-md-1 col-lg-1"></div>
                      <div className="col-sm-12 col-md-4 col-lg-6">
                        <Select
                          isSearchable={true}
                          type="text"
                          name="role"
                          id="role"
                          options={userRoleOptions}
                          styles={customstyles}
                          className="form-control"
                          value={userRoleOptions.filter(
                            (client) => client.value === adduser?.role
                          )}
                          onChange={(val) => {
                            handleDropDownChange({
                              target: {
                                name: "role",
                                value: val.value,
                              },
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="row p-1">
                      <div className="col-sm-12 col-md-4 col-lg-4"> Title</div>
                      <div className="col-sm-1 col-md-1 col-lg-1"></div>
                      <div className="col-sm-12 col-md-4 col-lg-6">
                        <input
                          type="text"
                          name="title"
                          id="title"
                          placeholder="Title"
                          className="form-control"
                          value={adduser?.title}
                          onChange={handleChange}
                        />
                        {/* {userErrors.title.length > 0 && (
                  <div className="text-danger">{userErrors.title}</div>
                )} */}
                      </div>
                    </div>
                    {/* Email Array */}

                    {emailArray?.map((row, index) => {
                      return (
                        <div className="row p-1">
                          <div className="col-sm-12 col-md-4 col-lg-4">
                            {index === 0 && "Additional Email"}
                          </div>
                          <div class="form-check col-sm-12 col-md-4 col-lg-1  ">
                            <input
                              class="form-check-input star  "
                              type="checkbox"
                              name={"isMain_" + index}
                              id={"isMain_" + index}
                              checked={row.isMain === 1 ? true : false}
                              onChange={(e) => {
                                setisvaliddata(true);
                                let _emailArray = [...emailArray];
                                _emailArray[index].isMain = e.target.checked
                                  ? 1
                                  : 0;
                                _emailArray.map((email, emailindex) => {
                                  if (index !== emailindex) {
                                    email.isMain = 0;
                                  }
                                });
                                setEmailArray(_emailArray);
                              }}
                              data-gtm-form-interact-field-id="0"
                            />
                          </div>
                          <div className="col-sm-12 col-md-4 col-lg-3">
                            <input
                              type="text"
                              name={"email_" + index}
                              id={"email_" + index}
                              className="form-control"
                              placeholder="Email"
                              value={row?.email}
                              onChange={(e) => {
                                handleChangeEmail(e, index, "email");
                              }}
                            />
                          </div>
                          <div className="col-sm-12 col-md-4 col-lg-3">
                            <input
                              type="text"
                              name={"label_" + index}
                              id={"label_" + index}
                              className="form-control"
                              placeholder="label"
                              value={row?.label}
                              onChange={(e) => {
                                handleChangeEmail(e, index, "label");
                              }}
                            />
                          </div>
                          {emailArray.length - 1 === index && (
                            <div className="col-sm-1 col-md-1 col-lg-1 mt-2">
                              <a
                                onClick={() => {
                                  let _emailArray = [...emailArray];
                                  _emailArray.push(initialEmailState);
                                  setEmailArray(_emailArray);
                                }}
                              >
                                <i
                                  className="fa-solid fa-circle-plus fa-xl"
                                  style={{ color: "#848a94" }}
                                ></i>
                              </a>
                            </div>
                          )}

                          {emailArray.length - 1 !== index && (
                            <div className="col-sm-1 col-md-1 col-lg-1 mt-2">
                              <a
                                onClick={() => {
                                  let _emailArray = [...emailArray];
                                  _emailArray.splice(index, 1);
                                  setEmailArray(_emailArray);
                                }}
                              >
                                <i
                                  class="fa-solid fa-circle-xmark fa-xl"
                                  style={{ color: "#cf3030" }}
                                ></i>
                              </a>
                            </div>
                          )}
                        </div>
                      );
                    })}

                    {/* Phone Array */}
                    {phoneArray?.map((row, index) => {
                      return (
                        <div className="row p-1">
                          <div className="col-sm-12 col-md-4 col-lg-4">
                            {index === 0 && "Additional Phone"}
                          </div>
                          <div className="col-sm-8 col-md-8 col-lg-8">
                            <div className="row">
                              {" "}
                              <div class="form-check col-sm-12 col-md-4 col-lg-1  ">
                                <input
                                  class="form-check-input star"
                                  type="checkbox"
                                  name={"isMain_" + index}
                                  id={"isMain_" + index}
                                  checked={row.isMain === 1 ? true : false}
                                  onChange={(e) => {
                                    setisvaliddata(true);
                                    let _phoneArray = [...phoneArray];
                                    _phoneArray[index].isMain = e.target.checked
                                      ? 1
                                      : 0;
                                    _phoneArray.map((phone, phoneindex) => {
                                      if (index !== phoneindex) {
                                        phone.isMain = 0;
                                      }
                                    });
                                    setPhoneArray(_phoneArray);
                                  }}
                                  data-gtm-form-interact-field-id="0"
                                />
                              </div>
                              <div className="col-sm-9 col-md-9 col-lg-9 ms-4">
                                <PhoneInput
                                  inputStyle={{ width: "100%" }}
                                  country={"us"}
                                  value={row?.fullPhone}
                                  placeholder="Phone Number"
                                  name="phone"
                                  id="phone"
                                  onChange={(e) =>
                                    handleOnPhoneChange(e, index, "fullPhone")
                                  }
                                />
                              </div>
                            </div>{" "}
                            <div className="row mt-1">
                              <div className="col-sm-1 col-md-1 col-lg-1" />
                              <div className="col-sm-5 col-md-5 col-lg-5 ms-4">
                                <input
                                  type="text"
                                  name="extphone"
                                  id="extphone"
                                  className="form-control"
                                  placeholder="Ext"
                                  value={row?.extphone}
                                  onChange={(e) => {
                                    handleChangePhone(e, index, "extphone");
                                  }}
                                />
                              </div>
                              <div className="col-sm-5 col-md-5 col-lg-4">
                                <input
                                  type="text"
                                  name="label"
                                  id="label"
                                  className="form-control"
                                  placeholder="label"
                                  value={row?.label}
                                  onChange={(e) => {
                                    handleChangePhone(e, index, "label");
                                  }}
                                />
                              </div>
                              {phoneArray.length - 1 === index && (
                                <div className="col-sm-1 col-md-1 col-lg-1 mt-2 p-0 ms-2">
                                  <a
                                    onClick={() => {
                                      let _phoneArray = [...phoneArray];
                                      _phoneArray.push(initialPhoneState);
                                      setPhoneArray(_phoneArray);
                                    }}
                                  >
                                    <i
                                      className="fa-solid fa-circle-plus fa-xl"
                                      style={{ color: "#848a94" }}
                                    ></i>
                                  </a>
                                </div>
                              )}

                              {phoneArray.length - 1 !== index && (
                                <div className="col-sm-1 col-md-1 col-lg-1 mt-2 p-0 ms-2">
                                  <a
                                    onClick={() => {
                                      let _phoneArray = [...phoneArray];
                                      _phoneArray.splice(index, 1);
                                      setPhoneArray(_phoneArray);
                                    }}
                                  >
                                    <i
                                      class="fa-solid fa-circle-xmark fa-xl"
                                      style={{ color: "#cf3030" }}
                                    ></i>
                                  </a>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                    {/*Address Array  */}
                    {addressArray?.map((row, index) => {
                      return (
                        <div className="row p-1">
                          <div className="col-sm-12 col-md-4 col-lg-4">
                            {index === 0 && "Address"}{" "}
                          </div>
                          <div class="form-check col-sm-12 col-md-4 col-lg-1  ">
                            <input
                              class="form-check-input star"
                              type="checkbox"
                              name={"isMain_" + index}
                              id={"isMain_" + index}
                              checked={row.isMain === 1 ? true : false}
                              onChange={(e) => {
                                setisvaliddata(true);
                                let _addressArray = [...addressArray];
                                _addressArray[index].isMain = e.target.checked
                                  ? 1
                                  : 0;
                                _addressArray.map((address, addressindex) => {
                                  if (index !== addressindex) {
                                    address.isMain = 0;
                                  }
                                });
                                setAddressArray(_addressArray);
                              }}
                              data-gtm-form-interact-field-id="0"
                            />
                          </div>
                          <div className="col-sm-12 col-md-4 col-lg-6">
                            <div className="mt-1">
                              <input
                                type="text"
                                name="address1"
                                placeholder="Address 1"
                                id="address1"
                                className="form-control"
                                value={row?.address1}
                                onChange={(e) =>
                                  handleChangeAddress(e, index, "address1")
                                }
                              />
                            </div>
                            <div className="mt-1">
                              <input
                                type="text"
                                name="address2"
                                id="address2"
                                className="form-control"
                                placeholder="Address 2"
                                value={row?.address2}
                                onChange={(e) =>
                                  handleChangeAddress(e, index, "address2")
                                }
                              />
                            </div>

                            <div className="mt-1">
                              {" "}
                              <input
                                type="text"
                                name="city"
                                id="city"
                                placeholder="City"
                                className="form-control"
                                value={row?.city}
                                onChange={(e) =>
                                  handleChangeAddress(e, index, "city")
                                }
                              />
                            </div>

                            <div className="mt-1 row ">
                              <div className="col-lg-6">
                                {" "}
                                <input
                                  type="text"
                                  name="state"
                                  id="state"
                                  placeholder="State"
                                  className="form-control"
                                  value={row?.state}
                                  onChange={(e) =>
                                    handleChangeAddress(e, index, "state")
                                  }
                                />
                              </div>
                              <div className="col-lg-6">
                                {" "}
                                <input
                                  type="number"
                                  name="zipCode"
                                  id="zipCode"
                                  className="form-control"
                                  placeholder="zipCode"
                                  value={row?.zipCode}
                                  onChange={(e) =>
                                    handleChangeAddress(e, index, "zipCode")
                                  }
                                />
                              </div>
                            </div>
                            <div className="mt-1">
                              <input
                                type="text"
                                name="country"
                                id="country"
                                className="form-control"
                                placeholder="Country"
                                value={row?.country}
                                onChange={(e) =>
                                  handleChangeAddress(e, index, "country")
                                }
                              />
                            </div>
                            <div className="mt-1">
                              {" "}
                              <input
                                type="text"
                                name="label"
                                id="label"
                                className="form-control"
                                placeholder="Label"
                                value={row.label}
                                onChange={(e) =>
                                  handleChangeAddress(e, index, "label")
                                }
                              />
                            </div>

                            {/* {userErrors.title.length > 0 && (
                  <div className="text-danger">{userErrors.title}</div>
                )} */}
                          </div>
                          {addressArray.length - 1 === index && (
                            <div className="col-sm-1 col-md-1 col-lg-1 mt-2">
                              <a
                                onClick={() => {
                                  let _addressArray = [...addressArray];
                                  _addressArray.push(initialAddressState);
                                  setAddressArray(_addressArray);
                                }}
                              >
                                <i
                                  className="fa-solid fa-circle-plus fa-xl"
                                  style={{ color: "#848a94" }}
                                ></i>
                              </a>
                            </div>
                          )}

                          {addressArray.length - 1 !== index && (
                            <div className="col-sm-1 col-md-1 col-lg-1 mt-2">
                              <a
                                onClick={() => {
                                  let _addressArray = [...addressArray];
                                  _addressArray.splice(index, 1);
                                  setAddressArray(_addressArray);
                                }}
                              >
                                <i
                                  class="fa-solid fa-circle-xmark fa-xl"
                                  style={{ color: "#cf3030" }}
                                ></i>
                              </a>
                            </div>
                          )}
                        </div>
                      );
                    })}
                    <div className="row p-1">
                      <div className="col-sm-12 col-md-4 col-lg-4">
                        {" "}
                        Company name
                      </div>
                      <div className="col-sm-12 col-md-4 col-lg-1"></div>
                      <div className="col-sm-12 col-md-4 col-lg-6">
                        <input
                          type="text"
                          name="title"
                          id="title"
                          disabled={true}
                          className="form-control"
                          value={companyList?.companyName}
                          onChange={handleChange}
                        />
                        {/* {userErrors.title.length > 0 && (
                  <div className="text-danger">{userErrors.title}</div>
                )} */}
                      </div>
                    </div>
                    <div className="row p-1">
                      <div className="col-sm-12 col-md-4 col-lg-4">
                        {" "}
                        Account name
                      </div>
                      <div className="col-sm-12 col-md-4 col-lg-1"></div>
                      <div className="col-sm-12 col-md-4 col-lg-6">
                        <input
                          type="text"
                          name="title"
                          id="title"
                          className="form-control"
                          value={accountList?.accountCompanyName}
                          disabled={true}
                          onChange={handleChange}
                        />
                        {/* {userErrors.title.length > 0 && (
                  <div className="text-danger">{userErrors.title}</div>
                )} */}
                      </div>
                    </div>
                    <div className="row p-1">
                      <div className="col-sm-12 col-md-4 col-lg-4"> Image</div>
                      <div className="col-sm-12 col-md-4 col-lg-1"></div>
                      <div className="d-flex justify-content-start col-sm-12 col-md-4 col-lg-6">
                        <div>
                          <Button
                            style={{ padding: "1px 6px" }}
                            onClick={() => handleToRemoveUserImage()}
                            className="me-2"
                          >
                            Clear
                          </Button>
                        </div>
                        <div>
                          <input
                            type="file"
                            multiple={false}
                            name="userImage"
                            onChange={(e) => handleFileUpload(e)}
                            accept=".jpeg, .png, .jpg"
                          ></input>
                          {/* <FileBase
                            type="file"
                            multiple={false}
                            onDone={({ base64 }: { base64: string }) => {
                              // if (base64.size > 50000) {
                              //   console.error("file size greater");
                              // }
                              setAddUser({ ...adduser, ["userImage"]: base64 });
                              // setLogo({ ...logo, ["applogo"]: base64 });
                            }}
                          /> */}
                          <img
                            src={
                              adduser.userImage === "" ||
                              adduser.userImage === undefined
                                ? "../assets/img/useraltimg.png"
                                : adduser.userImage
                            }
                            className="rounded-circle mx-auto d-block mw-100 mt-2"
                            width="100"
                            height="100"
                          />
                          {userErrors?.userImage.length > 0 && (
                            <div className="text-danger">
                              {userErrors?.userImage}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                {isSuccess && (
                  <SweetAlert
                    success
                    style={{ color: "black" }}
                    title={appConstants.import.alertmsg.usercreatedsucessfull}
                    onConfirm={() => {
                      setIsAddUser(false);
                      setisSuccess(false);
                    }}
                  ></SweetAlert>
                )}
                {isAlreadyExist && (
                  <SweetAlert
                    warning
                    style={{ color: "black" }}
                    title={appConstants.import.alertmsg.useralreadyexist}
                    onConfirm={() => {
                      setIsAlreadyExist(false);
                    }}
                  ></SweetAlert>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {" "}
            <div className="row p-1">
              {" "}
              <div className=" d-flex justify-content-end mt-3 col-sm-12 col-md-12 col-lg-12">
                <button
                  className=" btn btn-primary me-2 "
                  onClick={() => setIsAddUser(false)}
                >
                  Close
                </button>
                <button
                  className="btn btn-primary me-2 "
                  disabled={!isvaliddata}
                  onClick={submitHandler}
                >
                  Save
                </button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
      )}
      {/* Edit User */}
      {isEditUser && (
        <Modal
          scrollable={true}
          show={isEditUser}
          onHide={() => {
            setIsEditUser(false);
            getUserList();
          }}
          size="lg"
          centered
        >
          <Modal.Header className="fw-bold h4">
            Edit User{" "}
            <a
              style={{ "text-decoration": "none" }}
              onClick={() => setIsEditUser(false)}
              className="col d-flex justify-content-end text-dark"
            >
              <i class="fa-solid fa-xmark"></i>
            </a>
          </Modal.Header>
          <Modal.Body>
            {" "}
            <div className="container p-3">
              <div className="row">
                <div className="col-lg-12">
                  <div className="row p-1">
                    <div className="col-sm-12 col-md-4 col-lg-4">
                      Login email<label className="text-danger">*</label>
                    </div>
                    <div className="col-sm-1 col-md-1 col-lg-1"></div>
                    <div className="col-sm-12 col-md-4 col-lg-6">
                      <input
                        type="text"
                        name="email"
                        id="email"
                        placeholder="Email"
                        className="form-control"
                        disabled={true}
                        value={edituser?.email}
                        onChange={handleEditChange}
                      />
                      {userErrors.email.length > 0 && (
                        <div className="text-danger">{userErrors.email}</div>
                      )}
                    </div>
                  </div>
                  <div className="row p-1">
                    <div className="col-sm-12 col-md-4 col-lg-4">
                      Login phone<label className="text-danger">*</label>
                    </div>
                    <div className="col-sm-1 col-md-1 col-lg-1"></div>
                    <div className="col-sm-12 col-md-4 col-lg-6">
                      <PhoneInput
                        inputStyle={{ width: "100%" }}
                        country={"us"}
                        value={edituser?.phone}
                        placeholder="Phone Number"
                        name="phone"
                        id="phone"
                        onChange={handleEditcontactPhoneChange}
                      />
                      {userErrors.phone.length > 0 && (
                        <div className="text-danger">{userErrors.phone}</div>
                      )}
                    </div>
                  </div>
                  <div className=" row p-1">
                    <div className="col-sm-12 col-md-4 col-lg-4">
                      First name <label className="text-danger">*</label>
                    </div>
                    <div className="col-sm-1 col-md-1 col-lg-1"></div>
                    <div className="col-sm-12 col-md-4 col-lg-6">
                      <input
                        type="text"
                        name="firstName"
                        id="firstName"
                        className="form-control"
                        placeholder="First Name"
                        value={edituser?.firstName}
                        onChange={handleEditChange}
                      />
                      {userErrors.firstName.length > 0 && (
                        <div className="text-danger">
                          {userErrors.firstName}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row p-1">
                    <div className="col-sm-12 col-md-4 col-lg-4">
                      Last name<label className="text-danger">*</label>
                    </div>
                    <div className="col-sm-1 col-md-1 col-lg-1"></div>
                    <div className="col-sm-12 col-md-4 col-lg-6">
                      <input
                        type="text"
                        name="lastName"
                        id="lastName"
                        placeholder="Last Name"
                        className="form-control"
                        value={edituser?.lastName}
                        onChange={handleEditChange}
                      />
                      {userErrors.lastName.length > 0 && (
                        <div className="text-danger">{userErrors.lastName}</div>
                      )}
                    </div>
                  </div>
                  <div className="row p-1">
                    <div className="col-sm-12 col-md-4 col-lg-4">
                      {" "}
                      User role<label className="text-danger">*</label>
                    </div>
                    <div className="col-sm-1 col-md-1 col-lg-1"></div>
                    <div className="col-sm-12 col-md-4 col-lg-6">
                      <Select
                        isSearchable={true}
                        type="text"
                        name="role"
                        id="role"
                        options={userRoleOptions}
                        styles={customstyles}
                        className="form-control"
                        value={userRoleOptions.filter(
                          (client) => client.value === edituser?.role
                        )}
                        onChange={(val) => {
                          handleEditDropDownChange({
                            target: {
                              name: "role",
                              value: val.value,
                            },
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="row p-1">
                    <div className="col-sm-12 col-md-4 col-lg-4"> Title</div>
                    <div className="col-sm-1 col-md-1 col-lg-1"></div>
                    <div className="col-sm-12 col-md-4 col-lg-6">
                      <input
                        type="text"
                        name="title"
                        id="title"
                        placeholder="Title"
                        className="form-control"
                        value={edituser?.title}
                        onChange={handleEditChange}
                      />
                      {/* {userErrors.title.length > 0 && (
                  <div className="text-danger">{userErrors.title}</div>
                )} */}
                    </div>
                  </div>
                  {/* Email Array */}

                  {emailArray?.map((row, index) => {
                    return (
                      <div className="row p-1">
                        <div className="col-sm-12 col-md-4 col-lg-4">
                          {index === 0 && "Additional email"}
                        </div>
                        <div class="form-check col-sm-12 col-md-4 col-lg-1  ">
                          <input
                            class="form-check-input star"
                            type="checkbox"
                            name={"isMain_" + index}
                            id={"isMain_" + index}
                            checked={row.isMain === 1 ? true : false}
                            onChange={(e) => {
                              setisvaliddata(true);
                              let _emailArray = [...emailArray];
                              _emailArray[index].isMain = e.target.checked
                                ? 1
                                : 0;
                              _emailArray.map((email, emailindex) => {
                                if (index !== emailindex) {
                                  email.isMain = 0;
                                }
                              });
                              setEmailArray(_emailArray);
                            }}
                            data-gtm-form-interact-field-id="0"
                          />
                        </div>
                        <div className="col-sm-12 col-md-4 col-lg-3">
                          <input
                            type="text"
                            name={"email_" + index}
                            id={"email_" + index}
                            className="form-control"
                            placeholder="Email"
                            value={row?.email}
                            onChange={(e) => {
                              handleEditChangeEmail(e, index, "email");
                            }}
                          />
                        </div>
                        <div className="col-sm-12 col-md-4 col-lg-3">
                          <input
                            type="text"
                            name={"label_" + index}
                            id={"label_" + index}
                            className="form-control"
                            placeholder="label"
                            value={row?.label}
                            onChange={(e) => {
                              handleEditChangeEmail(e, index, "label");
                            }}
                          />
                        </div>
                        {emailArray?.length - 1 === index && (
                          <div className="col-sm-1 col-md-1 col-lg-1 mt-2">
                            <a
                              onClick={() => {
                                let _emailArray = [...emailArray];
                                _emailArray.push(initialEmailState);
                                setEmailArray(_emailArray);
                              }}
                            >
                              <i
                                className="fa-solid fa-circle-plus fa-xl"
                                style={{ color: "#848a94" }}
                              ></i>
                            </a>
                          </div>
                        )}

                        {emailArray?.length - 1 !== index && (
                          <div className="col-sm-1 col-md-1 col-lg-1 mt-2">
                            <a
                              onClick={() => {
                                let _emailArray = [...emailArray];
                                _emailArray.splice(index, 1);
                                setEmailArray(_emailArray);
                              }}
                            >
                              <i
                                class="fa-solid fa-circle-xmark fa-xl"
                                style={{ color: "#cf3030" }}
                              ></i>
                            </a>
                          </div>
                        )}
                      </div>
                    );
                  })}

                  {/* Phone Array */}
                  {phoneArray?.map((row, index) => {
                    return (
                      <div className="row p-1">
                        <div className="col-sm-12 col-md-4 col-lg-4">
                          {index === 0 && "Additional phone"}
                        </div>
                        <div className="col-sm-8 col-md-8 col-lg-8">
                          <div className="row">
                            {" "}
                            <div class="form-check col-sm-12 col-md-4 col-lg-1  ">
                              <input
                                class="form-check-input star"
                                type="checkbox"
                                name={"isMain_" + index}
                                id={"isMain_" + index}
                                checked={row.isMain === 1 ? true : false}
                                onChange={(e) => {
                                  setisvaliddata(true);
                                  let _phoneArray = [...phoneArray];
                                  _phoneArray[index].isMain = e.target.checked
                                    ? 1
                                    : 0;
                                  _phoneArray.map((phone, phoneindex) => {
                                    if (index !== phoneindex) {
                                      phone.isMain = 0;
                                    }
                                  });
                                  setPhoneArray(_phoneArray);
                                }}
                                data-gtm-form-interact-field-id="0"
                              />
                            </div>
                            <div className="col-sm-9 col-md-9 col-lg-9 ms-4">
                              <PhoneInput
                                inputStyle={{ width: "100%" }}
                                country={"us"}
                                value={row?.fullPhone}
                                placeholder="Phone Number"
                                name="phone"
                                id="phone"
                                onChange={(e) =>
                                  handleEditOnPhoneChange(e, index, "fullPhone")
                                }
                              />
                            </div>
                          </div>{" "}
                          <div className="row mt-1">
                            <div className="col-sm-1 col-md-1 col-lg-1" />
                            <div className="col-sm-5 col-md-5 col-lg-5 ms-4">
                              <input
                                type="text"
                                name="extphone"
                                id="extphone"
                                className="form-control"
                                placeholder="Ext"
                                value={row?.extphone}
                                onChange={(e) => {
                                  handleEditChangePhone(e, index, "extphone");
                                }}
                              />
                            </div>
                            <div className="col-sm-5 col-md-5 col-lg-4">
                              <input
                                type="text"
                                name="label"
                                id="label"
                                className="form-control"
                                placeholder="label"
                                value={row?.label}
                                onChange={(e) => {
                                  handleEditChangePhone(e, index, "label");
                                }}
                              />
                            </div>
                            {phoneArray.length - 1 === index && (
                              <div className="col-sm-1 col-md-1 col-lg-1 mt-2 p-0 ms-2">
                                <a
                                  onClick={() => {
                                    let _phoneArray = [...phoneArray];
                                    _phoneArray.push(initialPhoneState);
                                    setPhoneArray(_phoneArray);
                                  }}
                                >
                                  <i
                                    className="fa-solid fa-circle-plus fa-xl"
                                    style={{ color: "#848a94" }}
                                  ></i>
                                </a>
                              </div>
                            )}

                            {phoneArray.length - 1 !== index && (
                              <div className="col-sm-1 col-md-1 col-lg-1 mt-2 p-0 ms-2">
                                <a
                                  onClick={() => {
                                    let _phoneArray = [...phoneArray];
                                    _phoneArray.splice(index, 1);
                                    setPhoneArray(_phoneArray);
                                  }}
                                >
                                  <i
                                    class="fa-solid fa-circle-xmark fa-xl"
                                    style={{ color: "#cf3030" }}
                                  ></i>
                                </a>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  {/*Address Array  */}
                  {addressArray?.map((row, index) => {
                    return (
                      <div className="row p-1">
                        <div className="col-sm-12 col-md-4 col-lg-4">
                          {index === 0 && "Address"}{" "}
                        </div>
                        <div class="form-check col-sm-12 col-md-4 col-lg-1  ">
                          <input
                            class="form-check-input star"
                            type="checkbox"
                            name={"isMain_" + index}
                            id={"isMain_" + index}
                            checked={row.isMain === 1 ? true : false}
                            onChange={(e) => {
                              setisvaliddata(true);
                              let _addressArray = [...addressArray];
                              _addressArray[index].isMain = e.target.checked
                                ? 1
                                : 0;
                              _addressArray.map((address, addressindex) => {
                                if (index !== addressindex) {
                                  address.isMain = 0;
                                }
                              });
                              setAddressArray(_addressArray);
                            }}
                            data-gtm-form-interact-field-id="0"
                          />
                        </div>
                        <div className="col-sm-12 col-md-4 col-lg-6">
                          <div className="mt-1">
                            <input
                              type="text"
                              name="address1"
                              placeholder="Address 1"
                              id="address1"
                              className="form-control"
                              value={row?.address1}
                              onChange={(e) =>
                                handleEditChangeAddress(e, index, "address1")
                              }
                            />
                          </div>
                          <div className="mt-1">
                            <input
                              type="text"
                              name="address2"
                              id="address2"
                              className="form-control"
                              placeholder="Address 2"
                              value={row?.address2}
                              onChange={(e) =>
                                handleEditChangeAddress(e, index, "address2")
                              }
                            />
                          </div>

                          <div className="mt-1">
                            {" "}
                            <input
                              type="text"
                              name="city"
                              id="city"
                              placeholder="City"
                              className="form-control"
                              value={row?.city}
                              onChange={(e) =>
                                handleEditChangeAddress(e, index, "city")
                              }
                            />
                          </div>

                          <div className="mt-1 row ">
                            <div className="col-lg-6">
                              {" "}
                              <input
                                type="text"
                                name="state"
                                id="state"
                                placeholder="State"
                                className="form-control"
                                value={row?.state}
                                onChange={(e) =>
                                  handleEditChangeAddress(e, index, "state")
                                }
                              />
                            </div>
                            <div className="col-lg-6">
                              {" "}
                              <input
                                type="number"
                                name="zipCode"
                                id="zipCode"
                                className="form-control"
                                placeholder="zipCode"
                                value={row?.zipCode}
                                onChange={(e) =>
                                  handleEditChangeAddress(e, index, "zipCode")
                                }
                              />
                            </div>
                          </div>
                          <div className="mt-1">
                            <input
                              type="text"
                              name="country"
                              id="country"
                              className="form-control"
                              placeholder="Country"
                              value={row?.country}
                              onChange={(e) =>
                                handleEditChangeAddress(e, index, "country")
                              }
                            />
                          </div>
                          <div className="mt-1">
                            {" "}
                            <input
                              type="text"
                              name="label"
                              id="label"
                              className="form-control"
                              placeholder="Label"
                              value={row.label}
                              onChange={(e) =>
                                handleEditChangeAddress(e, index, "label")
                              }
                            />
                          </div>

                          {/* {userErrors.title.length > 0 && (
                  <div className="text-danger">{userErrors.title}</div>
                )} */}
                        </div>
                        {addressArray?.length - 1 === index && (
                          <div className="col-sm-1 col-md-1 col-lg-1 mt-2">
                            <a
                              onClick={() => {
                                let _addressArray = [...addressArray];
                                _addressArray.push(initialAddressState);
                                setAddressArray(_addressArray);
                              }}
                            >
                              <i
                                className="fa-solid fa-circle-plus fa-xl"
                                style={{ color: "#848a94" }}
                              ></i>
                            </a>
                          </div>
                        )}

                        {addressArray?.length - 1 !== index && (
                          <div className="col-sm-1 col-md-1 col-lg-1 mt-2">
                            <a
                              onClick={() => {
                                let _addressArray = [...addressArray];
                                _addressArray.splice(index, 1);
                                setAddressArray(_addressArray);
                              }}
                            >
                              <i
                                class="fa-solid fa-circle-xmark fa-xl"
                                style={{ color: "#cf3030" }}
                              ></i>
                            </a>
                          </div>
                        )}
                      </div>
                    );
                  })}
                  {edituser?.role !== "8000" && (
                    <div>
                      <div className="row p-1">
                        <div className="col-sm-12 col-md-4 col-lg-4">
                          {" "}
                          Company name
                        </div>
                        <div className="col-sm-12 col-md-1 col-lg-1"></div>
                        <div className="col-sm-12 col-md-7 col-lg-7">
                          <input
                            type="text"
                            name="title"
                            id="title"
                            disabled={true}
                            className="form-control"
                            value={companyList?.companyName}
                            onChange={handleEditChange}
                          />
                          {/* {userErrors.title.length > 0 && (
                  <div className="text-danger">{userErrors.title}</div>
                )} */}
                        </div>
                      </div>
                      <div className="row p-1">
                        <div className="col-sm-12 col-md-4 col-lg-4">
                          {" "}
                          Account name
                        </div>
                        <div className="col-sm-12 col-md-4 col-lg-1"></div>
                        <div className="col-sm-12 col-md-4 col-lg-7">
                          <input
                            type="text"
                            name="title"
                            id="title"
                            className="form-control"
                            value={accountList?.accountCompanyName}
                            disabled={true}
                            onChange={handleEditChange}
                          />
                          {/* {userErrors.title.length > 0 && (
                  <div className="text-danger">{userErrors.title}</div>
                )} */}
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="row p-1">
                    <div className="col-sm-12 col-md-4 col-lg-4"> Image</div>
                    <div className="col-sm-12 col-md-4 col-lg-1"></div>
                    <div className="d-flex justify-content-start col-sm-12 col-md-4 col-lg-6">
                      <div>
                        <Button
                          style={{ padding: "1px 6px" }}
                          onClick={() => handleEditToRemoveUserImage()}
                          className="me-2"
                        >
                          Clear
                        </Button>
                      </div>
                      <div>
                        {/* <FileBase
                          type="file"
                          multiple={false}
                          onDone={({ base64 }: { base64: string }) => {
                            setisvaliddata(true);
                            setEditUser({ ...edituser, ["userImage"]: base64 });
                            // setLogo({ ...logo, ["applogo"]: base64 });
                          }}
                        /> */}
                        <input
                          type="file"
                          multiple={false}
                          name="userImage"
                          onChange={(e) => handleEditFileUpload(e)}
                          accept=".jpeg, .png, .jpg"
                        ></input>
                        <img
                          src={
                            edituser?.userImage === "" ||
                            edituser?.userImage === undefined
                              ? "../assets/img/useraltimg.png"
                              : edituser?.userImage
                          }
                          className="rounded-circle mx-auto d-block mw-100 mt-2"
                          width="100"
                          height="100"
                        />
                        {userErrors?.userImage.length > 0 && (
                          <div className="text-danger">
                            {userErrors?.userImage}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {edituser?.role === "8000" && userinfo?.role === "8000" && (
                    <div>
                      <div className=" row p-1 mt-3">
                        <div className="h4 fw-bold">Company info </div>
                        <hr />
                        {companyInfo?.companyName !== "" && (
                          <div className="row mt-3 p-1">
                            <div className="col-lg-4">Company Name</div>
                            <div className="col-sm-12 col-md-1 col-lg-1"></div>
                            <div className="col-lg-7">
                              {companyInfo?.companyName}
                            </div>
                          </div>
                        )}
                        {/* Phone Array */}
                        {companyPhoneArray?.map((row, index) => {
                          return (
                            <div className="row p-1">
                              <div className="col-sm-12 col-md-4 col-lg-4">
                                {index === 0 && "Phone"}
                              </div>
                              <div className="col-sm-8 col-md-8 col-lg-8">
                                <div className="row">
                                  {" "}
                                  <div class="form-check col-sm-12 col-md-4 col-lg-1  ">
                                    <input
                                      class="form-check-input star"
                                      type="checkbox"
                                      name={"isMain_" + index}
                                      id={"isMain_" + index}
                                      checked={row.isMain === 1 ? true : false}
                                      onChange={(e) => {
                                        setisvaliddata(true);
                                        let _phoneArray = [
                                          ...companyPhoneArray,
                                        ];
                                        _phoneArray[index].isMain = e.target
                                          .checked
                                          ? 1
                                          : 0;
                                        _phoneArray.map((phone, phoneindex) => {
                                          if (index !== phoneindex) {
                                            phone.isMain = 0;
                                          }
                                        });
                                        setCompanyPhoneArray(_phoneArray);
                                      }}
                                      data-gtm-form-interact-field-id="0"
                                    />
                                  </div>
                                  <div className="col-sm-9 col-md-9 col-lg-9 ms-4">
                                    <PhoneInput
                                      inputStyle={{ width: "100%" }}
                                      country={"us"}
                                      value={row?.fullPhone}
                                      placeholder="Phone Number"
                                      name="phone"
                                      id="phone"
                                      onChange={(e) =>
                                        handleCompanyOnPhoneChange(
                                          e,
                                          index,
                                          "fullPhone"
                                        )
                                      }
                                    />
                                  </div>
                                </div>{" "}
                                <div className="row mt-1">
                                  <div className="col-sm-1 col-md-1 col-lg-1" />
                                  <div className="col-sm-5 col-md-5 col-lg-5 ms-4">
                                    <input
                                      type="text"
                                      name="extphone"
                                      id="extphone"
                                      className="form-control"
                                      placeholder="Ext"
                                      value={row?.extphone}
                                      onChange={(e) => {
                                        handleCompanyChangePhone(
                                          e,
                                          index,
                                          "extphone"
                                        );
                                      }}
                                    />
                                  </div>
                                  <div className="col-sm-5 col-md-5 col-lg-4">
                                    <input
                                      type="text"
                                      name="label"
                                      id="label"
                                      className="form-control"
                                      placeholder="label"
                                      value={row?.label}
                                      onChange={(e) => {
                                        handleCompanyChangePhone(
                                          e,
                                          index,
                                          "label"
                                        );
                                      }}
                                    />
                                  </div>
                                  {companyPhoneArray?.length - 1 === index && (
                                    <div className="col-sm-1 col-md-1 col-lg-1 mt-2 p-0 ms-2">
                                      <a
                                        onClick={() => {
                                          let _phoneArray = [
                                            ...companyPhoneArray,
                                          ];
                                          _phoneArray.push(initialPhoneState);
                                          setCompanyPhoneArray(_phoneArray);
                                        }}
                                      >
                                        <i
                                          className="fa-solid fa-circle-plus fa-xl"
                                          style={{ color: "#848a94" }}
                                        ></i>
                                      </a>
                                    </div>
                                  )}

                                  {companyPhoneArray?.length - 1 !== index && (
                                    <div className="col-sm-1 col-md-1 col-lg-1 mt-2 p-0 ms-2">
                                      <a
                                        onClick={() => {
                                          let _phoneArray = [
                                            ...companyPhoneArray,
                                          ];
                                          _phoneArray.splice(index, 1);
                                          setCompanyPhoneArray(_phoneArray);
                                        }}
                                      >
                                        <i
                                          class="fa-solid fa-circle-xmark fa-xl"
                                          style={{ color: "#cf3030" }}
                                        ></i>
                                      </a>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                        {/* Email Array */}

                        {companyEmailArray?.map((row, index) => {
                          return (
                            <div className="row p-1">
                              <div className="col-sm-12 col-md-4 col-lg-4">
                                {index === 0 && "Email"}
                              </div>
                              <div class="form-check col-sm-12 col-md-4 col-lg-1  ">
                                <input
                                  class="form-check-input star"
                                  type="checkbox"
                                  name={"isMain_" + index}
                                  id={"isMain_" + index}
                                  checked={row.isMain === 1 ? true : false}
                                  onChange={(e) => {
                                    setisvaliddata(true);
                                    let _emailArray = [...companyEmailArray];
                                    _emailArray[index].isMain = e.target.checked
                                      ? 1
                                      : 0;
                                    _emailArray.map((email, emailindex) => {
                                      if (index !== emailindex) {
                                        email.isMain = 0;
                                      }
                                    });
                                    setCompanyEmailArray(_emailArray);
                                  }}
                                  data-gtm-form-interact-field-id="0"
                                />
                              </div>
                              <div className="col-sm-12 col-md-4 col-lg-3">
                                <input
                                  type="text"
                                  name={"email_" + index}
                                  id={"email_" + index}
                                  className="form-control"
                                  placeholder="Email"
                                  value={row?.email}
                                  onChange={(e) => {
                                    handleCompanyChangeEmail(e, index, "email");
                                  }}
                                />
                              </div>
                              <div className="col-sm-12 col-md-4 col-lg-3">
                                <input
                                  type="text"
                                  name={"label_" + index}
                                  id={"label_" + index}
                                  className="form-control"
                                  placeholder="label"
                                  value={row?.label}
                                  onChange={(e) => {
                                    handleCompanyChangeEmail(e, index, "label");
                                  }}
                                />
                              </div>
                              {companyEmailArray?.length - 1 === index && (
                                <div className="col-sm-1 col-md-1 col-lg-1 mt-2">
                                  <a
                                    onClick={() => {
                                      let _emailArray = [...companyEmailArray];
                                      _emailArray.push(initialEmailState);
                                      setCompanyEmailArray(_emailArray);
                                    }}
                                  >
                                    <i
                                      className="fa-solid fa-circle-plus fa-xl"
                                      style={{ color: "#848a94" }}
                                    ></i>
                                  </a>
                                </div>
                              )}

                              {companyEmailArray?.length - 1 !== index && (
                                <div className="col-sm-1 col-md-1 col-lg-1 mt-2">
                                  <a
                                    onClick={() => {
                                      let _emailArray = [...companyEmailArray];
                                      _emailArray.splice(index, 1);
                                      setCompanyEmailArray(_emailArray);
                                    }}
                                  >
                                    <i
                                      class="fa-solid fa-circle-xmark fa-xl"
                                      style={{ color: "#cf3030" }}
                                    ></i>
                                  </a>
                                </div>
                              )}
                            </div>
                          );
                        })}

                        {/*Address Array  */}
                        {companyAddressArray?.map((row, index) => {
                          return (
                            <div className="row p-1">
                              <div className="col-sm-12 col-md-4 col-lg-4">
                                {index === 0 && "Address"}{" "}
                              </div>
                              <div class="form-check col-sm-12 col-md-4 col-lg-1  ">
                                <input
                                  class="form-check-input star"
                                  type="checkbox"
                                  name={"isMain_" + index}
                                  id={"isMain_" + index}
                                  checked={row.isMain === 1 ? true : false}
                                  onChange={(e) => {
                                    setisvaliddata(true);
                                    let _addressArray = [
                                      ...companyAddressArray,
                                    ];
                                    _addressArray[index].isMain = e.target
                                      .checked
                                      ? 1
                                      : 0;
                                    _addressArray.map(
                                      (address, addressindex) => {
                                        if (index !== addressindex) {
                                          address.isMain = 0;
                                        }
                                      }
                                    );
                                    setCompanyAddressArray(_addressArray);
                                  }}
                                  data-gtm-form-interact-field-id="0"
                                />
                              </div>
                              <div className="col-sm-12 col-md-4 col-lg-6">
                                <div className="mt-1">
                                  <input
                                    type="text"
                                    name="address1"
                                    placeholder="Address 1"
                                    id="address1"
                                    className="form-control"
                                    value={row?.address1}
                                    onChange={(e) =>
                                      handleCompanyChangeAddress(
                                        e,
                                        index,
                                        "address1"
                                      )
                                    }
                                  />
                                </div>
                                <div className="mt-1">
                                  <input
                                    type="text"
                                    name="address2"
                                    id="address2"
                                    className="form-control"
                                    placeholder="Address 2"
                                    value={row?.address2}
                                    onChange={(e) =>
                                      handleCompanyChangeAddress(
                                        e,
                                        index,
                                        "address2"
                                      )
                                    }
                                  />
                                </div>

                                <div className="mt-1">
                                  {" "}
                                  <input
                                    type="text"
                                    name="city"
                                    id="city"
                                    placeholder="City"
                                    className="form-control"
                                    value={row?.city}
                                    onChange={(e) =>
                                      handleCompanyChangeAddress(
                                        e,
                                        index,
                                        "city"
                                      )
                                    }
                                  />
                                </div>

                                <div className="mt-1 row ">
                                  <div className="col-lg-6">
                                    {" "}
                                    <input
                                      type="text"
                                      name="state"
                                      id="state"
                                      placeholder="State"
                                      className="form-control"
                                      value={row?.state}
                                      onChange={(e) =>
                                        handleCompanyChangeAddress(
                                          e,
                                          index,
                                          "state"
                                        )
                                      }
                                    />
                                  </div>
                                  <div className="col-lg-6">
                                    {" "}
                                    <input
                                      type="number"
                                      name="zipCode"
                                      id="zipCode"
                                      className="form-control"
                                      placeholder="zipCode"
                                      value={row?.zipCode}
                                      onChange={(e) =>
                                        handleCompanyChangeAddress(
                                          e,
                                          index,
                                          "zipCode"
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="mt-1">
                                  <input
                                    type="text"
                                    name="country"
                                    id="country"
                                    className="form-control"
                                    placeholder="Country"
                                    value={row?.country}
                                    onChange={(e) =>
                                      handleCompanyChangeAddress(
                                        e,
                                        index,
                                        "country"
                                      )
                                    }
                                  />
                                </div>
                                <div className="mt-1">
                                  {" "}
                                  <input
                                    type="text"
                                    name="label"
                                    id="label"
                                    className="form-control"
                                    placeholder="Label"
                                    value={row.label}
                                    onChange={(e) =>
                                      handleCompanyChangeAddress(
                                        e,
                                        index,
                                        "label"
                                      )
                                    }
                                  />
                                </div>

                                {/* {userErrors.title.length > 0 && (
                  <div className="text-danger">{userErrors.title}</div>
                )} */}
                              </div>
                              {companyAddressArray?.length - 1 === index && (
                                <div className="col-sm-1 col-md-1 col-lg-1 mt-2">
                                  <a
                                    onClick={() => {
                                      let _addressArray = [
                                        ...companyAddressArray,
                                      ];
                                      _addressArray.push(initialAddressState);
                                      setCompanyAddressArray(_addressArray);
                                    }}
                                  >
                                    <i
                                      className="fa-solid fa-circle-plus fa-xl"
                                      style={{ color: "#848a94" }}
                                    ></i>
                                  </a>
                                </div>
                              )}

                              {companyAddressArray?.length - 1 !== index && (
                                <div className="col-sm-1 col-md-1 col-lg-1 mt-2">
                                  <a
                                    onClick={() => {
                                      let _addressArray = [
                                        ...companyAddressArray,
                                      ];
                                      _addressArray.splice(index, 1);
                                      setCompanyAddressArray(_addressArray);
                                    }}
                                  >
                                    <i
                                      class="fa-solid fa-circle-xmark fa-xl"
                                      style={{ color: "#cf3030" }}
                                    ></i>
                                  </a>
                                </div>
                              )}
                            </div>
                          );
                        })}
                      </div>
                      <div className="row p-1">
                        <div className="col-sm-12 col-md-4 col-lg-4">
                          {" "}
                          Company logo
                        </div>
                        <div className="col-sm-12 col-md-4 col-lg-1"></div>
                        <div className="d-flex justify-content-start col-sm-12 col-md-4 col-lg-4">
                          <div>
                            <Button
                              style={{ padding: "1px 6px" }}
                              onClick={() =>
                                handleEditToRemoveCompanyLogoImage()
                              }
                              className="me-2"
                            >
                              Clear
                            </Button>
                          </div>
                          <div>
                            <input
                              type="file"
                              multiple={false}
                              name="companyLogo"
                              onChange={(e) => handleEditCompanyFileUpload(e)}
                              accept=".jpeg, .png, .jpg"
                            ></input>
                            <img
                              src={
                                companyInfo?.companyLogo === "" ||
                                companyInfo?.companyLogo === undefined
                                  ? "../assets/img/companyicon.png"
                                  : companyInfo?.companyLogo
                              }
                              className="rounded mx-auto d-block mw-100 mt-2"
                              width="300"
                              height="100"
                            />
                            {companyErrors?.companyLogo.length > 0 && (
                              <div className="text-danger">
                                {companyErrors?.companyLogo}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className=" row p-1 mt-3">
                        <div className="h4 fw-bold">Account Info </div>
                        <hr />
                        {accountInfo?.accountCompanyName !== "" && (
                          <div className="row mt-3">
                            <div className="col-lg-4">Account Name</div>
                            <div className="col-sm-12 col-md1 col-lg-1"></div>
                            <div className="col-lg-7">
                              {accountInfo?.accountCompanyName}
                            </div>
                          </div>
                        )}
                        {accountInfo?.accountEmail !== "" && (
                          <div className="row mt-3">
                            <div className="col-lg-4">Email</div>
                            <div className="col-sm-12 col-md1 col-lg-1"></div>
                            <div className="col-lg-7">
                              {accountInfo?.accountEmail}
                              {/* <input
                                type="text"
                                name="accountEmail"
                                id="titaccountEmaille"
                                placeholder="Email"
                                className="form-control"
                                value={accountInfo?.accountEmail}
                                onChange={handleAccountChange}
                              /> */}
                            </div>
                          </div>
                        )}

                        {/* Phone Array */}
                        {accountPhoneArray?.map((row, index) => {
                          return (
                            <div className="row mt-2">
                              <div className="col-sm-12 col-md-4 col-lg-4">
                                {index === 0 && "Phone"}
                              </div>
                              <div className="col-sm-8 col-md-8 col-lg-8">
                                <div className="row">
                                  {" "}
                                  <div class="form-check col-sm-12 col-md-4 col-lg-1  ">
                                    <input
                                      class="form-check-input star"
                                      type="checkbox"
                                      name={"isMain_" + index}
                                      id={"isMain_" + index}
                                      checked={row.isMain === 1 ? true : false}
                                      onChange={(e) => {
                                        setisvaliddata(true);
                                        let _phoneArray = [
                                          ...accountPhoneArray,
                                        ];
                                        _phoneArray[index].isMain = e.target
                                          .checked
                                          ? 1
                                          : 0;
                                        _phoneArray.map((phone, phoneindex) => {
                                          if (index !== phoneindex) {
                                            phone.isMain = 0;
                                          }
                                        });
                                        setAccountPhoneArray(_phoneArray);
                                      }}
                                      data-gtm-form-interact-field-id="0"
                                    />
                                  </div>
                                  <div className="col-sm-9 col-md-9 col-lg-9 ms-4">
                                    <PhoneInput
                                      inputStyle={{ width: "100%" }}
                                      country={"us"}
                                      value={row?.fullPhone}
                                      placeholder="Phone Number"
                                      name="phone"
                                      id="phone"
                                      onChange={(e) =>
                                        handleAccountOnPhoneChange(
                                          e,
                                          index,
                                          "fullPhone"
                                        )
                                      }
                                    />
                                  </div>
                                </div>{" "}
                                <div className="row mt-1">
                                  <div className="col-sm-1 col-md-1 col-lg-1" />
                                  <div className="col-sm-5 col-md-5 col-lg-5 ms-4">
                                    <input
                                      type="text"
                                      name="extphone"
                                      id="extphone"
                                      className="form-control"
                                      placeholder="Ext"
                                      value={row?.extphone}
                                      onChange={(e) => {
                                        handleAccountChangePhone(
                                          e,
                                          index,
                                          "extphone"
                                        );
                                      }}
                                    />
                                  </div>
                                  <div className="col-sm-5 col-md-5 col-lg-4">
                                    <input
                                      type="text"
                                      name="label"
                                      id="label"
                                      className="form-control"
                                      placeholder="label"
                                      value={row?.label}
                                      onChange={(e) => {
                                        handleAccountChangePhone(
                                          e,
                                          index,
                                          "label"
                                        );
                                      }}
                                    />
                                  </div>
                                  {accountPhoneArray?.length - 1 === index && (
                                    <div className="col-sm-1 col-md-1 col-lg-1 mt-2 p-0 ms-2">
                                      <a
                                        onClick={() => {
                                          let _phoneArray = [
                                            ...accountPhoneArray,
                                          ];
                                          _phoneArray.push(initialPhoneState);
                                          setAccountPhoneArray(_phoneArray);
                                        }}
                                      >
                                        <i
                                          className="fa-solid fa-circle-plus fa-xl"
                                          style={{ color: "#848a94" }}
                                        ></i>
                                      </a>
                                    </div>
                                  )}

                                  {accountPhoneArray?.length - 1 !== index && (
                                    <div className="col-sm-1 col-md-1 col-lg-1 mt-2 p-0 ms-2">
                                      <a
                                        onClick={() => {
                                          let _phoneArray = [
                                            ...accountPhoneArray,
                                          ];
                                          _phoneArray.splice(index, 1);
                                          setAccountPhoneArray(_phoneArray);
                                        }}
                                      >
                                        <i
                                          class="fa-solid fa-circle-xmark fa-xl"
                                          style={{ color: "#cf3030" }}
                                        ></i>
                                      </a>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                        {/* {accountInfo?.planStartDate !== "" && (
                          <div className="row mt-3">
                            <div className="col-lg-4">Plan Start Date</div>
                            <div className="col-sm-12 col-md1 col-lg-1"></div>
                            <div className="col-lg-7">
                              {(accountInfo?.planStartDate &&
                                Moment(accountInfo?.planStartDate).format(
                                  appConstants.DATEFORMAT
                                )) ||
                                ""}
                            </div>
                          </div>
                        )}
                        {accountInfo?.planEndDate !== "" && (
                          <div className="row mt-3">
                            <div className="col-lg-4">Plan End Date</div>
                            <div className="col-sm-12 col-md1 col-lg-1"></div>
                            <div className="col-lg-7">
                              {(accountInfo?.planEndDate &&
                                Moment(accountInfo?.planEndDate).format(
                                  appConstants.DATEFORMAT
                                )) ||
                                ""}
                            </div>
                          </div>
                        )} */}
                      </div>{" "}
                    </div>
                  )}
                </div>
              </div>
              <div>
                {isEditSuccess && (
                  <SweetAlert
                    success
                    style={{ color: "black" }}
                    title={appConstants.import.alertmsg.userupdatedsucessfull}
                    onConfirm={() => {
                      setisEditSuccess(false);
                      setIsEditUser(false);
                      getUserList();
                      window.location.reload();
                    }}
                  ></SweetAlert>
                )}
                {isAlreadyExist && (
                  <SweetAlert
                    warning
                    style={{ color: "black" }}
                    title={appConstants.import.alertmsg.useralreadyexist}
                    //onConfirm={handleSucessSweetAlert1}
                  ></SweetAlert>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {" "}
            <div className="row p-1">
              {" "}
              <div className=" d-flex justify-content-end mt-3 col-sm-12 col-md-12 col-lg-12">
                <button
                  className=" btn btn-primary me-2 "
                  onClick={() => setIsEditUser(false)}
                >
                  Close
                </button>
                <button
                  className="btn btn-primary me-2 "
                  disabled={!isvaliddata}
                  onClick={EditSubmitHandler}
                >
                  Save
                </button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
      )}
      {/* Detail User */}
      {isDetailUser && (
        <Modal
          scrollable={true}
          show={isDetailUser}
          onHide={() => {
            setIsDetailUser(false);
          }}
          size="lg"
          centered
        >
          <Modal.Header className="fw-bold h4">
            Detail User{" "}
            <a
              style={{ "text-decoration": "none" }}
              onClick={() => setIsDetailUser(false)}
              className="col d-flex justify-content-end text-dark"
            >
              <i class="fa-solid fa-xmark"></i>
            </a>
          </Modal.Header>
          <Modal.Body>
            {" "}
            <div className="container p-3">
              <div className="row">
                <div className="col-lg-12">
                  <div className=" row p-1">
                    <div className="col-sm-12 col-md-4 col-lg-2">
                      <img
                        src={
                          edituser?.userImage === "" ||
                          edituser?.userImage === undefined
                            ? "../assets/img/useraltimg.png"
                            : edituser?.userImage
                        }
                        className="rounded-circle mx-auto d-block mw-100 mt-2 d-flex justify-content-end"
                        width="80"
                        height="80"
                      />
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4">
                      <span className="h4 fw-bold d-flex justify-content-start mt-4">
                        {edituser?.firstName + " " + edituser?.lastName}
                      </span>
                      <span className=" d-flex justify-content-start mt-2">
                        Created On{" "}
                        {(edituser?.createdOn &&
                          Moment(edituser?.createdOn).format(
                            appConstants.DATEFORMAT
                          )) ||
                          ""}
                      </span>
                    </div>
                  </div>
                  <div className=" row p-1 mt-3">
                    <div className="col-lg-6 h4 fw-bold">Basic Info</div>
                    <hr />
                    {edituser?.email !== "" && (
                      <div className="row mt-3">
                        <div className="col-lg-3">Login email</div>
                        <div className="col-lg-9">{edituser?.email}</div>
                      </div>
                    )}
                    {edituser?.phone !== "" && (
                      <div className="row mt-3">
                        <div className="col-lg-3">Login phone</div>
                        <div className="col-lg-9">{edituser?.phone}</div>
                      </div>
                    )}
                    {edituser?.title !== "" && (
                      <div className="row mt-3">
                        <div className="col-lg-3">Title</div>
                        <div className="col-lg-9">{edituser?.title}</div>
                      </div>
                    )}
                    {edituser?.phoneArr?.length > 0 && (
                      <div className="row mt-3">
                        <div className="col-lg-3">Additional phone</div>
                        <div className="col-lg-9">
                          {edituser?.phoneArr?.length > 0 &&
                            edituser?.phoneArr?.map((row, index) => {
                              return (
                                <div className="row">
                                  <div className="col-lg-9 mb-2">
                                    <span className="">
                                      {row?.fullPhone === ""
                                        ? ""
                                        : row?.fullPhone}
                                      {row?.extphone === ""
                                        ? ""
                                        : ", extn " + row?.extphone}
                                      <br />
                                    </span>
                                  </div>
                                  <div className="col-lg-3 d-flex justify-content-end">
                                    <span>
                                      {" "}
                                      {row?.label.length > 0
                                        ? "  (" + row?.label + ")"
                                        : ""}
                                    </span>
                                    <span className="ms-2 ">
                                      {row?.isMain === 1 ? (
                                        <i
                                          class="fa-solid fa-star "
                                          style={{ color: "#ffd43b" }}
                                        ></i>
                                      ) : (
                                        ""
                                      )}
                                    </span>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    )}
                    {edituser?.emailArr?.length > 0 && (
                      <div className="row mt-3">
                        <div className="col-lg-3">Additional email</div>
                        <div className="col-lg-9">
                          {edituser?.emailArr?.length > 0 &&
                            edituser?.emailArr?.map((row, index) => {
                              return (
                                <div className="row mb-2">
                                  <div className="col-lg-9">
                                    {" "}
                                    <span>
                                      {row?.email}
                                      <br />
                                    </span>{" "}
                                  </div>
                                  <div className="col-lg-3 d-flex justify-content-end">
                                    <span>
                                      {row?.label.length > 0
                                        ? " (" + row?.label + ")"
                                        : ""}
                                    </span>
                                    <span className="ms-2">
                                      {row?.isMain === 1 ? (
                                        <i
                                          class="fa-solid fa-star"
                                          style={{ color: "#ffd43b" }}
                                        ></i>
                                      ) : (
                                        ""
                                      )}
                                    </span>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    )}
                    {edituser?.addressArr?.length > 0 && (
                      <div className="row mt-3">
                        <div className="col-lg-3"> Address</div>
                        <div className="col-lg-9">
                          {edituser?.addressArr?.length > 0 &&
                            edituser?.addressArr?.map((row, index) => {
                              return (
                                <div className="row mb-2">
                                  <div className="col-lg-9">
                                    {" "}
                                    <span>
                                      {row?.address1 === ""
                                        ? ""
                                        : row?.address1 + ", "}
                                      {row?.address2 === ""
                                        ? ""
                                        : row?.address2 + ", "}
                                      {row?.city === "" ? "" : row?.city + ", "}
                                      {row?.state === ""
                                        ? ""
                                        : row?.state + ", "}
                                      {row?.country === ""
                                        ? ""
                                        : row?.country + ", "}
                                      {row?.zipCode === ""
                                        ? ""
                                        : row?.zipCode + "  "}

                                      <br />
                                    </span>{" "}
                                  </div>
                                  <div className="col-lg-3 d-flex justify-content-end">
                                    <span>
                                      {" "}
                                      {row?.label.length > 0
                                        ? "  (" + row?.label + ")"
                                        : ""}
                                    </span>
                                    <span className="ms-2">
                                      {row?.isMain === 1 ? (
                                        <i
                                          class="fa-solid fa-star"
                                          style={{ color: "#ffd43b" }}
                                        ></i>
                                      ) : (
                                        ""
                                      )}
                                    </span>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    )}
                    {edituser?.role !== "8000" && (
                      <div>
                        {companyInfo?.companyName !== "" && (
                          <div className="row mt-3">
                            <div className="col-lg-3">Company Name</div>
                            <div className="col-lg-9">
                              {companyInfo?.companyName}
                            </div>
                          </div>
                        )}
                        {accountInfo?.accountCompanyName !== "" && (
                          <div className="row mt-3">
                            <div className="col-lg-3">Account Name</div>
                            <div className="col-lg-9">
                              {accountInfo?.accountCompanyName}
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                    {edituser?.role === "8000" && userinfo?.role === "8000" && (
                      <div>
                        <div className=" row p-1 mt-3">
                          <div className="h4 fw-bold">Company info </div>
                          <hr />
                          {companyInfo?.companyName !== "" && (
                            <div className="row mt-3">
                              <div className="col-lg-3">Company name</div>
                              <div className="col-lg-9">
                                {companyInfo?.companyName}
                              </div>
                            </div>
                          )}
                          {companyInfo?.phoneArr?.length > 0 && (
                            <div className="row mt-3">
                              <div className="col-lg-3">Phone</div>
                              <div className="col-lg-9">
                                {companyInfo?.phoneArr?.length > 0 &&
                                  companyInfo?.phoneArr?.map((row, index) => {
                                    return (
                                      <div className="row">
                                        <div className="col-lg-9 mb-2">
                                          <span className="">
                                            {row?.fullPhone === ""
                                              ? ""
                                              : row?.fullPhone}
                                            {row?.extphone === ""
                                              ? ""
                                              : ", extn " + row?.extphone}
                                            <br />
                                          </span>
                                        </div>
                                        <div className="col-lg-3 d-flex justify-content-end">
                                          <span>
                                            {" "}
                                            {row?.label.length > 0
                                              ? "  (" + row?.label + ")"
                                              : ""}
                                          </span>
                                          <span className="ms-2">
                                            {row?.isMain === 1 ? (
                                              <i
                                                class="fa-solid fa-star"
                                                style={{ color: "#ffd43b" }}
                                              ></i>
                                            ) : (
                                              ""
                                            )}
                                          </span>
                                        </div>
                                      </div>
                                    );
                                  })}
                              </div>
                            </div>
                          )}
                          {companyInfo?.companyEmail?.length > 0 && (
                            <div className="row mt-3">
                              <div className="col-lg-3">Email</div>
                              <div className="col-lg-9">
                                {companyInfo?.companyEmail?.length > 0 &&
                                  companyInfo?.companyEmail?.map(
                                    (row, index) => {
                                      return (
                                        <div className="row mb-2">
                                          <div className="col-lg-9">
                                            {" "}
                                            <span>
                                              {row?.email}
                                              <br />
                                            </span>{" "}
                                          </div>
                                          <div className="col-lg-3 d-flex justify-content-end">
                                            <span>
                                              {row?.label.length > 0
                                                ? " (" + row?.label + ")"
                                                : ""}
                                            </span>
                                            <span className="ms-2">
                                              {row?.isMain === 1 ? (
                                                <i
                                                  class="fa-solid fa-star"
                                                  style={{ color: "#ffd43b" }}
                                                ></i>
                                              ) : (
                                                ""
                                              )}
                                            </span>
                                          </div>
                                        </div>
                                      );
                                    }
                                  )}
                              </div>
                            </div>
                          )}
                          {companyInfo?.addressArr?.length > 0 && (
                            <div className="row mt-3">
                              <div className="col-lg-3"> Address</div>
                              <div className="col-lg-9">
                                {companyInfo?.addressArr?.length > 0 &&
                                  companyInfo?.addressArr?.map((row, index) => {
                                    return (
                                      <div className="row mb-2">
                                        <div className="col-lg-9">
                                          {" "}
                                          <span>
                                            {row?.address1 === ""
                                              ? ""
                                              : row?.address1 + ", "}
                                            {row?.address2 === ""
                                              ? ""
                                              : row?.address2 + ", "}
                                            {row?.city === ""
                                              ? ""
                                              : row?.city + ", "}
                                            {row?.state === ""
                                              ? ""
                                              : row?.state + ", "}
                                            {row?.country === ""
                                              ? ""
                                              : row?.country + ", "}
                                            {row?.zipCode === ""
                                              ? ""
                                              : row?.zipCode + "  "}

                                            <br />
                                          </span>{" "}
                                        </div>
                                        <div className="col-lg-3 d-flex justify-content-end">
                                          <span>
                                            {row?.label.length > 0
                                              ? "  (" + row?.label + ")"
                                              : ""}
                                          </span>
                                          <span className="ms-2">
                                            {row?.isMain === 1 ? (
                                              <i
                                                class="fa-solid fa-star"
                                                style={{ color: "#ffd43b" }}
                                              ></i>
                                            ) : (
                                              ""
                                            )}
                                          </span>
                                        </div>
                                      </div>
                                    );
                                  })}
                              </div>
                            </div>
                          )}
                        </div>
                        <div className=" row p-1 mt-3">
                          <div className="h4 fw-bold">Account info </div>
                          <hr />
                          {accountInfo?.accountCompanyName !== "" && (
                            <div className="row mt-3">
                              <div className="col-lg-3">Account name</div>
                              <div className="col-lg-9">
                                {accountInfo?.accountCompanyName}
                              </div>
                            </div>
                          )}
                          {accountInfo?.accountEmail !== "" && (
                            <div className="row mt-3">
                              <div className="col-lg-3">Email</div>
                              <div className="col-lg-9">
                                {accountInfo?.accountEmail}
                              </div>
                            </div>
                          )}
                          {/* {accountInfo?.planStartDate !== "" && (
                            <div className="row mt-3">
                              <div className="col-lg-3">Plan start date</div>
                              <div className="col-lg-9">
                                {(accountInfo?.planStartDate &&
                                  Moment(accountInfo?.planStartDate).format(
                                    appConstants.DATEFORMAT
                                  )) ||
                                  ""}
                              </div>
                            </div>
                          )}
                          {accountInfo?.planEndDate !== "" && (
                            <div className="row mt-3">
                              <div className="col-lg-3">Plan end date</div>
                              <div className="col-lg-9">
                                {(accountInfo?.planEndDate &&
                                  Moment(accountInfo?.planEndDate).format(
                                    appConstants.DATEFORMAT
                                  )) ||
                                  ""}
                              </div>
                            </div>
                          )} */}

                          {accountInfo?.accountPhoneArr?.length > 0 && (
                            <div className="row mt-3">
                              <div className="col-lg-3">Phone</div>
                              <div className="col-lg-9">
                                {accountInfo?.accountPhoneArr?.length > 0 &&
                                  accountInfo?.accountPhoneArr?.map(
                                    (row, index) => {
                                      return (
                                        <div className="row">
                                          <div className="col-lg-9 mb-2">
                                            <span className="">
                                              {row?.fullPhone === ""
                                                ? ""
                                                : row?.fullPhone}
                                              {row?.extphone === ""
                                                ? ""
                                                : ", extn " + row?.extphone}
                                              <br />
                                            </span>
                                          </div>
                                          <div className="col-lg-3 d-flex justify-content-end">
                                            <span>
                                              {row?.label.length > 0
                                                ? "  (" + row?.label + ")"
                                                : ""}
                                            </span>
                                            <span className="ms-2">
                                              {row?.isMain === 1 ? (
                                                <i
                                                  class="fa-solid fa-star"
                                                  style={{ color: "#ffd43b" }}
                                                ></i>
                                              ) : (
                                                ""
                                              )}
                                            </span>
                                          </div>
                                        </div>
                                      );
                                    }
                                  )}
                              </div>
                            </div>
                          )}
                        </div>{" "}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div>
                {isEditSuccess && (
                  <SweetAlert
                    success
                    style={{ color: "black" }}
                    title={appConstants.import.alertmsg.userupdatedsucessfull}
                    onConfirm={() => {
                      setisEditSuccess(false);
                      setIsEditUser(false);
                      getUserList();
                    }}
                  ></SweetAlert>
                )}
                {isAlreadyExist && (
                  <SweetAlert
                    warning
                    style={{ color: "black" }}
                    title={appConstants.import.alertmsg.useralreadyexist}
                    //onConfirm={handleSucessSweetAlert1}
                  ></SweetAlert>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {" "}
            <div className="row p-1">
              {" "}
              <div className=" d-flex justify-content-end mt-3 col-sm-12 col-md-12 col-lg-12">
                <button
                  className=" btn btn-primary me-2 "
                  onClick={() => setIsDetailUser(false)}
                >
                  Close
                </button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
      )}
      {isDeleteUser && (
        <SweetAlert
          warning
          style={{ color: "black" }}
          title=""
          confirmBtnText="Delete"
          cancelBtnText="No"
          showCancel={true}
          onConfirm={() => {
            setIsDeleteUser(false);
            ondelete();
          }}
          onCancel={() => setIsDeleteUser(false)}
        >
          <div>{appConstants.import.confirmationmsg.deleteuser}</div>
        </SweetAlert>
      )}
      {isDeleted && (
        <SweetAlert
          success
          style={{ color: "black" }}
          title=""
          confirmBtnText="Ok"
          onConfirm={() => setIsDeleted(false)}
        >
          <div>{appConstants.import.alertmsg.userdeletesucessfull}</div>
        </SweetAlert>
      )}
    </div>
  );
}

export default UserList;
