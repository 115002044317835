import { Panel, PanelHeader, PanelBody } from "../../components/panel/panel";
import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Link, useLocation } from "react-router-dom";
import { useHistory } from "react-router";
import NavDropdown from "react-bootstrap/NavDropdown";
import { NavDropdownMenu } from "react-bootstrap-submenu";
import Select from "react-select";
import customstyles from "../../components/customstyles/react-select-styles";
import Table from "react-bootstrap/Table";
import Spinner from "react-bootstrap/Spinner";
import TreeViewSidebar from "../../components/tree-view/TreeViewSidebar";
import { Button, Form, Modal } from "react-bootstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import {
  getCPPJobStatusCodeListByCompanyID,
  getapplistbyuserid,
} from "../../services/applications_services";
import {
  getTemplateFolders,
  getFolderTemplateByParentID,
  insertFolderTemplate,
  deleteFolderTemplateByID,
  updateFolderTemplateByID,
  getTemplateFoldersMinimumData,
} from "../../services/folder_service";
import { getuserbyauthproviderid } from "../../services/users_services";
import { useRealmApp } from "../../providers/RealmApp";
import { useAuth0 } from "@auth0/auth0-react";

import "react-toastify/dist/ReactToastify.css";
import Moment from "moment";
import { appConstants } from "../../constants/app_constants";
import GenericListTable from "../../components/gridTable/genericLlistTable";

function SharepointFolderStructure() {
  const { isLoading, error, isAuthenticated, user } = useAuth0();

  var [foldersList, setFoldersList] = useState([]);
  var [foldersListIDS, setFoldersListIDS] = useState([]);
  var [foldersTreeStructure, setFoldersTreeStructure] = useState([]);
  var [selectedSubFolderList, setSelectedSubFolderList] = useState([]);
  const [connectedAppList, setConnectedAppList] = useState([]);
  var [selectedFolder, setSelectedFolder] = useState({
    folderId: "0",
    folderName: "",
  });
  const [isDeleteFolder, setIsDeleteFolder] = useState(false);
  const [projectStatusList, setProjectStatusList] = useState();
  const [isDeleted, setIsDeleted] = useState(false);
  const [selectedSourceProject, setSelectedSourceProject] = useState([]);
  const [rowToDelete, setRowToDelete] = useState();
  const [msg, setMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [userinfo, setUserinfo] = useState();
  const initialfolderstate = {
    title: "",
    folderDescription: "",
    folderLevel: "",
    folderType: "",
    folderPath: "",
    icon: "",
    sortOrder: "",
    isActive: "1",
    userid:"",
    isSystemRecord: "0",
    rules: [
      {
        projectStatus: "Active",
        show: true,
        isActive: true,
      },
    ],
    parentId: "",
  };
  const [folder, setFolder] = useState({ initialfolderstate });
  const [isFolder, setIsFolder] = useState(false);
  const [isRuleFolder, setIsRuleFolder] = useState(false);
  const [isFolderSuccess, setIsFolderSuccess] = useState(false);
  const [isFolderRefresh, setIsFolderRefresh] = useState("");
  const ctechapp = useRealmApp();

  useEffect(() => {
    if (isAuthenticated) {
      getTemplateFoldersList();
      getUserInfoByID();
      //getTemplateFoldersListByParentID(selectedFolder.folderId);
    }
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      
      getTemplateFoldersList();
      if(userinfo?.userid)
      {
      getTemplateFoldersListByParentID(selectedFolder.folderId,userinfo.userid);
      }
    }
  }, [selectedFolder.folderId, isFolderRefresh]);

  async function getUserInfoByID() {
    try {
      getuserbyauthproviderid(user.sub, ctechapp.currentUser).then(
        (response) => {
          let user_info = JSON.parse(response);
          if (response !== undefined || user_info?.length !== 0) {
            setUserinfo(user_info[0]);
            Getapplistbyuserid(user_info[0]);
            getTemplateFoldersListByParentID(selectedFolder.folderId,user_info[0].userid);
          }
        }
      );
    } catch (exception) {
      console.error("getuserbyauthproviderid Error", exception);
    }
  }
  async function Getapplistbyuserid(_userinfo) {
    getapplistbyuserid(ctechapp.currentUser, _userinfo.userid).then(
      (response) => {
        if (response !== undefined) {
          let apps_list = JSON.parse(response);
          setConnectedAppList(apps_list);

          let filter_applist = apps_list.filter(
            (app) => app.appname.toLowerCase() === "commandpost"
          );
          if (filter_applist.length > 0) {
            //  console.log("filter_applist", filter_applist);
            var argobj = {
              _p_accountId: filter_applist[0].appaccountid,
            };
            GetCPPJobStatusCodeListByCompanyID(argobj);
          }
        }
      }
    );
  }
  async function GetCPPJobStatusCodeListByCompanyID(arg) {
    try {
      await getCPPJobStatusCodeListByCompanyID(
        arg,
        ctechapp.currentUser,
        user.sub
      ).then((response) => {
        if (response !== undefined) {
          var projectstatusinfo = JSON.parse(response);
          if (projectstatusinfo.error === "") {
            var projectstatus = projectstatusinfo.data;
            var options = [];
            projectstatus?.map(function (status) {
              options.push({
                value: status.jobStatusCodesCode,
                label: status.jobStatusCodesName,
                index: status.jobStatusSortIndex,
                _id: status._id,
              });
            });
            //   console.log(options);
            setProjectStatusList(options);
          } else {
            console.error(
              "GetDetailProjectListByID Error",
              projectstatusinfo.error
            );
          }
        }
      });
    } catch (err) {
      console.error("GetDetailProjectListByID Error", err);
    }
  }
  const getTemplateFoldersList = useCallback(() => {
    try {
      // getTemplateFolders(ctechapp.currentUser).then(async (response) => {
      //   let folderdatas = response;
      //   if (folderdatas !== undefined) {
      //     setFoldersList(folderdatas);
      //     var result_foldersTree = await getNestedChildren(folderdatas, 0);
      //     setFoldersTreeStructure(result_foldersTree);
      //     console.log("result_foldersTree", result_foldersTree);
      //   }
      // });

      getTemplateFoldersMinimumData(user.sub, ctechapp.currentUser).then(
        async (response) => {
          let folderdatas = response;
          if (folderdatas !== undefined) {
            setFoldersList(folderdatas);

            let folderIDS = await folderdatas?.map((row) => row.id?.toString());
            setFoldersListIDS(folderIDS);
            var result_foldersTree = await getNestedChildren(folderdatas, 0);
            console.log("result_foldersTree", result_foldersTree);
            setFoldersTreeStructure(result_foldersTree);
            // console.log("result_foldersTree", result_foldersTree);
          }
        }
      );
    } catch (exception) {
      console.log(exception);
    }
  }, []);

  async function getTemplateFoldersListByParentID(selectedFolderId,loggedin_userid) {
    try {
      getFolderTemplateByParentID(selectedFolderId,loggedin_userid, ctechapp.currentUser).then(
        async (response) => {
          let folderdatas = response;
          console.log("folderdatas", folderdatas);
          if (folderdatas !== undefined && folderdatas !== null) {
            setSelectedSubFolderList(folderdatas);
          }
        }
      );
    } catch (exception) {
      console.log(exception);
    }
  }

  async function InsertFolderTemplate() {
    let status = [];
    let _folder = { ...folder };    

     const filteredParentFolder = foldersList?.filter(
              (val) => val?.parent === "0"
            );
            if (filteredParentFolder && filteredParentFolder.length > 0) {               
               _folder.parentId=filteredParentFolder[0]?.id || "";              
            }

    if (selectedSourceProject.length > 0) {
      selectedSourceProject.map((val) => {
        let obj = {
          _id: val._id,
          name: val.label,
          code: val.value,
          index: val.index,
        };
        status.push(obj);
      });
    }
    _folder.rules[0].projectStatus = status;
    try {
      _folder.parentId =
        selectedFolder.folderId !== "0" ? selectedFolder.folderId : _folder.parentId;
      insertFolderTemplate(_folder, ctechapp.currentUser, user.sub).then(
        (response) => {
          if (response !== undefined) {
            setMsg(appConstants.import.alertmsg.foldercreatedsuccessfull);
            setIsFolderSuccess(true);
            setIsFolderRefresh("Add" + Date());
          }
        }
      );
    } catch (exception) {
      console.error("getuserbyauthproviderid Error", exception);
    }
  }
  async function UpdateFolderTemplateByID() {
    let status = [];
    let _folder = { ...folder };

    if (selectedSourceProject.length > 0) {
      selectedSourceProject.map((val) => {
        let obj = {
          _id: val._id,
          name: val.label,
          code: val.value,
          index: val.index,
        };
        status.push(obj);
      });
    }
    _folder.rules[0].projectStatus = status;
    try {
      updateFolderTemplateByID(_folder, ctechapp.currentUser, user.sub).then(
        (response) => {
          if (response !== undefined) {
            setMsg(appConstants.import.alertmsg.folderupdatedsuccessful);
            setIsFolderSuccess(true);
            setIsFolderRefresh("update" + Date());
          }
        }
      );
    } catch (exception) {
      console.error("getuserbyauthproviderid Error", exception);
    }
  }
  function ondelete(id) {
    try {
      deleteFolderTemplateByID(id, ctechapp.currentUser, user.sub).then(
        (response) => {
          if (response !== undefined) {
            setIsDeleted(true);
            setIsFolderRefresh("delete" + Date());
          }
        }
      );
    } catch (error) {
      console.error("deleteProjectByID Error", error);
    }
  }

  function getNestedChildren(arr, parentrootid) {
    var subFolders = [];
    for (var i = 0; i < arr.length; ++i) {
      if (arr[i].parent == parentrootid) {
        var grandChildren = getNestedChildren(arr, arr[i].id);

        if (grandChildren.length) {
          arr[i].children = grandChildren;
        }
        subFolders.push(arr[i]);
      }
    }
    return subFolders;
  }

  const [expanded, setExpanded] = useState([]);

  //table functions
  const data = useMemo(
    () => [...selectedSubFolderList],
    [selectedSubFolderList]
  );
  const Columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "_id",
        sortable: true,
        width: "20%",
        Filter: "",
        filter: "",
      },
      {
        Header: "Name",
        accessor: "title",
        sortable: true,
        width: "20%",
        Filter: "",
        filter: "",
        Cell: ({ value }) => {
          return <span> 📂{value}</span>;
        },
      },

      {
        Header: "Created on",
        accessor: "activity_history[0].activity_on",
        sortable: true,
        width: "10%",
        Filter: "",
        filter: "value",
        Cell: ({ value }) =>
          (value && Moment(value).format(appConstants.DATEFORMAT)) || "",
      },
      {
        Header: "Rules",
        accessor: "rules[0].projectStatus",
        sortable: false,

        Cell: ({ row, value }) => {
          var options = [];
          if (value[0]?.name && value?.length > 0) {
            value?.map((val) => {
              options.length === 0
                ? options.push(val?.name)
                : options.push(" " + val?.name);
            });
          }

          return (
            <span
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title={options}
              onClick={() => {
                handleRuleClickApps(row);
              }}
            >
              {options.length > 0 ? (
                <span className="badge border  text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                  Yes
                </span>
              ) : (
                <span className="badge border  text-danger px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                  No
                </span>
              )}
            </span>
          );
        },
      },
      {
        accessor: "action",
        Cell: ({ row }) => {
          const onEditItemClick = () => {
            handleEditClickApps(row);
          };
          const onDeleteItemClick = () => {
            handleDeleteClickApps(row);
          };
          const onRuleItemClick = () => {
            handleRuleClickApps(row);
          };
          return (
            <div className="d-flex gap-4">
              <NavDropdownMenu
                id="sharepoint-dropdown-toggle"
                title={
                  <i
                    class="fa-solid fa-ellipsis-vertical"
                    style={{
                      padding: "5px",
                      margin: "-5px",
                    }}
                  ></i>
                }
                class="sharepoint-dropdown-toggle"
              >
                <NavDropdown.Item
                  onClick={onEditItemClick}
                  className="text-dark"
                  style={{ "text-decoration": "none" }}
                >
                  <i className="fa  fa-pencil"></i> Rename
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={onDeleteItemClick}
                  className="text-dark"
                  style={{ "text-decoration": "none" }}
                >
                  {" "}
                  <i className="fa fa-trash-can"></i> Delete
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={onRuleItemClick}
                  className="text-dark"
                  style={{ "text-decoration": "none" }}
                >
                  {" "}
                  <i class="fa-solid fa-ruler-vertical"></i> Rules
                </NavDropdown.Item>
              </NavDropdownMenu>

              {/* <Button className="btn btn-sm " onClick={onEditItemClick}>
                <i className="fa  fa-pencil"></i>
                
              </Button>
              <Button className="btn btn-sm " onClick={onDeleteItemClick}>
                <i className="fa fa-trash-can"></i>
               
              </Button>

              <Button className="btn btn-sm " onClick={onRuleItemClick}>
                <i class="fa-solid fa-ruler-vertical"></i>
               
              </Button> */}
            </div>
          );
        },
      },
    ],
    [selectedSubFolderList]
  );
  const hiddenColumns = ["_id"];
  const handleDeleteClickApps = (row) => {
    setIsDeleteFolder(true);
    setRowToDelete(row.original._id);
  };
  const handleConfirmDelete = () => {
    setIsDeleteFolder(false);
    ondelete(rowToDelete);
  };
  const handleCancelDelete = (row) => {
    setIsDeleteFolder(false);
  };
  const handleConfirmDeleted = (row) => {
    setIsDeleted(false);
  };
  const handleEditClickApps = (row) => {
    setFolder(row.original);
    setIsFolder(true);
  };
  const handleRuleClickApps = (row) => {
    setIsRuleFolder(true);
    let _folder = row.original;
    setFolder(_folder);
    let options = [];
    _folder.rules[0]?.projectStatus?.map((val) => {
      let obj = {
        value: val.code,
        label: val.name,
        index: val.index,
        _id: val._id,
      };
      options.push(obj);
    });
    console.log("options", options);
    setSelectedSourceProject(options);
  };
  const handleChange = (event) => {
    const path = event.target.name.split(".");
    const finalProp = path.pop();
    const newfolder = { ...folder };
    let pointer = newfolder;
    path.forEach((el) => {
      pointer[el] = { ...pointer[el] };
      pointer = pointer[el];
    });
    pointer[finalProp] =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    setFolder(newfolder);
  };
  const handleDropDownChange = (selectedOption) => {
    const { name, value } = selectedOption.target;
    let _folder = { ...folder };
    if (name === "projectStatus") {
      let filterprojectstatus = {
        _id: value[0]._id,
        name: value[0].label,
        code: value[0].value,
        index: value[0].index,
      };
      _folder.rules[0][name].push(filterprojectstatus);
    }

    setFolder(_folder);
  };
  const handleClose = () => {
    setIsFolder(false);
    setIsRuleFolder(false);
    setSelectedSourceProject([]);
    setIsFolderSuccess(false);
  };
  const handleCreate = () => {
    InsertFolderTemplate();
  };
  const handleUpdate = () => {
    UpdateFolderTemplateByID();
  };
  const onHandleTreeivew = (e) => {
    //console.log("onHandleTreeivew", e);
    // let folderid = e.currentTarget.getAttribute("name");
    // if (e.element.id !== "0") {
    console.log("onHandleTreeivew", e.element.name);
    getTemplateFoldersListByParentID(e.element?.id);
    let _selectedFolder = { ...selectedFolder };
    _selectedFolder.folderId = e.element.id;
    _selectedFolder.folderName = e.element.name;
    setSelectedFolder(_selectedFolder);
    //  }
  };

  ///  console.log("selectedSourceProject", selectedSourceProject);
  const handleChange_SoureceSelect = (e, data) => {
    const { name, checked } = e.target;
    if (checked) {
      // if cheked and selectall checkbox add all fileds to selectedList
      if (name === "allSelectSource") {
        setSelectedSourceProject(projectStatusList);
      } else {
        // if cheked and specific checkbox add specific field to selectedList
        setSelectedSourceProject([...selectedSourceProject, data]);
      }
    } else {
      // if uncheked and selectall checkbox add remove all fileds from selectedList
      if (name === "allSelectSource") {
        setSelectedSourceProject([]);
      } else {
        // if uncheked and specific checkbox remove specific field from selectedList
        let tempproject = selectedSourceProject.filter(
          (item) => item._id !== data._id
        );
        setSelectedSourceProject(tempproject);
      }
    }
  };
  return (
    <div class="wrapper">
      <div className="tab-content p-0">
        <h3 align="center">Sharepoint folder template</h3>

        {loading && (
          <div>
            <Spinner animation="border" variant="info" />
          </div>
        )}
        <div className="row">
          <hr />
          <div className="row">
            <div className="col-sm-12 col-md-4 col-lg-4">
              <div className="h5 fw-bold">{`📖 Folder structure`} </div>
            </div>
            <div className="col-sm-12 col-md-8 col-lg-8">
              <div className="row">
                <div className="d-flex justify-content-between">
                  <Button
                    onClick={() => {
                      setIsFolder(true);
                      let _initialfolderstate = { ...initialfolderstate };
                      setSelectedSourceProject(projectStatusList);
                      setFolder(_initialfolderstate);
                    }}
                    className="btn btn-sm mb-2"
                  >
                    <span className="fa-stack fa-1x">
                      <i className="far fa-circle fa-stack-1x"></i>
                      <i className="fa fa-plus fa-stack-1x"></i>
                    </span>
                    New folder
                  </Button>
                  {selectedFolder?.folderName && (
                    <div className="d-flex justify-content-end">
                      <p class="fw-bolder">Selected folder : </p>
                      <span>{selectedFolder?.folderName}</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div className="col-xl-12">
            <div className="row">
              <div className="col-sm-12 col-md-4 col-lg-4">
                {foldersTreeStructure?.length > 0 && (
                  <>
                    {/* <MultiSelectCheckbox treeData={foldersTreeStructure} /> */}
                    <TreeViewSidebar
                      data={foldersTreeStructure}
                      onHandleNodeEvent={onHandleTreeivew}
                      folderListIDS={foldersListIDS}
                    />
                    {/* <TreeView
                    isRootExpand={selectedFolderId === "0" ? true : false}
                    data={foldersTreeStructure}
                    onHandleEvent={onHandleTreeivew}
                  /> */}
                  </>
                )}
              </div>
              <div className="col-sm-12 col-md-8 col-lg-8">
                {data.length > 0 && (
                  <GenericListTable
                    gcolumns={Columns}
                    gdata={data.length > 0 ? JSON.stringify(data) : ""}
                    ghiddencolumns={hiddenColumns}
                    gplaceholder={"Search user.."}
                    gsearchDisabled={true}
                    filterDisabled={true}
                    gdisablePagination={data.length > 10?false:true}
                    // gkey={"_id"}
                    // glink="/detailuser"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {isFolder && (
        <Modal
          scrollable={true}
          show={isFolder}
          onHide={handleClose}
          size={folder?._id === undefined ? "lg" : "l"}
          centered
        >
          <Modal.Header className="fw-bold h4">
            {folder?._id === undefined ? "Create folder" : "Rename folder"}
            <a
              style={{ "text-decoration": "none" }}
              onClick={handleClose}
              className="col d-flex justify-content-end text-dark"
            >
              <i class="fa-solid fa-xmark"></i>
            </a>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <label className="fw-bolder">Folder name:</label>
                <input
                  type="text"
                  name="title"
                  id="title"
                  placeholder="Folder name"
                  className="form-control"
                  value={folder?.title}
                  onChange={handleChange}
                />
              </div>
              {folder._id === undefined && (
                <div>
                  <label className="fw-bolder mt-1 mb-1">Project status:</label>
                  <div className="row">
                    <div className="col-4">
                      <input
                        type="checkbox"
                        className="form-check-input me-1 mt-1"
                        name="allSelectSource"
                        id="allSelectSource"
                        checked={
                          selectedSourceProject?.length ===
                            projectStatusList?.length &&
                          selectedSourceProject?.length !== 0
                        }
                        onChange={(e) =>
                          handleChange_SoureceSelect(e, projectStatusList)
                        }
                      />
                      {" Select all"}
                    </div>

                    {projectStatusList?.map((row) => {
                      return (
                        <div className="col-4">
                          <input
                            type="checkbox"
                            className="form-check-input me-1 mt-1"
                            checked={selectedSourceProject?.some(
                              (item) => item?._id === row?._id
                            )}
                            onChange={(e) => handleChange_SoureceSelect(e, row)}
                            name="selectsourceoption"
                            value={row?._id}
                          ></input>
                          {" " + row?.label}
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            {" "}
            <div className="row p-1">
              {" "}
              <div className=" d-flex justify-content-end mt-3 col-sm-12 col-md-12 col-lg-12">
                <button
                  className=" btn btn-primary me-2 "
                  onClick={handleClose}
                >
                  Cancel
                </button>

                {folder._id === undefined ? (
                  <button
                    className="btn btn-primary me-2 "
                    disabled={folder?.title?.length > 0 ? false : true}
                    onClick={() => {
                      handleCreate();
                    }}
                  >
                    Create
                  </button>
                ) : (
                  <button
                    className="btn btn-primary me-2 "
                    disabled={folder?.title.length > 0 ? false : true}
                    onClick={() => {
                      handleUpdate();
                    }}
                  >
                    Done
                  </button>
                )}
              </div>
            </div>
          </Modal.Footer>
        </Modal>
      )}
      {isRuleFolder && (
        <Modal
          scrollable={true}
          show={isRuleFolder}
          onHide={handleClose}
          size="lg"
          centered
        >
          <Modal.Header className="fw-bold h4">
            Rules
            <a
              style={{ "text-decoration": "none" }}
              onClick={handleClose}
              className="col d-flex justify-content-end text-dark"
            >
              <i class="fa-solid fa-xmark"></i>
            </a>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div className="row p-1">
                <label className="fw-bolder">Project status:</label>

                <div className="row">
                  <div className="col-4">
                    <input
                      type="checkbox"
                      className="form-check-input  me-1 mt-1"
                      name="allSelectSource"
                      id="allSelectSource"
                      checked={
                        selectedSourceProject?.length ===
                          projectStatusList?.length &&
                        selectedSourceProject?.length !== 0
                      }
                      onChange={(e) =>
                        handleChange_SoureceSelect(e, projectStatusList)
                      }
                    />
                    Select all
                  </div>

                  {projectStatusList?.map((row) => {
                    return (
                      <div className="col-4">
                        <input
                          type="checkbox"
                          className="form-check-input me-1 mt-1"
                          checked={selectedSourceProject?.some(
                            (item) => item?._id === row?._id
                          )}
                          onChange={(e) => handleChange_SoureceSelect(e, row)}
                          name="selectsourceoption"
                          value={row?._id}
                        ></input>
                        {row?.label}
                      </div>
                    );
                  })}
                </div>

                {/* <div className="row p-1">
                <div className="col-sm-12 col-md-4 col-lg-4">
                  <label className="fw-bolder">Show</label>
                </div>
                <div className="col-sm-12 col-md-4 col-lg-8">
                  {" "}
                  <input
                    type="checkbox"
                    className="form-check-input"
                    name="show"
                    id="show"
                    checked={folder?.rules[0]?.show ? true : false}
                    onChange={(e) => {
                      let _folder = { ...folder };
                      _folder.rules[0].show = e.target.checked ? true : false;
                      setFolder(_folder);
                    }}
                  />
                </div>
              </div>
              <div className="row p-1">
                <div className="col-sm-12 col-md-4 col-lg-4">
                  <label className="fw-bolder">Hide</label>
                </div>
                <div className="col-sm-12 col-md-4 col-lg-8">
                  {" "}
                  <input
                    type="checkbox"
                    className="form-check-input"
                    name="isActive"
                    id="isActive"
                    checked={folder?.rules[0]?.isActive ? true : false}
                    onChange={(e) => {
                      let _folder = { ...folder };
                      _folder.rules[0].isActive = e.target.checked
                        ? true
                        : false;
                      setFolder(_folder);
                    }}
                  />
                </div>
              </div> */}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {" "}
            <div className="row p-1">
              {" "}
              <div className=" d-flex justify-content-end mt-3 col-sm-12 col-md-12 col-lg-12">
                <button
                  className=" btn btn-primary me-2 "
                  onClick={handleClose}
                >
                  Cancel
                </button>

                <button
                  className="btn btn-primary me-2 "
                  disabled={folder?.title.length > 0 ? false : true}
                  onClick={() => {
                    handleUpdate();
                  }}
                >
                  Done
                </button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
      )}
      {isFolderSuccess && (
        <SweetAlert
          success
          style={{ color: "black" }}
          title={msg}
          onConfirm={handleClose}
        ></SweetAlert>
      )}
      {isDeleteFolder && (
        <SweetAlert
          warning
          style={{ color: "black" }}
          title=""
          confirmBtnText="Delete"
          cancelBtnText="No"
          showCancel={true}
          onConfirm={handleConfirmDelete}
          onCancel={handleCancelDelete}
        >
          <div>{appConstants.import.confirmationmsg.deleteprojectfolder}</div>
        </SweetAlert>
      )}
      {isDeleted && (
        <SweetAlert
          success
          style={{ color: "black" }}
          title=""
          confirmBtnText="Ok"
          onConfirm={handleConfirmDeleted}
        >
          <div>{appConstants.import.alertmsg.folderdeletesucessfull}</div>
        </SweetAlert>
      )}
    </div>
  );
}

export default SharepointFolderStructure;
