
import React from "react";
import { useHistory } from "react-router";
const PageSubHeader = ({title}) => {
  const history = useHistory();
  const handleRedirect = () => {
    history.push({
      pathname: "/apps",
    });
  };
  return (
    <div class="d-flex justify-content-between align-items-center p-0 m-0">
    <div class="" > </div>
      <div class=""><h3>{title}</h3></div>
      <div class=" ">{" "}</div>
    </div>
  );
};

export default PageSubHeader;
