export const getcommandpostaccountlistbyuserid_wrapper = async (
  email,
  Ctechapp,
  loggedinby
) => {
  try {
    const data =
      await Ctechapp.functions.getcommandpostaccountlistbyuserid_wrapper(
        email,
        loggedinby
      );
    if (data !== undefined) {
      // console.log("status: success");
      return JSON.stringify(data);
    }
  } catch (error) {
    console.log("error : ", error);
    throw Error(error);
  }
};

export const getcommandpostcontactlistbyaccountid_wrapper = async (
  accountid,
  Ctechapp,
  loggedinby
) => {
  try {
    const data =
      await Ctechapp.functions.getcommandpostcontactlistbyaccountid_wrapper(
        accountid,
        loggedinby
      );
    if (data !== undefined) {
      // console.log("status: success");
      return JSON.stringify(data);
    }
  } catch (error) {
    console.log("error : ", error);
    throw Error(error);
  }
};

export const getcommandpostcompanylistbyaccountid_wrapper = async (
  accountid,
  Ctechapp,
  loggedinby
) => {
  try {
    const data =
      await Ctechapp.functions.getcommandpostcompanylistbyaccountid_wrapper(
        accountid,
        loggedinby
      );
    if (data !== undefined) {
      // console.log("status: success");
      return JSON.stringify(data);
    }
  } catch (error) {
    console.log("error : ", error);
    throw Error(error);
  }
};
//getCommandPostProjectListWithPMProjectList
export const getCommandPostProjectListWithPMProjectList = async (
  accountid,
  Ctechapp,
  loggedinby
) => {
  try {
    const data =
      await Ctechapp.functions.getCommandPostProjectListWithPMProjectList(
        accountid,
        loggedinby
      );
    if (data !== undefined) {
      // console.log("status: success");
      return data;
    }
  } catch (error) {
    console.log("error : ", error);
    throw Error(error);
  }
};
export const getCommandPostProjectListWithoutPMProjectList = async (
  accountid,
  Ctechapp,
  loggedinby
) => {
  try {
    const data =
      await Ctechapp.functions.getCommandPostProjectListWithoutPMProjectList(
        accountid,
        loggedinby
      );
    if (data !== undefined) {
      // console.log("status: success");
      return data;
    }
  } catch (error) {
    console.log("error : ", error);
    throw Error(error);
  }
};
