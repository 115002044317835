import React from "react";
function ApplicationHeadername() {
  return (
    <div
      className=""
      style={{ "background-color": "white", "border-radius": "10px" }}
    >
      <h1 style={{ color: "skyblue" }} className="txtalign p-2">
        Project Sync
      </h1>
    </div>
  );
}
export default ApplicationHeadername;
