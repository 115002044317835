import { Formik } from "formik";
import { Button, Form } from "react-bootstrap";
import * as yup from "yup";
import * as Realm from "realm-web";
import { useRealmApp } from "../../providers/RealmApp";
import { AppSettings } from "../../config/app-settings";
import { useHistory } from "react-router";
import React, { useContext, useState, useEffect } from "react";
import { isAnon } from "../../utils";
import Loading from "../../components/Loading";
import ApplicationHeadername from "../../components/ApplicationHeadername";
//import { Link } from "react-router-dom";
const userSchema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().required().min(8),
});

export default function Authentication() {
  const ctechapp = useRealmApp();
  const context = useContext(AppSettings);

  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const initval = {
    email: "",
    password: "",
  };
  // useEffect(() => {
  //   if (!isAnon(user)) {
  //     history.push("/");
  //   }
  // }, [history, user]);

  async function submitHandler(values) {
    setLoading(true);
    // const creds = Realm.Credentials.emailPassword(
    //   values.email,
    //   values.password
    // );
    try {
      await ctechapp.logOutAllUsers();
      const newUser1 = await ctechapp.logIn(
        Realm.Credentials.emailPassword(values.email, values.password)
      );

      if (ctechapp.currentUser != undefined) {
        // setUser(newUser);

        window.location.href = "/apps";
      }
    } catch (err) {
      console.error("Failed to log in 1 ", err.message);
      setLoading(false);
      values.email = "";
      values.password = "";
      alert("Invalid User name and Password");
      window.location.reload();
    }
  }
  function gotosignup() {
    window.location.href = "/signupwithlob";
  }

  async function forgotpassword() {
    await ctechapp.logOutAllUsers();
    window.location.href = "/forgotpassword";
  }

  return (
    <></>
    // <div className="divcontainer">
    //   <ApplicationHeadername />
    //   <Formik
    //     initialValues={initval}
    //     validationSchema={userSchema}
    //     onSubmit={submitHandler}
    //   >
    //     {({
    //       errors,
    //       touched,
    //       handleSubmit,
    //       values,
    //       handleChange,
    //       resetForm,
    //     }) => (
    //       <Form noValidate onSubmit={handleSubmit}>
    //         {loading && <Loading />}
    //         {!loading && (
    //           <div>
    //             <p className="txtalign p-2">
    //               <h5>Login</h5>
    //             </p>
    //             <Form.Group className="rowctr mb-2">
    //               <div style={{ flexDirection: "column" }}>
    //                 <Form.Control
    //                   type="email"
    //                   name="email"
    //                   value={values.email}
    //                   onChange={handleChange}
    //                   isValid={touched.email && !errors.email}
    //                   placeholder="Enter email address"
    //                 />
    //                 <div style={{ color: "red" }}>{errors.email}</div>
    //               </div>
    //             </Form.Group>
    //             <Form.Group className="rowctr">
    //               <div style={{ flexDirection: "column" }}>
    //                 <Form.Control
    //                   type="password"
    //                   name="password"
    //                   value={values.password}
    //                   onChange={handleChange}
    //                   isValid={touched.password && !errors.password}
    //                   placeholder="Enter password"
    //                 />
    //                 <div style={{ color: "red" }}>{errors.password}</div>
    //               </div>
    //             </Form.Group>
    //             <div>
    //               <a
    //                 href="#"
    //                 onClick={() => forgotpassword()}
    //                 style={{ marginLeft: 20 }}
    //               >
    //                 Forgot Password
    //               </a>
    //             </div>
    //             <div className="text-center mt-2">
    //               <div style={{ flexDirection: "column" }}>
    //                 <Button variant="primary" type="submit" className="me-2">
    //                   Submit
    //                 </Button>
    //                 <Button
    //                   variant="secondary"
    //                   className="butn2"
    //                   onClick={resetForm}
    //                 >
    //                   clear
    //                 </Button>
    //               </div>
    //               <Button
    //                 varian="success"
    //                 onClick={gotosignup}
    //                 className="mt-2"
    //               >
    //                 Sign up
    //               </Button>
    //             </div>
    //             <div
    //               style={{
    //                 position: "fixed",
    //                 bottom: 0,
    //                 right: 0,
    //                 fontSize: "12px",
    //               }}
    //             >
    //               V1.0.1.00.005
    //             </div>
    //           </div>
    //         )}
    //       </Form>
    //     )}
    //   </Formik>
    // </div>
  );
}
