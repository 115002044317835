//services for getaccountbyid
export const getEventHistory = async (
  action,
  payload,
  loggedinby,
  Ctechapp
) => {
  try {
    const data = await Ctechapp.functions.getEventHistory(
      action,
      payload,
      loggedinby
    );

    return data;
  } catch (error) {
    return error.response.data.message;
  }
};
