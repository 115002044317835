import { Panel, PanelHeader, PanelBody } from "../../components/panel/panel";
import React, { useState, useMemo, useEffect, useCallback } from "react";
import { Button, Modal } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import { appConstants } from "../../constants/app_constants";
import Moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";
import { getuserbyauthproviderid } from "../../services/users_services";
import GenericListTable from "../../components/gridTable/genericLlistTable";
import { getCommandPostProjectListWithPMProjectList } from "../../services/commandpost_project_services";
import {
  getappcompanylistbyappname_userid,
  getapplistbyuserid,
} from "../../services/applications_services";
import { getProcoreProjectListWithoutPMProjectList } from "../../services/procore_project_services";
import {
  getPendingCreationTask,
  updatePendingCreateTaskByBatchStatus,
} from "../../services/pending_create_task_services";
import { createImportProjectTask } from "../../services/import_project_task_services";
import { useRealmApp } from "../../providers/RealmApp";
import { useAuth0 } from "@auth0/auth0-react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingDialog from "../../components/LoadingDialog";
import PageSubHeader from "../../components/pagesubheader";
const _appname = "Procore";
const initalSourceProjectInfo = {
  sourceID: "",
  projectName: "",
  projectNumber: "",
  altProjectNumber: "",
  companyId: "",
  source: "",
  sourceRecordIndex: "",
  projectCreatedDate: "",
  projectUpdatedDate: "",
  destinationID: "",
  destinationCompanyID: "",
  destinationSource: "",
  destinationProjectName: "",
  destinationProjectNumber: "",
  destinationProjectCreatedDate: "",
  destinationProjectUpdatedDate: "",
  stateMatched: "",
};

function PendingCreationList() {
  const [tabProcoreProjects, setProcoreProjects] = useState(true);
  const [tabSendverification, setSendverification] = useState(false);
  const [noRecord, setNoRecord] = useState(false);
  const [page, Stepage] = useState(0);
  const [approveDisiable, setApproveDisable] = useState(false);
  const { isLoading, error, isAuthenticated, user } = useAuth0();
  const [isApprove, setIsapprove] = useState(false);

  const [isMessage, setisMessage] = useState("");
  const [msgTitle, setMsgTitle] = useState("Project Sync");
  const [msgInfo, setmsgInfo] = useState("");
  const FormTitles = ["procoreproject", "sendverification"];
  const [cpdestinationCompanyList, setCPDestinationCompanyList] = useState("");
  const [prodestinationCompanyList, setProDestinationCompanyList] =
    useState("");
  const [sourceProjectList, setSourceProjectList] = useState([]);
  const [cpdestinationProjectList, setCPDestinationProjectList] = useState([]);
  const [prodestinationProjectList, setProDestinationProjectList] = useState(
    []
  );
  const [sourceSelectProjectInfo, setSourceSelectProjectInfo] = useState(
    initalSourceProjectInfo
  );
  const [destinationSelectProjectInfo, setDestinationSelectProjectInfo] =
    useState("");
  const ctechapp = useRealmApp();
  const [errordisplay, SetErrorDisplay] = useState("");
  const [isCPDestinationListModelWindow, setIsCPDestinationListModelWindow] =
    useState(false);
  const [isProDestinationListModelWindow, setIsProDestinationListModelWindow] =
    useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [errorAlertMsg, setErrorAlertMsg] = useState("");
  const [loadDialogOpen, setloadDialogOpen] = useState(false);
  var sortJsonArray = require("sort-json-array");

  const [userinfo, setUserinfo] = useState({
    role: "",
  });

  useEffect(() => {
    if (isAuthenticated) {
      getUserInfoByID();
      //  getCompanyListData(ctechapp.currentUser);
    }
  }, []);

  async function getUserInfoByID() {
    try {
      getuserbyauthproviderid(user.sub, ctechapp.currentUser).then(
        (response) => {
          let user_info = JSON.parse(response);
          if (response !== undefined || user_info.length !== 0) {
            setUserinfo(user_info[0]);
            Getapplistbyuserid(user_info[0]);
          }
        }
      );
    } catch (exception) {
      console.log(exception);
    }
  }
  //getPendingCreationTask
  async function GetPendingCreationTask(userid) {
    try {
      setloadDialogOpen(true);
      getPendingCreationTask(ctechapp.currentUser, userid).then((response) => {
        if (response.statusCode === 200) {
          setSourceProjectList(response.data);
          setloadDialogOpen(false);
          if (response.data.length === 0) {
            setNoRecord(true);
          } else {
            setNoRecord(false);
          }
        } else {
          setloadDialogOpen(false);
          setErrorAlert(true);
          setErrorAlertMsg(JSON.stringify(response.error));
        }
      });
    } catch (exception) {
      console.log(exception);
    }
  }

  async function Getapplistbyuserid(_userinfo) {
    try {
      getapplistbyuserid(ctechapp.currentUser, _userinfo.userid).then(
        (response) => {
          if (response !== undefined) {
            let apps_list = JSON.parse(response);

            let pro_apps_list = apps_list.filter(
              (app) => app.appname.toLowerCase() === "procore"
            );
            let options_pro = [];
            pro_apps_list.map((val) => options_pro.push(val.appaccountid));
            setProDestinationCompanyList({ $in: options_pro });
            GetProcoreProjectListWithoutPMProjectList(
              { $in: options_pro },
              _userinfo.userid
            );

            let cp_apps_list = apps_list.filter(
              (app) => app.appname.toLowerCase() === "commandpost"
            );
            let options_cp = [];
            cp_apps_list.map((val) => options_cp.push(val.appaccountid));

            setCPDestinationCompanyList({ $in: options_cp });
            GetCommandPostProjectListWithoutPMProjectList(
              { $in: options_cp },
              _userinfo.userid
            );
            setloadDialogOpen(true);
          }
        }
      );
    } catch (exception) {
      console.log(exception);
    }
  }

  const handleChange_CheckBoxSelect = (e, data, rowindex) => {
    const { name, checked } = e.target;
    let _sourceProject = [...sourceProjectList];
    let row = _sourceProject[rowindex];
    if (checked) {
      // if cheked and selectall checkbox add all fileds to selectedList
      row.stateMatched = "new";
      row.selected = true;
    } else {
      row.stateMatched = "";
      row.selected = false;
      row.destinationID = "";
      row.destinationCompanyID = "";
      row.destinationSource = "";
      row.destinationProjectName = "";
      row.destinationProjectNumber = "";
      row.destinationProjectCreatedDate = "";
      row.destinationProjectUpdatedDate = "";
    }
    _sourceProject[rowindex] = row;
    setSourceProjectList(_sourceProject);
  };

  const handleChange_MatchedRecordRemoveClick = (e, data, rowindex) => {
    let _sourceProject = [...sourceProjectList];
    let row = _sourceProject[rowindex];
    // if cheked and selectall checkbox add all fileds to selectedList
    row.stateMatched = "";
    row.selected = false;
    row.destinationID = "";
    row.destinationCompanyID = "";
    row.destinationSource = "";
    row.destinationProjectName = "";
    row.destinationProjectNumber = "";
    row.destinationProjectCreatedDate = "";
    row.destinationProjectUpdatedDate = "";
    _sourceProject[rowindex] = row;
    setSourceProjectList(_sourceProject);
  };

  const handleChangeDestination_MatchedRecordClick = () => {
    let _sourceProject = [...sourceProjectList];
    let _sourceSelectProjectInfo = { ...sourceSelectProjectInfo };
    let _destinationSelectProjectInfo = { ...destinationSelectProjectInfo };
    console.log("_destinationSelectProjectInfo", _destinationSelectProjectInfo);
    if (_destinationSelectProjectInfo.sourceID === undefined) {
      setErrorAlertMsg("Select the project");
      return;
    }
    let rowindex = _sourceSelectProjectInfo.sourceRecordIndex;
    _sourceProject[rowindex].stateMatched = "Matched";
    _sourceProject[rowindex].selected = true;
    _sourceProject[rowindex].destinationID =
      _destinationSelectProjectInfo.sourceID || "";
    _sourceProject[rowindex].destinationCompanyID =
      _destinationSelectProjectInfo.companyId || "";
    _sourceProject[rowindex].destinationSource =
      _destinationSelectProjectInfo.source || "";
    _sourceProject[rowindex].destinationProjectName =
      _destinationSelectProjectInfo.projectName || "";
    _sourceProject[rowindex].destinationProjectNumber =
      _destinationSelectProjectInfo.projectNumber || "";
    _sourceProject[rowindex].destinationProjectCreatedDate =
      _destinationSelectProjectInfo.projectCreatedDate || "";
    _sourceProject[rowindex].destinationProjectUpdatedDate =
      _destinationSelectProjectInfo.projectUpdatedDate || "";
    // if cheked and selectall checkbox add all fileds to selectedList
    setSourceProjectList(_sourceProject);
    setDestinationSelectProjectInfo("");
    setIsCPDestinationListModelWindow(false);
    setIsProDestinationListModelWindow(false);
    //console.log(_sourceSelectProjectInfo, _destinationSelectProjectInfo)
  };

  const handleChange_DestinationSelect = (e, data, rowindex) => {
    setErrorAlertMsg("");
    setDestinationSelectProjectInfo(data);
  };

  const handleSourceSearchClickApps = async (row) => {
    console.log("row.original", row.original);
    let _sourceSelectProjectInfo = row.original;

    _sourceSelectProjectInfo.sourceRecordIndex = row.index;
    setSourceSelectProjectInfo(_sourceSelectProjectInfo);
    if (_sourceSelectProjectInfo.appname === "CommandPost") {
      setIsProDestinationListModelWindow(true);
    } else {
      setIsCPDestinationListModelWindow(true);
    }
  };

  const showTab = (tab) => {
    switch (tab) {
      case "procoreproject":
        setProcoreProjects(true);
        setSendverification(false);
        return;
      case "sendverification":
        setProcoreProjects(false);
        setSendverification(true);
        return;
    }
  };

  const handleMessage = () => {
    setisMessage(false);
    setmsgInfo("");
  };
  // const handleCompanyChange = (event) => {
  //   setSelectedCompany(event.target.value);
  //   //  handleSelectedCompany(event.target.value);
  //   GetProcoreProjectListWithoutPMProjectList(event.target.value);
  //   GetCommandPostProjectListWithoutPMProjectList(cpdestinationCompanyList);
  //   setLoading(true);
  // };
  async function GetProcoreProjectListWithoutPMProjectList(companyid, userid) {
    try {
      getProcoreProjectListWithoutPMProjectList(
        companyid,
        ctechapp.currentUser,
        user.sub
      ).then((response) => {
        if (response !== undefined) {
          console.log(
            "getProcoreProjectListWithoutPMProjectList response",
            response
          );
          if (response.statusCode === 200) {
            setProDestinationProjectList(response?.data);
            setloadDialogOpen(false);
          } else {
           // setMsgTitle("getProcoreProjectListWithoutPMProjectList Error");
            setErrorAlertMsg(JSON.stringify(response?.error));
            setloadDialogOpen(false);
            setErrorAlert(true);
          }
          GetPendingCreationTask(userid);
        }
      });
    } catch (err) {
      console.error("GetProcoreProjectListWithoutPMProjectList Error", err);
    }
  }
  //getCommandPostProjectListWithPMProjectList
  async function GetCommandPostProjectListWithoutPMProjectList(companyid) {
    getCommandPostProjectListWithPMProjectList(
      companyid,
      ctechapp.currentUser,
      user.sub
    ).then((response) => {
      if (response !== undefined) {
        console.log(
          "getCommandPostProjectListWithPMProjectList response",
          response
        );
        if (response.statusCode === 200) {
          setCPDestinationProjectList(response.data);
        } else {
          setErrorAlert(true);
          setErrorAlertMsg(JSON.stringify(response.error));
        }
      }
    });
  }
  //setProcoreprojectlist;
  const approveclick = (e, status) => {
    setApproveDisable(true);
    let _sourceProjectList = sourceProjectList.filter(
      (val) => val.selected === true
    );

    UpdatePendingCreateTaskByBatchStatus(_sourceProjectList, status);
  };

  async function UpdatePendingCreateTaskByBatchStatus(batchpayload, status) {
    updatePendingCreateTaskByBatchStatus(
      batchpayload,
      status,
      ctechapp.currentUser,
      userinfo.userid
    ).then((response) => {
      response = JSON.parse(response);

      if (response.statusCode === 200) {
        setIsapprove(true);
        if (status === "reject") {
          setmsgInfo(appConstants.import.alertmsg.projectreject);
        } else {
          setmsgInfo(appConstants.import.alertmsg.projectapproved);
        }
      } else {
        setMsgTitle("UpdatePendingCreateTaskByBatchStatus function error");
        setErrorAlert(true);
        setErrorAlertMsg(JSON.stringify(response.error));
      }
    });
  }
  const sourceProjectListColumns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "_id",
        sortable: false,
        width: "20%",
      },
      // {
      //   Header: ({ rows }) => {
      //     // Toggle checkboxes manually to dont toggle disabled cells
      //     const changeableRows = !isRowDisabled
      //       ? [...rows]
      //       : rows.filter((row) => !!isRowDisabled && !isRowDisabled(row));

      //     const allSelected = changeableRows.every((row) => row.isSelected);
      //     const allUnselected = changeableRows.every((row) => !row.isSelected);

      //     return (
      //       <Checkbox
      //         checked={!allUnselected}
      //         indeterminate={!allSelected && !allUnselected}
      //         onChange={() => changeableRows.forEach((row) => row.toggleRowSelected(!allSelected))}
      //       />
      //     )
      //   }
      // },
      {
        Header: () => (
          <input
            type="checkbox"
            class="form-check-input "
            id="select"
            checked={
              sourceProjectList.filter((row) => row.selected === true)
                .length === sourceProjectList.length &&
              sourceProjectList.length > 0
                ? true
                : false
            }
            onChange={handleChange_CheckBoxSelectALL}
            name="select"
          />
        ),
        accessor: "selected",
        sortable: false,
        width: "10%",
        Cell: ({ row }) => (
          <input
            class="form-check-input mt-0"
            type="checkbox"
            name="destinationSelect"
            checked={row.original.selected}
            onChange={(e) =>
              handleChange_CheckBoxSelect(e, row.original, row.index)
            }
          />
        ),
      },
      {
        Header: "Source app",
        accessor: "appname",
        sortable: false,
        width: "10%",
      },
      {
        Header: "project#",
        accessor: "projectNumber",
        sortable: false,
        width: "10%",
      },
      {
        Header: "project name",
        accessor: "projectName",
        sortable: false,
        width: "30%",
      },
      {
        Header: "",
        accessor: "search",
        sortable: false,
        width: "30%",
        Cell: ({ row }) => {
          const onSerachItemClick = () => {
            handleSourceSearchClickApps(row);
          };
          return (
            <a title="search" className="text-dark" onClick={onSerachItemClick}>
              <i class="fa-solid fa-magnifying-glass"></i>
            </a>
          );
        },
      },
      {
        Header: "Destination app",
        accessor: "destinationSource",
        sortable: false,
        width: "10%",
      },
      {
        Header: " project#",
        accessor: "destinationProjectNumber",
        sortable: false,
        width: "30%",
      },
      {
        Header: " project name",
        accessor: "destinationProjectName",
        sortable: false,
        width: "30%",
      },
      {
        Header: "Status",
        accessor: "stateMatched",
        sortable: false,
        width: "30%",
      },
      {
        Header: "Action",
        accessor: "Action",
        sortable: false,
        width: "30%",
        Cell: ({ row }) => {
          return (
            <>
              {row.original?.stateMatched?.toLowerCase() === "matched" && (
                <a
                  title="Remove match"
                  onClick={
                    (e) =>
                      handleChange_MatchedRecordRemoveClick(
                        e,
                        row.original,
                        row.index
                      )
                    // let _procoreprojectlist = [...sourceProjectList];
                    // _procoreprojectlist.splice(row.index, 1);
                    // setSourceProjectList(_procoreprojectlist);
                  }
                >
                  <i
                    class="fa-regular fa-trash-can"
                    style={{ color: "#ff2424" }}
                  ></i>
                </a>
              )}
            </>
          );
        },
      },
    ],
    [sourceProjectList]
  );
  const handleChange_CheckBoxSelectALL = (e) => {
    const { name, checked } = e.target;
    let _sourceProject = [...sourceProjectList];

    if (checked) {
      // if cheked and selectall checkbox add all fileds to selectedList
      _sourceProject = _sourceProject.map((row) => {
        row.stateMatched = "new";
        row.selected = true;
        return row;
      });
    } else {
      _sourceProject = _sourceProject.map((row) => {
        row.stateMatched = "";
        row.selected = false;
        row.destinationID = "";
        row.destinationCompanyID = "";
        row.destinationSource = "";
        row.destinationProjectName = "";
        row.destinationProjectNumber = "";
        row.destinationProjectCreatedDate = "";
        row.destinationProjectUpdatedDate = "";
        return row;
      });
    }
    setSourceProjectList(_sourceProject);
  };
  const hiddenSourceProjectListColumns = ["_id"];

  const approveProjectListColumns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "_id",
        sortable: false,
        width: "20%",
      },
      {
        Header: "Source app",
        accessor: "appname",
        sortable: false,
        width: "10%",
      },
      {
        Header: "Project#",
        accessor: "projectNumber",
        sortable: false,
        width: "10%",
      },
      {
        Header: "Project name",
        accessor: "projectName",
        sortable: false,
        width: "30%",
      },
      {
        Header: "Destination app",
        accessor: "destinationSource",
        sortable: false,
        width: "10%",
      },

      {
        Header: " project#",
        accessor: "destinationProjectNumber",
        sortable: false,
        width: "30%",
      },
      {
        Header: " project name",
        accessor: "destinationProjectName",
        sortable: false,
        width: "30%",
      },
      {
        Header: "Status",
        accessor: "stateMatched",
        sortable: false,
        width: "30%",
      },
    ],
    [sourceProjectList]
  );

  const CPDestinationProjectListColumns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "_id",
        sortable: false,
        width: "20%",
      },
      {
        Header: "Select",
        accessor: "action",
        sortable: false,
        width: "10%",
        Cell: ({ row }) => (
          <input
            class="form-check-input mt-0"
            type="radio"
            name="destinationSelect"
            onChange={(e) =>
              handleChange_DestinationSelect(e, row.original, row.index)
            }
          />
        ),
      },

      {
        Header: "CP project#",
        accessor: "projectNumber",
        sortable: true,
        width: "30%",
      },
      {
        Header: "CP project name",
        accessor: "projectName",
        sortable: true,
        width: "30%",
      },

      {
        Header: "End date",
        accessor: "projectEndDate",
        sortable: true,
        width: "30%",
        Cell: ({ value }) => {
          return (value && Moment(value).format(appConstants.DATEFORMAT)) || "";
        },
      },
    ],
    []
  );
  const hiddenCPDestinationProjectListColumns = ["_id"];
  const proDestinationProjectListColumns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "_id",
        sortable: false,
        width: "20%",
      },
      {
        Header: "Select",
        accessor: "action",
        sortable: false,
        width: "10%",
        Cell: ({ row }) => (
          <input
            class="form-check-input mt-0"
            type="radio"
            name="destinationSelect"
            onChange={(e) =>
              handleChange_DestinationSelect(e, row.original, row.index)
            }
          />
        ),
      },

      {
        Header: "Procore project#",
        accessor: "projectNumber",
        sortable: true,
        width: "30%",
      },
      {
        Header: "Procore project name",
        accessor: "projectName",
        sortable: true,
        width: "30%",
      },

      {
        Header: "End date",
        accessor: "projectEndDate",
        sortable: true,
        width: "30%",
        Cell: ({ value }) => {
          return (value && Moment(value).format(appConstants.DATEFORMAT)) || "";
        },
      },
    ],
    []
  );
  const hiddenProDestinationProjectListColumns = ["_id"];
  const handleClose = (e, action) => {
    e.preventDefault();
    switch (action) {
      case "isCPModalClose":
        setIsCPDestinationListModelWindow(false);
        break;
      case "isProModalClose":
        setIsProDestinationListModelWindow(false);
        break;
      default:
        break;
    }
    setDestinationSelectProjectInfo("");
    setErrorAlertMsg("");
  };

  return (
    <div className="tab-content p-0">
       <PageSubHeader title={"Pending creation"} />

      <div className={"tab-pane fade " + (tabProcoreProjects ? "show active " : "")}>
      <div className=" stepWrapper justify-content-around">
          <div className={"stepBlock  " + (tabProcoreProjects ? "selected " : "")}>
            <div className="circleWrapper">
              <div className="wizardcircle d-flex align-item-center justify-content-center">
                1
              </div>
            </div>
            <span className="d-flex justify-content-center">
              Select project
            </span>
          </div>

          <div className={"stepBlock " + (tabSendverification ? "active " : "")}>
            <div className="circleWrapper d-flex align-item-center justify-content-center ">
              <div className="wizardcircle d-flex align-item-center justify-content-center">
                2
              </div>
            </div>
            <span className="d-flex justify-content-center">
              Review and Approve
            </span>
          </div>
        </div>
        <div className="col-xl-12">
          <Panel>
            <PanelHeader noButton={true}>
              {/* <div className="d-flex align-items-center">
                <div>
                  <ul className="profile-header-tab nav nav-tabs">
                    <li className="nav-item me-2" key="commandpostproject">
                      <div
                        className={
                          "nav-link text-dark " +
                          (tabProcoreProjects ? "active " : "")
                        }
                      >
                        Select projects
                      </div>
                    </li>
                    <li className="nav-item" key="sendverification">
                      <div
                        className={
                          "nav-link text-dark " +
                          (tabSendverification ? "active " : "")
                        }
                      >
                        Review & approve
                      </div>
                    </li>
                  </ul>
                </div>
              </div> */}
            </PanelHeader>

            <PanelBody className="p-0 m-0">
             
                <label for="error1">{errordisplay}</label>
                <GenericListTable
                  gcolumns={sourceProjectListColumns}
                  gdata={
                    sourceProjectList.length > 0
                      ? JSON.stringify(sourceProjectList)
                      : ""
                  }
                  ghiddencolumns={hiddenSourceProjectListColumns}
                  gplaceholder={"Search pending creation list.."}
                  gsearchDisabled={false}
                  filterDisabled={true}
                />
                <div className="text-center">
                  {noRecord && (
                    <span className="text-danger fw-bold">
                      {appConstants.import.handlefieldvalidation.nodata}
                    </span>
                  )}
                </div>
              
            </PanelBody>
          </Panel>
        </div>
      </div>
      <div className={"tab-pane fade " + (tabSendverification ? "show active " : "")}>
      <div className="stepWrapper  justify-content-around">
          <div className={"stepBlock " + (tabProcoreProjects ? "selected " : "")}>
            <div className="circleWrapper">
              <div className="wizardcircle d-flex align-item-center justify-content-center">
                1
              </div>
            </div>
            <span className="me-5 d-flex justify-content-center">
              Select project
            </span>
          </div>
          <div className={"stepBlock " + (tabSendverification ? "selected " : "")}>
            <div className="circleWrapper d-flex align-item-center justify-content-center">
              <div className="wizardcircle d-flex align-item-center justify-content-center">
                2
              </div>
            </div>
            <span className="me-5">Review and Approve</span>
          </div>
        </div>
        <div className="col-xl-12">
          <Panel>
            <PanelHeader noButton={true}>
              {/* <div className="d-flex align-items-center">
                <div>
                  <ul className="profile-header-tab nav nav-tabs">
                    <li className="nav-item me-2" key="commandpostproject">
                      <div
                        className={
                          "nav-link text-dark " +
                          (tabProcoreProjects ? "active " : "")
                        }
                      >
                        Select projects
                      </div>
                    </li>
                    <li className="nav-item" key="sendverification">
                      <div
                        className={
                          "nav-link text-dark " +
                          (tabSendverification ? "active " : "")
                        }
                      >
                        Review & approve
                      </div>
                    </li>
                  </ul>
                </div>
              </div> */}
            </PanelHeader>
            <PanelBody className="p-0 m-2">
              {" "}
              <GenericListTable
                gcolumns={approveProjectListColumns}
                gdata={
                  sourceProjectList.length > 0
                    ? JSON.stringify(
                        sourceProjectList.filter((row) => row.selected === true)
                      )
                    : ""
                }
                ghiddencolumns={hiddenSourceProjectListColumns}
                gplaceholder={"Search pending create list.."}
                gsearchDisabled={false}
                filterDisabled={true}
              />
            </PanelBody>
          </Panel>
        
        </div>
      </div>

      <div className="footer  m-3  d-flex justify-content-between">
      {tabSendverification && (
        <button
        className="btn btn-sm me-2"
        disabled={page === 0}
        onClick={(e) => {
          Stepage((currPage) => currPage - 1);
          if (page) showTab(FormTitles[page - 1]);
        }}
        >
        {"<< Prev"}
        </button>
      )}
      {tabProcoreProjects && (
        <button
        className="btn btn-sm"
        disabled={
          page === FormTitles.length - 1
            ? true
            : false ||
              sourceProjectList.filter((row) => row.selected === true)
                .length === 0
            ? true
            : false
        }
        onClick={(e) => {
          Stepage((currPage) => currPage + 1);
          showTab(FormTitles[page + 1]);
        }}
        >
        {"Next >>"}
        </button>
        )}
        {tabSendverification && (
             <div className="">
            
            <button
              className="btn btn-sm me-2 "
              disabled={approveDisiable}
              onClick={(e) => {
                approveclick(e, "approve");
              }}
            >
              {"Approve"}
            </button>
            <button
               className="btn btn-sm me-2"
              disabled={approveDisiable}
              onClick={(e) => {
                approveclick(e, "reject");
              }}
            >
              {"Reject"}
            </button>
          </div>
        )}
      </div>
      {isCPDestinationListModelWindow && (
        <Modal
          scrollable={true}
          show={isCPDestinationListModelWindow}
          onHide={(e) => handleClose(e, "isCPModalClose")}
          size="lg"
          centered
        >
          <Modal.Header className="fw-bold h4">
            Commandpost projects
            <a
              style={{ "text-decoration": "none" }}
              onClick={(e) => handleClose(e, "isCPModalClose")}
              className="col d-flex justify-content-end text-dark"
            >
              <i class="fa-solid fa-xmark"></i>
            </a>
          </Modal.Header>
          <Modal.Body>
            <GenericListTable
              gcolumns={CPDestinationProjectListColumns}
              gdata={
                cpdestinationProjectList.length > 0
                  ? JSON.stringify(cpdestinationProjectList)
                  : ""
              }
              ghiddencolumns={hiddenCPDestinationProjectListColumns}
              gplaceholder={"Search project list.."}
              gsearchDisabled={false}
              filterDisabled={true}
              gdisablePagination={cpdestinationProjectList.length > 10?false:true}
            />
          </Modal.Body>
          <Modal.Footer>
            <div className=" d-flex justify-content-between mt-1 col-sm-12 col-md-12 col-lg-12">
              <button
                className=" btn btn-primary me-2 "
                onClick={(e) => handleClose(e, "isCPModalClose")}
              >
                Close
              </button>
              <span className="text-danger">{errorAlertMsg}</span>
              <button
                className="btn btn-primary me-2 "
                // disabled={!isvaliddata}
                onClick={handleChangeDestination_MatchedRecordClick}
              >
                Matched record
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      )}
      {isProDestinationListModelWindow && (
        <Modal
          scrollable={true}
          show={isProDestinationListModelWindow}
          onHide={(e) => handleClose(e, "isProModalClose")}
          size="lg"
          centered
        >
          <Modal.Header className="fw-bold h4">
            Procore projects
            <a
              style={{ "text-decoration": "none" }}
              onClick={(e) => handleClose(e, "isProModalClose")}
              className="col d-flex justify-content-end text-dark"
            >
              <i class="fa-solid fa-xmark"></i>
            </a>
          </Modal.Header>
          <Modal.Body>
            <GenericListTable
              gcolumns={proDestinationProjectListColumns}
              gdata={
                prodestinationProjectList.length > 0
                  ? JSON.stringify(prodestinationProjectList)
                  : ""
              }
              ghiddencolumns={hiddenProDestinationProjectListColumns}
              gplaceholder={"Search project list.."}
              gsearchDisabled={false}
              filterDisabled={true}
              gdisablePagination={prodestinationProjectList.length > 10?false:true}
            />
          </Modal.Body>
          <Modal.Footer>
            <div className=" d-flex justify-content-between mt-1 col-sm-12 col-md-12 col-lg-12">
              <button
                className=" btn btn-primary me-2 "
                onClick={(e) => handleClose(e, "isProModalClose")}
              >
                Close
              </button>
              <span className="text-danger">{errorAlertMsg}</span>

              <button
                className="btn btn-primary me-2 "
                // disabled={!isvaliddata}
                onClick={handleChangeDestination_MatchedRecordClick}
              >
                Matched record
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      )}
      {isMessage && (
        <SweetAlert
          info
          style={{ color: "black" }}
          title={msgTitle}
          onConfirm={handleMessage}
        >
          {msgInfo}
        </SweetAlert>
      )}
      {errorAlert && (
        <SweetAlert
          danger
          style={{ color: "black" }}
          title={msgTitle}
          onConfirm={(e) => {
            setErrorAlert(false);
            setErrorAlertMsg("");
            setMsgTitle("");
          }}
        >
          {errorAlertMsg}
        </SweetAlert>
      )}
      {isApprove && (
        <SweetAlert
          success
          style={{ color: "black" }}
          title=""
          onConfirm={() => {
            setIsapprove(false);
            setmsgInfo("");
            window.location.href = "/projectlist";
          }}
        >
          {msgInfo}
        </SweetAlert>
      )}
      <LoadingDialog
        isOpen={loadDialogOpen}
        onClose={() => setloadDialogOpen(false)}
      />
    </div>
  );
}

export default PendingCreationList;
