import React from "react";
function LOBAppShortLogoCards(arg) {
  var { projectDetail, connectedAppList } = arg;

  return (
    <span>
      <div
        style={{
          display: "inline-flex",
        }}
      >
        {projectDetail?.linkedApplicationArr?.map((app) => {
          if (app.appName.toLowerCase() !== "projectmaster") {
            if (connectedAppList && connectedAppList.length > 0) {
              let filteredapp = connectedAppList.filter(
                (val) => val.appname === app.appName
              );
              if (filteredapp && filteredapp.length > 0) {
                return (
                  <div className="">
                    {app.appName.toLowerCase() === "commandpost" &&
                      app.projectID !== "" && (
                        <div>
                          <a
                            href={
                              app?.appurl +
                              `project-details/${app?.projectID}/timeline`
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <div
                              className="circle text-dark"
                              style={{
                                "background-color": app.shortnamecolor,
                              }}
                            >
                              <span className="circle__content">
                                {app?.shortname}
                              </span>
                            </div>
                          </a>
                        </div>
                      )}
                    {app?.appName?.toLowerCase() === "sharepoint" && (
                      <div>
                        <a
                          href={app?.appurl + "/" + app?.sitename}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <div
                            className="circle text-dark"
                            style={{
                              "background-color": app.shortnamecolor,
                            }}
                          >
                            <span className="circle__content">
                              {app?.shortname}
                            </span>
                          </div>
                        </a>
                      </div>
                    )}
                    {app?.appName?.toLowerCase() === "procore" &&
                      app.projectID !== "" && (
                        <div>
                          <a
                            href={
                              app?.appurl + app?.projectID + "/project/home"
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <div
                              className="circle text-dark"
                              style={{
                                "background-color": app.shortnamecolor,
                              }}
                            >
                              <span className="circle__content">
                                {app?.shortname}
                              </span>
                            </div>
                          </a>
                        </div>
                      )}
                    {app?.appName?.toLowerCase() === "quickbooks" &&
                      app.projectID !== "" && (
                        <div>
                          <a
                            href={
                              app?.appurl +
                              "app/customerdetail?nameId=" +
                              app.projectID
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <div
                              className="circle text-dark"
                              style={{
                                "background-color": app.shortnamecolor,
                              }}
                            >
                              <span className="circle__content">
                                {app?.shortname}
                              </span>
                            </div>
                          </a>
                        </div>
                      )}
                  </div>
                );
              }
            }
          }
        })}
      </div>
    </span>
  );
}
export default LOBAppShortLogoCards;
