export const getPendingCreationTask = async (Ctechapp, loggedin_by) => {
  try {
    const data = await Ctechapp.functions.getPendingCreationTask(loggedin_by);
    return data;
  } catch (error) {
    console.log("getPendingCreationTask services error : ", error);
    throw Error(error);
  }
};

//getPendingCreationTaskByID
export const getPendingCreationTaskByID = async (id, Ctechapp, loggedin_by) => {
  try {
    console.log("getPendingCreationTaskByID Services", id, loggedin_by);
    const data = await Ctechapp.functions.getPendingCreationTaskByID(
      id,
      loggedin_by
    );
    if (data !== undefined) {
      //console.log("data of getapplistbyuserid :" + JSON.stringify(data));
      return data;
    }
  } catch (error) {
    return error.response.data.message;
  }
};

//updatePendingCreateTaskByBatchStatus
export const updatePendingCreateTaskByBatchStatus = async (
  payload,
  status,
  Ctechapp,
  loggedin_by
) => {
  try {
    const data = await Ctechapp.functions.updatePendingCreateTaskByBatchStatus(
      payload,
      status,
      loggedin_by
    );
    if (data !== undefined) {
      return JSON.stringify(data);
    }
  } catch (error) {
    return error;
  }
};
