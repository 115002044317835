import { useParams } from "react-router";
import * as Realm from "realm-web";

export default function VerificationMsg() {
  const { requestcontact, affname } = useParams();
  console.log("test");
  var message =
    "Thank you for verifying your information for " +
    requestcontact +
    " from " +
    affname;
  console.log(message);
  return <div className="text-center bg-green">{message}</div>;
}
