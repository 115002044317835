import { useEffect, useState, useMemo } from "react";
import { useRealmApp } from "../../providers/RealmApp";
import { Button, Modal } from "react-bootstrap";
import { useAuth0 } from "@auth0/auth0-react";
import SweetAlert from "react-bootstrap-sweetalert";
import { getuserbyauthproviderid } from "../../services/users_services";
import Select from "react-select";
import GenericListTable from "../../components/gridTable/genericLlistTable";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router";
import Moment from "moment";
import customstyles from "../../components/customstyles/react-select-styles";
import { appConstants } from "../../constants/app_constants";
import {
  getAppMasterLOBApps,
  getAppMasterLOBByID,
  insertAppMasterLOB,
  updateAppMasterLOBByID,
  deleteAppMasterLOBByID,
} from "../../services/lob_app_info_services";
function LOBInfo() {
  const [list, setList] = useState([]);
  const ctechapp = useRealmApp();
  const { isLoading, error, isAuthenticated, user } = useAuth0();
  const [rowToDelete, setRowToDelete] = useState();
  const [isLobInfo, setIsLobInfo] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isDeleteApp, setIsDeleteApp] = useState(false);
  const [isAlreadyExist, setIsAlreadyExist] = useState(false);
  const [displayMsg, setDisplayMsg] = useState();
  const [isDeleted, setIsDeleted] = useState(false);
  const [isDetailLobInfo, setIsDetailLobInfo] = useState(false);
  const [userinfo, setUserinfo] = useState({
    firstname: "",
    lastname: "",
    email: "",
    role: "",
  });

  const initiallobstate = {
    appname: "",
    appenv: "",
    apphostname: "",
    apptokenhostname: "",
    appredirecturl: "",
    appclientid: "",
    appclientsecret: "",
    applogo: "",
    appsmalllogo: "",
    tenantid: "",
    tenantname: "",
    siteid: "",
    sitename: "",
    driveid: "",
    apiversion: "",
    appurl: "",
    isconnect: "false",
    isendpoint: "false",
    shortname: "",
    shortnamecolor: "#e9ecef",
    isactive: "1",
  };
  const [lobdetailinfo, setlobdetailinfo] = useState();
  //state variables

  const selectAppEnvOptions = [
    { value: "Sandbox", label: "Sandbox" },
    { value: "Production", label: "Production" },
  ];

  //table functions
  const data = useMemo(() => [...list], [list]);
  const appColumns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "_id",
        sortable: true,
        width: "20%",
        Filter: "",
        filter: "",
      },
      {
        Header: "App name",
        accessor: "appname",
        sortable: true,
        width: "20%",
      },
      {
        Header: "App environment",
        accessor: "appenv",
        sortable: true,
        width: "20%",
        Filter: "",
        filter: "",
        // Filter: DefaultColumnFilter,connectedAppList
        // filter: 'value',
      },
      {
        Header: "App host name",
        accessor: "apphostname",
        sortable: true,
        width: "20%",
        Filter: "",
        filter: "",
        // Filter: DefaultColumnFilter,
        // filter: 'value',
      },
      {
        Header: "App token host name",
        accessor: "apptokenhostname",
        sortable: true,
        width: "30%",
      },
      {
        Header: "Action",

        Cell: ({ row }) => {
          const onViewItemClick = () => {
            handleViewClickApps(row);
          };
          const onEditItemClick = () => {
            handleEditClickApps(row);
          };
          const onDeleteItemClick = () => {
            handleDeleteClickApps(row);
          };
          return (
            <div className="d-flex gap-4">
              <Button className="btn btn-sm " onClick={onViewItemClick}>
                {/* <i className="fas fa-2x  fa-pencil"></i> */}
                View
              </Button>
              <Button className="btn btn-sm " onClick={onEditItemClick}>
                {/* <i className="fas fa-2x  fa-pencil"></i> */}
                Edit
              </Button>
              <Button className="btn btn-sm " onClick={onDeleteItemClick}>
                {/* <i className="fas fa-2x  fa-trash-can"></i> */}
                Delete
              </Button>
            </div>
          );
        },
      },
    ],
    []
  );

  const hiddenappColumns = ["_id"];

  useEffect(() => {
    if (isAuthenticated) {
      getUserInfoByID();
      //  getData();
    }
  }, []);

  async function getUserInfoByID() {
    try {
      getuserbyauthproviderid(user.sub, ctechapp.currentUser).then(
        (response) => {
          let user_info = JSON.parse(response);
          if (response !== undefined || user_info?.length !== 0) {
            setUserinfo(user_info[0]);
            GetAppMasterLOBApps();
          }
        }
      );
    } catch (exception) {
      console.error("getuserbyauthproviderid Error", exception);
    }
  }
  async function GetAppMasterLOBApps() {
    try {
      getAppMasterLOBApps(ctechapp.currentUser, { isactive: "1" }).then(
        (response) => {
          let lob_info = JSON.parse(response);
          console.log("user_info", lob_info);
          setList(lob_info);
        }
      );
    } catch (exception) {
      console.error("getuserbyauthproviderid Error", exception);
    }
  }
  async function GetAppMasterLOBByID(id) {
    try {
      console.log("id", id);
      getAppMasterLOBByID(ctechapp.currentUser, id, userinfo).then(
        (response) => {
          let lob_info = JSON.parse(response);
          console.log("lob_info", lob_info);
          setlobdetailinfo(lob_info);
        }
      );
    } catch (exception) {
      console.error("getuserbyauthproviderid Error", exception);
    }
  }
  async function UpdateAppMasterLOBByID() {
    try {
      updateAppMasterLOBByID(
        lobdetailinfo,
        ctechapp.currentUser,
        userinfo
      ).then((response) => {
        setDisplayMsg(appConstants.import.alertmsg.lobupdatesucessfull);
        setIsSuccess(true);
      });
    } catch (exception) {
      console.error("getuserbyauthproviderid Error", exception);
    }
  }
  async function InsertAppMasterLOB() {
    try {
      insertAppMasterLOB(lobdetailinfo, ctechapp.currentUser, userinfo).then(
        (response) => {
          GetAppMasterLOBApps();
          setDisplayMsg(appConstants.import.alertmsg.lobinsertsucessfull);
          setIsSuccess(true);
        }
      );
    } catch (exception) {
      console.error("getuserbyauthproviderid Error", exception);
    }
  }
  function ondelete(id) {
    try {
      deleteAppMasterLOBByID(ctechapp.currentUser, id, user.sub).then(
        (response) => {
          if (response !== undefined) {
            GetAppMasterLOBApps();
            setIsDeleted(true);
          }
        }
      );
    } catch (error) {
      console.error("deleteProjectByID Error", error);
    }
  }

  const handleViewClickApps = async (row) => {
    await GetAppMasterLOBByID(row.original._id);
    setIsDetailLobInfo(true);
  };
  const handleEditClickApps = (row) => {
    GetAppMasterLOBByID(row.original._id);

    setIsLobInfo(true);
  };
  const handleDeleteClickApps = (row) => {
    setIsDeleteApp(true);
    setRowToDelete(row.original._id);
  };
  const handleConfirmDelete = () => {
    setIsDeleteApp(false);
    ondelete(rowToDelete);
  };
  const handleConfirmDeleted = (row) => {
    setIsDeleted(false);
  };
  const handleCancelDelete = (row) => {
    setIsDeleteApp(false);
  };

  async function AddSubmitHandler() {
    InsertAppMasterLOB();
    // if (validAddForm()) {
    // }
  }
  const validAddForm = () => {
    try {
      let valid = false;
      valid = handleFieldListAddRequiredValidation();
      Object.values().forEach((val) => {
        if (val.length > 0) valid = false;
      });
      //console.log("valid : ", valid);
      return valid;
    } catch (error) {
      return false;
    }
  };

  const handleFieldListAddRequiredValidation = () => {
    let iserror = "";

    return iserror.length === 0;
  };

  const EditSubmitHandler = () => {
    UpdateAppMasterLOBByID();
  };
  const validEditForm = () => {
    try {
      let valid = false;
      valid = handleFieldListEditRequiredValidation();
      Object.values().forEach((val) => {
        if (val.length > 0) valid = false;
      });
      //console.log("valid : ", valid);
      return valid;
    } catch (error) {
      return false;
    }
  };

  const handleFieldListEditRequiredValidation = () => {
    let iserror = "";

    return iserror.length === 0;
  };
  const handleDropDownChange = (selectedOption) => {
    const { name, value } = selectedOption.target;
    let _lobdetailinfo = { ...lobdetailinfo };
    _lobdetailinfo[name] = value;
    setlobdetailinfo(_lobdetailinfo);
  };
  const handleChange = (event) => {
    const path = event.target.name.split(".");
    const finalProp = path.pop();
    const newuser = { ...lobdetailinfo };
    let pointer = newuser;
    path.forEach((el) => {
      pointer[el] = { ...pointer[el] };
      pointer = pointer[el];
    });
    pointer[finalProp] =
      event.target.type === "checkbox"
        ? event.target.checked.toString()
        : event.target.value.toString();

    setlobdetailinfo(newuser);
    console.log("newuser", newuser);
    // handleFieldValidation(event);
  };
  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  const handleFileUpload = async (e, Name) => {
    const file = e.target.files[0];
    // if (file.size > 5242880) {
    //   setUserErrors({
    //     ...userErrors,
    //     ["userImage"]: "Image size should be less than 5mb",
    //   });
    //   return;
    // }
    const base64 = await convertToBase64(file);
    setlobdetailinfo({ ...lobdetailinfo, [Name]: base64 });
  };
  const handleRemoveAppLogo = (name) => {
    console.log("name", name);
    var _lobdetailinfo = { ...lobdetailinfo };
    _lobdetailinfo[name] = "";
    setlobdetailinfo(_lobdetailinfo);
  };
  const handleAddButton = () => {
    setlobdetailinfo(initiallobstate);
    setIsLobInfo(true);
  };
  const handleClose = () => {
    setlobdetailinfo(initiallobstate);
    setIsLobInfo(false);
    setIsDetailLobInfo(false);
    setIsSuccess(false);
  };
  return (
    <div>
      <h3 align="center">LOB app list</h3>
      <div className="d-flex justify-content-end">
        <Button
          onClick={() => {
            handleAddButton();
          }}
          className="btn btn-lg mb-2"
        >
          Add
        </Button>
      </div>
      <GenericListTable
        gcolumns={appColumns}
        gdata={data.length > 0 ? JSON.stringify(data) : ""}
        ghiddencolumns={hiddenappColumns}
        gplaceholder={"Search lob app list.."}
        gsearchDisabled={false}
        filterDisabled={true}
      />

      <div className="p-1"></div>
      {isDeleteApp && (
        <SweetAlert
          warning
          style={{ color: "black" }}
          title=""
          confirmBtnText="Delete"
          cancelBtnText="No"
          showCancel={true}
          onConfirm={handleConfirmDelete}
          onCancel={handleCancelDelete}
        >
          <div>{appConstants.import.confirmationmsg.delete}</div>
        </SweetAlert>
      )}
      {isDeleted && (
        <SweetAlert
          success
          style={{ color: "black" }}
          title=""
          confirmBtnText="Ok"
          onConfirm={handleConfirmDeleted}
        >
          <div>{appConstants.import.alertmsg.appdeletesucessfull}</div>
        </SweetAlert>
      )}
      {isLobInfo && (
        <Modal
          scrollable={true}
          show={isLobInfo}
          onHide={handleClose}
          size="xl"
          centered
        >
          <Modal.Header className="fw-bold h4">
            {lobdetailinfo?._id === undefined
              ? "Add lob info"
              : "Edit lob info"}
            <a
              style={{ "text-decoration": "none" }}
              onClick={handleClose}
              className="col d-flex justify-content-end text-dark"
            >
              <i class="fa-solid fa-xmark"></i>
            </a>
          </Modal.Header>
          <Modal.Body>
            {" "}
            <div className="container p-3">
              <div className="p-1">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="row p-1">
                      <div className="col-sm-12 col-md-4 col-lg-4">
                        App name<label className="text-danger">*</label>
                      </div>

                      <div className="col-sm-12 col-md-4 col-lg-8">
                        <input
                          type="text"
                          name="appname"
                          id="appname"
                          placeholder="App name"
                          className="form-control"
                          value={lobdetailinfo?.appname}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className="row p-1">
                      <div className="col-sm-12 col-md-4 col-lg-4">
                        {" "}
                        App environment<label className="text-danger">*</label>
                      </div>

                      <div className="col-sm-12 col-md-4 col-lg-8">
                        <Select
                          name="appenv"
                          options={selectAppEnvOptions}
                          className="form-control"
                          isSearchable={true}
                          isDisabled={
                            lobdetailinfo?._id === undefined ? false : true
                          }
                          styles={customstyles}
                          value={selectAppEnvOptions?.filter(
                            (record) => record.value === lobdetailinfo?.appenv
                          )}
                          onChange={(val) => {
                            handleDropDownChange({
                              target: {
                                name: "appenv",
                                value: val.value,
                              },
                            });
                          }}
                        ></Select>
                      </div>
                    </div>

                    <div className="row p-1">
                      <div className="col-sm-12 col-md-4 col-lg-4">
                        App host name<label className="text-danger">*</label>
                      </div>

                      <div className="col-sm-12 col-md-4 col-lg-8">
                        <input
                          type="text"
                          name="apphostname"
                          id="apphostname"
                          placeholder="App host name"
                          className="form-control"
                          value={lobdetailinfo?.apphostname}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="row p-1">
                      <div className="col-sm-12 col-md-4 col-lg-4">
                        App token host name
                        <label className="text-danger">*</label>
                      </div>

                      <div className="col-sm-12 col-md-4 col-lg-8">
                        <input
                          type="text"
                          name="apptokenhostname"
                          id="apptokenhostname"
                          placeholder="App token host name"
                          className="form-control"
                          value={lobdetailinfo?.apptokenhostname}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="row p-1">
                      <div className="col-sm-12 col-md-4 col-lg-4">
                        App redirect url<label className="text-danger">*</label>
                      </div>

                      <div className="col-sm-12 col-md-4 col-lg-8">
                        <input
                          type="text"
                          name="appredirecturl"
                          id="appredirecturl"
                          placeholder="App redirect url"
                          className="form-control"
                          value={lobdetailinfo?.appredirecturl}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="row p-1">
                      <div className="col-sm-12 col-md-4 col-lg-4">
                        App clinet id<label className="text-danger">*</label>
                      </div>

                      <div className="col-sm-12 col-md-4 col-lg-8">
                        <input
                          type="text"
                          name="appclientid"
                          id="appclientid"
                          placeholder="App clinet id"
                          className="form-control"
                          value={lobdetailinfo?.appclientid}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="row p-1">
                      <div className="col-sm-12 col-md-4 col-lg-4">
                        App clinet secret
                        <label className="text-danger">*</label>
                      </div>

                      <div className="col-sm-12 col-md-4 col-lg-8">
                        <input
                          type="text"
                          name="appclientsecret"
                          id="appclientsecret"
                          placeholder="App clinet secret"
                          className="form-control"
                          value={lobdetailinfo?.appclientsecret}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="row p-1">
                      <div className="col-sm-12 col-md-4 col-lg-4">
                        Tenant id
                        <label className="text-danger">*</label>
                      </div>

                      <div className="col-sm-12 col-md-4 col-lg-8">
                        <input
                          type="text"
                          name="tenantid"
                          id="tenantid"
                          placeholder="Tenant id"
                          className="form-control"
                          value={lobdetailinfo?.tenantid}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="row p-1">
                      <div className="col-sm-12 col-md-4 col-lg-4">
                        Tenant name
                        <label className="text-danger">*</label>
                      </div>

                      <div className="col-sm-12 col-md-4 col-lg-8">
                        <input
                          type="text"
                          name="tenantname"
                          id="tenantname"
                          placeholder="Tenant name"
                          className="form-control"
                          value={lobdetailinfo?.tenantname}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="row p-1">
                      <div className="col-sm-12 col-md-4 col-lg-4">
                        Site id
                        <label className="text-danger">*</label>
                      </div>

                      <div className="col-sm-12 col-md-4 col-lg-8">
                        <input
                          type="text"
                          name="siteid"
                          id="siteid"
                          placeholder="Site id"
                          className="form-control"
                          value={lobdetailinfo?.siteid}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="row p-1">
                      <div className="col-sm-12 col-md-4 col-lg-4">
                        Site name
                        <label className="text-danger">*</label>
                      </div>

                      <div className="col-sm-12 col-md-4 col-lg-8">
                        <input
                          type="text"
                          name="sitename"
                          id="sitename"
                          placeholder="Site name"
                          className="form-control"
                          value={lobdetailinfo?.sitename}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="row p-1">
                      <div className="col-sm-12 col-md-4 col-lg-4">
                        Drive id
                        <label className="text-danger">*</label>
                      </div>

                      <div className="col-sm-12 col-md-4 col-lg-8">
                        <input
                          type="text"
                          name="driveid"
                          id="driveid"
                          placeholder="Drive id"
                          className="form-control"
                          value={lobdetailinfo?.driveid}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="row p-1">
                      <div className="col-sm-12 col-md-4 col-lg-4">
                        Api version
                        <label className="text-danger">*</label>
                      </div>

                      <div className="col-sm-12 col-md-4 col-lg-8">
                        <input
                          type="text"
                          name="apiversion"
                          id="apiversion"
                          placeholder="Api version"
                          className="form-control"
                          value={lobdetailinfo?.apiversion}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="row p-1">
                      <div className="col-sm-12 col-md-4 col-lg-4">
                        {" "}
                        App logo
                      </div>

                      <div className="d-flex justify-content-start col-sm-12 col-md-4 col-lg-8">
                        <div>
                          <Button
                            style={{ padding: "1px 6px" }}
                            onClick={() => handleRemoveAppLogo("applogo")}
                            className="me-2"
                          >
                            Clear
                          </Button>
                        </div>
                        <div>
                          <input
                            type="file"
                            multiple={false}
                            name="applogo"
                            onChange={(e) => handleFileUpload(e, "applogo")}
                            accept=".jpeg, .png, .jpg"
                          ></input>
                          <img
                            src={
                              lobdetailinfo?.applogo === "" ||
                              lobdetailinfo?.applogo === undefined
                                ? "../assets/img/companyicon.png"
                                : lobdetailinfo?.applogo
                            }
                            className="rounded mx-auto d-block mw-100 mt-2"
                            width="300"
                            height="100"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row p-1">
                      <div className="col-sm-12 col-md-4 col-lg-4">
                        {" "}
                        App small logo
                      </div>

                      <div className="d-flex justify-content-start col-sm-12 col-md-4 col-lg-8">
                        <div>
                          <Button
                            style={{ padding: "1px 6px" }}
                            onClick={() => handleRemoveAppLogo("appsmalllogo")}
                            className="me-2"
                          >
                            Clear
                          </Button>
                        </div>
                        <div>
                          <input
                            type="file"
                            multiple={false}
                            name="appsmalllogo"
                            onChange={(e) =>
                              handleFileUpload(e, "appsmalllogo")
                            }
                            accept=".jpeg, .png, .jpg"
                          ></input>
                          <img
                            src={
                              lobdetailinfo?.appsmalllogo === "" ||
                              lobdetailinfo?.appsmalllogo === undefined
                                ? "../assets/img/companyicon.png"
                                : lobdetailinfo?.appsmalllogo
                            }
                            className="rounded mx-auto d-block mw-100 mt-2"
                            width="300"
                            height="100"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row p-1">
                      <div className="col-sm-12 col-md-4 col-lg-4">
                        App url
                        <label className="text-danger">*</label>
                      </div>

                      <div className="col-sm-12 col-md-4 col-lg-8">
                        <input
                          type="text"
                          name="appurl"
                          id="appurl"
                          placeholder="App url"
                          className="form-control"
                          value={lobdetailinfo?.appurl}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="row p-1">
                      <div className="col-sm-12 col-md-4 col-lg-4">
                        Is connect
                        <label className="text-danger">*</label>
                      </div>

                      <div className="col-sm-12 col-md-4 col-lg-8">
                        <input
                          type="checkbox"
                          name="isconnect"
                          id="isconnect"
                          placeholder="App url"
                          class="form-check-input"
                          checked={
                            lobdetailinfo?.isconnect === "true" ? true : false
                          }
                          //value={lobdetailinfo?.isconnect}
                          onChange={handleChange}
                        />
                        <span>
                          {lobdetailinfo?.isconnect === "true"
                            ? "  True"
                            : "  False"}
                        </span>
                      </div>
                    </div>
                    <div className="row p-1">
                      <div className="col-sm-12 col-md-4 col-lg-4">
                        Is endpoint
                        <label className="text-danger">*</label>
                      </div>

                      <div className="col-sm-12 col-md-4 col-lg-8">
                        <input
                          type="checkbox"
                          name="isendpoint"
                          id="isendpoint"
                          placeholder="App url"
                          class="form-check-input"
                          checked={
                            lobdetailinfo?.isendpoint === "true" ? true : false
                          }
                          // value={lobdetailinfo?.isendpoint}
                          onChange={handleChange}
                        />
                        <span>
                          {lobdetailinfo?.isendpoint === "true"
                            ? "  True"
                            : "  False"}
                        </span>
                      </div>
                    </div>
                    <div className="row p-1">
                      <div className="col-sm-12 col-md-4 col-lg-4">
                        Short name
                        <label className="text-danger">*</label>
                      </div>

                      <div className="col-sm-12 col-md-4 col-lg-8">
                        <input
                          type="text"
                          name="shortname"
                          id="shortname"
                          placeholder="Short name"
                          className="form-control"
                          value={lobdetailinfo?.shortname}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div></div>
              {isSuccess && (
                <SweetAlert
                  success
                  style={{ color: "black" }}
                  title={displayMsg}
                  onConfirm={handleClose}
                ></SweetAlert>
              )}
              {isAlreadyExist && (
                <SweetAlert
                  warning
                  style={{ color: "black" }}
                  title={displayMsg}
                  onConfirm={() => {
                    setIsAlreadyExist(false);
                    setDisplayMsg("");
                  }}
                ></SweetAlert>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            {" "}
            <div className="row p-1">
              {" "}
              <div className=" d-flex justify-content-end mt-3 col-sm-12 col-md-12 col-lg-12">
                <button
                  className=" btn btn-primary me-2 "
                  onClick={handleClose}
                >
                  Close
                </button>
                {lobdetailinfo?._id === undefined ? (
                  <button
                    className="btn btn-primary me-2 "
                    // disabled={!isvaliddata}
                    onClick={AddSubmitHandler}
                  >
                    Save
                  </button>
                ) : (
                  <button
                    className="btn btn-primary me-2 "
                    // disabled={!isvaliddata}
                    onClick={() => EditSubmitHandler()}
                  >
                    Update
                  </button>
                )}
              </div>
            </div>
          </Modal.Footer>
        </Modal>
      )}
      {isDetailLobInfo && (
        <Modal
          scrollable={true}
          show={isDetailLobInfo}
          onHide={handleClose}
          size="xl"
          centered
        >
          <Modal.Header className="fw-bold h4">
            Detail lob info
            <a
              style={{ "text-decoration": "none" }}
              onClick={handleClose}
              className="col d-flex justify-content-end text-dark"
            >
              <i class="fa-solid fa-xmark"></i>
            </a>
          </Modal.Header>
          <Modal.Body>
            {" "}
            <div className="container">
              <div className="">
                <div className="row">
                  <div className="col-lg-12">
                    <div className=" row">
                      <div className=" row p-1">
                        <div className="col-sm-12 col-md-4 col-lg-2">
                          <img
                            src={
                              lobdetailinfo?.applogo === "" ||
                              lobdetailinfo?.applogo === undefined
                                ? "../assets/img/companyicon.png"
                                : lobdetailinfo?.applogo
                            }
                            className="rounded mx-auto d-block mw-100 mt-2"
                            width="300"
                            height="100"
                          />
                        </div>
                        <div className="col-sm-12 col-md-4 col-lg-4 ">
                          <span className="h4 fw-bold d-flex justify-content-start mt-4">
                            {lobdetailinfo?.appname}
                          </span>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-4 col-lg-8">
                        {lobdetailinfo?.appenv !== undefined &&
                          lobdetailinfo?.appenv !== "" && (
                            <div className="row p-1">
                              <div className="col-sm-12 col-md-4 col-lg-4 fw-bolder">
                                {" "}
                                App environment
                              </div>
                              <div className="col-sm-12 col-md-4 col-lg-6">
                                {lobdetailinfo?.appenv}
                              </div>
                            </div>
                          )}
                        {lobdetailinfo?.apphostname !== undefined &&
                          lobdetailinfo?.apphostname !== "" && (
                            <div className="row p-1">
                              <div className="col-sm-12 col-md-4 col-lg-4 fw-bolder">
                                {" "}
                                App host name
                              </div>
                              <div className="col-sm-12 col-md-4 col-lg-6">
                                {lobdetailinfo?.apphostname}
                              </div>
                            </div>
                          )}
                        {lobdetailinfo?.apptokenhostname !== undefined &&
                          lobdetailinfo?.apptokenhostname !== "" && (
                            <div className="row p-1">
                              <div className="col-sm-12 col-md-4 col-lg-4 fw-bolder">
                                {" "}
                                App token host name
                              </div>
                              <div className="col-sm-12 col-md-4 col-lg-6">
                                {lobdetailinfo?.apptokenhostname}
                              </div>
                            </div>
                          )}
                        {lobdetailinfo?.appredirecturl !== undefined &&
                          lobdetailinfo?.appredirecturl !== "" && (
                            <div className="row p-1">
                              <div className="col-sm-12 col-md-4 col-lg-4 fw-bolder">
                                {" "}
                                App redirect url
                              </div>
                              <div className="col-sm-12 col-md-4 col-lg-6">
                                {lobdetailinfo?.appredirecturl}
                              </div>
                            </div>
                          )}
                        {lobdetailinfo?.appredirecturl !== undefined &&
                          lobdetailinfo?.appredirecturl !== "" && (
                            <div className="row p-1">
                              <div className="col-sm-12 col-md-4 col-lg-4 fw-bolder">
                                {" "}
                                App redirect url
                              </div>
                              <div className="col-sm-12 col-md-4 col-lg-6">
                                {lobdetailinfo?.appredirecturl}
                              </div>
                            </div>
                          )}
                        {lobdetailinfo?.appclientid !== undefined &&
                          lobdetailinfo?.appclientid !== "" && (
                            <div className="row p-1">
                              <div className="col-sm-12 col-md-4 col-lg-4 fw-bolder">
                                {" "}
                                App client id
                              </div>
                              <div className="col-sm-12 col-md-4 col-lg-6">
                                {lobdetailinfo?.appclientid}
                              </div>
                            </div>
                          )}
                        {lobdetailinfo?.appclientsecret !== undefined &&
                          lobdetailinfo?.appclientsecret !== "" && (
                            <div className="row p-1">
                              <div className="col-sm-12 col-md-4 col-lg-4 fw-bolder">
                                {" "}
                                App client secret
                              </div>
                              <div className="col-sm-12 col-md-4 col-lg-6">
                                {lobdetailinfo?.appclientsecret}
                              </div>
                            </div>
                          )}
                        {lobdetailinfo?.tenantid !== undefined &&
                          lobdetailinfo?.tenantid !== "" && (
                            <div className="row p-1">
                              <div className="col-sm-12 col-md-4 col-lg-4 fw-bolder">
                                {" "}
                                Tenant id
                              </div>
                              <div className="col-sm-12 col-md-4 col-lg-6">
                                {lobdetailinfo?.tenantid}
                              </div>
                            </div>
                          )}
                        {lobdetailinfo?.tenantname !== undefined &&
                          lobdetailinfo?.tenantname !== "" && (
                            <div className="row p-1">
                              <div className="col-sm-12 col-md-4 col-lg-4 fw-bolder">
                                {" "}
                                Tenant name
                              </div>
                              <div className="col-sm-12 col-md-4 col-lg-6">
                                {lobdetailinfo?.tenantname}
                              </div>
                            </div>
                          )}
                        {lobdetailinfo?.siteid !== undefined &&
                          lobdetailinfo?.siteid !== "" && (
                            <div className="row p-1">
                              <div className="col-sm-12 col-md-4 col-lg-4 fw-bolder">
                                {" "}
                                Site id
                              </div>
                              <div className="col-sm-12 col-md-4 col-lg-6">
                                {lobdetailinfo?.siteid}
                              </div>
                            </div>
                          )}
                        {lobdetailinfo?.sitename !== undefined &&
                          lobdetailinfo?.sitename !== "" && (
                            <div className="row p-1">
                              <div className="col-sm-12 col-md-4 col-lg-4 fw-bolder">
                                {" "}
                                Site name
                              </div>
                              <div className="col-sm-12 col-md-4 col-lg-6">
                                {lobdetailinfo?.sitename}
                              </div>
                            </div>
                          )}
                        {lobdetailinfo?.driveid !== undefined &&
                          lobdetailinfo?.driveid !== "" && (
                            <div className="row p-1">
                              <div className="col-sm-12 col-md-4 col-lg-4 fw-bolder">
                                {" "}
                                Drive id
                              </div>
                              <div className="col-sm-12 col-md-4 col-lg-6">
                                {lobdetailinfo?.driveid}
                              </div>
                            </div>
                          )}
                        {lobdetailinfo?.apiversion !== undefined &&
                          lobdetailinfo?.apiversion !== "" && (
                            <div className="row p-1">
                              <div className="col-sm-12 col-md-4 col-lg-4 fw-bolder">
                                {" "}
                                API version
                              </div>
                              <div className="col-sm-12 col-md-4 col-lg-6">
                                {lobdetailinfo?.apiversion}
                              </div>
                            </div>
                          )}
                        {lobdetailinfo?.appurl !== undefined &&
                          lobdetailinfo?.appurl !== "" && (
                            <div className="row p-1">
                              <div className="col-sm-12 col-md-4 col-lg-4 fw-bolder">
                                {" "}
                                App url
                              </div>
                              <div className="col-sm-12 col-md-4 col-lg-6">
                                {lobdetailinfo?.appurl}
                              </div>
                            </div>
                          )}
                        {lobdetailinfo?.shortname !== undefined &&
                          lobdetailinfo?.shortname !== "" && (
                            <div className="row p-1">
                              <div className="col-sm-12 col-md-4 col-lg-4 fw-bolder">
                                {" "}
                                Short name
                              </div>
                              <div className="col-sm-12 col-md-4 col-lg-6">
                                {lobdetailinfo?.shortname}
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {" "}
            <div className="row p-1">
              {" "}
              <div className=" d-flex justify-content-end mt-3 col-sm-12 col-md-12 col-lg-12">
                <button
                  className=" btn btn-primary me-2 "
                  onClick={handleClose}
                >
                  Close
                </button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
}

export default LOBInfo;
