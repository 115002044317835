import { useEffect, useState, useMemo } from "react";
import { Button } from "react-bootstrap";
import { appConstants } from "../../constants/app_constants";
import Moment from "moment";
import GenericListTable from "../../components/gridTable/genericLlistTable";
import Select from "react-select";
import { useRealmApp } from "../../providers/RealmApp";
import { useAuth0 } from "@auth0/auth0-react";
import customstyles from "../../components/customstyles/react-select-styles";
import {
  getuserbyauthproviderid,
  getUserFilter,
} from "../../services/users_services";
import { getEventHistory } from "../../services/event_history_services";
import { getAppMasterApplicationByFilter } from "../../services/applications_services";
function EventHistory(arg) {
  const ctechapp = useRealmApp();
  const { isLoading, error, isAuthenticated, user } = useAuth0();
  var [companyList, setCompanyList] = useState([]);
  var [appInfoList, setAppInfoList] = useState([]);
  var [adminUserList, setAdminUserList] = useState([]);
  var [filterAppInfoList, setFilterAppInfo] = useState([]);
  var [eventHistoryList, setEventHistoryList] = useState([]);
  const [loading, setLoading] = useState(false);

  const [userinfo, setUserinfo] = useState({
    role: "",
  });
  const statusOptions = [
    {
      label: "All",
      value: "all",
    },
    {
      label: "Success",
      value: "success",
    },
    {
      label: "Fail",
      value: "fail",
    },
  ];
  var _fromDate = new Date(new Date().setDate(new Date().getDate() - 30));
  const [collectionInfo, setCollectionInfo] = useState({
    collectionName: "",
    adminUserId: "",
    companyId: "",

    status: "fail",
    fromDate: Moment(_fromDate).format("YYYY-MM-DD"),
    toDate: Moment().format("YYYY-MM-DD"),
  });
  const [errors, setErrors] = useState({
    collectionName: "",
    adminUserId: "",
    companyId: "",
    status: "",
    fromDate: "",
    toDate: "",
  });

  const data = useMemo(() => [...eventHistoryList], [eventHistoryList]);

  const appColumns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "_id",
        sortable: true,
        width: "20%",
        filter: "",
        Filter: "",
        // Cell: ({ value, row }) => {
        //   return <span>{row.original.project_info.projectNumber}</span>;
        // },
      },
      {
        Header: "Created On",
        accessor: "createdOn",
        sortable: true,
        width: "30%",
        Filter: "",
        filter: "",
        Cell: ({ value }) => Moment(value).format(appConstants.DATEFORMAT),
      },

      {
        Header: "Status",
        accessor: "status",
        sortable: true,
        width: "30%",
        Filter: "",
        filter: "",
        // Cell: ({ value, row }) => {
        //   return <span>{row.original.project_info.projectName}</span>;
        // },
      },
      {
        Header: "Error",
        accessor: "error",
        sortable: true,
        width: "30%",
        Filter: "",
        filter: "",
        // Cell: ({ value, row }) => {
        //   return <span>{row.original.project_info.altProjectNumber}</span>;
        // },
      },
    ],
    []
  );

  const hiddenappColumns = [""];

  useEffect(() => {
    if (isAuthenticated) {
      getUserInfoByID();

      async function getUserInfoByID() {
        try {
          setLoading(true);
          getuserbyauthproviderid(user.sub, ctechapp.currentUser).then(
            (response) => {
              let project_info = JSON.parse(response);
              if (response !== undefined || project_info.length !== 0) {
                setUserinfo(project_info[0]);
                console.log(project_info[0].userid);
                //  GetEventHistoryLookUp(project_info[0]);
                GetAppMasterApplicationByFilter(project_info[0]);
              }
            }
          );
        } catch (exception) {
          setLoading(false);
          console.log(exception);
        }
      }
      async function GetAppMasterApplicationByFilter(userinfo) {
        let arg = {
          isactive: "1",
          sourceapp: "PM",
        };
        getAppMasterApplicationByFilter(arg, "", ctechapp.currentUser).then(
          (response) => {
            try {
              if (response && response.length > 0) {
                let appinfo = response;
                let userid = [];
                appinfo.map((val) => {
                  if (
                    val.appname.toLowerCase() !== "projectmaster" &&
                    val.appuserinfo[0].userid &&
                    !userid.includes(val.appuserinfo[0].userid)
                  ) {
                    userid.push(val.appuserinfo[0].userid);
                  }
                });
                console.log("userid", userid);
                let query = {
                  userid: { $in: userid },
                  isactive: "1",
                };
                let project = {
                  email: 1,
                  userid: 1,
                };
                let sort = {
                  email: -1,
                };
                let limit = {};

                GetUserFilter(query, project, sort, limit);
              }
            } catch (err) {
              console.log("getAppMasterApplicationByFilter ", err);
            }
          }
        );
      }
      async function GetUserFilter(query, project, sort, limit) {
        getUserFilter(query, project, sort, limit, ctechapp.currentUser).then(
          (response) => {
            try {
              if (response && response.length > 0) {
                let userinfo = response;
                console.log("userinfo", userinfo);
                let _obj = {
                  label: "All",
                  value: "all",
                };
                let options = [_obj];
                userinfo.map((val) => {
                  let obj = {
                    label: val.email,
                    value: val.userid,
                  };
                  options.push(obj);
                });
                console.log(options);
                setAdminUserList(options);
              }
            } catch (err) {
              console.log("getAppMasterApplicationByFilter ", err);
            }
          }
        );
      }
    }
  }, []);
  async function GetEventHistoryLookUp() {
    getEventHistory(
      "getEventHistoryCollectionLookUp",

      "",
      "",
      ctechapp.currentUser
    ).then((response) => {
      try {
        if (response && response.statusCode === 200) {
          setCompanyList(response.data);
        }
      } catch (exception) {}
    });
  }
  async function GetAppMasterApplicationByFilterByUserID(id, collectionInfo) {
    let arg = {
      "appuserinfo.userid": id,
      isactive: "1",
      sourceapp: "PM",
    };
    getAppMasterApplicationByFilter(arg, "", ctechapp.currentUser).then(
      (response) => {
        try {
          if (response && response.length > 0) {
            let appinfo = response;
            let options = [];
            appinfo.map((val) => {
              let obj = {
                label: val.appuserinfo[0].appdisplayname,
                value: val.appaccountid,
                appname: val.appname,
              };
              options.push(obj);
            });
            if (collectionInfo.collectionName) {
              let _options = [];
              options.map((row) => {
                if (
                  collectionInfo.collectionName.includes(
                    row.appname.toLowerCase()
                  )
                ) {
                  setCollectionInfo({ ...collectionInfo, ["companyId"]: "" });
                  console.log("filter app info", row);
                  _options.push(row);
                }
              });
              setFilterAppInfo(_options);
            }
            setAppInfoList(options);
          }
        } catch (err) {
          console.log("getAppMasterApplicationByFilter ", err);
        }
      }
    );
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      getEventHistoryData(collectionInfo);
    }
  };
  const validateForm = () => {
    try {
      let valid = false;
      valid = handleFieldRequiredValidation();
      Object.values(errors).forEach((val) => {
        if (val.length > 0) valid = false;
      });
      // console.log(valid);
      return valid;
    } catch (error) {
      return false;
    }
  };
  const handleFieldRequiredValidation = () => {
    let iserror = "";

    setErrors({
      ...errors,
      collectionName:
        collectionInfo.collectionName === ""
          ? (iserror = "Collection Name is required")
          : errors.collectionName,
      companyId:
        collectionInfo.companyId === ""
          ? (iserror = "App Display Name is required")
          : errors.companyId,
      adminUserId:
        collectionInfo.adminUserId === ""
          ? (iserror = "Admin User is required")
          : errors.adminUserId,

      status:
        collectionInfo.status === ""
          ? (iserror = "Status is required")
          : errors.status,
      fromDate:
        collectionInfo.fromDate === ""
          ? (iserror = "From Date is required")
          : errors.fromDate,
      toDate:
        collectionInfo.toDate === ""
          ? (iserror = "To Date is required")
          : errors.toDate,
    });
    // console.log(iserror);
    return iserror.length === 0;
  };

  async function getEventHistoryData(data) {
    getEventHistory(
      "getEventHistoryList",
      data,
      userinfo.userid,
      ctechapp.currentUser
    ).then((response) => {
      if (response && response.statusCode === 200) {
        console.log("response", response.data);
        setEventHistoryList(response.data);
      }
    });
  }

  const handleDropDownChange = (selectedOption) => {
    const { name, value } = selectedOption.target;
    console.log(name, value);
    let _collectionInfo = { ...collectionInfo };
    _collectionInfo[name] = value;
    // setCollectionInfo({ ...collectionInfo, [name]: value });
    setErrors({ ...errors, [name]: "" });
    console.log("_collectionInfo", _collectionInfo);
    if (name === "collectionName" && _collectionInfo.adminUserId !== "all") {
      console.log("name", name);
      let appinfo = [...appInfoList];
      console.log("appinfo", appinfo);
      let options = [];
      appinfo.map((row) => {
        if (value.includes(row.appname.toLowerCase())) {
          console.log("filter app info", row);
          options.push(row);
        }
      });
      setFilterAppInfo(options);
    } else if (name === "adminUserId") {
      GetEventHistoryLookUp();
      if (value !== "all") {
        GetAppMasterApplicationByFilterByUserID(value, _collectionInfo);
      } else {
        _collectionInfo["companyId"] = "all";

        //collectionInfo.companyId = "all";
        setFilterAppInfo([{ label: "All", value: "all" }]);
      }
    }
    setCollectionInfo(_collectionInfo);
  };
  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    setCollectionInfo({ ...collectionInfo, [name]: value });
    handleFieldValidation(event);
  };
  const handleFieldValidation = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let localerror = "";
    switch (name) {
      default:
        break;
    }
    setErrors({ ...errors, [name]: localerror });
  };
  return (
    <div className="mt-3">
      <h3 align="center">Event History</h3>
      <div className="d-flex ustify-content-start align-items-center  row mb-3">
        <div className="row p-1">
          <div className="col-sm-12 col-md-4 col-lg-4">
            <label className="form-label form-label ">
              Admin User <label className="text-danger">*</label>
            </label>
            <Select
              width={"10%"}
              name="adminUserId,
"
              id="adminUserId"
              styles={customstyles}
              className="form-control"
              options={adminUserList}
              value={adminUserList?.filter(
                (record) => record.value === collectionInfo?.adminUserId
              )}
              onChange={(val) => {
                handleDropDownChange({
                  target: {
                    name: "adminUserId",
                    value: val.value,
                  },
                });
              }}
            ></Select>
            {errors.adminUserId.length > 0 && (
              <small
                className="form-check-label text-danger"
                htmlFor="adminUserId"
              >
                {errors.adminUserId}
              </small>
            )}
          </div>
          <div className="col-sm-12 col-md-4 col-lg-4">
            <label className="form-label form-label ">
              Collection Name
              <label className="text-danger">*</label>
            </label>
            <Select
              width={"10%"}
              name="collectionName"
              id="collectionName"
              styles={customstyles}
              className="form-control"
              options={companyList}
              value={companyList?.filter(
                (record) => record.value === collectionInfo?.collectionName
              )}
              onChange={(val) => {
                handleDropDownChange({
                  target: {
                    name: "collectionName",
                    value: val.value,
                  },
                });
              }}
            ></Select>
            {errors.collectionName.length > 0 && (
              <small
                className="form-check-label text-danger"
                htmlFor="collectionName"
              >
                {errors.collectionName}
              </small>
            )}
          </div>
          <div className="col-sm-12 col-md-4 col-lg-4">
            <label className="form-label form-label">
              App Display Name
              <label className="text-danger">*</label>
            </label>
            <Select
              width={"10%"}
              name="companyId"
              id="companyId"
              isDisabled={collectionInfo.adminUserId === "all" ? true : false}
              styles={customstyles}
              className="form-control"
              options={filterAppInfoList}
              value={filterAppInfoList?.filter(
                (record) => record.value === collectionInfo?.companyId
              )}
              onChange={(val) => {
                handleDropDownChange({
                  target: {
                    name: "companyId",
                    value: val.value,
                  },
                });
              }}
            ></Select>
            {errors.companyId.length > 0 && (
              <small
                className="form-check-label text-danger"
                htmlFor="companyId"
              >
                {errors.companyId}
              </small>
            )}
          </div>
        </div>
        <div className="row p-1">
          <div className="col-sm-12 col-md-4 col-lg-3">
            <label className="form-label form-label">
              Status
              <label className="text-danger">*</label>
            </label>
            <Select
              width={"10%"}
              name="companyId"
              id="companyId"
              styles={customstyles}
              className="form-control"
              options={statusOptions}
              value={statusOptions?.filter(
                (record) => record.value === collectionInfo?.status
              )}
              onChange={(val) => {
                handleDropDownChange({
                  target: {
                    name: "status",
                    value: val.value,
                  },
                });
              }}
            ></Select>
            {errors.status.length > 0 && (
              <small className="form-check-label text-danger" htmlFor="status">
                {errors.status}
              </small>
            )}
          </div>
          <div className="col-sm-12 col-md-4 col-lg-3">
            <label className="form-label form-label ">
              From Date
              <label className="text-danger">*</label>
            </label>
            <input
              type="date"
              className="form-control"
              id="fromDate"
              max={Moment().format(collectionInfo?.toDate, "YYYY-MM-DD")}
              onChange={handleChange}
              value={collectionInfo?.fromDate}
              name="fromDate"
            />
            {errors.fromDate.length > 0 && (
              <small
                className="form-check-label text-danger"
                htmlFor="fromDate"
              >
                {errors.fromDate}
              </small>
            )}
          </div>
          <div className="col-sm-12 col-md-4 col-lg-3">
            <label className="form-label form-label">
              To Date
              <label className="text-danger">*</label>
            </label>
            <input
              type="date"
              className="form-control"
              id="toDate"
              name="toDate"
              min={Moment().format(collectionInfo?.fromDate, "YYYY-MM-DD")}
              value={collectionInfo?.toDate}
              onChange={handleChange}
            />
            {errors.toDate.length > 0 && (
              <small className="form-check-label text-danger" htmlFor="toDate">
                {errors.toDate}
              </small>
            )}
          </div>
          <div className="col-sm-12 col-md-4 col-lg-3 mt-4">
            <Button className="btn btn-success " onClick={handleSubmit}>
              Submit
            </Button>
          </div>
        </div>
      </div>

      <div className="table-responsive">
        <GenericListTable
          gcolumns={appColumns}
          gdata={data.length > 0 ? JSON.stringify(data) : ""}
          ghiddencolumns={hiddenappColumns}
          gplaceholder={`Search ${collectionInfo.collectionName}`}
          gsearchDisabled={false}
          filterDisabled={true}
        />
        {data.length === 0 && (
          <div className="form-check-label  text-center" htmlFor="validation">
            <h3 className="text-danger ">
              {appConstants.import.handlefieldvalidation.nodata}
            </h3>
          </div>
        )}
      </div>
      <div></div>
    </div>
  );
}

export default EventHistory;
