import { useEffect, useState, useMemo } from "react";
import { Button, Table, Modal } from "react-bootstrap";
import { useParams } from "react-router";
import Select from "react-select";
import customstyles from "../../components/customstyles/react-select-styles";
import PhoneInput from "react-phone-input-2";
import { useAuth0 } from "@auth0/auth0-react";
import { v4 as uuidv4 } from "uuid";
import { useRealmApp } from "../../providers/RealmApp";
import SweetAlert from "react-bootstrap-sweetalert";
import {
  getuserbyauthproviderid,
  insertUser,
} from "../../services/users_services";
import FileBase from "react-file-base64";
import { getCollectionListsByFormName } from "../../services/collection_lists_services";
import { getaccountbyid } from "../../services/accounts_services";
import { getcompaniesbyid } from "../../services/companies_services";
import { isEmail } from "validator";
function AddUser() {
  const ctechapp = useRealmApp();
  const [loading, setLoading] = useState(false);
  const { isLoading, error, isAuthenticated, user } = useAuth0();
  const [isSuccess, setisSuccess] = useState(false);
  const [isAlreadyExist, setIsAlreadyExist] = useState(false);
  const [isvaliddata, setisvaliddata] = useState(false);
  const [userInfo, setUserInfo] = useState(false);
  const [userRoleOptions, setUserRoleOption] = useState([]);
  const [companyList, setCompanyList] = useState();
  const [accountList, setAccountList] = useState();

  const [isVaildData, setIsVaildData] = useState(false);
  const [errormsg, setErrormsg] = useState("");
  const { userid } = useParams();
  const { v4: uuidv4 } = require("uuid");
  const initialContactState = {
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
  };
  const initialEmailState = {
    id: uuidv4().replace(/-/g, ""),
    email: "",
    label: "",
    isMain: 0,
  };

  const initialPhoneState = {
    id: uuidv4().replace(/-/g, ""),
    phone: "",
    ext: "",
    fullPhone: "",
    country: "",
    label: "",
    extphone: "",
    isMain: 0,
  };

  const initialAddressState = {
    id: uuidv4().replace(/-/g, ""),
    label: "",
    address1: "",
    address2: "",
    city: "",
    country: "",
    state: "",
    zipCode: "",
    isMain: 0,
  };
  const [emailArray, setEmailArray] = useState([initialEmailState]);
  const [phoneArray, setPhoneArray] = useState([initialPhoneState]);
  const [addressArray, setAddressArray] = useState([initialAddressState]);
  const [userErrors, setUserErrors] = useState(initialContactState);

  const [adduser, setAddUser] = useState({
    _p_accountId: userInfo?._p_accountId,
    _p_companyId: userInfo?._p_companyId,
    userid: "",
    firstName: "",
    lastName: "",
    email: "",
    role: "100",
    userEmailActivation: true,
    createdFrom: "PMaster",
    phone: "",
    title: "",
    phoneArr: [],
    emailArr: [],
    addressArr: [],
    userImage: "",
  });

  useEffect(() => {
    if (isAuthenticated) {
      getUserInfoByID();
      GetUserRoleOptions();
    }
  }, [user.sub]);

  async function getUserInfoByID() {
    try {
      setLoading(true);
      await getuserbyauthproviderid(user.sub, ctechapp.currentUser).then(
        (response) => {
          let user_info = JSON.parse(response);
          if (response !== undefined || user_info.length !== 0) {
            console.log(user_info[0]);
            setUserInfo(user_info[0]);
            //    GetUserDetailByID(user_info[0]?._id);
            loadcompanies(user_info[0]._p_companyId);
            loadaccount(user_info[0]._p_accountId);
          }
        }
      );
    } catch (exception) {
      setLoading(false);
      console.log(exception);
    }
  }
  async function GetUserRoleOptions() {
    await getCollectionListsByFormName(
      "ContactRole",
      ctechapp.currentUser
    ).then((response) => {
      if (response !== undefined) {
        let userrole_info = JSON.parse(response);
        console.log("userrole_info", userrole_info);
        let options = userrole_info.map(function (userrole) {
          return {
            value: userrole.listValue,
            label: userrole.listName,
          };
        });

        setUserRoleOption(options);
      }
    });
  }
  async function loadcompanies(id) {
    getcompaniesbyid(id, ctechapp.currentUser).then((response) => {
      if (response !== undefined) {
        let companiesdata = JSON.parse(response);
        console.log("companiesdata", companiesdata);
        setCompanyList(companiesdata);
      }
    });
  }
  async function loadaccount(id) {
    getaccountbyid(id, ctechapp.currentUser).then((response) => {
      if (response !== undefined) {
        //let data[] = response;
        //console.log("Facility" + JSON.parse(response));
        let accountdata = JSON.parse(response);
        console.log("accountdata", accountdata);
        setAccountList(accountdata);
      }
    });
  }

  //save functions
  const handleDropDownChange = (selectedOption, itemupdate, index) => {
    const { name, value } = selectedOption.target;
    setAddUser({ ...adduser, [name]: value });
    setisvaliddata(true);
  };
  function handlecontactPhoneChange(value) {
    console.log(value);

    setAddUser({ ...adduser, ["phone"]: value });
    // handlePersonalFieldValidation(event);
    setisvaliddata(true);
  }
  const handleChange = (event) => {
    const path = event.target.name.split(".");
    const finalProp = path.pop();
    const newuser = { ...adduser };
    let pointer = newuser;
    path.forEach((el) => {
      pointer[el] = { ...pointer[el] };
      pointer = pointer[el];
    });
    pointer[finalProp] =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    setAddUser(newuser);
    handleFieldValidation(event);
    setisvaliddata(true);
  };
  const handleFieldValidation = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let localerror = "";
    switch (name) {
      case "firstName":
        localerror =
          value.length < 3 ? "first name must be at least 3 characters" : "";
        break;
      case "lastName":
        localerror =
          value.length < 3 ? "last name must be at least 3 characters" : "";
        break;
      case "email":
        localerror = !isEmail(value) ? "Invalid Email" : "";
        break;
      default:
        break;
    }
    setUserErrors({ ...userErrors, [name]: localerror });
    setisvaliddata(true);
  };

  async function submitHandler(values) {
    adduser.emailArr = emailArray;
    adduser.phoneArr = phoneArray;
    adduser.addressArr = addressArray;
    adduser._p_accountId = userInfo?._p_accountId;
    adduser._p_companyId = userInfo?._p_companyId;

    if (validForm()) {
      insertUser(adduser, ctechapp.currentUser, user.sub).then((response) => {
        if (response !== undefined) {
          if (response === false) {
            setIsAlreadyExist(true);
          } else {
            setisSuccess(true);
          }
        }
      });
    }
  }
  const validForm = () => {
    try {
      let valid = false;
      valid = handleFieldListRequiredValidation();
      Object.values(userErrors).forEach((val) => {
        if (val.length > 0) valid = false;
      });
      //console.log("valid : ", valid);
      return valid;
    } catch (error) {
      return false;
    }
  };
  const handleFieldListRequiredValidation = () => {
    let iserror = "";

    setUserErrors({
      ...userErrors,
      firstName:
        adduser.firstName === ""
          ? (iserror = "First Name required")
          : userErrors.firstName,
      lastName:
        adduser.lastName === ""
          ? (iserror = "Last Name required")
          : userErrors.lastName,
      email:
        adduser.email === "" ? (iserror = "Email required") : userErrors.email,
    });

    return iserror.length === 0;
  };

  const handleSucessSweetAlert1 = () => {
    setIsAlreadyExist(false);
  };
  const handleSucessSweetAlert = () => {
    setisSuccess(false);
    gotoUserList();
  };

  function gotoUserList() {
    window.location.href = "/userlist";
  }

  const handleCancel = () => {
    gotoUserList();
  };
  const handleToRemoveUserImage = () => {
    var _user = { ...adduser };
    _user.userImage = "";
    setAddUser(_user);
  };

  function handleChangeEmail(e, index, property) {
    let _emailArray = [...emailArray];
    _emailArray[index][property] = e.target.value;

    setEmailArray(_emailArray);
  }
  function handleChangePhone(e, index, property) {
    let _phoneArray = [...phoneArray];
    _phoneArray[index][property] = e.target.value;

    setPhoneArray(_phoneArray);
  }
  function handleChangeAddress(e, index, property) {
    let _addressArray = [...addressArray];
    _addressArray[index][property] = e.target.value;

    setAddressArray(_addressArray);
  }
  const handleOnPhoneChange = (phoneinputvalue, index, property) => {
    let { value, data, event, formattedValue } = phoneinputvalue;
    let _phoneArray = [...phoneArray];
    //const dialcode = data.dialCode;
    _phoneArray[index].ext = "";
    _phoneArray[index].country = "";
    // const phonevalue = value;
    _phoneArray[index].fullPhone = phoneinputvalue;
    _phoneArray[index].phone = "";
    setPhoneArray(_phoneArray);
  };

  return (
    <div className="container p-3">
      <div className="p-1">
        <div className="row">
          <div name="basicinfo" className="h4 fw-bolder">
            Add User
          </div>
          <hr />
          <div className="col-lg-2"></div>

          <div className="col-lg-8">
            <div className="row p-1">
              <div className="col-sm-4 col-md-4 col-lg-4">
                Login Email<label className="text-danger">*</label>
              </div>
              <div className="col-sm-1 col-md-1 col-lg-1"></div>
              <div className="col-sm-4 col-md-4 col-lg-6">
                <input
                  type="text"
                  name="email"
                  id="email"
                  placeholder="Email"
                  className="form-control"
                  value={adduser?.email}
                  onChange={handleChange}
                />
                {userErrors.email.length > 0 && (
                  <div className="text-danger">{userErrors.email}</div>
                )}
              </div>
            </div>
            <div className="row p-1">
              <div className="col-sm-4 col-md-4 col-lg-4">
                Login Phone<label className="text-danger">*</label>
              </div>
              <div className="col-sm-1 col-md-1 col-lg-1"></div>
              <div className="col-sm-4 col-md-4 col-lg-6">
                <PhoneInput
                  inputStyle={{ width: "100%" }}
                  country={"us"}
                  value={adduser?.phone}
                  placeholder="Phone Number"
                  name="phone"
                  id="phone"
                  onChange={handlecontactPhoneChange}
                />
                {userErrors.phone.length > 0 && (
                  <div className="text-danger">{userErrors.phone}</div>
                )}
              </div>
            </div>
            <div className=" row p-1">
              <div className="col-sm-4 col-md-4 col-lg-4">
                First Name <label className="text-danger">*</label>
              </div>
              <div className="col-sm-1 col-md-1 col-lg-1"></div>
              <div className="col-sm-4 col-md-4 col-lg-6">
                <input
                  type="text"
                  name="firstName"
                  id="firstName"
                  className="form-control"
                  placeholder="First Name"
                  value={adduser?.firstName}
                  onChange={handleChange}
                />
                {userErrors.firstName.length > 0 && (
                  <div className="text-danger">{userErrors.firstName}</div>
                )}
              </div>
            </div>
            <div className="row p-1">
              <div className="col-sm-4 col-md-4 col-lg-4">
                Last Name<label className="text-danger">*</label>
              </div>
              <div className="col-sm-1 col-md-1 col-lg-1"></div>
              <div className="col-sm-4 col-md-4 col-lg-6">
                <input
                  type="text"
                  name="lastName"
                  id="lastName"
                  placeholder="Last Name"
                  className="form-control"
                  value={adduser?.lastName}
                  onChange={handleChange}
                />
                {userErrors.lastName.length > 0 && (
                  <div className="text-danger">{userErrors.lastName}</div>
                )}
              </div>
            </div>
            <div className="row p-1">
              <div className="col-sm-4 col-md-4 col-lg-4">
                {" "}
                User Role<label className="text-danger">*</label>
              </div>
              <div className="col-sm-1 col-md-1 col-lg-1"></div>
              <div className="col-sm-4 col-md-4 col-lg-6">
                <Select
                  isSearchable={true}
                  type="text"
                  name="role"
                  id="role"
                  options={userRoleOptions}
                  styles={customstyles}
                  className="form-control"
                  value={userRoleOptions.filter(
                    (client) => client.value === adduser?.role
                  )}
                  onChange={(val) => {
                    handleDropDownChange({
                      target: {
                        name: "role",
                        value: val.value,
                      },
                    });
                  }}
                />
              </div>
            </div>
            <div className="row p-1">
              <div className="col-sm-4 col-md-4 col-lg-4"> Title</div>
              <div className="col-sm-1 col-md-1 col-lg-1"></div>
              <div className="col-sm-4 col-md-4 col-lg-6">
                <input
                  type="text"
                  name="title"
                  id="title"
                  placeholder="Title"
                  className="form-control"
                  value={adduser?.title}
                  onChange={handleChange}
                />
                {/* {userErrors.title.length > 0 && (
                  <div className="text-danger">{userErrors.title}</div>
                )} */}
              </div>
            </div>
            {/* Email Array */}

            {emailArray.map((row, index) => {
              return (
                <div className="row p-1">
                  <div className="col-sm-4 col-md-4 col-lg-4">
                    {index === 0 && "Email"}
                  </div>
                  <div class="form-check form-switch col-sm-4 col-md-4 col-lg-1 mt-2 d-flex justify-content-end">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      name={"isMain_" + index}
                      id={"isMain_" + index}
                      checked={row.isMain === 1 ? true : false}
                      onChange={(e) => {
                        let _emailArray = [...emailArray];
                        _emailArray[index].isMain = e.target.checked ? 1 : 0;
                        _emailArray.map((email, emailindex) => {
                          if (index !== emailindex) {
                            email.isMain = 0;
                          }
                        });
                        setEmailArray(_emailArray);
                      }}
                      data-gtm-form-interact-field-id="0"
                    />
                  </div>
                  <div className="col-sm-4 col-md-4 col-lg-3">
                    <input
                      type="text"
                      name={"email_" + index}
                      id={"email_" + index}
                      className="form-control"
                      placeholder="Email"
                      value={row?.email}
                      onChange={(e) => {
                        handleChangeEmail(e, index, "email");
                      }}
                    />
                  </div>
                  <div className="col-sm-4 col-md-4 col-lg-3">
                    <input
                      type="text"
                      name={"label_" + index}
                      id={"label_" + index}
                      className="form-control"
                      placeholder="label"
                      value={row?.label}
                      onChange={(e) => {
                        handleChangeEmail(e, index, "label");
                      }}
                    />
                  </div>
                  {emailArray.length - 1 === index && (
                    <div className="col-sm-1 col-md-1 col-lg-1 mt-2">
                      <a
                        onClick={() => {
                          let _emailArray = [...emailArray];
                          _emailArray.push(initialEmailState);
                          setEmailArray(_emailArray);
                        }}
                      >
                        <i
                          className="fa-solid fa-circle-plus fa-xl"
                          style={{ color: "#848a94" }}
                        ></i>
                      </a>
                    </div>
                  )}

                  {emailArray.length - 1 !== index && (
                    <div className="col-sm-1 col-md-1 col-lg-1 mt-2">
                      <a
                        onClick={() => {
                          let _emailArray = [...emailArray];
                          _emailArray.splice(index, 1);
                          setEmailArray(_emailArray);
                        }}
                      >
                        <i
                          class="fa-solid fa-circle-xmark fa-xl"
                          style={{ color: "#cf3030" }}
                        ></i>
                      </a>
                    </div>
                  )}
                </div>
              );
            })}

            {/* Phone Array */}
            {phoneArray.map((row, index) => {
              return (
                <div className="row p-1">
                  <div className="col-sm-4 col-md-4 col-lg-4">
                    {index === 0 && "Phone Number"}
                  </div>
                  <div class="form-check form-switch col-sm-4 col-md-4 col-lg-1 mt-2 d-flex justify-content-end">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      name={"isMain_" + index}
                      id={"isMain_" + index}
                      checked={row.isMain === 1 ? true : false}
                      onChange={(e) => {
                        let _phoneArray = [...phoneArray];
                        _phoneArray[index].isMain = e.target.checked ? 1 : 0;
                        _phoneArray.map((phone, phoneindex) => {
                          if (index !== phoneindex) {
                            phone.isMain = 0;
                          }
                        });
                        setPhoneArray(_phoneArray);
                      }}
                      data-gtm-form-interact-field-id="0"
                    />
                  </div>
                  <div className="col-sm-4 col-md-4 col-lg-2">
                    <PhoneInput
                      inputStyle={{ width: "100%" }}
                      country={"us"}
                      value={row?.fullPhone}
                      placeholder="Phone Number"
                      name="phone"
                      id="phone"
                      onChange={(e) =>
                        handleOnPhoneChange(e, index, "fullPhone")
                      }
                    />
                  </div>
                  <div className="col-sm-4 col-md-4 col-lg-2">
                    <input
                      type="text"
                      name="extphone"
                      id="extphone"
                      className="form-control"
                      placeholder="Ext Phone"
                      value={row?.extphone}
                      onChange={(e) => {
                        handleChangePhone(e, index, "extphone");
                      }}
                    />
                  </div>
                  <div className="col-sm-4 col-md-4 col-lg-2">
                    <input
                      type="text"
                      name="label"
                      id="label"
                      className="form-control"
                      placeholder="label"
                      value={row?.label}
                      onChange={(e) => {
                        handleChangePhone(e, index, "label");
                      }}
                    />
                  </div>
                  {phoneArray.length - 1 === index && (
                    <div className="col-sm-1 col-md-1 col-lg-1 mt-2">
                      <a
                        onClick={() => {
                          let _phoneArray = [...phoneArray];
                          _phoneArray.push(initialPhoneState);
                          setPhoneArray(_phoneArray);
                        }}
                      >
                        <i
                          className="fa-solid fa-circle-plus fa-xl"
                          style={{ color: "#848a94" }}
                        ></i>
                      </a>
                    </div>
                  )}

                  {phoneArray.length - 1 !== index && (
                    <div className="col-sm-1 col-md-1 col-lg-1 mt-2">
                      <a
                        onClick={() => {
                          let _phoneArray = [...phoneArray];
                          _phoneArray.splice(index, 1);
                          setPhoneArray(_phoneArray);
                        }}
                      >
                        <i
                          class="fa-solid fa-circle-xmark fa-xl"
                          style={{ color: "#cf3030" }}
                        ></i>
                      </a>
                    </div>
                  )}
                </div>
              );
            })}
            {/*Address Array  */}
            {addressArray.map((row, index) => {
              return (
                <div className="row p-1">
                  <div className="col-sm-4 col-md-4 col-lg-4">
                    {index === 0 && "Address"}{" "}
                  </div>
                  <div class="form-check form-switch col-sm-4 col-md-4 col-lg-1 mt-2 d-flex justify-content-end">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      name={"isMain_" + index}
                      id={"isMain_" + index}
                      checked={row.isMain === 1 ? true : false}
                      onChange={(e) => {
                        let _addressArray = [...addressArray];
                        _addressArray[index].isMain = e.target.checked ? 1 : 0;
                        _addressArray.map((address, addressindex) => {
                          if (index !== addressindex) {
                            address.isMain = 0;
                          }
                        });
                        setAddressArray(_addressArray);
                      }}
                      data-gtm-form-interact-field-id="0"
                    />
                  </div>
                  <div className="col-sm-4 col-md-4 col-lg-6">
                    <div className="mt-1">
                      <input
                        type="text"
                        name="address1"
                        placeholder="Address 1"
                        id="address1"
                        className="form-control"
                        value={row?.address1}
                        onChange={(e) =>
                          handleChangeAddress(e, index, "address1")
                        }
                      />
                    </div>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="address2"
                        id="address2"
                        className="form-control"
                        placeholder="Address 2"
                        value={row?.address2}
                        onChange={(e) =>
                          handleChangeAddress(e, index, "address2")
                        }
                      />
                    </div>

                    <div className="mt-1">
                      {" "}
                      <input
                        type="text"
                        name="city"
                        id="city"
                        placeholder="City"
                        className="form-control"
                        value={row?.city}
                        onChange={(e) => handleChangeAddress(e, index, "city")}
                      />
                    </div>

                    <div className="mt-1 row ">
                      <div className="col-lg-6">
                        {" "}
                        <input
                          type="text"
                          name="state"
                          id="state"
                          placeholder="State"
                          className="form-control"
                          value={row?.state}
                          onChange={(e) =>
                            handleChangeAddress(e, index, "state")
                          }
                        />
                      </div>
                      <div className="col-lg-6">
                        {" "}
                        <input
                          type="text"
                          name="zipCode"
                          id="zipCode"
                          className="form-control"
                          placeholder="zipCode"
                          value={row?.zipCode}
                          onChange={(e) =>
                            handleChangeAddress(e, index, "zipCode")
                          }
                        />
                      </div>
                    </div>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="country"
                        id="country"
                        className="form-control"
                        placeholder="Country"
                        value={row?.country}
                        onChange={(e) =>
                          handleChangeAddress(e, index, "country")
                        }
                      />
                    </div>
                    <div className="mt-1">
                      {" "}
                      <input
                        type="text"
                        name="label"
                        id="label"
                        className="form-control"
                        placeholder="Label"
                        value={row.label}
                        onChange={(e) => handleChangeAddress(e, index, "label")}
                      />
                    </div>

                    {/* {userErrors.title.length > 0 && (
                  <div className="text-danger">{userErrors.title}</div>
                )} */}
                  </div>
                  {addressArray.length - 1 === index && (
                    <div className="col-sm-1 col-md-1 col-lg-1 mt-2">
                      <a
                        onClick={() => {
                          let _addressArray = [...addressArray];
                          _addressArray.push(initialAddressState);
                          setAddressArray(_addressArray);
                        }}
                      >
                        <i
                          className="fa-solid fa-circle-plus fa-xl"
                          style={{ color: "#848a94" }}
                        ></i>
                      </a>
                    </div>
                  )}

                  {addressArray.length - 1 !== index && (
                    <div className="col-sm-1 col-md-1 col-lg-1 mt-2">
                      <a
                        onClick={() => {
                          let _addressArray = [...addressArray];
                          _addressArray.splice(index, 1);
                          setAddressArray(_addressArray);
                        }}
                      >
                        <i
                          class="fa-solid fa-circle-xmark fa-xl"
                          style={{ color: "#cf3030" }}
                        ></i>
                      </a>
                    </div>
                  )}
                </div>
              );
            })}
            <div className="row p-1">
              <div className="col-sm-4 col-md-4 col-lg-4"> Company Name</div>
              <div className="col-sm-4 col-md-4 col-lg-1"></div>
              <div className="col-sm-4 col-md-4 col-lg-6">
                <input
                  type="text"
                  name="title"
                  id="title"
                  disabled={true}
                  className="form-control"
                  value={companyList?.companyName}
                  onChange={handleChange}
                />
                {/* {userErrors.title.length > 0 && (
                  <div className="text-danger">{userErrors.title}</div>
                )} */}
              </div>
            </div>
            <div className="row p-1">
              <div className="col-sm-4 col-md-4 col-lg-4"> Account Name</div>
              <div className="col-sm-4 col-md-4 col-lg-1"></div>
              <div className="col-sm-4 col-md-4 col-lg-6">
                <input
                  type="text"
                  name="title"
                  id="title"
                  className="form-control"
                  value={accountList?.accountCompanyName}
                  disabled={true}
                  onChange={handleChange}
                />
                {/* {userErrors.title.length > 0 && (
                  <div className="text-danger">{userErrors.title}</div>
                )} */}
              </div>
            </div>
            <div className="row p-1">
              <div className="col-sm-4 col-md-4 col-lg-4"> Image</div>
              <div className="col-sm-4 col-md-4 col-lg-1"></div>
              <div className="d-flex justify-content-start col-sm-4 col-md-4 col-lg-6">
                <div>
                  <Button
                    style={{ padding: "1px 6px" }}
                    onClick={() => handleToRemoveUserImage()}
                    className="me-2"
                  >
                    Clear
                  </Button>
                </div>
                <div>
                  <FileBase
                    type="file"
                    multiple={false}
                    onDone={({ base64 }: { base64: string }) => {
                      setAddUser({ ...adduser, ["userImage"]: base64 });
                      // setLogo({ ...logo, ["applogo"]: base64 });
                    }}
                  />
                  <img
                    src={
                      adduser.userImage === "" ||
                      adduser.userImage === undefined
                        ? "../assets/img/useraltimg.png"
                        : adduser.userImage
                    }
                    className="rounded-circle mx-auto d-block mw-100 mt-2"
                    width="100"
                    height="100"
                  />
                </div>
              </div>
            </div>
            <div className="row p-1">
              {" "}
              <div className=" d-flex justify-content-end mt-3 col-sm-12 col-md-12 col-lg-12">
                <button
                  className=" btn btn-primary me-2 "
                  onClick={handleCancel}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-primary me-2 "
                  disabled={!isvaliddata}
                  onClick={submitHandler}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
          <div className="col-lg-2"></div>
        </div>
      </div>
      <div>
        {isSuccess && (
          <SweetAlert
            success
            style={{ color: "black" }}
            title="User created successfully.."
            onConfirm={handleSucessSweetAlert}
          ></SweetAlert>
        )}
        {isAlreadyExist && (
          <SweetAlert
            warning
            style={{ color: "black" }}
            title="User is already exist.."
            onConfirm={handleSucessSweetAlert1}
          ></SweetAlert>
        )}
      </div>
    </div>
  );
}
export default AddUser;
