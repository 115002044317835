//services for getsignupactivationbytoken
export const getsignupactivationbytoken = async (_id, Ctechapp) => {
  try {
    const data = await Ctechapp.functions.getsignupactivationbytoken(_id);
    if (data !== undefined) {
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};

//services for getsignupactivationeventsbyid
export const getsignupactivationeventsbyid = async (_id, Ctechapp) => {
  try {
    const data = await Ctechapp.functions.getsignupactivationeventsbyid(_id);
    if (data !== undefined) {
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};
//insertUserOnBoarding

export const insertUserOnBoarding = async (_id, Ctechapp) => {
  try {
    const data = await Ctechapp.functions.insertUserOnBoarding(_id);
    if (data !== undefined) {
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};
