//services for getLOBApps
export const getLOBApps = async (Ctechapp) => {
  try {
    const data = await Ctechapp.functions.getLOBApps();
    if (data !== undefined) {
      // console.log("data of getLOBApps: " + data);
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};

//services for getLOBAppsEnvironment
export const getLOBAppsEnvironment = async (appname, appenv, Ctechapp) => {
  try {
    const data = await Ctechapp.functions.getLOBAppsEnvironment(
      appname,
      appenv
    );
    if (data !== undefined) {
      // console.log("data of getLOBAppsEnvironment: " + JSON.stringify(data));
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};

//services for getLOBAppsEnvironmentInfoByAppID
export const getLOBAppsEnvironmentInfoByAppID = async (Ctechapp) => {
  try {
    const data = await Ctechapp.functions.getLOBAppsEnvironmentInfoByAppID();
    if (data !== undefined) {
      // console.log("data of getLOBAppsEnvironmentInfoByAppID: " + JSON.stringify(data));
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};

//services for getLOBApps
export const getAppMasterLOBApps = async (Ctechapp, arg) => {
  try {
    const data = await Ctechapp.functions.getAppMasterLOBApps(arg, "");
    if (data !== undefined) {
      // console.log("data of getLOBApps: " + data);
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};
//getAppMasterLOBByID
export const getAppMasterLOBByID = async (Ctechapp, arg, loggedin_by) => {
  try {
    const data = await Ctechapp.functions.getAppMasterLOBByID(arg, loggedin_by);
    if (data !== undefined) {
      // console.log("data of getLOBApps: " + data);
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};
//updateAppMasterLOBByID

export const updateAppMasterLOBByID = async (
  lobinfo,
  Ctechapp,
  loggedin_by
) => {
  try {
    const data = await Ctechapp.functions.updateAppMasterLOBByID(
      JSON.stringify(lobinfo),
      loggedin_by
    );
    if (data !== undefined) {
      // console.log("data of getLOBApps: " + data);
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};
//insertAppMasterLOB
export const insertAppMasterLOB = async (
  lobinfo,
  Ctechapp,

  loggedin_by
) => {
  try {
    const data = await Ctechapp.functions.insertAppMasterLOB(
      JSON.stringify(lobinfo),
      loggedin_by
    );
    if (data !== undefined) {
      // console.log("data of getLOBApps: " + data);
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};
//deleteAppMasterLOBByID
export const deleteAppMasterLOBByID = async (Ctechapp, arg, loggedin_by) => {
  try {
    const data = await Ctechapp.functions.deleteAppMasterLOBByID(
      arg,
      loggedin_by
    );
    if (data !== undefined) {
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};
