import { useEffect, useState, useMemo } from "react";
import { useRealmApp } from "../../providers/RealmApp";
import { Button } from "react-bootstrap";
import { getapplistbyuserid, deleteAppByuserID } from "../../services/applications_services";
import { useAuth0 } from "@auth0/auth0-react";
import SweetAlert from "react-bootstrap-sweetalert";
import { getuserbyauthproviderid } from "../../services/users_services";
import GenericListTable from "../../components/gridTable/genericLlistTable";
import { useHistory } from "react-router";
import Moment from "moment";
import { appConstants } from "../../constants/app_constants";
import LoadingDialog from "../../components/LoadingDialog";
function Listapps() {
  const [list, setList] = useState([]);
  const [isDeleteApp, setIsDeleteApp] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [rowToDelete, setRowToDelete] = useState();
  const [addApp, setaddApp] = useState([]);
  const [isReload, setisReload] = useState(false);
  const ctechapp = useRealmApp();
  const { isLoading, error, isAuthenticated, user } = useAuth0();
  const msgTite = "Project Sync"
  const [msgContent, setmsgContent] = useState("App connection was changed and reloading the page. ");
  const [loadDialogOpen, setloadDialogOpen] = useState(false);
  const [userinfo, setUserinfo] = useState({
    firstName: "",
    lastName: "",
    email: "",
    role: "",
    userid: "",
    appid: "",
    action: "",
    auth_sub: "",
  });
  //state variables
  const history = useHistory();
  //table functions
  const data = useMemo(() => [...list], [list]);
  const appColumns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "_id",
        sortable: true,
        width: "20%",
        Filter: "",
        filter: "",
      },
      {
        Header: "GroupID",
        accessor: "appuserinfo[0].groupid",
        sortable: true,
        width: "20%",
        Filter: "",
        filter: "",
      },
      {
        Header: "Source app name ",
        accessor: "appname",
        sortable: true,
        width: "20%",
        Filter: "",
        filter: "",

      },
      {
        Header: "Relationship ",
        accessor: "apprelationship",
        sortable: true,
        width: "20%",
        Filter: "",
        filter: "",

      },
      {
        Header: "App display name",

        sortable: true,
        width: "30%",
        Cell: ({ row }) => (
          <>
            {row?.original?.appuserinfo?.map((item) => (
              <span>{item.appdisplayname}</span>
            ))}
          </>
        ),
      },

      {
        Header: "Environment",
        accessor: "appenvironment",
        sortable: true,
        width: "30%",
        Filter: "",
        filter: "",
      },
      // {
      //   Header: "Auto approval",
      //   accessor: "appautoapproval",
      //   sortable: true,
      //   width: "30%",
      //   Filter: "",
      //   filter: "",
      //   Cell: ({ value }) => {
      //     return (
      //       <span className="text-center">
      //         {value === "true" ? "Yes" : "No"}
      //       </span>
      //     );
      //   },
      // },
      {
        Header: "Created on",
        accessor: "activity_history[0].activity_on",
        sortable: true,
        width: "10%",
        Filter: "",
        filter: "value",
        Cell: ({ value }) => Moment(value).format(appConstants.DATEFORMAT),
      },
      {
        Header: "Active",
        accessor: "isactive",
        sortable: false,
        width: "10%",
        Filter: "",
        filter: "value",
        Cell: ({ row, value }) => {
          return (
            <div>
              {row.original.istokenvalid ? (
                <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                  <i className="fa fa-circle fs-9px fa-fw me-5px"></i>
                  {"Yes"}
                </span>
              ) : row.original.appaccountid === "" ? (
                <span
                  style={{ color: "#b58b1d" }}
                  className="badge border border-warning px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center"
                  onClick={() => {
                    popupAppMaster(
                      row?.original?.amappurl +
                      "?data=" +
                      row?.original?.applauchurl
                    );
                    /* window.open(
                      row?.original?.amappurl +
                      "?data=" +
                      row?.original?.applauchurl,
                      "_blank",
                      "noopener"
                    );
                    autoRefresh(); */
                  }}
                >
                  <i className="fa fa-circle fs-9px fa-fw me-5px"></i>
                  {"Configure"}
                </span>
              ) : (
                <span
                  className="badge border border-danger text-danger px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center"
                  onClick={() => {
                    popupAppMaster(
                      row?.original?.amappurl +
                      "?data=" +
                      row?.original?.applauchurl
                    );
                    // window.open(
                    //   row?.original?.amappurl +
                    //   "?data=" +
                    //   row?.original?.applauchurl,
                    //   "_blank",
                    //   "noopener"
                    // );
                    // autoRefresh();
                  }}
                >
                  <i className="fa fa-circle fs-9px fa-fw me-5px"></i>
                  {"Review"}
                </span>
              )}
            </div>
          );
        },
      },
      {
        Header: "Action",

        Cell: ({ row }) => {
          const onDeleteItemClick = () => {
            handleDeleteClickApps(row);
          };
          return (
            <div className="d-flex gap-4">
              <Button
                class="bg-success"
                onClick={() => {
                  popupAppMaster(
                    row?.original?.amappurl +
                    "?data=" +
                    row?.original?.applauchurl
                  );
                  // window.open(
                  //   row?.original?.amappurl +
                  //   "?data=" +
                  //   row?.original?.applauchurl,
                  //   "_blank",
                  //   "noopener"
                  // );
                  // autoRefresh();
                }}
                variant="secondary"
              >
                Edit
              </Button>{" "}
              {row?.original?.appname !== "Projectmaster" && (
                <Button className="btn btn-sm " onClick={onDeleteItemClick}>
                  Disable
                </Button>
              )}
            </div>
          );
        },
      },
    ],
    []
  );

  const hiddenappColumns = ["_id", "appuserinfo[0].groupid"];
  //handle function

  const handleDeleteClickApps = (row) => {
    setIsDeleteApp(true);
    setRowToDelete(row.original._id);
  };
  const handleConfirmDelete = () => {
    setIsDeleteApp(false);
    ondelete(rowToDelete);
  };
  const handleConfirmDeleted = (row) => {
    setIsDeleted(false);
    window.location.reload(true);
  };
  const handleCancelDelete = (row) => {
    setIsDeleteApp(false);
  };
  //use effect function
  useEffect(() => {
    if (isAuthenticated) {
      getUserInfoByID();
    }
  }, []);
  const autoRefresh = () => {
    setisReload(true);
  };
  const handleSucessSweetAlert = () => {
    setisReload(false);
    window.location.reload();
  };
  async function getUserInfoByID() {
    try {
      getuserbyauthproviderid(user.sub, ctechapp.currentUser).then(
        (response) => {
          let user_info = JSON.parse(response);
          if (response !== undefined || user_info.length !== 0) {
            setUserinfo(user_info[0]);
            // get_environment_config(user_info[0]);
            getData(user_info[0]);
          }
        }
      );
    } catch (exception) {
      console.log(exception);
    }
  }

  async function getData(_userinfo) {
    try {
      console.log("_userinfo", _userinfo);
      setloadDialogOpen(true);
      getapplistbyuserid(ctechapp.currentUser, _userinfo.userid).then(
        (response) => {
          setloadDialogOpen(false);
          if (response !== undefined) {
            let apps_list = JSON.parse(response);
            console.log("apps_list", apps_list);
            if (apps_list.length > 0) setaddApp(apps_list[0]);
            apps_list = apps_list.sort((a, b) => {
              if (a.sortOrder < b.sortOrder) {
                return -1;
              }
            });
            setList(apps_list);
            cpUserMode(apps_list);
          } else {
            setloadDialogOpen(false);
          }
        }
      );
    } catch (ex) {
      setloadDialogOpen(false);
    }
  }
  function cpUserMode(apps_list) {
    try {
      const _oldCPUserMode = localStorage.getItem("menuCPUserMode");

      if (apps_list.length === 0)  localStorage.setItem('menuCPUserMode', 2);
      let cpappexist = false;
      apps_list.map((app)=>{
        if(app.appname.toLowerCase() === "commandpost" ) cpappexist = true;
      });
      if(!cpappexist) localStorage.setItem('menuCPUserMode', 2);
      if(cpappexist) localStorage.setItem('menuCPUserMode', 1);
      const _newcPUserMode = localStorage.getItem("menuCPUserMode");
      if(_oldCPUserMode!==_newcPUserMode) 
      window.location.href = window.location.origin + "/apps";
    } catch (error) {
      console.log(error);
    }
  }
  function ondelete(id) {
    try {
      deleteAppByuserID(id, ctechapp.currentUser, userinfo.userid).then((response) => {
        if (response !== undefined) {
          setIsDeleted(true);
        }
      });
    } catch (error) {
      console.log(error);
    }
  }
  function popupAppMaster(url) {
    //window.open(url,"_blank","noopener");
    // autoRefresh();
    window.open(
      url,
      "popUpWindow",
      "height = 700, width = 500, left = 100, top = 100, scrollbars = yes, resizable = yes, menubar = no, toolbar = yes, location = no, directories = no, status = yes"
    );

  }
  return (
    <div className="mb-2">
       <div className="d-flex justify-content-between mt-2">
        <h1 className="m-2" align="left">
          Connected Apps
        </h1>
        <div className="p-2 m-2">
          <Button
            class="btn-sm"
            onClick={() => {
              popupAppMaster(addApp?.amappurl + "?data=" + addApp?.addapplauchurl);
            }}
            variant="secondary"
          >
            Add
          </Button>{" "}
          <Button
            class="bg-success"
            onClick={() => {
              getUserInfoByID();
            }}
            variant="secondary"
          >
            Refresh
          </Button>{" "}
        </div>
      </div>
      {/* <h3 className="m-2" align="center">Connected apps</h3>
      <div className="d-flex justify-content-end mb-2">
        <div className="p-2 m-2">
          <Button
            class="bg-success"
            onClick={() => {
              popupAppMaster(addApp?.amappurl + "?data=" + addApp?.addapplauchurl);
              // window.open(
              //   addApp?.amappurl + "?data=" + addApp?.addapplauchurl,
              //   "_blank",
              //   "noopener"
              // );
              // autoRefresh();
            }}
            variant="secondary"
          >
            Add
          </Button>{" "}
          <Button
            class="bg-success"
            onClick={() => {
              getUserInfoByID();
            }}
            variant="secondary"
          >
            Refresh
          </Button>{" "}
        </div>
      </div> */}
      <GenericListTable
        gcolumns={appColumns}
        gdata={data.length > 0 ? JSON.stringify(data) : ""}
        ghiddencolumns={hiddenappColumns}
        gplaceholder={"Search Apps.."}
        gsearchDisabled={false}
        filterDisabled={true}
      />
      <div>
        <LoadingDialog
          isOpen={loadDialogOpen}
          onClose={() => setloadDialogOpen(false)}
        />
        {isDeleteApp && (
          <SweetAlert
            warning
            style={{ color: "black" }}
            title=""
            confirmBtnText="Delete"
            cancelBtnText="No"
            showCancel={true}
            onConfirm={handleConfirmDelete}
            onCancel={handleCancelDelete}
          >
            <div>Do you want to delete ?</div>
          </SweetAlert>
        )}
        {isDeleted && (
          <SweetAlert
            success
            style={{ color: "black" }}
            title=""
            confirmBtnText="Ok"
            onConfirm={handleConfirmDeleted}
          >
            <div>App deleted successfully.</div>
          </SweetAlert>
        )}
        {isReload && (
          <SweetAlert
            success
            style={{ color: "black" }}
            title={msgTite}
            onConfirm={handleSucessSweetAlert}
          >{msgContent}</SweetAlert>
        )}
      </div>
    </div>

  );
}

export default Listapps;
