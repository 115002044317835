import { useEffect, useState, useMemo } from "react";
import { Button, Table, Modal } from "react-bootstrap";
import { useParams } from "react-router";
import Select from "react-select";
import customstyles from "../../components/customstyles/react-select-styles";
import { useAuth0 } from "@auth0/auth0-react";
import { useRealmApp } from "../../providers/RealmApp";
import SweetAlert from "react-bootstrap-sweetalert";
import { getaccountbyid } from "../../services/accounts_services";
import { getcompaniesbyid } from "../../services/companies_services";
import Moment from "moment";
import { appConstants } from "../../constants/app_constants";
import {
  getuserbyauthproviderid,
  updateuserbyid,
  getuserbyid,
} from "../../services/users_services";
import { getCollectionListsByFormName } from "../../services/collection_lists_services";
import { isEmail } from "validator";
import PhoneInput from "react-phone-input-2";

function UserProfile() {
  const ctechapp = useRealmApp();
  const [loading, setLoading] = useState(false);
  const { isLoading, error, isAuthenticated, user } = useAuth0();
  const [isSuccess, setisSuccess] = useState(false);
  const [isAlreadyExist, setIsAlreadyExist] = useState(false);
  const [userRoleOptions, setUserRoleOption] = useState([]);
  const [isvaliddata, setisvaliddata] = useState(false);
  const [userInfo, setUserInfo] = useState(false);
  const [isVaildData, setIsVaildData] = useState(false);
  const [errormsg, setErrormsg] = useState("");
  const { userid } = useParams();
  const [companyInfo, setCompanyInfo] = useState();
  const [accountInfo, setAccountInfo] = useState();

  const initialContactState = {
    firstName: "",
    lastName: "",
    mainPhoneNumber: "",
    userEmail: "",
  };
  const [userErrors, setUserErrors] = useState(initialContactState);

  const [edituser, setEditUser] = useState({
    userid: "",
    authId: "",
    firstName: "",
    lastName: "",
    mainPhoneNumber: "",
    userEmail: "",
    role: "",
  });
  useEffect(() => {
    if (isAuthenticated) {
      getUserInfoByID();
      GetUserRoleOptions();
    }
  }, [user.sub]);

  async function getUserInfoByID() {
    try {
      setLoading(true);
      await getuserbyauthproviderid(user.sub, ctechapp.currentUser).then(
        (response) => {
          let user_info = JSON.parse(response);
          if (response !== undefined || user_info.length !== 0) {
            // console.log(user_info[0]);
            setUserInfo(user_info[0]);
            user_info[0].createdOn =
              user_info[0].activity_history[0]?.activity_on;
            setEditUser(user_info[0]);
            if (user_info[0].role !== "9000") {
              loadcompanies(user_info[0]._p_companyId);
              loadaccount(user_info[0]._p_accountId);
            }

            //    GetUserDetailByID(user_info[0]?._id);
          }
        }
      );
    } catch (exception) {
      setLoading(false);
      console.log(exception);
    }
  }

  async function GetUserRoleOptions() {
    await getCollectionListsByFormName(
      "ContactRole",
      ctechapp.currentUser
    ).then((response) => {
      if (response !== undefined) {
        let userrole_info = JSON.parse(response);
        console.log("userrole_info", userrole_info);
        let options = userrole_info.map(function (userrole) {
          return {
            value: userrole.listValue,
            label: userrole.listName,
          };
        });

        setUserRoleOption(options);
      }
    });
  }

  async function loadcompanies(id) {
    getcompaniesbyid(id, ctechapp.currentUser).then((response) => {
      if (response !== undefined) {
        let companiesdata = JSON.parse(response);
        console.log("companiesdata", companiesdata);
        setCompanyInfo(companiesdata);
      }
    });
  }
  async function loadaccount(id) {
    getaccountbyid(id, ctechapp.currentUser).then((response) => {
      if (response !== undefined) {
        //let data[] = response;
        //console.log("Facility" + JSON.parse(response));
        let accountdata = JSON.parse(response);
        console.log("accountdata", accountdata);
        setAccountInfo(accountdata);
      }
    });
  }
  //save functions
  const handleDropDownChange = (selectedOption, itemupdate, index) => {
    const { name, value } = selectedOption.target;
    setEditUser({ ...edituser, [name]: value });
    setisvaliddata(true);
  };
  function handlecontactPhoneChange(value) {
    console.log(value);

    setEditUser({ ...edituser, ["mainPhoneNumber"]: value });
    // handlePersonalFieldValidation(event);
    setisvaliddata(true);
  }
  const handleChange = (event) => {
    const path = event.target.name.split(".");
    const finalProp = path.pop();
    const newuser = { ...edituser };
    let pointer = newuser;
    path.forEach((el) => {
      pointer[el] = { ...pointer[el] };
      pointer = pointer[el];
    });
    pointer[finalProp] =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    setEditUser(newuser);
    handleFieldValidation(event);
    setisvaliddata(true);
  };
  const handleFieldValidation = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let localerror = "";
    switch (name) {
      case "firstName":
        localerror =
          value.length < 3 ? "first name must be at least 3 characters" : "";
        break;
      case "lastName":
        localerror =
          value.length < 3 ? "last name must be at least 3 characters" : "";
        break;
      case "userEmail":
        localerror = !isEmail(value) ? "Invalid Email" : "";
        break;
      default:
        break;
    }
    setUserErrors({ ...userErrors, [name]: localerror });
    setisvaliddata(true);
  };

  async function submitHandler(values) {
    if (validForm()) {
      updateuserbyid(edituser, ctechapp.currentUser, user.sub).then(
        (response) => {
          if (response !== undefined) {
            if (response === false) {
              setIsAlreadyExist(true);
            } else {
              setisSuccess(true);
            }
          }
        }
      );
    }
  }
  const validForm = () => {
    try {
      let valid = false;
      valid = handleFieldListRequiredValidation();
      Object.values(userErrors).forEach((val) => {
        if (val.length > 0) valid = false;
      });
      //console.log("valid : ", valid);
      return valid;
    } catch (error) {
      return false;
    }
  };
  const handleFieldListRequiredValidation = () => {
    let iserror = "";

    setUserErrors({
      ...userErrors,
      firstName:
        edituser.firstName === ""
          ? (iserror = "First Name required")
          : userErrors.firstName,
      lastName:
        edituser.lastName === ""
          ? (iserror = "Last Name required")
          : userErrors.lastName,
      userEmail:
        edituser.userEmail === ""
          ? (iserror = "Email required")
          : userErrors.userEmail,
    });

    return iserror.length === 0;
  };

  const handleSucessSweetAlert1 = () => {
    setIsAlreadyExist(false);
  };
  const handleSucessSweetAlert = () => {
    setisSuccess(false);
    // gotoUserList();
  };

  function gotoUserList() {
    window.location.href = "/";
  }

  const handleCancel = () => {
    gotoUserList();
  };

  return (
    <div className="container p-3">
      <div className="row">
        <div name="basicinfo" className="h4 fw-bolder">
          Profile
        </div>

        <hr />
        <div className="col-lg-2"></div>
        <div className="col-lg-8">
          <div className=" row p-1">
            <div className="col-sm-4 col-md-4 col-lg-2">
              <img
                src={
                  edituser.userImage === "" || edituser.userImage === undefined
                    ? "../assets/img/useraltimg.png"
                    : edituser.userImage
                }
                className="rounded-circle mx-auto d-block mw-100 mt-2 d-flex justify-content-end"
                width="80"
                height="80"
              />
            </div>
            <div className="col-sm-4 col-md-4 col-lg-4">
              <span className="h4 fw-bold d-flex justify-content-start mt-4">
                {edituser?.firstName + " " + edituser?.lastName}
              </span>
              <span className=" d-flex justify-content-start mt-2">
                Created On{" "}
                {(edituser?.createdOn &&
                  Moment(edituser?.createdOn).format(
                    appConstants.DATEFORMAT
                  )) ||
                  ""}
              </span>
            </div>
          </div>
          <div className=" row p-1 mt-3">
            <div className="h4 fw-bold">Basic Info </div>
            <hr />
            {edituser?.email !== "" && (
              <div className="row mt-3">
                <div className="col-lg-4">Login Email</div>
                <div className="col-lg-8">{edituser?.email}</div>
              </div>
            )}
            {edituser?.phone !== "" && (
              <div className="row mt-3">
                <div className="col-lg-4">Login Phone</div>
                <div className="col-lg-8">{"+" + edituser?.phone}</div>
              </div>
            )}
            {edituser?.title !== "" && (
              <div className="row mt-3">
                <div className="col-lg-4">Title</div>
                <div className="col-lg-8">{edituser?.title}</div>
              </div>
            )}
            {edituser?.phoneArr?.length > 0 && (
              <div className="row mt-3">
                <div className="col-lg-4">Additional Phone</div>
                <div className="col-lg-8">
                  {edituser?.phoneArr?.length > 0 &&
                    edituser?.phoneArr?.map((row, index) => {
                      return (
                        <div className="row">
                          <div className="col-lg-6 mb-2">
                            <span className="">
                              {row?.fullPhone === ""
                                ? ""
                                : "+" + row?.fullPhone}
                              {row?.extphone === ""
                                ? ""
                                : ", extn " + row?.extphone}
                              <br />
                            </span>
                          </div>
                          <div className="col-lg-6">
                            <span>{"(" + row?.label + ")"}</span>
                            <span className="ms-2">
                              {row?.isMain === 1 ? (
                                <i
                                  class="fa-solid fa-star"
                                  style={{ color: "#ffd43b" }}
                                ></i>
                              ) : (
                                ""
                              )}
                            </span>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            )}
            {edituser?.emailArr?.length > 0 && (
              <div className="row mt-3">
                <div className="col-lg-4">Additional Email</div>
                <div className="col-lg-8">
                  {edituser?.emailArr?.length > 0 &&
                    edituser?.emailArr?.map((row, index) => {
                      return (
                        <div className="row mb-2">
                          <div className="col-lg-6">
                            {" "}
                            <span>
                              {row?.email}
                              <br />
                            </span>{" "}
                          </div>
                          <div className="col-lg-6">
                            <span>{" (" + row?.label + ")"}</span>
                            <span className="ms-2">
                              {row?.isMain === 1 ? (
                                <i
                                  class="fa-solid fa-star"
                                  style={{ color: "#ffd43b" }}
                                ></i>
                              ) : (
                                ""
                              )}
                            </span>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            )}
            {edituser?.addressArr?.length > 0 && (
              <div className="row mt-3">
                <div className="col-lg-4"> Address</div>
                <div className="col-lg-8">
                  {edituser?.addressArr?.length > 0 &&
                    edituser?.addressArr?.map((row, index) => {
                      return (
                        <div className="row mb-2">
                          <div className="col-lg-6">
                            {" "}
                            <span>
                              {row?.address1 === "" ? "" : row?.address1 + ", "}
                              {row?.address2 === "" ? "" : row?.address2 + ", "}
                              {row?.city === "" ? "" : row?.city + ", "}
                              {row?.state === "" ? "" : row?.state + ", "}
                              {row?.country === "" ? "" : row?.country + ", "}
                              {row?.zipCode === "" ? "" : row?.zipCode + "  "}

                              <br />
                            </span>{" "}
                          </div>
                          <div className="col-lg-6">
                            <span> {"  (" + row?.label + ")"}</span>
                            <span className="ms-2">
                              {row?.isMain === 1 ? (
                                <i
                                  class="fa-solid fa-star"
                                  style={{ color: "#ffd43b" }}
                                ></i>
                              ) : (
                                ""
                              )}
                            </span>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            )}
            {companyInfo?.companyName !== "" && (
              <div className="row mt-3">
                <div className="col-lg-4">Company Name</div>
                <div className="col-lg-8">{companyInfo?.companyName}</div>
              </div>
            )}
            {accountInfo?.accountCompanyName !== "" && (
              <div className="row mt-3">
                <div className="col-lg-4">Account Name</div>
                <div className="col-lg-8">
                  {accountInfo?.accountCompanyName}
                </div>
              </div>
            )}
          </div>
          {/* {edituser.role === "8000" && (
            <div>
              <div className=" row p-1 mt-3">
                <div className="h4 fw-bold">Company Info </div>
                <hr />
                {companyInfo?.companyName !== "" && (
                  <div className="row mt-3">
                    <div className="col-lg-4">Company Name</div>
                    <div className="col-lg-8">{companyInfo?.companyName}</div>
                  </div>
                )}
                {companyInfo?.phoneArr?.length > 0 && (
                  <div className="row mt-3">
                    <div className="col-lg-4">Phone Number</div>
                    <div className="col-lg-8">
                      {companyInfo?.phoneArr?.length > 0 &&
                        companyInfo?.phoneArr?.map((row, index) => {
                          return (
                            <div className="row">
                              <div className="col-lg-6 mb-2">
                                <span className="">
                                  {row?.fullPhone === ""
                                    ? ""
                                    : "+" + row?.fullPhone}
                                  {row?.extphone === ""
                                    ? ""
                                    : ", extn " + row?.extphone}
                                  <br />
                                </span>
                              </div>
                              <div className="col-lg-6">
                                <span>{"(" + row?.label + ")"}</span>
                                <span className="ms-2">
                                  {row?.isMain === 1 ? (
                                    <i
                                      class="fa-solid fa-star"
                                      style={{ color: "#ffd43b" }}
                                    ></i>
                                  ) : (
                                    ""
                                  )}
                                </span>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                )}
                {companyInfo?.companyEmail?.length > 0 && (
                  <div className="row mt-3">
                    <div className="col-lg-4">Email Address</div>
                    <div className="col-lg-8">
                      {edituser?.emailArr?.length > 0 &&
                        edituser?.emailArr?.map((row, index) => {
                          return (
                            <div className="row mb-2">
                              <div className="col-lg-6">
                                {" "}
                                <span>
                                  {row?.email}
                                  <br />
                                </span>{" "}
                              </div>
                              <div className="col-lg-6">
                                <span>{" (" + row?.label + ")"}</span>
                                <span className="ms-2">
                                  {row?.isMain === 1 ? (
                                    <i
                                      class="fa-solid fa-star"
                                      style={{ color: "#ffd43b" }}
                                    ></i>
                                  ) : (
                                    ""
                                  )}
                                </span>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                )}
                {companyInfo?.addressArr?.length > 0 && (
                  <div className="row mt-3">
                    <div className="col-lg-4"> Address</div>
                    <div className="col-lg-8">
                      {companyInfo?.addressArr?.length > 0 &&
                        companyInfo?.addressArr?.map((row, index) => {
                          return (
                            <div className="row mb-2">
                              <div className="col-lg-6">
                                {" "}
                                <span>
                                  {row?.address1 === ""
                                    ? ""
                                    : row?.address1 + ", "}
                                  {row?.address2 === ""
                                    ? ""
                                    : row?.address2 + ", "}
                                  {row?.city === "" ? "" : row?.city + ", "}
                                  {row?.state === "" ? "" : row?.state + ", "}
                                  {row?.country === ""
                                    ? ""
                                    : row?.country + ", "}
                                  {row?.zipCode === ""
                                    ? ""
                                    : row?.zipCode + "  "}

                                  <br />
                                </span>{" "}
                              </div>
                              <div className="col-lg-6">
                                <span> {"  (" + row?.label + ")"}</span>
                                <span className="ms-2">
                                  {row?.isMain === 1 ? (
                                    <i
                                      class="fa-solid fa-star"
                                      style={{ color: "#ffd43b" }}
                                    ></i>
                                  ) : (
                                    ""
                                  )}
                                </span>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                )}
              </div>
              <div className=" row p-1 mt-3">
                <div className="h4 fw-bold">Account Info </div>
                <hr />
                {accountInfo?.accountCompanyName !== "" && (
                  <div className="row mt-3">
                    <div className="col-lg-4">Account Name</div>
                    <div className="col-lg-8">
                      {accountInfo?.accountCompanyName}
                    </div>
                  </div>
                )}
                {accountInfo?.accountEmail !== "" && (
                  <div className="row mt-3">
                    <div className="col-lg-4">Email Address</div>
                    <div className="col-lg-8">{accountInfo?.accountEmail}</div>
                  </div>
                )}
                {accountInfo?.planStartDate !== "" && (
                  <div className="row mt-3">
                    <div className="col-lg-4">Plan Start Date</div>
                    <div className="col-lg-8">
                      {Moment(accountInfo?.planStartDate).format(
                        appConstants.DATEFORMAT
                      )}
                    </div>
                  </div>
                )}
                {accountInfo?.planEndDate !== "" && (
                  <div className="row mt-3">
                    <div className="col-lg-4">Plan End Date</div>
                    <div className="col-lg-8">
                      {Moment(accountInfo?.planEndDate).format(
                        appConstants.DATEFORMAT
                      )}
                    </div>
                  </div>
                )}

                {accountInfo?.accountPhoneArr?.length > 0 && (
                  <div className="row mt-3">
                    <div className="col-lg-4">Phone Number</div>
                    <div className="col-lg-8">
                      {accountInfo?.accountPhoneArr?.length > 0 &&
                        accountInfo?.accountPhoneArr?.map((row, index) => {
                          return (
                            <div className="row">
                              <div className="col-lg-6 mb-2">
                                <span className="">
                                  {row?.fullPhone === ""
                                    ? ""
                                    : "+" + row?.fullPhone}
                                  {row?.extphone === ""
                                    ? ""
                                    : ", extn " + row?.extphone}
                                  <br />
                                </span>
                              </div>
                              <div className="col-lg-6">
                                <span>{"(" + row?.label + ")"}</span>
                                <span className="ms-2">
                                  {row?.isMain === 1 ? (
                                    <i
                                      class="fa-solid fa-star"
                                      style={{ color: "#ffd43b" }}
                                    ></i>
                                  ) : (
                                    ""
                                  )}
                                </span>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                )}
              </div>{" "}
            </div>
          )} */}
        </div>
        <div className="col-lg-2"></div>
      </div>
      <div>
        {isSuccess && (
          <SweetAlert
            success
            style={{ color: "black" }}
            title={appConstants.import.alertmsg.profileupdatesucessfull}
            onConfirm={handleSucessSweetAlert}
          ></SweetAlert>
        )}
        {isAlreadyExist && (
          <SweetAlert
            warning
            style={{ color: "black" }}
            title={appConstants.import.alertmsg.useralreadyexist}
            onConfirm={handleSucessSweetAlert1}
          ></SweetAlert>
        )}
      </div>
    </div>
  );
}
export default UserProfile;
