//services for getProcorecontactlistbycompanyid
export const getProcoreuserlistbycompanyid = async (
  procoreCompanyID,
  procoreAccessTokenHostName,
  apiclientid,
  apiclientsecret,
  apirefreshtoken,
  apiapptoken,
  apiredirecturi,
  procoreRestAPIHostName,
  Ctechapp,
  loggedinby
) => {
  try {
    const userdata = {
      procoreCompanyID: procoreCompanyID,
      procoreAccessTokenHostName: procoreAccessTokenHostName,
      procoreClientID: apiclientid,
      procoreClientSecret: apiclientsecret,
      procoreRefreshToken: apirefreshtoken,
      procoreAppToken: apiapptoken,
      procoreRedirectUri: apiredirecturi,
      procoreRestAPIHostName: procoreRestAPIHostName,
    };
    const data = await Ctechapp.functions.getProcoreuserlistbycompanyid(
      JSON.stringify(userdata),
      loggedinby
    );
    if (data !== undefined) {
      // console.log("status: success");
      return data;
    }
  } catch (error) {
    console.log("error : ", error);
    throw Error(error);
  }
};

export const getProcoreuserbyid = async (
  procoreApiInfo,
  Ctechapp,
  loggedinby
) => {
  try {
    const data = await Ctechapp.functions.getProcoreuserbyid(
      JSON.stringify(procoreApiInfo),
      loggedinby
    );
    if (data !== undefined) {
      // console.log("status: success");
      return data;
    }
  } catch (error) {
    console.log("error : ", error);
    throw Error(error);
  }
};
export const getProcoreProjectListByCompanyID = async (
  procoreApiInfo,
  Ctechapp,
  loggedinby
) => {
  try {
    const data = await Ctechapp.functions.getProcoreProjectListByCompanyID(
      procoreApiInfo,
      loggedinby
    );
    if (data !== undefined) {
      // console.log("status: success");
      return data;
    }
  } catch (error) {
    console.log("error : ", error);
    throw Error(error);
  }
};

//getProcoreProjectListWithoutPMProjectList
export const getProcoreProjectListWithoutPMProjectList = async (
  companyid,
  Ctechapp,
  loggedinby
) => {
  try {
    const data =
      await Ctechapp.functions.getProcoreProjectListWithoutPMProjectList(
        companyid,
        loggedinby
      );
    if (data !== undefined) {
      // console.log("status: success");
      return data;
    }
  } catch (error) {
    console.log("error : ", error);
    throw Error(error);
  }
};
export const getProcoreProjectListWithPMProjectList = async (
  companyid,
  Ctechapp,
  loggedinby
) => {
  try {
    const data =
      await Ctechapp.functions.getProcoreProjectListWithPMProjectList(
        companyid,
        loggedinby
      );
    if (data !== undefined) {
      // console.log("status: success");
      return data;
    }
  } catch (error) {
    console.log("error : ", error);
    throw Error(error);
  }
};
