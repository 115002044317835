import { Button, Form } from "react-bootstrap";
import * as Realm from "realm-web";
import { useState, useEffect } from "react";
import { useParams } from "react-router";
import SweetAlert from "react-bootstrap-sweetalert";
import {
  getuserhistorybyid,
  updateuserhistorybyid,
} from "../../services/user_history_services";
import { isEmail } from "validator";
import PhoneInput from "react-phone-input-2";
import initFontAwesome from "../../utils/initFontAwesome";
function SignUpVerificationCode() {
  const apiKey = process.env.REACT_APP_APIKEY;
  if (!apiKey) {
    throw new Error("Could  not find a Realm Server API Key.");
  }
  const { ids } = useParams();
  console.log("ids", ids);
  const credentials = Realm.Credentials.apiKey(apiKey);
  const ctechapp = new Realm.App(process.env.REACT_APP_REALM_APP_ID);
  const [loading, setLoading] = useState(false);
  const [initialval, setInitialval] = useState();
  const [code, setCode] = useState({
    _smscode: "",
    _emailcode: "",
  });
  const [codeErrors, setCodeErrors] = useState({
    _smscode: "",
    _emailcode: "",
  });
  const [isSuccess, setisSuccess] = useState(false);
  const [isFailure, setisFailure] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const [validatemsg, SetValidatemsg] = useState("");
  const [isvaliddata, setisvaliddata] = useState(false);

  // handle change for business phone
  useEffect(() => {
    Getuserhistorybyid();

    async function Getuserhistorybyid() {
      ctechapp.logIn(credentials).then(() => {
        setLoading(true);
        getuserhistorybyid(
          ids,
          ctechapp.currentUser,
          ctechapp.currentUser.id
        ).then((response) => {
          let user_info = JSON.parse(response);
          if (user_info !== undefined) {
            console.log("user_info ", user_info);
            setInitialval(user_info);
          }
          setLoading(false);
        });
        setLoading(false);
      });
    }
  }, []);
  // function handleChangePhone(e, property) {
  //   SetValidatemsg("");
  //   let value = e;

  //   SetValidatemsg("");
  //   const path = property.split(".");
  //   const finalProp = path.pop();
  //   const newuser = { ...initialval };
  //   let pointer = newuser;
  //   path.forEach((el) => {
  //     pointer[el] = { ...pointer[el] };
  //     pointer = pointer[el];
  //   });
  //   pointer[finalProp] = value;
  //   setInitialval(newuser);
  //   setisvaliddata(true);
  // }
  const handleChange = (event) => {
    seterrorMessage("");
    SetValidatemsg("");
    const path = event.target.name.split(".");
    const finalProp = path.pop();
    const newuser = { ...code };
    let pointer = newuser;
    path.forEach((el) => {
      pointer[el] = { ...pointer[el] };
      pointer = pointer[el];
    });
    pointer[finalProp] =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    setCode(newuser);
    handleFieldValidation(event);
    setisvaliddata(true);
  };
  const handleFieldValidation = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let localerror = "";
    switch (name) {
      case "_emailcode":
        localerror = value.length < 3 ? "Email Code must be 4 characters" : "";
        break;
      case "_smscode":
        localerror = value.length < 3 ? "SMS Code must be 4 characters" : "";
        break;
      default:
        break;
    }
    setCodeErrors({ ...codeErrors, [name]: localerror });
    setisvaliddata(true);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      console.log("code", code);
      if (initialval.emailcode === code._emailcode) {
        if (initialval.phone.length > 0) {
          if (initialval.smscode === code._smscode) {
            initialval.isEmailValidate = true;
            initialval.isSMSValidate = true;
            console.log("email and sms validation successfull");
            UpdateUserHistoryByID();
          } else {
            seterrorMessage("Invaild SMS Code");
          }
        } else {
          initialval.isEmailValidate = true;
          console.log("email validation successfull");
          UpdateUserHistoryByID();
        }
      } else {
        seterrorMessage("InVaild Email Code");
      }
    }
  };

  async function UpdateUserHistoryByID() {
    ctechapp.logIn(credentials).then(() => {
      setLoading(true);
      updateuserhistorybyid(
        initialval,
        ctechapp.currentUser,
        ctechapp.currentUser.id
      ).then((response) => {
        if (response !== undefined) {
          setisSuccess(true);
        }
      });
      setLoading(false);
    });
  }

  //error handling functions
  const validateForm = () => {
    try {
      let valid = false;
      valid = handleFieldRequiredValidation();
      Object.values(codeErrors).forEach((val) => {
        if (val.length > 0) valid = false;
      });
      //console.log("valid : ", valid);
      return valid;
    } catch (error) {
      return false;
    }
  };

  const handleFieldRequiredValidation = () => {
    let iserror = "";

    setCodeErrors({
      ...codeErrors,
      _emailcode:
        code._emailcode === ""
          ? (iserror = "Email Code required")
          : codeErrors._emailcode,
    });
    if (initialval.phone.length > 0) {
      setCodeErrors({
        ...codeErrors,
        _smscode:
          code._smscode === ""
            ? (iserror = "Sms Code required")
            : codeErrors._smscode,
        _emailcode:
          code._emailcode === ""
            ? (iserror = "Email Code required")
            : codeErrors._emailcode,
      });
    }

    return iserror.length === 0;
  };

  function gotoLogin() {
    window.location.href = "/";
  }

  function gotoSignup() {
    window.location.href = "/Signup/0/0";
  }

  const handleSucessSweetAlert = () => {
    setisSuccess(false);
    gotoLogin();
  };
  const handleFailureSweetAlert = () => {
    setisFailure(false);
    window.location.href = "/Signup/0/0";
  };

  const handleOnInput = (e) => {
    let maxNum = 4;
    if (e.target.value.length > maxNum) {
      e.target.value = e.target.value.slice(0, maxNum);
    }
  };

  return (
    <Form>
      <div className="bg-black">
        <div className="container py-5 h-80">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col-lg-8 col-xl-3">
              <div className="card rounded-3">
                <img
                  src="/images/pm_logo.png"
                  className="px-5 mt-5"
                  alt="Logo"
                />
                <div className="card-body p-4 p-md-2">
                  <h2 className="text-center">Welcome</h2>
                  <p className="text-center mb-0">
                    Enter verification code to activate your
                  </p>
                  <p className="text-center mt-0">
                    {" "}
                    Command Technologies Inc account
                  </p>
                  {/* <form className="px-md-5"> */}
                  <div className="row mb-4 px-md-5">
                    <label className="mb-1 fw-lighter">
                      {initialval?.email}
                    </label>
                    <input
                      type="number"
                      onInput={handleOnInput}
                      id="_emailcode"
                      name="_emailcode"
                      className="form-control"
                      value={code._emailcode}
                      placeholder="Email Code"
                      onChange={handleChange}
                    />
                    {codeErrors._emailcode.length > 0 && (
                      <small
                        className="form-check-label text-danger"
                        htmlFor="firstname"
                      >
                        {codeErrors._emailcode}
                      </small>
                    )}
                  </div>
                  {initialval?.phone.length > 0 && (
                    <div className="row mb-4 px-md-5">
                      <label className="mb-1 fw-lighter">
                        +{initialval?.phone}
                      </label>
                      <input
                        type="number"
                        onInput={handleOnInput}
                        id="_smscode"
                        name="_smscode"
                        className="form-control"
                        onkeydown="return false"
                        onwheel="return false"
                        placeholder="SMS Code"
                        onChange={handleChange}
                      />
                      {codeErrors._smscode.length > 0 && (
                        <small
                          className="form-check-label text-danger"
                          htmlFor="firstname"
                        >
                          {codeErrors._smscode}
                        </small>
                      )}
                    </div>
                  )}
                  {/* <div className="row mb-4 px-md-5">
                    <input
                      type="text"
                      id="lastname"
                      name="lastname"
                      className="form-control"
                      placeholder="Last Name"
                      onChange={handleChange}
                    />
                    {codeErrors.lastname.length > 0 && (
                      <small
                        className="form-check-label text-danger"
                        htmlFor="lastname"
                      >
                        {codeErrors.lastname}
                      </small>
                    )}
                  </div>

                  <div className="d-flex justify-content-start row mb-4 px-md-5">
                    <label>Mobile:</label>
                    <PhoneInput
                      inputStyle={{ width: "250px" }}
                      country={"us"}
                      id="phone"
                      name="phone"
                      onChange={(event) => {
                        handleChangePhone(event, "phone");
                      }}
                    />
                    {codeErrors.phone.length > 0 && (
                      <small
                        className="form-check-label text-danger"
                        htmlFor="phone"
                      >
                        {codeErrors.phone}
                      </small>
                    )}
                  </div>

                  <div className="row mb-4 px-md-5">
                    <input
                      type="text"
                      id="email"
                      name="email"
                      className="form-control"
                      placeholder="Email"
                      autoComplete="new-email"
                      onChange={handleChange}
                    />
                    {codeErrors.email.length > 0 && (
                      <small
                        className="form-check-label text-danger"
                        htmlFor="email"
                      >
                        {codeErrors.email}
                      </small>
                    )}
                  </div>

                  <div className="row mb-4 px-md-5">
                    <input
                      type="password"
                      id="password"
                      name="password"
                      className="form-control"
                      autoComplete="new-password"
                      placeholder="Create Password"
                      onChange={handleChange}
                    />

                    {codeErrors.password.length > 0 && (
                      <small
                        className="form-check-label text-danger"
                        htmlFor="password"
                      >
                        {codeErrors.password}
                      </small>
                    )}
                  </div>
*/}
                  <div className="row mb-2 px-md-5">
                    <Button
                      className="form-control btn btn-lg"
                      onClick={handleSubmit}
                      type="submit"
                    >
                      Submit
                    </Button>
                  </div>
                  <div className="row px-md-5">
                    <Button
                      className="form-control btn btn-lg ml-2 mb-4"
                      onClick={() => gotoSignup()}
                    >
                      Cancel
                    </Button>
                  </div>
                  <div className="text-center">
                    {errorMessage !== "" && (
                      <div className="text-danger">{errorMessage}</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        {isSuccess && (
          <SweetAlert
            success
            style={{ color: "black" }}
            title="User is added successfully.."
            onConfirm={handleSucessSweetAlert}
          ></SweetAlert>
        )}
      </div>

      <div>
        {isFailure && (
          <SweetAlert
            danger
            style={{ color: "black" }}
            title="User Signup is failed."
            onConfirm={handleFailureSweetAlert}
          >
            {errorMessage}
          </SweetAlert>
        )}
      </div>
    </Form>
  );
}
export default SignUpVerificationCode;
