import React from "react";
import ReactDOM from "react-dom";
// import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
// import "./App.css";

import { RealmAppProvider } from "./providers/RealmApp";
import { Provider } from "react-redux";
import store from "./storestate/store";
import { Auth0Provider } from "@auth0/auth0-react";
import history from "./utils/history";
import { getConfig } from "./config";
import { BrowserRouter as Router } from "react-router-dom";
export const APP_ID = process.env.REACT_APP_REALM_APP_ID;
// export const AUTH_CLIENTID = process.env.REACT_APP_AUTH_CLIENTID;
// export const AUTH_DOMAIN = process.env.REACT_APP_AUTH_DOMAIN;
// export const AUTH_AUDIENCE = process.env.REACT_APP_AUTH_AUDIENCE;
/* Auth 0 */

// const onRedirectCallback = (appState) => {
//   history.push(
//     appState && appState.returnTo ? appState.returnTo : window.location.pathname
//   );
// };

const onRedirectCallback = (appState) => {
  if (appState) {
    const appStateJSON = appState.appStateJSON;
    
    console.log("appStateJSON", appStateJSON);
    if (appStateJSON) {
      const appStateDecodeJSON = decodeURIComponent(appStateJSON);
      const paramsinfo = JSON.parse(appStateDecodeJSON);
    const queryString = `?appStateJSON=${paramsinfo.pathName}`;
    if (queryString && queryString.includes("/manage")) {
      window.location.search = `?appStateJSON=${appStateJSON}`;
    }
  }
  }
  }

// Please see https://auth0.github.io/auth0-react/interfaces/Auth0ProviderOptions.html
// for a full list of the available properties on the provider
const config = getConfig();

const providerConfig = {
  domain: config.domain,
  clientId: config.clientId,
  onRedirectCallback,
  authorizationParams: {
    redirect_uri: window.location.origin,
    ...(config.audience ? { audience: config.audience } : null),
  },
};
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Auth0Provider {...providerConfig}>
        <RealmAppProvider appId={APP_ID}>
          <Router>
            <App />
          </Router>
        </RealmAppProvider>
      </Auth0Provider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
