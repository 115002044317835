export const getTemplateFolders = async (Ctechapp) => {
  try {
    //console.log("Ctechapp?.id", Ctechapp?.id);
    const data = await Ctechapp.functions.getTemplateFolders(Ctechapp?.id);
    return data;
  } catch (error) {
    console.log("error : ", error);
    throw Error(error);
  }
};

export const getTemplateFoldersMinimumData = async (authID, Ctechapp) => {
  try {
    //console.log("Ctechapp?.id", Ctechapp?.id);
    const data = await Ctechapp.functions.getTemplateFoldersMinimumData(
      authID
    );
    return data;
  } catch (error) {
    console.log("error : ", error);
    throw Error(error);
  }
};

export const getFolderTemplateByParentID = async (ParentId,loggedinUserID, Ctechapp) => {
  try {
    //console.log("Ctechapp?.id", Ctechapp?.id);
    const data = await Ctechapp.functions.getFolderTemplateByParentID(
      ParentId,
      loggedinUserID
    );
    return data;
  } catch (error) {
    console.log("error : ", error);
    throw Error(error);
  }
};

// services for insertProject
export const insertFolderTemplate = async (folderdata, Ctechapp, loggedinby) => {
  try {
    const data = await Ctechapp.functions.insertFolderTemplate(
      JSON.stringify(folderdata),
      loggedinby
    );
    return data;
  } catch (error) {
    console.log("error : ", error);
    throw Error(error);
  }
};

//DeleteFolderTemplateByID
export const deleteFolderTemplateByID = async (id, Ctechapp, loggedinby) => {
  try {
    const data = await Ctechapp.functions.deleteFolderTemplateByID(
      id,
      loggedinby
    );
    return data;
  } catch (error) {
    console.log("error : ", error);
    throw Error(error);
  }
};
//updateFolderTemplateByID
export const updateFolderTemplateByID = async (
  folderdata,
  Ctechapp,
  loggedinby
) => {
  try {
    const data = await Ctechapp.functions.updateFolderTemplateByID(
      JSON.stringify(folderdata),
      loggedinby
    );
    return data;
  } catch (error) {
    console.log("error : ", error);
    throw Error(error);
  }
};
