import { Panel, PanelHeader, PanelBody } from "../../components/panel/panel";
import React, { useState, useEffect, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import { useHistory } from "react-router";
import Select from "react-select";
import customstyles from "../../components/customstyles/react-select-styles";
import ClipLoader from "react-spinners/ClipLoader";
import Table from "react-bootstrap/Table";
import Spinner from "react-bootstrap/Spinner";
// import {
//   getappcompanylistbyappname_userid,
//   getappinfobyappname_userid_companyid,
// } from "../../services/applications_services";
import {
  getimportcontactsbyappname,
  getuserbyauthproviderid,
} from "../../services/users_services";
import { Alert } from "react-bootstrap";

import { GetImportUserListFromQuickBooks } from "../../services/quickbooks_project_services";
import { createImportProjectTask } from "../../services/import_project_task_services";
import { useRealmApp } from "../../providers/RealmApp";
import { useAuth0 } from "@auth0/auth0-react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const _appname = "QuickBooks";

function QuickbooksImportWizard() {
  const [tabQuickBooksusers, setquickbooksusers] = useState(true);
  const [tabApproveWithoutverification, setApproveWithoutverification] =
    useState(false);
  const [tabSendverification, setSendverification] = useState(false);
  const [userStepbutton2, setuserStepbutton2] = useState(false);
  const [userStepbutton3, setuserStepbutton3] = useState(false);
  const [page, Stepage] = useState(0);
  const { isLoading, error, isAuthenticated, user } = useAuth0();

  const [selectedSourceUser, setSelectedSourceUser] = useState([]);
  const [userapisettingsinfo, setUserapisettingsinfo] = useState([]);
  const [selectedMatchingSourceUser, setSelectedMatchingSourceUser] = useState(
    []
  );

  const selectActionOptions = [
    { value: "0", label: "Select" },
    { value: "1", label: "Approve w/o Verification" },
    { value: "2", label: "Review and Send Verification" },
  ];

  const [formData, setFormData] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    firstName: "",
    lastName: "",
    username: "",
    nationality: "",
    other: "",
  });

  const FormTitles = [
    "quickbooksusers",
    "approvewithoutverification",
    "sendverification",
  ];
  const [selectedcompany, setSelectedCompany] = useState("DEFAULT");

  var [companylist, setCompanyList] = useState([]);
  const [userlist, setUserList] = useState([]);
  const [userlist_step2, setUserList_Step2] = useState([]);
  const [userlist_step3, setUserList_Step3] = useState([]);
  var [quickbooksuserlist, setQuickBooksuserlist] = useState([]);
  var [apiuserlist, setApiuserlist] = useState([]);
  const [loading, setLoading] = useState(false);
  const [order, SetOrder] = useState("ASC");
  const [result, SetResult] = useState("0");
  const ctechapp = useRealmApp();
  const [errordisplay, SetErrorDisplay] = useState("");
  let [userackid, SetUserAckId] = useState("0");
  var sortJsonArray = require("sort-json-array");

  const [userinfo, setUserinfo] = useState({
    role: "",
  });

  var array = [];

  const sorting = (col) => {
    if (order === "ASC") {
      const sorted = userlist.sort((a, b) =>
        a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
      );
      setUserList(sorted);
      SetOrder("DSC");
    }
    if (order === "DSC") {
      const sorted = userlist.sort((a, b) =>
        a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
      );
      setUserList(sorted);
      SetOrder("ASC");
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      getUserInfoByID();
      // getCompanyListData(ctechapp.currentUser);
    }
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      // getDatauserinfobyCompanyid(ctechapp.currentUser);
    }
  }, [selectedcompany]);

  async function getUserInfoByID() {
    try {
      getuserbyauthproviderid(user.sub, ctechapp.currentUser).then(
        (response) => {
          let user_info = JSON.parse(response);
          if (response !== undefined || user_info.length !== 0) {
            setUserinfo(user_info[0]);
          }
        }
      );
    } catch (exception) {
      console.log(exception);
    }
  }

  // async function getCompanyListData() {
  //   getappcompanylistbyappname_userid(
  //     _appname,
  //     ctechapp.currentUser,
  //     user.sub
  //   ).then((response) => {
  //     if (response !== undefined) {
  //       let company_info = JSON.parse(response);
  //       setCompanyList(company_info);
  //     }
  //   });
  // }

  const handleChange_SoureceSelect = (e, data) => {
    const { name, checked } = e.target;
    if (checked) {
      // if cheked and selectall checkbox add all fileds to selectedList
      if (name === "allSelectSource") {
        setSelectedSourceUser(userlist);
      } else {
        // if cheked and specific checkbox add specific field to selectedList
        setSelectedSourceUser([...selectedSourceUser, data]);
      }
    } else {
      // if uncheked and selectall checkbox add remove all fileds from selectedList
      if (name === "allSelectSource") {
        setSelectedSourceUser([]);
      } else {
        // if uncheked and specific checkbox remove specific field from selectedList
        let tempuser = selectedSourceUser.filter((item) => item.id !== data.id);
        setSelectedSourceUser(tempuser);
      }
    }
  };

  const handleChange_MatchingSoureceSelect = (e, data) => {
    const { name, checked } = e.target;
    if (checked) {
      // if cheked and selectall checkbox add all fileds to selectedList
      if (name === "allSelectMatchingSource") {
        setSelectedMatchingSourceUser(userlist);
      } else {
        // if cheked and specific checkbox add specific field to selectedList
        setSelectedMatchingSourceUser([...selectedMatchingSourceUser, data]);
        var _userlist = [...userlist];
        let index = _userlist.findIndex((_user) => _user.id === data.linkid);

        if (index !== -1) {
          _userlist[index].contactid = data.id;
          setUserList(_userlist);
        }
      }
    } else {
      // if uncheked and selectall checkbox add remove all fileds from selectedList
      if (name === "allSelectMatchingSource") {
        setSelectedMatchingSourceUser([]);
      } else {
        // if uncheked and specific checkbox remove specific field from selectedList
        let tempuser = selectedMatchingSourceUser.filter(
          (item) => item.id !== data.id
        );
        console.log(
          "selectedMatchingSourceUser : ",
          selectedMatchingSourceUser
        );
        console.log("tempuser : ", tempuser);
        setSelectedMatchingSourceUser(tempuser);
      }
    }
  };

  // function getDatauserinfobyCompanyid() {
  //   try {
  //     if (selectedcompany !== "DEFAULT") {
  //       setLoading(true);
  //       getappinfobyappname_userid_companyid(
  //         _appname,
  //         selectedcompany,
  //         ctechapp.currentUser,
  //         user.sub
  //       ).then((response) => {
  //         if (response !== undefined || response !== "null") {
  //           let company_info = JSON.parse(response);
  //           let res = company_info[0];
  //           setUserList([]);

  //           getUserListData(
  //             res.appaccesstokenhostname,
  //             res.appresthostname,
  //             selectedcompany,
  //             res.appclientid,
  //             res.appclientsecret,
  //             res.appuserinfo[0].apprefreshtoken,
  //             res.appuserinfo[0].apptoken,
  //             res.appredirecturi
  //           );
  //         }
  //       });
  //     }
  //   } catch (Exception) {
  //     //  setLoading(false);
  //     console.log("getDatauserinfobyCompanyid error : ", Exception);
  //   }
  // }

  async function getUserListData(
    quickbooksAccessTokenHostName,
    quickbooksRestAPIHostName,
    quickbooksCompanyID,
    quickbooksClientID,
    quickbooksClientSecret,
    quickbooksRefreshToken,
    quickbooksAppToken,
    quickbooksRedirectUri
  ) {
    GetImportUserListFromQuickBooks(
      quickbooksCompanyID,
      quickbooksAccessTokenHostName,
      quickbooksClientID,
      quickbooksClientSecret,
      quickbooksRefreshToken,
      quickbooksAppToken,
      quickbooksRedirectUri,
      quickbooksRestAPIHostName,
      ctechapp.currentUser,
      user.sub
    ).then((response) => {
      if (response !== undefined || response.length !== 0) {
        const result = response;
        //console.log(JSON.stringify("result:"+ result));
        result.forEach((row) => {
          // console.log(JSON.stringify(row));
          if (
            row.primaryPhone != undefined &&
            row.primaryPhone.freeFormNumber
          ) {
            //console.log(row.primaryPhone.freeFormNumber)

            row.primaryPhone.freeFormNumber =
              row.primaryPhone.freeFormNumber.replace("-", "");
            row.primaryPhone.freeFormNumber =
              row.primaryPhone.freeFormNumber.replace("(", "");
            row.primaryPhone.freeFormNumber =
              row.primaryPhone.freeFormNumber.replace(")", "");
            row.primaryPhone.freeFormNumber =
              row.primaryPhone.freeFormNumber.replace(" ", "");
            row.primaryPhone.freeFormNumber =
              row.primaryPhone.freeFormNumber.replace("-", "");
            row.primaryPhone.freeFormNumber =
              row.primaryPhone.freeFormNumber.replace("+", "");
            row.primaryPhone.freeFormNumber =
              row.primaryPhone.freeFormNumber.replace(" ", "");
            row.primaryPhone.freeFormNumber =
              row.primaryPhone.freeFormNumber.replace(" ", "");
            row.primaryPhone.freeFormNumber =
              row.primaryPhone.freeFormNumber.replace(" ", "");
          }
        });
        setQuickBooksuserlist(result);
        getapiDatauserinfo(result);
      }
    });
  }

  function getapiDatauserinfo(_quickbooksuserslist) {
    getimportcontactsbyappname(_appname, ctechapp.currentUser, user.sub).then(
      (response) => {
        if (response !== undefined) {
          let user_list = JSON.parse(response);
          setApiuserlist(user_list);
          GetBothUserList(_quickbooksuserslist, user_list);
        }
      }
    );
  }

  const handleReload = () => {
    window.location.reload();
  };
  const formatphone = (phone) => {
    phone = phone.replace("+", "");
    phone = phone.replace("(", "");
    phone = phone.replace("(", "");
    phone = phone.replace("-", "");
    phone = phone.replace("-", "");
    return phone;
  };
  async function GetBothUserList(_quickbooksuserslist, _contactusersList) {
    console.log("_quickbooksuserslist ", _quickbooksuserslist);
    //  setUserList([]);
    var _quickbooksusersList = [];
    let sortorder = 1;

    for (const quickbooksuserinfo of _quickbooksuserslist) {
      let firstname = "";
      let lastname = "";
      let contactrole = "";
      let id = quickbooksuserinfo.id;
      if (quickbooksuserinfo.sourceType === "Employee") {
        firstname = quickbooksuserinfo.givenName;
        lastname = quickbooksuserinfo.familyName;
        contactrole = quickbooksuserinfo.sourceType;
      } else if (quickbooksuserinfo.sourceType === "Vendor") {
        firstname = quickbooksuserinfo.givenName;
        lastname = quickbooksuserinfo.familyName;
        if (quickbooksuserinfo.vendor1099 === false) {
          contactrole = quickbooksuserinfo.sourceType;
        } else {
          contactrole = "Individual Contractor";
        }
      } else if (quickbooksuserinfo.sourceType === "Customer") {
        firstname = quickbooksuserinfo.givenName;
        lastname = quickbooksuserinfo.familyName;
        contactrole = quickbooksuserinfo.sourceType;
      }
      let email = quickbooksuserinfo.primaryEmailAddr?.address;
      let phone = quickbooksuserinfo.primaryPhone?.freeFormNumber;
      let source = quickbooksuserinfo.source;

      var _user = {};
      _user["id"] = id;
      _user["firstname"] = firstname;
      _user["lastname"] = lastname;
      _user["email"] = email;
      _user["phone"] = phone;
      _user["contactrole"] = contactrole;
      _user["source"] = source;
      _user["is_active"] = 1;
      _user["companyid"] = selectedcompany;
      _user["contactid"] = "";
      _user["sortorder"] = sortorder;
      _user["sortby"] = contactrole;
      _user["linkid"] = id;
      //check whether user exists in projectmaster already.

      const filteredbysourceuserid = [];
      _contactusersList.filter((contactinfo) => {
        //console.log("contactinfo",contactinfo);
        if (
          contactinfo !== undefined &&
          contactinfo?.sourceSystem !== undefined
        ) {
          contactinfo?.sourceSystem.map((sourcerecord) => {
            if (
              _user?.id === sourcerecord.appuserid &&
              _user?.companyid === sourcerecord.companyid
            )
              filteredbysourceuserid.push(contactinfo);
          });
        }
      });

      if (filteredbysourceuserid.length === 0) {
        _quickbooksusersList.push(_user);
        //check for confidence factor and push related records in the user array

        //filterby emailand firstname and lastname
        const filteredbyemailandfirstname = _contactusersList.filter(
          (contactinfo) =>
            contactinfo?.email !== undefined &&
            contactinfo?.email !== null &&
            contactinfo?.email !== "" &&
            _user.email === contactinfo?.email &&
            _user.firstname === contactinfo?.firstname &&
            _user.lastname === contactinfo?.lastname
        );
        if (filteredbyemailandfirstname.length > 0) {
          var _localuser = {};
          _localuser["id"] = filteredbyemailandfirstname[0]._id;
          _localuser["firstname"] = filteredbyemailandfirstname[0].firstname;
          _localuser["lastname"] = filteredbyemailandfirstname[0].lastname;
          _localuser["email"] = filteredbyemailandfirstname[0].email;
          _localuser["phone"] = filteredbyemailandfirstname[0].phone;
          _localuser["is_active"] = 1;
          _localuser["matchingsource"] = "Project Master";
          _localuser["sortorder"] = sortorder;
          _localuser["sortby"] = contactrole;
          _localuser["linkid"] = id;
          console.log("_localuser1 ", _localuser);
          // 👇️ check if array contains object
          const isFound = _quickbooksusersList.some((element) => {
            if (element.id === _localuser.id) {
              return true;
            }
            return false;
          });
          if (!isFound) {
            _quickbooksusersList.push(_localuser);
          }
        }

        //filterby phoneand firstnameand lastname
        const filteredbyphoneandfirstname = [];
        _contactusersList.filter((contactinfo) => {
          if (
            contactinfo?.phone !== undefined &&
            contactinfo?.phone !== null &&
            contactinfo?.phone !== "" &&
            // _user.phone === contactinfo?.phone &&
            _user.firstname === contactinfo?.firstname &&
            _user.lastname === contactinfo?.lastname
          ) {
            var contactinfophone = formatphone(phone);
            var userinfophone = formatphone(_user.phone);
            if (contactinfophone === userinfophone)
              filteredbyphoneandfirstname.push(contactinfo);
          }
        });
        if (filteredbyphoneandfirstname.length > 0) {
          var _localuser = {};
          _localuser["id"] = filteredbyphoneandfirstname[0]._id;
          _localuser["firstname"] = filteredbyphoneandfirstname[0].firstname;
          _localuser["lastname"] = filteredbyphoneandfirstname[0].lastname;
          _localuser["email"] = filteredbyphoneandfirstname[0].email;
          _localuser["phone"] = filteredbyphoneandfirstname[0].phone;
          _localuser["is_active"] = 1;
          _localuser["matchingsource"] = "Project Master";
          _localuser["sortorder"] = sortorder;
          _localuser["sortby"] = contactrole;
          _localuser["linkid"] = id;
          console.log("_localuser1 ", _localuser);
          // 👇️ check if array contains object
          const isFound = _quickbooksusersList.some((element) => {
            if (element.id === _localuser.id) {
              return true;
            }
            return false;
          });
          if (!isFound) {
            _quickbooksusersList.push(_localuser);
          }
        }

        //filterby email and firstname
        const filteredbyemailandfirstnameonly = _contactusersList.filter(
          (contactinfo) =>
            contactinfo?.email !== undefined &&
            contactinfo?.email !== null &&
            contactinfo?.email !== "" &&
            _user.email === contactinfo?.email &&
            _user.firstname === contactinfo?.firstname
        );
        if (filteredbyemailandfirstnameonly.length > 0) {
          var _localuser = {};
          _localuser["id"] = filteredbyemailandfirstnameonly[0]._id;
          _localuser["firstname"] =
            filteredbyemailandfirstnameonly[0].firstname;
          _localuser["lastname"] = filteredbyemailandfirstnameonly[0].lastname;
          _localuser["email"] = filteredbyemailandfirstnameonly[0].email;
          _localuser["phone"] = filteredbyemailandfirstnameonly[0].phone;
          _localuser["is_active"] = 1;
          _localuser["matchingsource"] = "Project Master";
          _localuser["sortorder"] = sortorder;
          _localuser["sortby"] = email;
          _localuser["linkid"] = id;
          console.log("_localuser1 ", _localuser);
          // 👇️ check if array contains object
          const isFound = _quickbooksusersList.some((element) => {
            if (element.id === _localuser.id) {
              return true;
            }
            return false;
          });
          if (!isFound) {
            _quickbooksusersList.push(_localuser);
          }
        }

        //filterby emailand lastname
        const filteredbyemailandlastnameonly = _contactusersList.filter(
          (contactinfo) =>
            contactinfo?.email !== undefined &&
            contactinfo?.email !== null &&
            contactinfo?.email !== "" &&
            _user.email === contactinfo?.email &&
            _user.lastname === contactinfo?.lastname
        );
        if (filteredbyemailandlastnameonly.length > 0) {
          var _localuser = {};
          _localuser["id"] = filteredbyemailandlastnameonly[0]._id;
          _localuser["firstname"] = filteredbyemailandlastnameonly[0].firstname;
          _localuser["lastname"] = filteredbyemailandlastnameonly[0].lastname;
          _localuser["email"] = filteredbyemailandlastnameonly[0].email;
          _localuser["phone"] = filteredbyemailandlastnameonly[0].phone;
          _localuser["is_active"] = 1;
          _localuser["matchingsource"] = "Project Master";
          _localuser["sortorder"] = sortorder;
          _localuser["sortby"] = email;
          _localuser["linkid"] = id;
          console.log("_localuser1 ", _localuser);
          // 👇️ check if array contains object
          const isFound = _quickbooksusersList.some((element) => {
            if (element.id === _localuser.id) {
              return true;
            }
            return false;
          });
          if (!isFound) {
            _quickbooksusersList.push(_localuser);
          }
        }

        sortorder = sortorder + 1;
      }
    }
    console.log("_contactusersList ", _contactusersList);

    let result = _quickbooksuserslist.includes("errors");
    if (result === true) {
      var _displaymsg = _quickbooksuserslist.replace(/"/g, "");
      _displaymsg = _displaymsg.replace("{errors:", "");
      _displaymsg = _displaymsg.replace("}", "");
      SetErrorDisplay(_displaymsg);
    }

    try {
      setUserList(sortJsonArray(_quickbooksusersList, "sortby"));
      //setUserList(_quickbooksusersList);
      console.log("_usersList", _quickbooksusersList);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }
  const showTab = (tab) => {
    console.log("tab ", tab);
    switch (tab) {
      case "quickbooksusers":
        setquickbooksusers(true);
        setApproveWithoutverification(false);
        setSendverification(false);
        return;
      case "approvewithoutverification":
        setquickbooksusers(false);
        setApproveWithoutverification(true);
        setSendverification(false);
        return;
      case "sendverification":
        setquickbooksusers(false);
        setApproveWithoutverification(false);
        setSendverification(true);
        return;
    }
  };

  const handleCompanyChange = (event) => {
    setSelectedCompany(event.target.value);
  };

  const handleInputChange = (evnt, index) => {
    const { name, value } = evnt.target;
    const rowsInput = [...userlist];
    rowsInput[index]["selectdropdownsource"] = value;
    setUserList(rowsInput);
    console.log(rowsInput);
  };

  const handleHeaderInputChange = (evnt) => {
    const { name, value } = evnt.target;
    console.log("value ", value);
    const rowsInput = [...userlist];
    rowsInput.map((row, index) => {
      row["selectdropdownsource"] = value;
    });
    setUserList(rowsInput);
    console.log(rowsInput);
  };

  async function approvewithoutverificationclick() {
    console.log("userlist_step2 : " + JSON.stringify(userlist_step2));

    let userquickbooksapiInfo = {
      quickbooksCompanyID: selectedcompany,
      appname: "QuickBooks",
      LoggedinBy: user.sub,
      signupurl: window.location.origin,
    };

    createImportProjectTask(
      userquickbooksapiInfo,
      userlist_step2,
      ctechapp.currentUser,
      user.sub
    )
      .then((response) => {
        if (response != undefined) {
          userlist_step2.map((userlist2, index) => {
            const objIndex = userlist.findIndex(
              (obj) => obj.id === userlist2.id
            );
            if (objIndex !== -1) {
              const list = [...userlist];
              list[objIndex]["is_active"] = 0;
              setUserList(list);
            }
          });
          setuserStepbutton2(true);
          console.log("response : ", response);
          console.log("User pushed Successfully.");
          toast("QuickBooks contact record import has been initiated");
        }
      })
      .catch((err) => {
        console.log("error : " + err);

        toast("QuickBooks contact record import has been not initiated");
      });
  }

  async function approvewithverificationclick() {
    console.log("userlist_step3 : " + JSON.stringify(userlist_step3));

    let userquickbooksapiInfo = {
      quickbooksCompanyID: selectedcompany,
      appname: "QuickBooks",
      LoggedinBy: user.sub,
      signupurl: window.location.origin,
    };

    createImportProjectTask(
      userquickbooksapiInfo,
      userlist_step3,
      ctechapp.currentUser,
      user.sub
    )
      .then((response) => {
        if (response != undefined) {
          userlist_step3.map((userlist3, index) => {
            const objIndex = userlist.findIndex(
              (obj) => obj.id === userlist3.id
            );
            if (objIndex !== -1) {
              const list = [...userlist];
              list[objIndex]["is_active"] = 0;
              setUserList(list);
            }
          });
          setuserStepbutton3(true);
          console.log("response : ", response);
          console.log("User pushed Successfully.");
          toast("QuickBooks contact record import has been initiated");
        }
      })
      .catch((err) => {
        console.log("error : " + err);
        toast("QuickBooks contact record import has been not initiated");
      });
  }

  return (
    <div className="tab-content p-0">
      <h3 align="center">QuickBooks Import Wizard</h3>
      <div className="d-flex justify-content-start align-items-center  row mb-3">
        <div className="col-sm-12 col-lg-2">
          <label className="">App Display Name:</label>
        </div>
        <div className="col-sm-12 col-lg-3">
          <select
            value={selectedcompany}
            defaultValue={"DEFAULT"}
            disabled={tabApproveWithoutverification || tabSendverification}
            styles={customstyles}
            className="form-select form-control"
            onChange={handleCompanyChange}
          >
            <option value="DEFAULT" disabled>
              Select company
            </option>
            {companylist.map((companylist) => (
              <option value={companylist.appaccountid}>
                {companylist.appuserinfo[0].appdisplayname}
              </option>
            ))}
          </select>
        </div>
        <div className="col-sm-12 col-lg-3">
          <button className=" btn btn-xs " onClick={handleReload}>
            <i class="fas fa-sync fa-spin"></i>
          </button>
        </div>
      </div>
      {console.log("loading", loading)}
      {loading && (
        <div>
          <Spinner animation="border" variant="info" />
        </div>
      )}

      <div
        className={
          "tab-pane fade " + (tabQuickBooksusers ? "show active " : "")
        }
      >
        <div className="col-xl-12">
          <Panel>
            <PanelHeader noButton={true}>
              <div className="d-flex align-items-center">
                <div>
                  <ul className="profile-header-tab nav nav-tabs">
                    <li className="nav-item me-2" key="quickbooksusers">
                      <Link
                        // onClick={(e) => {
                        //   showTab("quickbooksusers");
                        //   Stepage(0);
                        // }}
                        className={
                          "nav-link " + (tabQuickBooksusers ? "active " : "")
                        }
                      >
                        Step 1
                      </Link>
                    </li>
                    <li className="nav-item me-2" key="quickbooksusers">
                      <Link
                        // onClick={(e) => {
                        //   Stepage(1);
                        //   showTab("approvewithoutverification");
                        // }}
                        className={
                          "nav-link " +
                          (tabApproveWithoutverification ? "active " : "")
                        }
                      >
                        Step 2
                      </Link>
                    </li>
                    <li className="nav-item" key="sendverification">
                      <Link
                        // onClick={(e) => {
                        //   Stepage(2);
                        //   showTab("sendverification");
                        // }}
                        className={
                          "nav-link " + (tabSendverification ? "active " : "")
                        }
                      >
                        Step 3
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </PanelHeader>

            <PanelBody className="p-0 m-2">
              <label for="error1">{errordisplay}</label>
              <Table striped bordered hover responsive>
                <thead className="tablehead">
                  <tr>
                    <th>
                      {" "}
                      <input
                        type="checkbox"
                        className="form-check-input"
                        name="allSelectSource"
                        id="allSelectSource"
                        // allSelect selected when both length equal
                        // slecteduser === allUser
                        checked={
                          selectedSourceUser?.length === userlist?.length &&
                          selectedSourceUser?.length !== 0
                        }
                        onChange={(e) =>
                          handleChange_SoureceSelect(e, userlist)
                        }
                      />
                    </th>
                    <th>Source</th>
                    <th>
                      Matching Source{" "}
                      <input
                        type="checkbox"
                        className="form-check-input"
                        name="allSelectMatchingSource"
                        id="allSelectMatchingSource"
                        // allSelect selected when both length equal
                        // slecteduser === allUser
                        checked={
                          selectedMatchingSourceUser?.length ===
                            userlist?.length &&
                          selectedMatchingSourceUser?.length !== 0
                        }
                        onChange={(e) =>
                          handleChange_MatchingSoureceSelect(e, userlist)
                        }
                      />
                    </th>
                    <th onClick={() => sorting("contactrole")}>Contact Role</th>
                    <th onClick={() => sorting("firstname")}>First Name</th>
                    <th onClick={() => sorting("lastname")}>Last Name</th>
                    <th onClick={() => sorting("email")}>Email</th>
                    <th>Phone</th>
                    <th>
                      <div className="row">
                        <label className="col-3 mt-3">Action</label>

                        <div className="col-9 ">
                          <Select
                            width={"10%"}
                            name="reacord_header_action"
                            styles={customstyles}
                            className="form-control"
                            options={selectActionOptions}
                            onChange={(val) => {
                              handleHeaderInputChange({
                                target: {
                                  name: "reacord_header_action",
                                  value: val.value,
                                },
                              });
                            }}
                          ></Select>
                        </div>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {console.log("userlist " + userlist.length)}
                  {userlist.map((row, index) => (
                    <tr>
                      {!row.hasOwnProperty("matchingsource") ? (
                        <td>
                          <input
                            type="checkbox"
                            checked={selectedSourceUser.some(
                              (item) => item?.id === row.id
                            )}
                            onChange={(e) => handleChange_SoureceSelect(e, row)}
                            name="selectsourceoption"
                            value={row.id}
                          />
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td>{row.source}</td>
                      {row.hasOwnProperty("matchingsource") ? (
                        <td>
                          <div class="form-check checkbox-lg">
                            <input
                              type="checkbox"
                              className="me-3 "
                              name="selectmatchingsourceoption"
                              value={row.id}
                              checked={selectedMatchingSourceUser.some(
                                (item) => item?.id === row.id
                              )}
                              onChange={(e) =>
                                handleChange_MatchingSoureceSelect(e, row)
                              }
                            />
                            {row.matchingsource}
                          </div>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td>{row.contactrole}</td>
                      <td>{row.firstname}</td>
                      <td> {row.lastname}</td>
                      <td> {row.email}</td>
                      <td>{row.phone}</td>
                      {!row.hasOwnProperty("matchingsource") ? (
                        <td>
                          {" "}
                          <div className="col-10 px-3">
                            <Select
                              name="reacord_action"
                              styles={customstyles}
                              className="form-control"
                              options={selectActionOptions}
                              value={selectActionOptions.filter(
                                (record) =>
                                  record.value === row.selectdropdownsource
                              )}
                              onChange={(val) => {
                                handleInputChange(
                                  {
                                    target: {
                                      name: "reacord_action",
                                      value: val.value,
                                    },
                                  },
                                  index
                                );
                              }}
                            ></Select>
                          </div>
                        </td>
                      ) : (
                        <td></td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </Table>
            </PanelBody>
          </Panel>
        </div>
      </div>
      <div
        className={
          "tab-pane fade " +
          (tabApproveWithoutverification ? "show active " : "")
        }
      >
        <div className="col-xl-12">
          <Panel>
            <PanelHeader noButton={true}>
              <div className="d-flex align-items-center">
                <div>
                  <ul className="profile-header-tab nav nav-tabs">
                    <li className="nav-item me-2" key="quickbooksusers">
                      <Link
                        // onClick={(e) => {
                        //   Stepage(0);
                        //   showTab("quickbooksusers");
                        // }}
                        className={
                          "nav-link " + (tabQuickBooksusers ? "active " : "")
                        }
                      >
                        Step 1
                      </Link>
                    </li>
                    <li className="nav-item me-2" key="quickbooksusers">
                      <Link
                        // onClick={(e) => {
                        //   Stepage(1);
                        //   showTab("approvewithoutverification");
                        // }}
                        className={
                          "nav-link " +
                          (tabApproveWithoutverification ? "active " : "")
                        }
                      >
                        Step 2
                      </Link>
                    </li>
                    <li className="nav-item" key="sendverification">
                      <Link
                        // onClick={(e) => {
                        //   Stepage(2);
                        //   showTab("sendverification");
                        // }}
                        className={
                          "nav-link " + (tabSendverification ? "active " : "")
                        }
                      >
                        Step 3
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </PanelHeader>

            <PanelBody className="p-0 m-2">
              <label for="error1">{errordisplay}</label>
              <Table striped bordered hover>
                <thead className="tablehead">
                  <tr>
                    <th onClick={() => sorting("contactrole")}>Contact Role</th>
                    <th onClick={() => sorting("firstname")}>First Name</th>
                    <th onClick={() => sorting("lastname")}>Last Name</th>
                    <th onClick={() => sorting("email")}>Email</th>
                    <th>Phone</th>
                  </tr>
                </thead>
                <tbody>
                  {userlist_step2.map((row, index) => (
                    <tr>
                      <td>{row.contactrole}</td>
                      <td>{row.firstname}</td>
                      <td> {row.lastname}</td>
                      <td> {row.email}</td>
                      <td>{row.phone}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </PanelBody>
          </Panel>
          <button
            className="btn btn-sm me-2 float-end"
            disabled={userlist_step2.length == 0 || userStepbutton2}
            onClick={() => approvewithoutverificationclick()}
          >
            {"Approve without Verification >>"}
          </button>
        </div>
      </div>
      <div
        className={
          "tab-pane fade " + (tabSendverification ? "show active " : "")
        }
      >
        <div className="col-xl-12">
          <Panel>
            <PanelHeader noButton={true}>
              <div className="d-flex align-items-center">
                <div>
                  <ul className="profile-header-tab nav nav-tabs">
                    <li className="nav-item me-2" key="quickbooksusers">
                      <Link
                        // onClick={(e) => {
                        //   Stepage(0);
                        //   showTab("quickbooksusers");
                        // }}
                        className={
                          "nav-link " + (tabQuickBooksusers ? "active " : "")
                        }
                      >
                        Step 1
                      </Link>
                    </li>
                    <li className="nav-item me-2" key="quickbooksusers">
                      <Link
                        // onClick={(e) => {
                        //   Stepage(1);
                        //   showTab("approvewithoutverification");
                        // }}
                        className={
                          "nav-link " +
                          (tabApproveWithoutverification ? "active " : "")
                        }
                      >
                        Step 2
                      </Link>
                    </li>
                    <li className="nav-item" key="sendverification">
                      <Link
                        // onClick={(e) => {
                        //   Stepage(2);
                        //   showTab("sendverification");
                        // }}
                        className={
                          "nav-link " + (tabSendverification ? "active " : "")
                        }
                      >
                        Step 3
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </PanelHeader>

            <PanelBody className="p-0 m-2">
              <label for="error1">{errordisplay}</label>
              <Table striped bordered hover>
                <thead className="tablehead">
                  <tr>
                    <th onClick={() => sorting("contactrole")}>Contact Role</th>
                    <th onClick={() => sorting("firstname")}>First Name</th>
                    <th onClick={() => sorting("lastname")}>Last Name</th>
                    <th onClick={() => sorting("email")}>Email</th>
                    <th>Phone</th>
                  </tr>
                </thead>
                <tbody>
                  {userlist_step3.map((row, index) => (
                    <tr>
                      <td>{row.contactrole}</td>
                      <td>{row.firstname}</td>
                      <td> {row.lastname}</td>
                      <td> {row.email}</td>
                      <td>{row.phone}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </PanelBody>
          </Panel>
          <button
            className="btn btn-sm me-2 float-end"
            disabled={userlist_step3.length == 0 || userStepbutton3}
            onClick={() => approvewithverificationclick()}
          >
            {"Send Verification >>"}
          </button>
        </div>
      </div>
      <div className="footer m-3">
        <button
          className="btn btn-sm me-2"
          disabled={page == 0}
          onClick={(e) => {
            console.log("page - 1", page - 1);
            Stepage((currPage) => currPage - 1);
            if (page) showTab(FormTitles[page - 1]);
            const filtered1 = userlist.filter(
              (usr) => usr.selectdropdownsource === "1" && usr.is_active === 1
            );
            const filtered2 = userlist.filter(
              (usr) => usr.selectdropdownsource === "2" && usr.is_active === 1
            );
            setUserList_Step2(filtered1);
            setUserList_Step3(filtered2);
          }}
        >
          {"<< Prev"}
        </button>
        <button
          className="btn btn-sm"
          disabled={page === FormTitles.length - 1 ? true : false}
          onClick={(e) => {
            console.log("page + 1", page + 1);
            console.log("FormTitles[page] ", FormTitles[page + 1]);
            Stepage((currPage) => currPage + 1);
            showTab(FormTitles[page + 1]);
            const filtered1 = userlist.filter(
              (usr) => usr.selectdropdownsource === "1" && usr.is_active === 1
            );
            const filtered2 = userlist.filter(
              (usr) => usr.selectdropdownsource === "2" && usr.is_active === 1
            );

            console.log("userlist ", userlist);

            setUserList_Step2(filtered1);
            setUserList_Step3(filtered2);
          }}
        >
          {"Next >>"}
          {console.log(page === FormTitles.length - 1)}
        </button>
      </div>
    </div>
  );
}

export default QuickbooksImportWizard;
