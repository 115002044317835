import React, { useState, useRef } from "react";
import { useAsyncDebounce } from "react-table";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

export function GenericSearchWithExport({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  pageSize,
  setPageSize,
  filterDisabled,
  placeholder,
  exportEnabled,
  gexportHeaders,
  gexportData,
  gdisablePagination,
  gsearchDisabled,
  globalFilteredRows
}) {
  const [value, setValue] = useState(globalFilter);
  const [dropdownOpen, setdropdownOpen] = useState(false);
  const [dropdownExportOpen, setdropdownExportOpen] = useState(false);
  const componentRef = useRef();
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 300);

  const toggle = () => {
    setdropdownOpen(!dropdownOpen);
  };

  return (
    <div>
       {
        ((globalFilteredRows.length > 10 || 
          ( globalFilteredRows.length <= 10 && value!==undefined && value!==""))) && (
            <div className="input-group mb-0">
            {!filterDisabled && (
              <div className="dropdown me-2">
                <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                  <DropdownToggle color="white" className={"btn-me"} caret>
                    Filter <span className="caret ms-3px"></span>
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem>
                      <i className="fa fa-circle fs-9px fa-fw me-2"></i> All
                    </DropdownItem>
                    {/**
                      <DropdownItem><i className="fa fa-circle fs-9px fa-fw me-2 text-muted"></i> Item1</DropdownItem>
                      <DropdownItem><i className="fa fa-circle fs-9px fa-fw me-2 text-blue"></i> Item2</DropdownItem>
                      */}
                  </DropdownMenu>
                </Dropdown>
              </div>
            )}
      
            {!gsearchDisabled && (
              <div className="flex-fill position-relative ml-1 me-2">
                <div className="input-group mb-md-0">
                  {/*  <div className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 start-5" style={{zIndex: 10}}>
                    <i className="fa fa-search opacity-5"></i>
                </div> */}
                  <input
                    type="text"
                    className="form-control px-35px bg-light"
                    value={value || ""}
                    onChange={(e) => {
                      setValue(e.target.value);
                      onChange(e.target.value);
                    }}
                    placeholder={placeholder}
                  />
                  {/* <button color="primary"
                  onClick={1} 
                  className="btn btn-primary btn-sm"> 
                  Search
                </button> */}
                  {/* <Button color="primary" className="rounded-0 btn-lg"><i className="fa fa-search fa-fw"></i> Search</Button> */}
                </div>
              </div>
            )}
            {!gdisablePagination && (
              <div className="d-flex align-items-center">
                <label className="form-label pe-2 mb-0">Page size:</label>
                <div>
                  <select
                    className="form-select"
                    value={pageSize}
                    onChange={(e) => {
                      setPageSize(Number(e.target.value));
                    }}
                  >
                    {[10, 20, 30, 40, 50].map((pageSize) => (
                      <option key={pageSize} value={pageSize}>
                        Show {pageSize}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            )}
          </div>
 )
}
    </div>
  );
}
