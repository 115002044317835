//services for getAllCountries
export const getAllCountries = async (Ctechapp) => {
  try {
    const data = await Ctechapp.functions.getAllCountries();
    if (data !== undefined) {
      // console.log("data of getAllCountries: " + data);
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};

//services for getAllStates
export const getAllStates = async (Ctechapp) => {
  try {
    const data = await Ctechapp.functions.getAllStates();
    if (data !== undefined) {
      // console.log("data of getAllStates: " + data);
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};
//services for getAllCountryCodes
export const getAllCountryCodes = async (Ctechapp) => {
  try {
    const data = await Ctechapp.functions.getAllCountryCodes();
    if (data !== undefined) {
      // console.log("data of getAllCountryCodes: " + data);
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};

//services for getCollectionListsByFormName
export const getCollectionListsByFormName = async (formName, Ctechapp) => {
  try {
    const data = await Ctechapp.functions.getCollectionListsByFormName(
      formName
    );
    if (data !== undefined) {
      // console.log("data of getCollectionListsByFormName: " + data);
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};
// services for getAppsCollectionListsByFormName
export const getAppsCollectionListsByFormName = async (formName, Ctechapp) => {
  try {
    const data = await Ctechapp.functions.getAppsCollectionListsByFormName(
      formName
    );
    if (data !== undefined) {
      console.log("data of getCollectionListsByFormName: " + data);
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};
