import React from "react";
import { appConstants } from "../constants/app_constants";
import Moment from "moment";
const Footer = () => {
  return (
    <footer className="">
      <div className="container no-print">
        <div className="row">
          <div className="col-sm-6"></div>
          <div className="col-sm-6">
            <div className="footRight clearfix">
              <div className="footerLogo">

                <img
                  className="d-none d-md-block"
                  width="200px"
                  src={
                    localStorage.getItem("companyLogo") === undefined
                      ? localStorage.getItem("companyLogo")
                      : appConstants.bannerimage
                  }
                  alt="Project Sync Logo"
                />

                <img
                  className="d-md-none"
                  width="100px"

                  src={
                    localStorage.getItem("companyLogo") === undefined
                      ? localStorage.getItem("companyLogo")
                      : appConstants.bannersmallimage
                  }
                  alt="Project Sync Logo"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container no-print">

        <div className="row">
          <div className="d-flex mb-3 copyright no-print">
            <div className="ms-3 ">
              {" "}
              &copy; {Moment().year()} - Command Technologies, Inc. All rights
              reserved.
            </div>

            <div className="ms-auto pe-2">Ver: 1.0.5</div>
            {/* <div className="ms-5 container">
          &copy; 2023-{formatDateYear} Command Technologies, Inc. All rights
          reserved.
        </div>
      </div>
      <div className="copyright no-print col text-end pe-3">Ver: 1.0.1</div> */}
          </div>
        </div>
      </div>
    </footer>

  );

};

export default Footer;
