//services for getcompaniesbyid
export const getcompaniesbyid = async (id, Ctechapp) => {
  try {
    const data = await Ctechapp.functions.getcompaniesbyid(id);
    if (data !== undefined) {
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};
//services for updateCompaniesByID

export const updateCompaniesByID = async (userdata, Ctechapp) => {
  try {
    const data = await Ctechapp.functions.updateCompaniesByID(
      JSON.stringify(userdata)
    );

    return data;
  } catch (error) {
    console.log("error : ", error);
    throw Error(error);
  }
};
