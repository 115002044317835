import { useRef, useState, useCallback, useEffect } from "react";
const initialAddressState = {
  address1: "",
  address2: "",
  city: "",
  country: "",
  state: "",
  zipCode: "",
  lng: "",
  lat: "",
};

const customWindow = window;
const useLocationFinder = () => {
  const inputId = useRef();
  const [googleAddress, setGoogleAddress] = useState(initialAddressState);

  const handlePlaceSelect = useCallback(() => {
    const place = customWindow[inputId.current].getPlace();
    console.log("place", place);

    let _address = {};
    for (const component of place.address_components) {
      const type = component.types[0];
      switch (type) {
        case "street_number":
          _address["address1"] = component.long_name;
          break;
        case "route":
          _address["address2"] = component.long_name;
          break;
        case "locality":
          _address["city"] = component.long_name;
          break;
        case "administrative_area_level_1":
          _address["state"] = component.short_name;
          break;
        case "country":
          _address["country"] = component.long_name;
          break;
        case "postal_code":
          _address["zipCode"] = component.long_name;
          break;
        default:
          console.log("irrelevant component type");
          break;
      }
    }
    _address["lat"] = place.geometry.location.lat();
    _address["lng"] = place.geometry.location.lng();
    setGoogleAddress((preAddress) => {
      return { ...preAddress, ..._address };
    });
  }, []);

  //on mount, load google auto complete
  const onInputElementCreated = useCallback((element) => {
    if (element === null || element === undefined) return;

    inputId.current = element.id;
    const renderGoogle = () => {
      customWindow[element.id] =
        new customWindow.google.maps.places.Autocomplete(element, {});

      //listen for place change in input field
      customWindow[element.id].addListener("place_changed", handlePlaceSelect);
    };

    //if places script is already found then listen for load and then renderGoogle
    let found = document.getElementById("placesScript") ? true : false;
    if (!found) {
      const script = document.createElement("script");
      script.id = "placesScript";
      script.src =
        "https://maps.googleapis.com/maps/api/js?key=" +
        process.env.REACT_APP_GOOLGE_API_KEY +
        "&libraries=places";
      script.async = true;
      script.onload = () => renderGoogle();
      document.body.appendChild(script);
    }
    if (found) {
      document
        .getElementById("placesScript")
        ?.addEventListener("load", renderGoogle);
    }
  }, []);

  useEffect(() => {
    return () => {
      console.log(
        "customWindow[inputId?.current]",
        customWindow[inputId?.current]
      );
      //   if (customWindow[inputId?.current]?.removeListener)
      //     customWindow[inputId?.current]?.removeListener(
      //       "place_changed",
      //       handlePlaceSelect
      //     );
    };
  }, []);
  return { googleAddress, setGoogleAddress, onInputElementCreated };
};
export default useLocationFinder;
