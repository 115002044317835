import { Button, Form } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useRealmApp } from "../../providers/RealmApp";
import { appConstants } from "../../constants/app_constants";
import {
  getContactDetailSettingsByID,
  getContactDetailAffiliationLookupByID,
  updateContactDetailSettingsByID,
} from "../../services/project_detail_services";
import {
  getAdminUserListByUserID,
  getuserbyauthproviderid,
} from "../../services/users_services";
import UserAdminCard from "../../components/UserAdminCard";
import { useAuth0 } from "@auth0/auth0-react";
import SweetAlert from "react-bootstrap-sweetalert";
import { isEmail } from "validator";
import Select from "react-select";
import customstyles from "../../components/customstyles/react-select-styles";
import Loading from "../../components/Loading";
import FileBase from "react-file-base64";
import { v4 as uuidv4 } from "uuid";
function GeneralSettings() {
  const ctechapp = useRealmApp();
  const initialContactSettngs = {
    _id: "",
    _p_contactID: "",
    primaryCustomerID: "",
    primaryCustomerName: "",
    primaryCustomerLogo: "",
    primaryContactUserID: "",
    isAcive: "",
    state: "",
    type: "0",
  };
  const initialContactSettngsArray = {
    _id: "",
    settings: [initialContactSettngs],
  };
  const [contactSettingsArray, setcontactSettingsArray] = useState(
    initialContactSettngsArray
  );
  const [contactSettings, setcontactSettings] = useState(initialContactSettngs);

  const [errors, setErrors] = useState({
    _id: "",
    primaryCustomerName: "",
    primaryCustomerLogo: "",
    primaryContactUserID: "",
  });
  const [loading, setLoading] = useState(false);
  const [isSuccess, setisSuccess] = useState(false);
  const [isFailure, setisFailure] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const [validatemsg, SetValidatemsg] = useState("");
  const [isvaliddata, setisvaliddata] = useState(false);
  const [userInfoLoggedin, setUserInfoLoggedin] = useState({
    _id: "",
    userid: "",
    authId: "",
    role: "",
    authId_sub: [],
  });
  const [affArray, setaffArray] = useState([]);
  const [companyList, setcompanyList] = useState([]);
  const { isLoading, error, isAuthenticated, user } = useAuth0();
  const [adminContacts, setAdmincontacts] = useState([]);

  //validation functions
  const handleDropDownChange = (selectedOption, updatedata) => {
    const { name, ddvalue } = selectedOption.target;
    SetValidatemsg("");
    const path = name.split(".");
    const finalProp = path.pop();
    const newSettings = { ...contactSettings };
    let pointer = newSettings;
    path.forEach((el) => {
      pointer[el] = { ...pointer[el] };
      pointer = pointer[el];
    });
    if (name === "primaryCustomerID") {
      pointer[finalProp] = ddvalue.value;
      pointer["primaryCustomerName"] = ddvalue.label;
    } else {
      pointer[finalProp] = ddvalue.value;
    }
    setcontactSettings(newSettings);
    setisvaliddata(true);
  };
  const handleFieldValidation = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let localerror = "";
    switch (name) {
      case "primaryCustomerName":
        localerror = value.length <= 0 ? "Customer Name is Required" : "";
        break;
      case "primaryCustomerLogo":
        localerror = value.length < 1 ? "Customer Logo is Required" : "";
        break;
      default:
        break;
    }
    setErrors({ ...errors, [name]: localerror });
    setisvaliddata(true);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      await saveSettings();
    }
  };

  const handleToRemoveAppLogo = () => {
    var _contactSettings = { ...contactSettings };
    _contactSettings.primaryCustomerLogo = "";
    setcontactSettings(_contactSettings);
  };
  //error handling functions
  const validateForm = () => {
    try {
      let valid = false;
      valid = handleFieldRequiredValidation();
      Object.values(errors).forEach((val) => {
        if (val.length > 0) valid = false;
      });
      //console.log("valid : ", valid);
      return valid;
    } catch (error) {
      return false;
    }
  };
  function gotoLogin() {
    window.location.href = "/";
  }
  const handleSucessSweetAlert = () => {
    SetValidatemsg("");
    setisSuccess(false);
  };
  const handleFailureSweetAlert = () => {
    setisFailure(false);
    SetValidatemsg("");
  };

  const handleFieldRequiredValidation = () => {
    let iserror = "";

    setErrors({
      ...errors,
      primaryCustomerName:
        contactSettings.primaryCustomerName === ""
          ? (iserror = " Customer Name is required")
          : errors.primaryCustomerName,
      /*   primaryCustomerLogo:
      contactSettings.primaryCustomerLogo === ""
          ? (iserror = "Customer Logo is required")
          : errors.lastname,
 */
    });

    return iserror.length === 0;
  };

  useEffect(() => {
    getUserInfoByID();
  }, []);
  async function getUserInfoByID() {
    try {
      setLoading(true);
      await getuserbyauthproviderid(user.sub, ctechapp.currentUser).then(
        (response) => {
          let user_info = JSON.parse(response);
          if (response !== undefined || user_info.length !== 0) {
            setUserInfoLoggedin(user_info[0]);
            if (user_info[0]?.role !== "1") {
              // GetContactDetailAffiliationLookupByID(user_info[0]?.userid);
              // GetContactDetailSettingsByID(user_info[0]?.userid);
              // getAdminUserData();
            }
          }
        }
      );
      setLoading(false);
    } catch (exception) {
      setLoading(false);
      console.error(exception);
    }
  }
  // async function GetContactDetailSettingsByID(currentuser_id) {
  //   let useridvalue = currentuser_id;

  //   console.log("useridvalue: ", useridvalue);

  //   await getContactDetailSettingsByID(
  //     useridvalue,
  //     ctechapp.currentUser,
  //     user.sub
  //   ).then((response) => {
  //     if (response !== undefined) {
  //       let user_info = JSON.parse(response);
  //      var _contactsettingsList = user_info[0];
  //       console.log("_contact", _contactsettingsList);
  //       //settings info
  //       if(_contactsettingsList?.settings !== undefined &&  _contactsettingsList?.settings.length>0)
  //       {
  //         var _contactsettings = _contactsettingsList.settings[0];
  //         contactSettings.state="add";
  //         contactSettings.primaryContactUserID= currentuser_id;
  //         setcontactSettings(_contactsettings);
  //       }
  //       else
  //       {
  //         contactSettings._id = uuidv4();
  //         contactSettings._p_contactID=_contactsettingsList._id;
  //         contactSettings.state="add";
  //         contactSettings.primaryContactUserID= currentuser_id;
  //         setcontactSettings(contactSettings);
  //         _contactsettingsList.settings=[];
  //       }
  //       setcontactSettingsArray(_contactsettingsList);
  //     }
  //   });
  // }
  // async function GetContactDetailAffiliationLookupByID(currentuser_id) {
  //   let useridvalue =currentuser_id;

  //   console.log("useridvalue: ", useridvalue);

  //   await getContactDetailAffiliationLookupByID(
  //     useridvalue,
  //     ctechapp.currentUser,
  //     user.sub
  //   ).then((response) => {
  //     if (response !== undefined) {
  //       var result = JSON.stringify(response)
  //       if (result.toUpperCase().includes("ERROR")) {
  //         var errmsg = JSON.parse(result);
  //         seterrorMessage(errmsg.error);
  //         setisFailure(true);
  //         return;
  //       }
  //       let affarray = JSON.parse(response);
  //       if(affarray.length>0)
  //       {
  //       var _affarray = affarray[0].contactAffiliationsArray;
  //       console.log("_affarray", _affarray);
  //       //settings info
  //       let options = _affarray.map(function (rowdata) {
  //         return { value: rowdata.companyId, label: rowdata.companyName };
  //       });
  //       setcompanyList(options);
  //       setaffArray(_affarray);
  //     }

  //     }
  //   });
  // }
  // async function getAdminUserData() {
  //   getAdminUserListByUserID(ctechapp.currentUser, user.sub).then((response) => {
  //     if (response !== undefined) {
  //       let user_list = JSON.parse(response);
  //       setAdmincontacts(user_list);
  //     }
  //   });
  // }
  async function saveSettings() {
    try {
      setLoading(true);
      let useridvalue = contactSettings.primaryContactUserID;
      if (contactSettingsArray.settings.length === 0) {
        contactSettingsArray.settings.push(contactSettings);
      } else {
        contactSettingsArray.settings[0] = contactSettings;
      }
      updateContactDetailSettingsByID(
        useridvalue,
        contactSettingsArray,
        ctechapp.currentUser,
        user.sub
      ).then((response) => {
        if (response !== undefined) {
          var result = JSON.stringify(response);
          if (result.toUpperCase().includes("ERROR")) {
            var errmsg = JSON.parse(result);
            seterrorMessage(errmsg.error);
            setisFailure(true);
            return;
          }
          SetValidatemsg(
            appConstants.import.alertmsg.contactupdatesettingsucessfull
          );
          setisSuccess(true);
        } else {
          seterrorMessage(
            appConstants.import.alertmsg.contactupdatesettingfail
          );
          setisFailure(true);
          return;
        }
      });
      setLoading(false);
    } catch (error) {
      seterrorMessage(error);
      setisFailure(true);
      return;
    }
  }

  function gotoHome() {
    window.location.href = "/";
  }

  return (
    <div className="px-3">
      {loading && <Loading />}
      <h3 align="center">General Settings</h3>
      {userInfoLoggedin.role !== "1" && (
        <Form>
          <div>
            <div id="generalsection">
              <div
                className="row p-2 mb-3"
                style={{
                  "background-color": "#f2f2f2",
                }}
              >
                <label className="fw-bolder">General Section</label>
              </div>

              <div className="row mb-3">
                <div className="col-sm-12 col-md-2 col-lg-2">
                  <label>
                    Customer Name <span style={{ color: "red" }}>*</span>
                  </label>
                </div>
                <div className="col-sm-12 col-md-5 col-lg-5">
                  <Select
                    name="customername"
                    options={companyList}
                    className="form-control"
                    isSearchable={true}
                    styles={customstyles}
                    value={companyList.filter(
                      (record) =>
                        record.value === contactSettings.primaryCustomerID
                    )}
                    onChange={(val) => {
                      handleDropDownChange({
                        target: {
                          name: "primaryCustomerID",
                          ddvalue: val,
                        },
                      });
                    }}
                  ></Select>
                </div>
                {errors.primaryCustomerName.length > 0 && (
                  <small
                    className="form-check-label text-danger"
                    htmlFor="firstname"
                  >
                    {errors.primaryCustomerName}
                  </small>
                )}
              </div>
              <div className="row mb-3">
                <div className="col-sm-12 col-md-2 col-lg-2">
                  <label>
                    Customer Logo <span style={{ color: "red" }}>*</span>
                  </label>
                  <div>
                    {" "}
                    <small>(Logo Size: 200px * 80px)</small>
                  </div>
                </div>
                <div className="col-sm-12 col-md-5 col-lg-5">
                  <Form.Group>
                    <div className="d-flex gap-3 justify-content-start align-items-start">
                      <Button
                        style={{ padding: "1px 6px" }}
                        onClick={() => handleToRemoveAppLogo()}
                      >
                        Clear
                      </Button>
                      <FileBase
                        type="file"
                        multiple={false}
                        onDone={({ base64 }: { base64: string }) => {
                          setcontactSettings({
                            ...contactSettings,
                            ["primaryCustomerLogo"]: base64,
                          });
                        }}
                      />
                      <img
                        src={
                          contactSettings.primaryCustomerLogo === "" ||
                          contactSettings.primaryCustomerLogo === undefined
                            ? "../assets/img/noimg1.png"
                            : contactSettings.primaryCustomerLogo
                        }
                        className="rounded mx-auto d-block mw-100"
                        width="200"
                        height="80"
                      />
                    </div>
                  </Form.Group>
                </div>
              </div>
              <div className="mt-2 " className="d-none">
                <div id="additionalsection ">
                  <div
                    className="row p-2 mb-3"
                    style={{
                      "background-color": "#f2f2f2",
                    }}
                  >
                    <label className="fw-bolder">Additional Section</label>
                  </div>
                </div>
                <UserAdminCard contact={adminContacts} />
              </div>
              <div className="d-flex  justify-content-end gap-2 mb-3 mt-3">
                <Button variant="secondary" onClick={() => gotoHome()}>
                  Cancel
                </Button>
                {userInfoLoggedin?.role === "8000" && (
                  <Button
                    variant="primary"
                    id="procoreToken"
                    onClick={handleSubmit}
                    className="btn btn-success btn-sm"
                  >
                    Save
                  </Button>
                )}
              </div>
            </div>
          </div>
        </Form>
      )}

      <div>
        {isSuccess && (
          <SweetAlert
            success
            style={{ color: "black" }}
            title={validatemsg}
            onConfirm={handleSucessSweetAlert}
          ></SweetAlert>
        )}
      </div>

      <div>
        {isFailure && (
          <SweetAlert
            danger
            style={{ color: "black" }}
            title={validatemsg}
            onConfirm={handleFailureSweetAlert}
          >
            {errorMessage}
          </SweetAlert>
        )}
      </div>
    </div>
  );
}

export default GeneralSettings;
