import React from "react";
import { FaSquare, FaCheckSquare, FaMinusSquare } from "react-icons/fa";
import { FcOpenedFolder } from "react-icons/fc";

import { IoMdArrowDropright } from "react-icons/io";
import TreeView, { flattenTree } from "react-accessible-treeview";
import cx from "classnames";
import "./styles6.css";

//   id: "6538ec0150202c923fcf5ac7",
//   name: "Project Number",
//   parent: "0",
//   children: [
//     {
//       id: "6538ed0f50202c923fcf5aca",
//       name: "Contract documents",
//       parent: "6538ec0150202c923fcf5ac7",
//       children: [
//         {
//           id: "654c8a68b4c27821ebb53e08",
//           name: "test1",
//           parent: "6538ed0f50202c923fcf5aca",
//         },
//         {
//           id: "654cd352348f5821e1f666ee",
//           name: "test2",
//           parent: "6538ed0f50202c923fcf5aca",
//         },
//         {
//           id: "654cd36e348f5821e1f6ae47",
//           name: "test4",
//           parent: "6538ed0f50202c923fcf5aca",
//         },
//       ],
//     },
//     {
//       id: "6538ed2650202c923fcf5acb",
//       name: "Change order documents",
//       parent: "6538ec0150202c923fcf5ac7",
//     },
//     {
//       id: "65393b5fe83d5cb02e6dcbed",
//       name: "Bid documents",
//       parent: "6538ec0150202c923fcf5ac7",
//     },
//     {
//       id: "653fcb8076f226e79dc59a2c",
//       name: "Subcontract documents",
//       parent: "6538ec0150202c923fcf5ac7",
//     },
//     {
//       id: "654b588e6f1aef416302ff1e",
//       name: "test_11/8",
//       parent: "6538ec0150202c923fcf5ac7",
//     },
//   ],
// });

function TreeViewSidebar({ data, onHandleNodeEvent, folderListIDS }) {
  var datarootFolder = {
    "id": "2",
    "name": "root folder",
    "parent": "1", "children": data
  }

  const dataWithPredefinedIds = flattenTree(datarootFolder);

  //console.log("dataWithPredefinedIds", dataWithPredefinedIds);
  return (
    <div>
      <div className="checkbox">
        <TreeView
          data={dataWithPredefinedIds}
          className="basic"
          aria-label="Data type Map tree"
          propagateSelect
          propagateSelectUpwards
          togglableSelect
          defaultExpandedIds={folderListIDS}
          onNodeSelect={(event1) => {
            // console.log("event1", event1);
            onHandleNodeEvent(event1);
          }}
          nodeRenderer={({
            element,
            isBranch,
            isExpanded,
            isSelected,
            isHalfSelected,
            getNodeProps,
            level,
            handleSelect,
            handleExpand,
          }) => {
            return (
              <div
                {...getNodeProps({ onClick: handleExpand })}
                style={{ marginLeft: 40 * (level - 1) }}
              >
                {isBranch && <ArrowIcon isOpen={isExpanded} />}
                <span
                  onClick={(e) => {
                    handleSelect(e);
                    e.stopPropagation();
                  }}
                >
                  <FcOpenedFolder />
                  {` ${element.name}`}
                </span>
              </div>
            );
          }}
        />
      </div>
    </div>
  );
}

const ArrowIcon = ({ isOpen, className }) => {
  const baseClass = "arrow";
  const classes = cx(
    baseClass,
    { [`${baseClass}--closed`]: !isOpen },
    { [`${baseClass}--open`]: isOpen },
    className
  );
  return <IoMdArrowDropright className={classes} />;
};

const CheckBoxIcon = ({ variant, ...rest }) => {
  switch (variant) {
    case "all":
      return <FaCheckSquare {...rest} />;
    case "none":
      return <FaSquare {...rest} />;
    case "some":
      return <FaMinusSquare {...rest} />;
    default:
      return null;
  }
};

export default TreeViewSidebar;
