// services for insertProject
export const insertProject = async (projectdata, Ctechapp, loggedinby) => {
  try {
    const data = await Ctechapp.functions.insertProject(
      JSON.stringify(projectdata),
      loggedinby
    );
    return data;
  } catch (error) {
    console.log("error : ", error);
    throw Error(error);
  }
};
// services for getProjectListByUserID

export const getProjectListByUserID = async (Ctechapp, loggedinby) => {
  try {
    const data = await Ctechapp.functions.getProjectListByUserID(loggedinby);
    return JSON.stringify(data);
  } catch (error) {
    console.log("error : ", error);
    throw Error(error);
  }
};
// services for getProjectListByID

export const getProjectListByID = async (id, Ctechapp, loggedinby) => {
  try {
    const data = await Ctechapp.functions.getProjectListByID(id, loggedinby);
    return data;
  } catch (error) {
    console.log("error : ", error);
    throw Error(error);
  }
};
//services for getProjectListByFilter
export const getProjectListByFilter = async (query, Ctechapp, loggedinby) => {
  try {
    const data = await Ctechapp.functions.getProjectListByFilter(
      query,
      loggedinby
    );
    return data;
  } catch (error) {
    console.log("error : ", error);
    throw Error(error);
  }
};
//  services for updateProjectByID
export const updateProjectByID = async (projectdata, Ctechapp, loggedinby) => {
  try {
    const data = await Ctechapp.functions.updateProjectByID(
      JSON.stringify(projectdata),
      loggedinby
    );
    return data;
  } catch (error) {
    console.log("error : ", error);
    throw Error(error);
  }
};
//services for updateProjectByID_ProjectNumber

export const updateProjectByID_ProjectNumber = async (
  projectdata,
  Ctechapp,
  loggedinby
) => {
  try {
    const data = await Ctechapp.functions.updateProjectByID_ProjectNumber(
      JSON.stringify(projectdata),
      loggedinby
    );
    return data;
  } catch (error) {
    console.log("error : ", error);
    throw Error(error);
  }
};
// services for deleteProjectByID
export const deleteProjectByID = async (id, Ctechapp, loggedinby) => {
  try {
    const data = await Ctechapp.functions.deleteProjectByID(id, loggedinby);
    return data;
  } catch (error) {
    console.log("error : ", error);
    throw Error(error);
  }
};

//service to call Project Number
export const getCommandPostNewProjectNumber = async (
  arg,
  Ctechapp,
  loggedinby
) => {
  try {
    const data = await Ctechapp.functions.getCommandPostNewProjectNumber(
      arg,
      loggedinby
    );
    return JSON.stringify(data);
  } catch (error) {
    console.log("error : ", error);
    throw Error(error);
  }
};

//getCommandPostAccountSettingsByID

export const getCommandPostAccountSettingsByID = async (
  arg,
  Ctechapp,
  loggedinby
) => {
  try {
    const data = await Ctechapp.functions.getCommandPostAccountSettingsByID(
      arg,
      loggedinby
    );
    return JSON.stringify(data);
  } catch (error) {
    console.log("error : ", error);
    throw Error(error);
  }
};
