import { useEffect, useState, useMemo } from "react";
import { Button } from "react-bootstrap";
import { appConstants } from "../../constants/app_constants";
import Moment from "moment";
import GenericListTable from "../../components/gridTable/genericLlistTable";
import Select from "react-select";
import { useLocation } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import { useRealmApp } from "../../providers/RealmApp";
import { getImportedStatus } from "../../services/import_project_task_services";
import { useAuth0 } from "@auth0/auth0-react";
import customstyles from "../../components/customstyles/react-select-styles";
import {
  getappcompanylistbyappname_userid,
  get_CPPProjects_API,
} from "../../services/applications_services";
import { getuserbyauthproviderid } from "../../services/users_services";
function AppImportStatus(arg) {
  const location = useLocation();
  const queryparams = new URLSearchParams(location.search);

  const [_appname, setAppname] = useState(queryparams.get("action"));
  const [sentanceCaseAppName, setSentanceCaseAppName] = useState("");
  const [importprojectstatus, setimportprojectstatus] = useState([]);

  const ctechapp = useRealmApp();
  const { isLoading, error, isAuthenticated, user } = useAuth0();
  var [companylist, setCompanyList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [autoSubmit, setAutoSubmit] = useState(false);
  const [isTokenLinkSend, setisTokenLinkSend] = useState(false);
  const [isInvaildToken, setisInvaildToken] = useState(false);
  const [vaildmsg, setvaildmsg] = useState(false);

  const [userinfo, setUserinfo] = useState({
    role: "",
  });
  const [projectstatus, setprojectstatus] = useState({
    company_ID: "",
    fromdate: "",
    todate: "",
  });
  const [errors, setErrors] = useState({
    company_ID: "",
    fromdate: "",
    todate: "",
  });

  const data = useMemo(() => [...importprojectstatus], [importprojectstatus]);

  const appColumns = useMemo(
    () => [
      {
        Header: "Project number",
        accessor: "project_info.projectNumber",
        sortable: true,
        width: "20%",
        filter: "",
        Filter: "",
        Cell: ({ value, row }) => {
          return <span>{row.original.project_info.projectNumber}</span>;
        },
      },

      {
        Header: "Project name",
        accessor: "project_info.projectName",
        sortable: true,
        width: "30%",
        Filter: "",
        filter: "",
        Cell: ({ value, row }) => {
          return <span>{row.original.project_info.projectName}</span>;
        },
      },
      {
        Header: "Alternate project number",
        accessor: "project_info.altProjectNumber",
        sortable: true,
        width: "30%",
        Filter: "",
        filter: "",
        Cell: ({ value, row }) => {
          return <span>{row.original.project_info.altProjectNumber}</span>;
        },
      },
      {
        Header: "Imported date",
        accessor: "created_date",
        sortable: true,
        width: "30%",
        Filter: "",
        filter: "",
        Cell: ({ value }) => Moment(value).format(appConstants.DATEFORMAT),
      },
      {
        Header: "Status",
        accessor: "status",
        sortable: true,
        width: "30%",
        Filter: "",
        filter: "",
        Cell: ({ value, row }) => {
          return <span>{row.original.status}</span>;
        },
      },
      //exception
      {
        Header: "Exception",
        accessor: "exception",
        sortable: true,
        width: "30%",
        Filter: "",
        filter: "",
      },
    ],
    []
  );

  const hiddenappColumns = ["_id"];

  const handleSendLinkClickApps = (_id) => {
    ResendSignupURL(_id);
  };
  async function ResendSignupURL(_id) {}

  const handleSucessSweetAlert = () => {
    setisInvaildToken(false);
    setisTokenLinkSend(false);
  };

  useEffect(() => {
    if (isAuthenticated) {
      getUserInfoByID();
      Sentancecase_appname(_appname);
      async function getUserInfoByID() {
        try {
          setLoading(true);
          getuserbyauthproviderid(user.sub, ctechapp.currentUser).then(
            (response) => {
              let project_info = JSON.parse(response);
              if (response !== undefined || project_info.length !== 0) {
                setUserinfo(project_info[0]);
                getCompanyListData(project_info[0]);
              }
            }
          );
        } catch (exception) {
          setLoading(false);
          console.log(exception);
        }
      }

      async function getCompanyListData(userinfo) {
        getappcompanylistbyappname_userid(
          _appname,
          ctechapp.currentUser,
          userinfo
        ).then((response) => {
          try {
            if (response !== undefined) {
              let company_info = JSON.parse(response);

              var options = [];
              company_info?.map(function (company) {
                if (company?.appuserinfo.length > 0) {
                  options.push({
                    value: company.appaccountid,
                    label: company.appuserinfo[0].appdisplayname,
                  });
                }
              });

              setCompanyList(options);
              if (!autoSubmit) {
                handleAutoSubmit(options);
              }
            }
          } catch (exception) {}
        });
      }
    }
    async function Sentancecase_appname(_appname) {
      const word = _appname.toLowerCase();
      const firstLetter = word.charAt(0);
      const firstLetterCap = firstLetter.toUpperCase();
      const remainingLetters = word.slice(1);
      setSentanceCaseAppName(firstLetterCap + remainingLetters);
    }
  }, []);

  const handleAutoSubmit = (companylist) => {
    var fromdate = new Date(new Date().setDate(new Date().getDate() - 30));

    let _projectstatus = { ...projectstatus };
    _projectstatus.company_ID = companylist[0]?.value;
    _projectstatus.fromdate = Moment(fromdate).format("YYYY-MM-DD");
    _projectstatus.todate = Moment().format("YYYY-MM-DD");
    setprojectstatus(_projectstatus);
    getData(_projectstatus);
    setAutoSubmit(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      getData(projectstatus);
    }
  };
  const validateForm = () => {
    try {
      let valid = false;
      valid = handleFieldRequiredValidation();
      Object.values(errors).forEach((val) => {
        if (val.length > 0) valid = false;
      });
      // console.log(valid);
      return valid;
    } catch (error) {
      return false;
    }
  };
  const handleFieldRequiredValidation = () => {
    let iserror = "";

    setErrors({
      ...errors,
      company_ID:
        projectstatus.company_ID === ""
          ? (iserror = "Account  Display Name is required")
          : errors.company_ID,
      fromdate:
        projectstatus.fromdate === ""
          ? (iserror = "From Date is required")
          : errors.fromdate,
      todate:
        projectstatus.todate === ""
          ? (iserror = "To Date is required")
          : errors.todate,
    });
    // console.log(iserror);
    return iserror.length === 0;
  };

  async function getData(_projectstatus) {
    getImportedStatus(
      _appname,
      _projectstatus,
      `apiinfo.companyid`,
      ctechapp.currentUser,
      user.sub
    ).then((response) => {
      if (response !== undefined) {
        let project_list = JSON.parse(response);
        console.log("project_list", project_list);
        if (project_list.length > 0) {
          setimportprojectstatus(project_list);
          setvaildmsg(false);
        } else {
          setimportprojectstatus([]);
          setvaildmsg(true);
        }
      }
    });
  }

  // const handleCompanyChange = (event) => {
  //   setSelectedCompany(event.target.value);
  // };
  const handleDropDownChange = (selectedOption) => {
    const { name, value } = selectedOption.target;
    setprojectstatus({ ...projectstatus, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };
  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    setprojectstatus({ ...projectstatus, [name]: value });
    handleFieldValidation(event);
  };
  const handleFieldValidation = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let localerror = "";
    switch (name) {
      default:
        break;
    }
    setErrors({ ...errors, [name]: localerror });
  };
  // Define a default UI for filtering
  function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }) {
    const count = preFilteredRows.length;

    return (
      <input
        className="form-control"
        value={filterValue || ""}
        onChange={(e) => {
          setFilter(e.target.value || undefined); // Set undefined toLowerCase() remove the filter entirely
        }}
      />
    );
  }
  return (
    <div className="mt-3">
      <h3 align="center">
        {sentanceCaseAppName + " "}
        import status
      </h3>
      <div className="d-flex ustify-content-start align-items-center  row mb-3">
        <div className=" col-sm-12 col-lg-2">
          <label className="">
            App display name<label className="text-danger">*</label> :
          </label>
        </div>
        <div className="col-sm-12 col-lg-3">
          <Select
            width={"10%"}
            name="company_ID"
            id="company_ID"
            styles={customstyles}
            className="form-control"
            options={companylist}
            value={companylist?.filter(
              (record) => record.value === projectstatus?.company_ID
            )}
            onChange={(val) => {
              handleDropDownChange({
                target: {
                  name: "company_ID",
                  value: val.value,
                },
              });
            }}
          ></Select>
          {errors.company_ID.length > 0 && (
            <small
              className="form-check-label text-danger"
              htmlFor="company_ID"
            >
              {errors.company_ID}
            </small>
          )}
        </div>
        <div className="col-sm-12 col-lg-1 col-xs-2">
          <label> From date :</label>{" "}
        </div>
        <div className="col-sm-12 col-lg-2">
          <input
            type="date"
            className="form-control"
            id="fromdate"
            max={Moment().format(projectstatus?.todate, "YYYY-MM-DD")}
            onChange={handleChange}
            value={projectstatus?.fromdate}
            name="fromdate"
          />
          {errors.fromdate.length > 0 && (
            <small className="form-check-label text-danger" htmlFor="fromdate">
              {errors.fromdate}
            </small>
          )}
        </div>
        <div className="col-sm-12 col-lg-1 col-xs-2">
          <label>To date :</label>{" "}
        </div>
        <div className="col-sm-12 col-lg-2">
          <input
            type="date"
            className="form-control"
            id="todate"
            name="todate"
            min={Moment().format(projectstatus?.fromdate, "YYYY-MM-DD")}
            value={projectstatus?.todate}
            onChange={handleChange}
          />
          {errors.todate.length > 0 && (
            <small className="form-check-label text-danger" htmlFor="todate">
              {errors.todate}
            </small>
          )}
        </div>
        <div className="col-sm-12 col-lg-1">
          <Button className="btn btn-success" onClick={handleSubmit}>
            Submit
          </Button>
        </div>
      </div>

      <div className="table-responsive">
        <GenericListTable
          gcolumns={appColumns}
          gdata={data.length > 0 ? JSON.stringify(data) : ""}
          ghiddencolumns={hiddenappColumns}
          gplaceholder={`Search ${_appname.toLowerCase()} import project status...`}
          gsearchDisabled={false}
          filterDisabled={true}
        />
        {vaildmsg === true && (
          <div className="form-check-label  text-center" htmlFor="validation">
            <h3 className="text-danger ">
              {appConstants.import.handlefieldvalidation.nodata}
            </h3>
          </div>
        )}
      </div>
      <div>
        {isTokenLinkSend && (
          <SweetAlert
            success
            style={{ color: "black" }}
            title={appConstants.import.alertmsg.newlink}
            onConfirm={handleSucessSweetAlert}
          ></SweetAlert>
        )}
        {isInvaildToken && (
          <SweetAlert
            danger
            style={{ color: "black" }}
            title={appConstants.import.alertmsg.errorlink}
            onConfirm={handleSucessSweetAlert}
          ></SweetAlert>
        )}
      </div>
    </div>
  );
}

export default AppImportStatus;
