/* eslint-disable no-fallthrough */
import { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import React from "react";
import * as Realm from "realm-web";
import { useLocation } from "react-router-dom";
import {
  ChargebeeUserOnboarding,
  ChargebeeUserCheckoutRetrieveID,
  ChargebeeUserProjectmasterOnboarding,
  getusersbyemail,
} from "../../../services/users_services";
import LoadingDialog from "../../../components/LoadingDialog";
import { useAuth0 } from "@auth0/auth0-react";
import SweetAlert from "react-bootstrap-sweetalert";
import { Button, Modal } from "react-bootstrap";
function AppSetup() {
  const { user, isAuthenticated, loginWithRedirect, logout } = useAuth0();
  const [loadDialogOpen, setloadDialogOpen] = useState(false);
  const queryparams = new URLSearchParams(window.location.search);
  var localapp = new Realm.App(process.env.REACT_APP_REALM_APP_ID);
  const [charegbee_response_id, setCharegbee_response_id] = useState(
    queryparams.get("id")
  );
  const [charegbee_response_state, setCharegbee_response_state] = useState(
    queryparams.get("state")
  );
  const [charegbee_result, setCharegbee_result] = useState({});
  const [isUserExist, setIsUserExist] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [isFailure, setisFailure] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isvaliddata, setisvaliddata] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [apptitle, setapptitle] = useState("Project Sync");
  const location = useLocation();
  //console.log("location", location);
  const redirectUrl = window.location.originin;
  // Create an api key credential
  const apiKey = process.env.REACT_APP_APIKEY;
  const credentials = Realm.Credentials.apiKey(apiKey);

  const redirectManagerURLmap = new Map()
    .set(redirectUrl + "/connection/commandpost/manage", "Command Post")
    .set(redirectUrl + "/connection/quickbooks/manage", "Quickbooks")
    .set(redirectUrl + "/connection/procore/manage", "Procore")
    .set(redirectUrl + "/connection/sharepoint/manage", "Sharepoint")
    .set(redirectUrl + "/connection/master/manage", "Projectmaster")
    .set(redirectUrl + "/connection/master/quickbooks/setup", "Quickbooks")
    .set(redirectUrl + "/connection/master/procore/setup", "Procore")
    .set(redirectUrl + "/connection/master/sharepoint/setup", "Sharepoint");

  const [initialval, setInitialval] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    password: "",
    confirmpassword: "",
  });
  const [errors, setErrors] = useState({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    confirmpassword: "",
  });

  function ChargebeeSubscriptionCheckoutRetrieveByID() {
    setloadDialogOpen(true);
    if (!apiKey) {
      throw new Error("Could  not find a Realm Server API Key.");
    }
    const chargebee_checkout_id = queryparams.get("id") || "";
    if (chargebee_checkout_id) {
      try {
        // Get the API key from the local environment
        localapp.logIn(credentials).then(() => {
          ChargebeeUserCheckoutRetrieveID(
            chargebee_checkout_id,
            window.location.href,
            localapp.currentUser,
            localapp.currentUser.id
          ).then((response) => {
            if (response !== undefined && response !== null) {
              //   console.log("response", JSON.stringify(response));
              setCharegbee_result(response);

              let _initialval = { ...initialval };
              _initialval.firstname = response?.customer?.first_name || "";
              _initialval.lastname = response?.customer?.last_name || "";
              _initialval.email = response?.customer?.email || "";
              _initialval.phone = response?.customer?.phone || "";
              setInitialval(_initialval);

              getusersbyemail(
                response?.customer?.email,
                localapp.currentUser,
                localapp.currentUser.id
              ).then((response) => {
                const _userResult = JSON.parse(response);
                //  console.log("_userResult.length ", _userResult.length);
                if (
                  response !== undefined &&
                  response !== null &&
                  _userResult.length !== 0
                ) {
                  setIsUserExist(true);
                } else {
                  setIsUserExist(false);
                }
                setloadDialogOpen(false);
                if (
                  localapp.currentUser !== null &&
                  localapp.currentUser !== undefined
                ) {
                  localapp.removeUser(localapp.currentUser);
                }
              });
            } else {
              setIsError(true);
              setErrorMessage(JSON.stringify(response));
              if (
                localapp.currentUser !== null &&
                localapp.currentUser !== undefined
              ) {
                localapp.removeUser(localapp.currentUser);
              }
            }
          });

          setloadDialogOpen(false);
        });
        if (
          localapp.currentUser !== null &&
          localapp.currentUser !== undefined
        ) {
          localapp.removeUser(localapp.currentUser);
        }
      } catch (err) {
        console.log("err : " + err);
        setloadDialogOpen(false);
        if (
          localapp.currentUser !== null &&
          localapp.currentUser !== undefined
        ) {
          localapp.removeUser(localapp.currentUser);
        }
      }
    }
  }

  async function RedirectPage(e) {
    e.preventDefault();
    setloadDialogOpen(true);
    if (!apiKey) {
      throw new Error("Could  not find a Realm Server API Key.");
    }
    var localapp = new Realm.App(process.env.REACT_APP_REALM_APP_ID);
    try {
      // Get the API key from the local environment
      localapp.logIn(credentials).then(() => {
        ChargebeeUserOnboarding(
          charegbee_response_id,
          window.location.href,
          location.pathname || "",
          localapp.currentUser,
          localapp.currentUser.id
        ).then((response) => {
          if (response !== undefined) {
            //console.log("response", JSON.stringify(response));
            if (
              localapp.currentUser !== null &&
              localapp.currentUser !== undefined
            ) {
              localapp.removeUser(localapp.currentUser);
            }
            if (response.statusCode === 200) {
              setIsSuccess(true);
            } else {
              setIsError(true);
              setErrorMessage(response.message);
            }
          }
        });
        setloadDialogOpen(false);
      });
      if (localapp.currentUser !== null && localapp.currentUser !== undefined) {
        await localapp.removeUser(localapp.currentUser);
      }
    } catch (err) {
      console.log("err : " + err);
      setloadDialogOpen(false);
      if (localapp.currentUser !== null && localapp.currentUser !== undefined) {
        await localapp.removeUser(localapp.currentUser);
      }
    }
  }

  async function handleSweetAlertSuccess() {
    setIsSuccess(false);
    if (!isAuthenticated) {
      const options = {
        appState: {
          type: "appconnection",
          returnTo: window.location.origin + "/apps",
        },
        // redirectUri: window.location.origin,
      };
      await loginWithRedirect(options);
    } else {
      //console.log("user.email ", user.email);
      window.location.href = window.location.origin + "/apps";
    }
  }
  function handleSweetAlertError() {
    setIsError(false);
    logout({
      returnTo: window.location.origin,
    });
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      await saveuser();
    }
  };

  async function saveuser() {
    setloadDialogOpen(true);
    // console.log(JSON.stringify(values));
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var userinfo = {};

    let phoneformatvalue = initialval.phone || "";
    phoneformatvalue =
      phoneformatvalue !== "" ? phoneformatvalue.replaceAll("+", "") : "";

    userinfo = {
      firstname: initialval.firstname,
      lastname: initialval.lastname,
      email: initialval.email,
      phone: phoneformatvalue,
      password: initialval.confirmpassword,
    };

    var raw = JSON.stringify(userinfo);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch("/createdirectuserbyemail", requestOptions)
      .then((response) => response.text())
      .then(async (result) => {
        if (result.toUpperCase().includes("[ERROR]:")) {
          // console.log(result);
          var message = result.replace("[error]:", "");
          var errmsg = JSON.parse(message);
          // console.log("errmsg", errmsg);
          if (errmsg?.response?.text) {
            let passerr = JSON.parse(errmsg?.response?.text);
            // console.log("passerr", passerr);
            setPasswordError(passerr?.message);
          }

          setErrorMessage(errmsg);
          setisFailure(true);
        } else {
          await InsertUserOnBoarding();
          setIsSuccess(true);
        }
      })
      .catch((error) => console.log("error", error));
    setloadDialogOpen(false);
  }

  async function InsertUserOnBoarding() {
    try {
      const chargebee_checkout_id = queryparams.get("id") || "";
      localapp.logIn(credentials).then(() => {
        ChargebeeUserProjectmasterOnboarding(
          chargebee_checkout_id,
          window.location.href,
          location.pathname || "",
          localapp.currentUser,
          localapp.currentUser.id
        ).then((response) => {
          if (response !== undefined) {
            // console.log("response", JSON.stringify(response));
            if (response.statusCode === 200) {
              setIsSuccess(true);
              if (
                localapp.currentUser !== null &&
                localapp.currentUser !== undefined
              ) {
                localapp.removeUser(localapp.currentUser);
              }
            } else {
              setIsError(true);
              setErrorMessage(response.message);
              if (
                localapp.currentUser !== null &&
                localapp.currentUser !== undefined
              ) {
                localapp.removeUser(localapp.currentUser);
              }
            }
          }
        });
      });
      if (localapp.currentUser !== null && localapp.currentUser !== undefined) {
        await localapp.removeUser(localapp.currentUser);
      }
    } catch (ex) {
      console.ex(ex);
      if (localapp.currentUser !== null && localapp.currentUser !== undefined) {
        await localapp.removeUser(localapp.currentUser);
      }
    }
  }

  const handleChange = (event) => {
    const path = event.target.name.split(".");
    const finalProp = path.pop();
    const newuser = { ...initialval };
    let pointer = newuser;
    path.forEach((el) => {
      pointer[el] = { ...pointer[el] };
      pointer = pointer[el];
    });
    pointer[finalProp] =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    setInitialval(newuser);
    handleFieldValidation(event);
    setisvaliddata(true);
  };
  const handleFieldValidation = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let localerror = "";
    switch (name) {
      case "password":
        localerror =
          value.length < 8 ? "Password must be at least 8 characters" : "";
        setPasswordError("");
        break;
      case "confirmpassword":
        localerror =
          value !== initialval.password
            ? "Password & Re-enter Password are mismatched"
            : "";
      default:
        break;
    }
    setErrors({ ...errors, [name]: localerror });
    setisvaliddata(true);
  };

  const handleFieldRequiredValidation = () => {
    let iserror = "";

    setErrors({
      ...errors,
      password:
        initialval.password === ""
          ? (iserror = "Password required")
          : errors.password,
      confirmpassword:
        initialval.confirmpassword === ""
          ? (iserror = "Re-enter password required")
          : errors.confirmpassword,
    });

    return iserror.length === 0;
  };

  //error handling functions
  const validateForm = () => {
    try {
      let valid = false;
      valid = handleFieldRequiredValidation();
      Object.values(errors).forEach((val) => {
        if (val.length > 0) valid = false;
      });
      //console.log("valid : ", valid);
      return valid;
    } catch (error) {
      return false;
    }
  };
  useEffect(() => {
    setapptitle(redirectManagerURLmap.get(window.location.href));
    if (
      location.pathname.toLowerCase().includes("/connection/master/setup") ||
      location.pathname
        .toLowerCase()
        .includes("/connection/commandpost/setup") ||
      location.pathname
        .toLowerCase()
        .includes("/connection/master/procore/setup") ||
      location.pathname
        .toLowerCase()
        .includes("/connection/master/sharepoint/setup")
    ) {
      ChargebeeSubscriptionCheckoutRetrieveByID();
    }
  }, []);
  return (
    <div>
      <div className="mb-2">
        <h3 className="m-2 pb-2" align="center">
          Welcome to Project Sync - {apptitle} app connection onboarding
        </h3>
        {(location.pathname
          .toLowerCase()
          .includes("/connection/commandpost/setup") ||
          location.pathname
            .toLowerCase()
            .includes("/connection/procore/setup") ||
          location.pathname
            .toLowerCase()
            .includes("/connection/sharepoint/setup") ||
          location.pathname
            .toLowerCase()
            .includes("/connection/quickbooks/setup")) &&
          isUserExist === true && (
            <div className="d-flex justify-content-center align-self-start  welcomehintwindow">
              {/* <ui>
                    <li>Sample hint1</li>
                    <li>Sample hint2</li>
                    <li>Sample hint3</li>
                    <li>Sample hint4</li>
                </ui> */}
            </div>
          )}
        {isUserExist === true &&
          (location.pathname
            .toLowerCase()
            .includes("/connection/master/setup") ||
            location.pathname
              .toLowerCase()
              .includes("/connection/commandpost/setup") ||
            location.pathname
              .toLowerCase()
              .includes("/connection/procore/setup") ||
            location.pathname
              .toLowerCase()
              .includes("/connection/sharepoint/setup") ||
            location.pathname
              .toLowerCase()
              .includes("/connection/quickbooks/setup") ||
            location.pathname
              .toLowerCase()
              .includes("/connection/master/procore/setup") ||
            location.pathname
              .toLowerCase()
              .includes("/connection/sharepoint/procore/setup") ||
            location.pathname
              .toLowerCase()
              .includes("/connection/master/quickbooks/setup")) && (
            <div className="d-flex-inline align-items-center">
              <div class="d-flex justify-content-center mt-4">
                <div>
                  <button
                    className="btn btn-primary"
                    onClick={(e) => {
                      RedirectPage(e);
                    }}
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
          )}
        <div></div>
        <LoadingDialog
          isOpen={loadDialogOpen}
          onClose={() => setloadDialogOpen(false)}
        />
        {isSuccess && (
          <SweetAlert
            success
            style={{ color: "black" }}
            title="App has been onboarded successfully."
            onConfirm={handleSweetAlertSuccess}
          ></SweetAlert>
        )}
        {isError && (
          <SweetAlert
            danger
            style={{ color: "black" }}
            title={errorMessage}
            onConfirm={handleSweetAlertError}
          ></SweetAlert>
        )}
      </div>
      {isUserExist === false &&
        (location.pathname.toLowerCase().includes("/connection/master/setup") ||
          location.pathname
            .toLowerCase()
            .includes("/connection/master/procore/setup") ||
          location.pathname
            .toLowerCase()
            .includes("/connection/master/sharepoint/setup") ||
          location.pathname
            .toLowerCase()
            .includes("/connection/master/quickbooks/setup") ||
          location.pathname
            .toLowerCase()
            .includes("/connection/procore/setup") ||
          location.pathname
            .toLowerCase()
            .includes("/connection/quickbooks/setup") ||
          location.pathname
            .toLowerCase()
            .includes("/connection/commandpost/setup")) && (
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col-lg-8 col-xl-3">
              <div className="card rounded-3">
                <div className="card-body p-4 p-md-2">
                  <p className="text-center mb-0 font-weight-bold mb-4 display-7">
                    Create a password below to activate your account
                  </p>

                  {/* <form className="px-md-5"> */}
                  <div className="row mb-4 px-md-5">
                    <input
                      type="text"
                      id="firstname"
                      name="firstname"
                      disabled={true}
                      className="form-control"
                      value={initialval.firstname}
                      placeholder="First Name"
                      onChange={handleChange}
                    />
                    {errors.firstname.length > 0 && (
                      <small
                        className="form-check-label text-danger"
                        htmlFor="firstname"
                      >
                        {errors.firstname}
                      </small>
                    )}
                  </div>

                  <div className="row mb-4 px-md-5">
                    <input
                      type="text"
                      id="lastname"
                      name="lastname"
                      disabled={true}
                      className="form-control"
                      value={initialval.lastname}
                      placeholder="Last Name"
                      onChange={handleChange}
                    />
                    {errors.lastname.length > 0 && (
                      <small
                        className="form-check-label text-danger"
                        htmlFor="lastname"
                      >
                        {errors.lastname}
                      </small>
                    )}
                  </div>

                  <div className="row mb-4 px-md-5">
                    <input
                      type="text"
                      id="email"
                      name="email"
                      value={initialval.email}
                      disabled={true}
                      className="form-control"
                      placeholder="Email"
                      autoComplete="new-email"
                      onChange={handleChange}
                    />
                    {errors.email.length > 0 && (
                      <small
                        className="form-check-label text-danger"
                        htmlFor="email"
                      >
                        {errors.email}
                      </small>
                    )}
                  </div>

                  <div className="row mb-4 px-md-5">
                    <input
                      type="password"
                      id="password"
                      name="password"
                      className="form-control"
                      autoComplete="new-password"
                      placeholder="Create Password"
                      onChange={handleChange}
                    />

                    {errors.password.length > 0 && (
                      <small
                        className="form-check-label text-danger"
                        htmlFor="password"
                      >
                        {errors.password}
                      </small>
                    )}
                  </div>
                  <div className="row mb-4 px-md-5">
                    <input
                      type="password"
                      id="confirmpassword"
                      name="confirmpassword"
                      className="form-control"
                      placeholder="Re-enter new Password"
                      onChange={handleChange}
                    />
                    <div style={{ color: "red" }}>{errors.confirmpassword}</div>
                  </div>

                  <div className="row mb-2 px-md-5">
                    <Button
                      className="form-control btn btn-lg"
                      onClick={handleSubmit}
                      type="submit"
                    >
                      Continue
                    </Button>
                  </div>
                  {passwordError !== "" && (
                    <span className="text-danger d-flex justify-content-center">
                      {passwordError}
                    </span>
                  )}
                  {/* <div className="row px-md-5">
                    <Button
                      className="form-control btn btn-lg ml-2 mb-4"
                      onClick={() => gotoLogin()}
                    >
                      Cancel
                    </Button>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        )}
    </div>
  );
}
export default AppSetup;
