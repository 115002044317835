//services for getaccountbyid
export const getaccountbyid = async (id, Ctechapp) => {
  try {
    const data = await Ctechapp.functions.getaccountbyid(id);
    if (data !== undefined) {
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};
// services for updateAccountByID
export const updateAccountByID = async (userdata, Ctechapp) => {
  try {
    const data = await Ctechapp.functions.updateAccountByID(
      JSON.stringify(userdata)
    );

    return data;
  } catch (error) {
    console.log("error : ", error);
    throw Error(error);
  }
};
