import { Button, Form } from "react-bootstrap";
import * as Realm from "realm-web";
import { useState, useEffect } from "react";
import { useParams } from "react-router";
import SweetAlert from "react-bootstrap-sweetalert";
import {
  getuserhistorybyid,
  updateuserhistorybyid,
} from "../../services/user_history_services";
import {
  getsignupactivationeventsbyid,
  getsignupactivationbytoken,
  insertUserOnBoarding,
} from "../../services/signup_activation_events_services";
import {
  getuserbyid,
  updateuserEmailActivationByID,
} from "../../services/users_services";
//getsignupactivationeventsbyid
import { isEmail } from "validator";
import PhoneInput from "react-phone-input-2";
import initFontAwesome from "../../utils/initFontAwesome";
function SignUpUserOnBoarding() {
  const apiKey = process.env.REACT_APP_APIKEY;
  if (!apiKey) {
    throw new Error("Could  not find a Realm Server API Key.");
  }
  const { ids } = useParams();

  const credentials = Realm.Credentials.apiKey(apiKey);
  const ctechapp = new Realm.App(process.env.REACT_APP_REALM_APP_ID);
  const [loading, setLoading] = useState(false);
  const [initialval, setInitialval] = useState();
  const [token, setToken] = useState();
  const [password, setPassword] = useState({
    password: "",
    confirmpassword: "",
  });
  const [passwordErrors, setPasswordErrors] = useState({
    password: "",
    confirmpassword: "",
  });
  const [isSuccess, setisSuccess] = useState(false);
  const [isFailure, setisFailure] = useState(false);
  const [isUserAlreadyExist, setIsUserAlreadyExist] = useState(false);
  const [istokenexpired, setTokenExpired] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const [validatemsg, SetValidatemsg] = useState("");
  const [isvaliddata, setisvaliddata] = useState(false);
  const [isUserActivation, setIsUserActivation] = useState(false);

  // handle change for business phone
  useEffect(() => {
    Getsignupactivationbytoken();
  }, []);
  async function Getsignupactivationeventsbyid(id) {
    ctechapp.logIn(credentials).then(() => {
      setLoading(true);
      getsignupactivationeventsbyid(
        id,
        ctechapp.currentUser,
        ctechapp.currentUser.id
      ).then((response) => {
        let user_info = JSON.parse(response);
        if (user_info !== undefined) {
          if (user_info.userEmailActivation === true) {
            setIsUserAlreadyExist(true);
          }
          setInitialval(user_info);
        }
        setLoading(false);
      });
      setLoading(false);
    });
  }
  async function Getsignupuserbyid(id) {
    ctechapp.logIn(credentials).then(() => {
      setLoading(true);
      getuserbyid(id, ctechapp.currentUser).then((response) => {
        let user_info = JSON.parse(response);
        if (user_info !== undefined) {
          if (user_info.userEmailActivation === true) {
            setIsUserAlreadyExist(true);
          }
          setInitialval(user_info);
        }
        setLoading(false);
      });
      setLoading(false);
    });
  }

  async function Getsignupactivationbytoken() {
    ctechapp.logIn(credentials).then(() => {
      setLoading(true);
      getsignupactivationbytoken(
        ids,
        ctechapp.currentUser,
        ctechapp.currentUser.id
      ).then((response) => {
        let token = JSON.parse(response);
        if (token !== undefined && token.istokenvalid === "true") {
          setToken(token);
          if (token?.sourceapp?.toLowerCase() === "chargebee")
            Getsignupactivationeventsbyid(token.signupverificationid);
          else if (token?.sourceapp?.toLowerCase() === "projectmaster")
            Getsignupuserbyid(token.signupverificationid);
        } else {
          setTokenExpired(true);
        }
        setLoading(false);
      });
      setLoading(false);
    });
  }
  //insertUserOnBoarding
  async function InsertUserOnBoarding() {
    ctechapp.logIn(credentials).then(() => {
      setLoading(true);
      insertUserOnBoarding(
        token.signupverificationid,
        ctechapp.currentUser,
        ctechapp.currentUser.id
      ).then((response) => {
        let token = JSON.parse(response);
        if (token !== undefined) {
        }
        setLoading(false);
      });
    });
  }

  async function updateUserEmailActivationByID() {
    ctechapp.logIn(credentials).then(() => {
      setLoading(true);
      updateuserEmailActivationByID(
        token.signupverificationid,
        ctechapp.currentUser,
        ctechapp.currentUser.id
      ).then((response) => {
        let token = JSON.parse(response);
        if (token !== undefined) {
        }
        setLoading(false);
      });
    });
  }

  const handleChange = (event) => {
    seterrorMessage("");
    SetValidatemsg("");
    const path = event.target.name.split(".");
    const finalProp = path.pop();
    const newuser = { ...password };
    let pointer = newuser;
    path.forEach((el) => {
      pointer[el] = { ...pointer[el] };
      pointer = pointer[el];
    });
    pointer[finalProp] =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    setPassword(newuser);
    handleFieldValidation(event);
    setisvaliddata(true);
  };
  const handleFieldValidation = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let localerror = "";
    switch (name) {
      case "password":
        localerror =
          value.length < 7 ? "Password must be atleast 8 characters" : "";
        break;
      case "confirmpassword":
        localerror =
          value != password.password
            ? "Password & Re-enter Password are mismatched"
            : "";
      default:
        break;
    }
    setPasswordErrors({ ...passwordErrors, [name]: localerror });
    setisvaliddata(true);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      saveuser();
    }
  };
  async function saveuser() {
    setLoading(true);
    // console.log(JSON.stringify(values));
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var userinfo = {};

    

    if (token?.sourceapp?.toLowerCase() === "chargebee") {

      let phoneformatvalue = initialval.fullDocument.customer?.phone || "";
      phoneformatvalue = phoneformatvalue !== "" ? phoneformatvalue.replaceAll("+", "") : "";

      userinfo = {
        firstname: initialval.fullDocument.customer.first_name,
        lastname: initialval.fullDocument.customer.last_name,
        email: initialval.fullDocument.customer.email,
        phone: phoneformatvalue,
        password: password.confirmpassword,
      };
    } else if (token?.sourceapp?.toLowerCase() === "projectmaster") {
      let phoneformatvalue = initialval?.phone || "";
      phoneformatvalue = phoneformatvalue !== "" ? phoneformatvalue.replaceAll("+", "") : "";

      userinfo = {
        firstname: initialval?.firstName,
        lastname: initialval?.lastName,
        email: initialval?.email,
        phone: phoneformatvalue,
        password: password.confirmpassword,
      };
    }
    var raw = JSON.stringify(userinfo);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch("/createdirectuserbyemail", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        if (result.toUpperCase().includes("[ERROR]:")) {
          // console.log(result);
          var message = result.replace("[error]:", "");
          var errmsg = JSON.parse(message);
          console.log(JSON.stringify(errmsg));
          seterrorMessage(errmsg);
          setisFailure(true);
        } else {
          if (token?.sourceapp?.toLowerCase() === "chargebee") {
            InsertUserOnBoarding();
          } else if (token?.sourceapp?.toLowerCase() === "projectmaster") {
            updateUserEmailActivationByID();
          }

          setisSuccess(true);
        }
      })
      .catch((error) => console.log("error", error));
    setLoading(false);
  }
  // async function savephoneuser() {
  //   setLoading(true);
  //   // console.log(JSON.stringify(values));
  //   var myHeaders = new Headers();
  //   myHeaders.append("Content-Type", "application/json");
  //   var userinfo = {
  //     firstname: initialval.fullDocument.customer.first_name,
  //     lastname: initialval.fullDocument.customer.last_name,
  //     email: initialval.fullDocument.customer.email,
  //     phone: initialval.fullDocument.customer?.phone,
  //     password: password.confirmpassword,
  //   };
  //   var raw = JSON.stringify(userinfo);

  //   var requestOptions = {
  //     method: "POST",
  //     headers: myHeaders,
  //     body: raw,
  //     redirect: "follow",
  //   };

  //   fetch("/createdirectuserbyphone", requestOptions)
  //     .then((response) => response.text())
  //     .then((result) => {
  //       if (result.toUpperCase().includes("[ERROR]:")) {
  //         // console.log(result);
  //         var message = result.replace("[error]:", "");
  //         var errmsg = JSON.parse(message);
  //         //console.log(JSON.stringify(errmsg));
  //         seterrorMessage(errmsg);
  //         setisFailure(true);
  //       } else {
  //         setisSuccess(true);
  //       }
  //     })
  //     .catch((error) => console.log("error", error));
  //   setLoading(false);
  // }
  const handleCreatePassword = () => {
    setIsUserActivation(true);
  };
  async function UpdateUserHistoryByID() {
    ctechapp.logIn(credentials).then(() => {
      setLoading(true);
      updateuserhistorybyid(
        initialval,
        ctechapp.currentUser,
        ctechapp.currentUser.id
      ).then((response) => {
        if (response !== undefined) {
          setisSuccess(true);
        }
      });
      setLoading(false);
    });
  }

  //error handling functions
  const validateForm = () => {
    try {
      let valid = false;
      valid = handleFieldRequiredValidation();
      Object.values(passwordErrors).forEach((val) => {
        if (val.length > 0) valid = false;
      });
      //console.log("valid : ", valid);
      return valid;
    } catch (error) {
      return false;
    }
  };

  const handleFieldRequiredValidation = () => {
    let iserror = "";

    setPasswordErrors({
      ...passwordErrors,
      password:
        password.password === ""
          ? (iserror = "Password required")
          : passwordErrors.password,
      confirmpassword:
        password.confirmpassword === ""
          ? (iserror = "Re-enter password required")
          : passwordErrors.confirmpassword,
    });

    return iserror.length === 0;
  };

  function gotoLogin() {
    window.location.href = "/";
  }

  const handleSucessSweetAlert = () => {
    setisSuccess(false);
    gotoLogin();
  };
  const handleFailureSweetAlert = () => {
    setisFailure(false);
    window.location.href = "/Signin";
  };

  const handleOnInput = (e) => {
    let maxNum = 4;
    if (e.target.value.length > maxNum) {
      e.target.value = e.target.value.slice(0, maxNum);
    }
  };

  return (
    <div>
      {!isUserActivation && !isUserAlreadyExist && (
        <Form>
          <div className="bg-black">
            <div className="container py-5 h-80">
              <div className="row d-flex justify-content-center align-items-center h-100">
                <div className="col-lg-8 col-xl-3">
                  <div className="card rounded-3">
                    <img
                      src="/images/pm_logo.png"
                      className="px-5 mt-5"
                      alt="Logo"
                    />
                    <div className="card-body p-4 p-md-2">
                      {/* <h1 className="text-center">User activation</h1> */}
                      <h2 className="text-center">
                        Thank you for verifying your account!
                      </h2>
                      <p className="text-center mb-0 px-2">
                        Please continue to create a new password for your
                        account
                      </p>

                      <div className="row mb-2 mt-3 px-md-5">
                        <Button
                          className="form-control btn btn-lg"
                          onClick={handleCreatePassword}
                          type="submit"
                        >
                          Create Password
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            {isSuccess && (
              <SweetAlert
                success
                style={{ color: "black" }}
                title="User is added successfully.."
                onConfirm={handleSucessSweetAlert}
              ></SweetAlert>
            )}
          </div>

          <div>
            {isFailure && (
              <SweetAlert
                danger
                style={{ color: "black" }}
                title="User Signup is failed."
                onConfirm={handleFailureSweetAlert}
              >
                {errorMessage}
              </SweetAlert>
            )}
          </div>
        </Form>
      )}
      {isUserActivation && !isUserAlreadyExist && (
        <Form>
          <div className="bg-black">
            <div className="container py-5 h-80">
              <div className="row d-flex justify-content-center align-items-center h-100">
                <div className="col-lg-8 col-xl-3">
                  <div className="card rounded-3">
                    <img
                      src="/images/pm_logo.png"
                      className="px-5 mt-5"
                      alt="Logo"
                    />
                    <div className="card-body p-4 p-md-2">
                      <h2 className="text-center">Welcome</h2>
                      <p className="text-center mb-0">
                        Enter new password below to activate your
                      </p>
                      <p className="text-center mt-0">
                        {" "}
                        Command Technologies Inc account
                      </p>

                      <div className="row mb-4 px-md-5">
                        <input
                          type="password"
                          id="password"
                          name="password"
                          className="form-control"
                          placeholder="New password"
                          value={password.password}
                          onChange={handleChange}
                        />
                        <div style={{ color: "red" }}>
                          {passwordErrors.password}
                        </div>
                      </div>
                      <div className="row mb-4 px-md-5">
                        <input
                          type="password"
                          id="confirmpassword"
                          name="confirmpassword"
                          className="form-control"
                          placeholder="Re-enter new Password"
                          value={password.confirmpassword}
                          onChange={handleChange}
                        />
                        <div style={{ color: "red" }}>
                          {passwordErrors.confirmpassword}
                        </div>
                      </div>

                      <div className="row mb-2 px-md-5">
                        <Button
                          className="form-control btn btn-lg"
                          onClick={handleSubmit}
                          type="submit"
                        >
                          Submit
                        </Button>
                      </div>
                      <div className="row px-md-5">
                        <Button
                          className="form-control btn btn-lg ml-2 mb-4"
                          onClick={() => gotoLogin()}
                        >
                          Cancel
                        </Button>
                      </div>
                      <div className="text-center">
                        {errorMessage !== "" && (
                          <div className="text-danger">{errorMessage}</div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            {isSuccess && (
              <SweetAlert
                success
                style={{ color: "black" }}
                title="User is added successfully.."
                onConfirm={handleSucessSweetAlert}
              ></SweetAlert>
            )}
          </div>

          <div>
            {isFailure && (
              <SweetAlert
                danger
                style={{ color: "black" }}
                title="User Signup is failed."
                onConfirm={handleFailureSweetAlert}
              >
                {errorMessage}
              </SweetAlert>
            )}
          </div>
        </Form>
      )}
      {isUserAlreadyExist && (
        <Form>
          <div className="bg-black">
            <div className="container py-5 h-80">
              <div className="row d-flex justify-content-center align-items-center h-100">
                <div className="col-lg-8 col-xl-3">
                  <div className="card rounded-3">
                    <img
                      src="/images/pm_logo.png"
                      className="px-5 mt-5"
                      alt="Logo"
                    />
                    <div className="card-body p-4 p-md-2">
                      {/* <h1 className="text-center">User activation</h1> */}
                      <h2 className="text-center">Account already verified!</h2>
                      <p className="text-center mb-0 px-2">
                        This account has been already verified, if you need to
                        set a new password please select "Forgot Password" at
                        the login screen
                      </p>

                      <div className="row mb-2 mt-3 px-md-5">
                        <Button
                          className="form-control btn btn-lg"
                          onClick={() => gotoLogin()}
                        >
                          Continue to Log In
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            {isSuccess && (
              <SweetAlert
                success
                style={{ color: "black" }}
                title="User is added successfully.."
                onConfirm={handleSucessSweetAlert}
              ></SweetAlert>
            )}
          </div>

          <div>
            {isFailure && (
              <SweetAlert
                danger
                style={{ color: "black" }}
                title="User Signup is failed."
                onConfirm={handleFailureSweetAlert}
              >
                {errorMessage}
              </SweetAlert>
            )}
          </div>
        </Form>
      )}
      {istokenexpired && (
        <SweetAlert
          danger
          style={{ color: "black" }}
          title="Requested signup URL has been expired, please signup again!"
          onConfirm={handleFailureSweetAlert}
        >
          {errorMessage}
        </SweetAlert>
      )}
    </div>
  );
}
export default SignUpUserOnBoarding;
